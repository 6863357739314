import { isAxiosError } from 'axios'
import { groupBy } from 'lodash'
import { defineStore } from 'pinia'

import {
  addSelection,
  clearSelection,
  createClassificationsSelection,
  createParentSelection,
  deleteElementsSelection,
  listSelection,
  moveSelection,
  removeSelection,
  validateClassificationsSelection,
} from '@/api'
import { errorParser } from '@/helpers'
import type { UUID } from '@/types'

import { useElementStore, useJobsStore, useNotificationStore } from '.'

interface State {
  count: number | null
  /**
   * Selected element IDs grouped by corpus ID
   */
  selection: { [corpusId: UUID]: UUID[] }
}

type SelectableElement = { id: UUID; corpus: { id: UUID } }

export const useSelectionStore = defineStore('selection', {
  state: (): State => ({
    count: null,
    selection: {},
  }),
  actions: {
    /**
     * Mark some elements as selected without making any API request.
     *
     * To actually select some elements through the API, use `select()`.
     */
    add(elements: SelectableElement[]) {
      for (const [corpusId, corpusElements] of Object.entries(
        groupBy(elements, (element) => element.corpus.id),
      )) {
        const elementIds = corpusElements.map((element) => element.id)
        if (Array.isArray(this.selection[corpusId])) {
          // Add to the already selected elements for this corpus, but make sure we don't add duplicates
          this.selection[corpusId] = [...new Set([...this.selection[corpusId], ...elementIds])]
        } else this.selection[corpusId] = elementIds
      }
    },

    async get() {
      const elementStore = useElementStore()
      this.selection = {}
      try {
        let page = 1
        let data
        do {
          data = await listSelection({ page })
          elementStore.bulkSet(data.results)
          this.add(data.results)
          this.count = data.count

          page += 1
        } while (data.next)
      } catch (err) {
        this.selection = {}
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async select(elements: SelectableElement[]) {
      try {
        const returnedElements = await addSelection(elements.map(({ id }) => id))
        /*
         * Make sure the details for these elements are also available in the elements store,
         * as this store only keeps UUIDs and more than that is needed to display them on the selection page
         */
        useElementStore().bulkSet(returnedElements)
        this.add(elements)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async unselect(element: SelectableElement) {
      try {
        await removeSelection(element.id)
      } catch (err) {
        this.selection = {}
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }

      const corpusSelection = this.selection[element.corpus.id]
      if (!corpusSelection?.length) return
      const index = corpusSelection.findIndex((id) => id === element.id)
      if (index < 0) return
      corpusSelection.splice(index, 1)
      if (this.count !== null) this.count--
    },

    async clear() {
      try {
        await clearSelection()
        this.selection = {}
        this.count = 0
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async validateClassifications(corpusId: UUID) {
      try {
        await validateClassificationsSelection(corpusId)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async createClassifications(corpusId: UUID, mlClassId: UUID) {
      try {
        await createClassificationsSelection(corpusId, mlClassId)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async deleteElements(corpusId: UUID) {
      const notificationStore = useNotificationStore()
      try {
        await deleteElementsSelection(corpusId)
        notificationStore.notify({ type: 'success', text: 'Elements deletion has been scheduled.' })
        if (this.count !== null && Array.isArray(this.selection[corpusId]))
          this.count -= this.selection[corpusId].length
        delete this.selection[corpusId]
      } catch (err) {
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      } finally {
        useJobsStore().list()
      }
    },

    async move(corpusId: UUID, destination: UUID) {
      const notificationStore = useNotificationStore()
      try {
        await moveSelection(corpusId, destination)
        notificationStore.notify({ type: 'success', text: 'Elements moving has been scheduled.' })
      } catch (err) {
        const message =
          (isAxiosError(err)
            ? (err.response?.data?.destination ?? err.response?.data?.corpus_id)
            : null) ?? err
        notificationStore.notify({ type: 'error', text: errorParser(message) })
      } finally {
        useJobsStore().list()
      }
    },

    async createParent(corpusId: UUID, parentId: UUID) {
      const notificationStore = useNotificationStore()
      try {
        await createParentSelection(corpusId, parentId)
        notificationStore.notify({ type: 'success', text: 'Element linking has been scheduled.' })
      } catch (err) {
        const message =
          (isAxiosError(err)
            ? (err.response?.data?.parent_id ?? err.response?.data?.corpus_id)
            : null) ?? err
        notificationStore.notify({ type: 'error', text: errorParser(message) })
      } finally {
        useJobsStore().list()
      }
    },
  },
  getters: {
    loaded: (state): number =>
      Object.values(state.selection).reduce((total, array) => total + array.length, 0),
  },
})
