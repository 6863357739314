<template>
  <span>
    <a
      class="icon icon-help has-text-info"
      :class="iconClass"
      v-on:click="openModal"
      v-if="!noIcon"
    ></a>
    <Modal
      :title="title"
      v-model="showModal"
    >
      <div
        class="help-item"
        v-for="(section, index) in data"
        :key="index"
      >
        <div>
          <strong v-if="section.title">{{ section.title }}</strong>
          <!-- eslint-disable vue/no-v-html -->
          <p
            v-for="(line, i) in section.content"
            v-html="line"
            :key="i"
          ></p>
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </Modal>
  </span>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import type { HelpItem } from '@/help'

import Modal from './Modal.vue'

export default defineComponent({
  components: {
    Modal,
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    title: {
      type: String,
      default: 'Help',
    },
    /*
     * The help data to display:
     * [
     *   {
     *     title: string?
     *     content: string[]
     *   },
     *   ...
     * ]
     */
    data: {
      type: Array as PropType<HelpItem[]>,
      required: true,
    },
    // The modal's opened state.
    modelValue: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: '',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showModal: false,
  }),
  methods: {
    openModal() {
      this.showModal = true
    },
  },
  watch: {
    showModal(value: boolean) {
      this.$emit('update:modelValue', value)
    },
    modelValue: {
      handler(value: boolean) {
        this.showModal = value
      },
      immediate: true,
    },
  },
})
</script>

<style lang="scss" scoped>
.help-item:not(:last-child) {
  margin-bottom: 1.5rem;
}
</style>
