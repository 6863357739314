<template>
  <Modal
    title="Create element"
    :model-value="modelValue"
    v-on:update:model-value="$emit('update:modelValue', $event)"
  >
    <form
      v-on:submit.prevent="createElement"
      v-on:keyup.enter="createElement"
    >
      <div class="columns is-compact is-multiline">
        <div class="column is-narrow">
          <figure class="is-inline-block">
            <ElementImage
              class="image is-flex"
              :element="{ ...element, ...annotationStore.selectedElement }"
            />
          </figure>
        </div>

        <div class="column elt-fields">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    :class="{ 'is-danger': fieldErrors.name }"
                    v-model="name"
                    :maxlength="ELEMENT_NAME_MAX_LENGTH"
                    :placeholder="annotationStore.defaultName(element.id, type)"
                  />
                  <template v-if="fieldErrors.name">
                    <p
                      class="help is-danger"
                      v-for="err in fieldErrors.name"
                      :key="err"
                    >
                      {{ err }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Type</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <div
                    class="control"
                    title="Filter by type"
                  >
                    <span
                      class="select is-fullwidth"
                      :class="{ 'is-danger': fieldErrors.type }"
                    >
                      <select
                        ref="typeSelect"
                        v-model="type"
                      >
                        <option
                          value=""
                          disabled
                          selected
                        >
                          Type…
                        </option>
                        <option
                          v-for="t in sortedTypes"
                          :key="t.slug"
                          :value="t.slug"
                        >
                          {{ truncateSelect(t.display_name) }}
                        </option>
                      </select>
                    </span>
                    <template v-if="fieldErrors.type">
                      <p
                        class="help is-danger"
                        v-for="err in fieldErrors.type"
                        :key="err"
                      >
                        {{ err }}
                      </p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Class</label>
            </div>
            <div class="field-body control">
              <MLClassSelect
                v-if="corpusId"
                v-model="classId"
                class="is-full-width"
                v-model:is-valid="validClassification"
                placeholder="Class…"
                :corpus-id="corpusId"
                allow-empty
                auto-select
                is-fixed
                v-on:submit="createElement"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <template v-slot:footer="{ close }">
      <button
        class="button"
        v-on:click="close"
      >
        Cancel
      </button>
      <button
        class="button is-success"
        :class="{ 'is-loading': loading }"
        :disabled="loading || !isValid || undefined"
        :title="
          isValid
            ? 'Create the sub-element'
            : 'A valid type and class are required to create the element'
        "
        v-on:click="createElement"
      >
        Create
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { orderBy } from 'lodash'
import { mapActions, mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import ElementImage from '@/components/Image/ElementImage.vue'
import MLClassSelect from '@/components/MLClassSelect.vue'
import Modal from '@/components/Modal.vue'
import { ELEMENT_NAME_MAX_LENGTH } from '@/config'
import { errorParser } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAnnotationStore, useNotificationStore } from '@/stores'
import type { Element, ElementBase, UUID } from '@/types'

export default defineComponent({
  mixins: [truncateMixin, corporaMixin],
  components: {
    ElementImage,
    MLClassSelect,
    Modal,
  },
  emits: ['update:modelValue'],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    ELEMENT_NAME_MAX_LENGTH,
    loading: false,
    type: '',
    classId: '',
    // Do not allow form validation on invalid class input
    validClassification: true,
    name: '',
    // API fields validation errors
    fieldErrors: {} as { name?: string[]; type?: string[] },
  }),
  mounted() {
    ;(this.$refs.typeSelect as HTMLSelectElement).focus()
  },
  computed: {
    ...mapStores(useAnnotationStore),
    corpusId(): UUID | null {
      // Corpus ID for corporaMixin
      return this.element.corpus?.id ?? null
    },
    sortedTypes() {
      if (!this.corpus?.types) return []
      return orderBy(this.corpus.types, [(t) => t.display_name.toLowerCase(), (t) => t.slug])
    },
    isValid() {
      return this.type && this.validClassification
    },
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    async createElement() {
      if (
        this.loading ||
        !this.corpusId ||
        !this.isValid ||
        !this.element.zone ||
        !this.annotationStore.selectedElement?.zone?.polygon
      )
        return
      if (!this.type) {
        this.fieldErrors = { type: ['A type is required'] }
        return
      }
      this.loading = true
      this.fieldErrors = {}
      try {
        await this.annotationStore.create({
          corpus: this.corpusId,
          name: this.name || this.annotationStore.defaultName(this.element.id, this.type),
          type: this.type,
          parent: this.element.id,
          image: this.element.zone.image.id,
          polygon: this.annotationStore.selectedElement.zone.polygon,
          classId: this.classId,
        })
        this.notify({ type: 'success', text: 'Element created.' })
        // Close modal in case of success
        this.$emit('update:modelValue', false)
      } catch (err) {
        if (isAxiosError(err) && (err.response?.data?.name || err?.response?.data?.type)) {
          this.fieldErrors = {
            name: err.response.data.name,
            type: err.response.data.type,
          }
        }
        this.notify({
          type: 'error',
          text: `An error occurred during element creation: ${errorParser(err)}`,
        })
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    defaultType: {
      immediate: true,
      handler() {
        if (!this.corpusId) this.classId = ''
        else this.type = this.annotationStore.defaultType[this.corpusId] || ''
      },
    },
    defaultClass: {
      immediate: true,
      handler() {
        if (!this.corpusId) this.classId = ''
        else this.classId = this.annotationStore.defaultClass[this.corpusId] || ''
      },
    },
    type() {
      delete this.fieldErrors.type
    },
  },
})
</script>

<style lang="scss" scoped>
.image {
  height: 15rem;
  max-width: 30rem;
}
.elt-fields {
  margin: auto;
  max-width: 25rem;
  & > .field {
    margin-bottom: 0.2rem;
  }
  & ::placeholder {
    color: #202020;
  }
}
.is-full-width {
  width: 100%;
}
.columns .is-compact {
  margin-bottom: 0;
  & > .column {
    padding-bottom: 0;
  }
}
</style>
