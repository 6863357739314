<template>
  <div class="columns mt-0 mr-0">
    <div class="column">
      {{ classification.ml_class.name }}
      <i
        v-if="classification.high_confidence"
        title="High confidence classification"
        class="icon-award has-text-warning"
      ></i>
    </div>
    <div class="column is-narrow is-paddingless">
      <ConfidenceTag :value="classification.confidence" />
      <div class="tags has-addons ml-1">
        <span
          :disabled="disabled || loading || null"
          class="tag button icon-check"
          :class="{ 'is-success': validated, 'is-loading': loading }"
          v-on:click="validate"
        ></span>
        <span
          :disabled="disabled || loading || null"
          class="tag button"
          :class="{
            'is-loading': loading,
            'is-danger': rejected,
            'icon-trash has-text-danger': isManual,
            'is-delete': !isManual,
          }"
          v-on:click="reject"
        ></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import ConfidenceTag from '@/components/ConfidenceTag.vue'
import { CLASSIFICATION_STATES } from '@/config'
import { useClassificationStore } from '@/stores'
import type { Classification, UUID } from '@/types'

export default defineComponent({
  components: {
    ConfidenceTag,
  },
  props: {
    classification: {
      type: Object as PropType<Classification>,
      required: true,
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapStores(useClassificationStore),
    validated() {
      return this.classification.state === CLASSIFICATION_STATES.validated
    },
    rejected() {
      return this.classification.state === CLASSIFICATION_STATES.rejected
    },
    isManual() {
      return !this.classification.worker_run
    },
  },
  methods: {
    async validate() {
      if (this.validated || this.disabled || this.loading) return
      this.loading = true
      try {
        await this.classificationStore.validate(this.classification.id, this.elementId)
      } finally {
        this.loading = false
      }
    },
    async reject() {
      if (this.rejected || this.disabled || this.loading) return
      this.loading = true
      try {
        await this.classificationStore.reject(this.classification.id, this.elementId)
      } finally {
        this.loading = false
      }
    },
  },
})
</script>

<style scoped>
.column {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.button.is-danger.tag.is-delete:hover,
.button.is-danger.tag.is-delete:focus {
  background-color: #ff3529;
}
.button.icon-trash {
  width: 2em;
}
</style>
