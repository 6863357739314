<template>
  <div
    class="loader m-auto is-size-3"
    v-if="datasetSets === null"
  ></div>
  <div
    class="message-body has-text-grey"
    v-else-if="datasetSets.length === 0"
  >
    No datasets
  </div>
  <table
    v-else
    class="table is-fullwidth is-hoverable"
  >
    <thead>
      <tr>
        <th>Name</th>
        <th>Set</th>
        <th class="has-text-right">Navigation</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="elementset in datasetSets"
        :key="elementset.dataset.id + elementset.set"
      >
        <Row
          :corpus-id="corpusId"
          :element-set="elementset"
          :element="element"
        />
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { useElementStore } from '@/stores'
import type { Element, ElementBase } from '@/types'
import type { ElementDatasetSet } from '@/types/dataset'

import Row from './Row.vue'

export default defineComponent({
  components: {
    Row,
  },
  props: {
    corpusId: {
      type: String,
      required: true,
    },
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
  },
  computed: {
    ...mapState(useElementStore, ['elementDatasetSets']),
    datasetSets(): ElementDatasetSet[] | null {
      return this.elementDatasetSets?.[this.element.id] ?? null
    },
  },
})
</script>
