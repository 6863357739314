<template>
  <span :title="'original date: ' + rawDate">
    <template v-if="lower && upper"> From {{ display(lower) }} to {{ display(upper) }} </template>
    <template v-else-if="lower"> After {{ display(lower) }} </template>
    <template v-else-if="upper"> Before {{ display(upper) }} </template>
    <span
      v-else
      v-for="(date, index) in dates"
      :key="index"
    >
      {{ display(date) }}
      <template v-if="dates.length > 1"> [{{ date.type }}]</template>
    </span>
  </span>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import { MONTHS } from '@/config'
import type { InterpretedDate } from '@/types/metadata'

export default defineComponent({
  props: {
    dates: {
      type: Array as PropType<InterpretedDate[]>,
      required: true,
    },
    rawDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    upper() {
      return this.dates.find((d) => d.type === 'upper')
    },
    lower() {
      return this.dates.find((d) => d.type === 'lower')
    },
  },
  methods: {
    display(date: InterpretedDate): string {
      return `${date.year}${date.month || date.day ? ', ' : ''}${date.month ? MONTHS[date.month - 1] : ''}${date.day ? ' ' + date.day : ''}`
    },
  },
})
</script>
