import axios from 'axios'

import { unique } from '@/helpers/api'
import type { Classification, UUID } from '@/types'

/*
 * Only manual classifications can be created from the frontend
 * Thus, worker_run_id, confidence and high_confidence parameters are ignored
 */
interface ClassificationCreatePayload {
  ml_class: UUID
  element: UUID
}

export type ClassificationCreateResponse = Omit<Classification, 'ml_class' | 'worker_run'> &
  ClassificationCreatePayload & { worker_run_id: UUID | null }

// Create a classification.
export const createClassification = async (
  classification: ClassificationCreatePayload,
): Promise<ClassificationCreateResponse> =>
  (await axios.post('/classifications/', classification)).data

// Validate a classification.
export const validateClassification = unique(
  async (id: UUID) => await axios.put(`/classifications/${id}/validate/`),
)

/**
 * Reject a classification.
 * In case the classification has been manually annotated, it will be deleted (HTTP 204_NO_CONTENT)
 */
export const rejectClassification = unique(
  async (id: UUID) => await axios.put(`/classifications/${id}/reject/`),
)
