import { defineStore } from 'pinia'

import { type ImageElementsParameters, listImageElements } from '@/api'
import { errorParser } from '@/helpers'
import type { ElementTiny, PageNumberPagination, UUID } from '@/types'

import { useNotificationStore } from '.'

export interface ImageElements extends PageNumberPagination<ElementTiny> {
  imageId: UUID
}

interface State {
  elements: ImageElements | null
}

export const useImageStore = defineStore('image', {
  state: (): State => ({
    elements: null,
  }),
  actions: {
    async listElements(payload: ImageElementsParameters) {
      if (!payload.id) throw new Error('Missing image ID in listElements')
      try {
        const data = await listImageElements(payload)
        this.setElements(payload.id, data)
      } catch (err) {
        const notificationStore = useNotificationStore()
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      }
    },
    setElements(imageId: UUID, data: PageNumberPagination<ElementTiny>) {
      this.elements = {
        ...data,
        imageId,
      }
    },
  },
})
