import axios from 'axios'

import { unique } from '@/helpers/api'
import type { ElementTiny, PageNumberPagination, UUID } from '@/types'
import type { Entity, EntityLight, TranscriptionEntity } from '@/types/entity'

import type { PageNumberPaginationParameters } from '.'

// Retrieve an entity
export const retrieveEntity = unique(
  async (id: UUID): Promise<Entity> => (await axios.get(`/entity/${id}/`)).data,
)

export interface CorpusEntitiesListParameters extends PageNumberPaginationParameters {
  name?: string
  parent?: UUID
}

// List entities in a corpus
export const listCorpusEntities = unique(
  async (
    id: UUID,
    params: CorpusEntitiesListParameters = {},
  ): Promise<PageNumberPagination<EntityLight>> =>
    (await axios.get(`/corpus/${id}/entities/`, { params })).data,
)

// List all elements linked to an entity
export const listEntityElements = unique(
  async (
    id: UUID,
    params: PageNumberPaginationParameters = {},
  ): Promise<PageNumberPagination<ElementTiny>> =>
    (await axios.get(`/entity/${id}/elements/`, { params })).data,
)

interface TranscriptionEntityListParameters extends PageNumberPaginationParameters {
  entity_worker_run?: UUID | false
  entity_worker_version?: UUID | false
  worker_run?: UUID | false
  worker_version?: UUID | false
}

// List all entities linked to a transcription
export const listTranscriptionEntities = unique(
  async (
    id: UUID,
    params: TranscriptionEntityListParameters = {},
  ): Promise<PageNumberPagination<TranscriptionEntity>> =>
    (await axios.get(`/transcription/${id}/entities/`, { params })).data,
)
