import axios from 'axios'

import { unique } from '@/helpers/api'
import type { MLClass, PageNumberPagination, UUID } from '@/types'

import type { PageNumberPaginationParameters } from '.'

export interface ListCorpusMLClassesParameters extends PageNumberPaginationParameters {
  /**
   * Filter MLClasses by case-insensitive name.
   */
  search?: string
}

/**
 * List ML classes on a corpus.
 */
export const listCorpusMLClasses = unique(
  async (
    corpusId: UUID,
    params: ListCorpusMLClassesParameters,
  ): Promise<PageNumberPagination<MLClass>> =>
    (await axios.get(`/corpus/${corpusId}/classes/`, { params })).data,
)

/**
 * Retrieve an MLClass on a corpus.
 * @param corpusId ID of the corpus to retrieve a class on.
 * @param id ID of the MLClass.
 */
export const retrieveMLClass = unique(
  async (corpusId: UUID, id: UUID): Promise<MLClass> =>
    (await axios.get(`/corpus/${corpusId}/classes/${id}/`)).data,
)

/**
 * Create an MLClass on a corpus.
 * @param corpusId ID of the corpus to create a class on.
 * @param data Attributes of the new MLClass.
 */
export const createMLClass = async (corpusId: UUID, data: Omit<MLClass, 'id'>): Promise<MLClass> =>
  (await axios.post(`/corpus/${corpusId}/classes/`, data)).data

/**
 * Update an existing MLClass on a corpus.
 * @param corpusId ID of the corpus of the MLClass to update.
 * @param classId ID of the MLClass to update.
 * @param data Attributes to update on the MLClass.
 */
export const updateMLClass = async (
  corpusId: UUID,
  classId: UUID,
  data: Partial<Omit<MLClass, 'id'>>,
): Promise<MLClass> => (await axios.patch(`/corpus/${corpusId}/classes/${classId}/`, data)).data

/**
 * Delete an MLClass.
 * @param corpusId ID of the corpus of the MLClass to delete.
 * @param classId ID of the MLClass to delete.
 */
export const deleteMLClass = async (corpusId: UUID, classId: UUID) =>
  await axios.delete(`/corpus/${corpusId}/classes/${classId}/`)
