<template>
  <div class="tags">
    <ConfidenceTag
      :value="transcription.confidence"
      v-if="transcription.confidence && Number.isFinite(transcription.confidence)"
    />
    <div class="tags has-addons mb-0">
      <span
        v-if="transcription.worker_run"
        class="tag button px-1 icon-copy has-text-primary"
        :class="{ 'is-loading': loading }"
        :disabled="!canCopy || loading || undefined"
        title="Copy this transcription to a manual transcription"
        v-on:click="copyTranscription"
      ></span>
      <span
        v-else
        class="tag button px-1 icon-edit has-text-primary"
        :class="{ 'is-loading': loading }"
        :disabled="!canEdit || loading || undefined"
        title="Edit this transcription"
        v-on:click="editTranscription"
      ></span>
      <span
        class="tag button px-1 icon-trash has-text-danger"
        :class="{ 'is-loading': loading }"
        :disabled="!canDelete || loading || undefined"
        title="Delete this transcription"
        v-on:click="confirmDeleteModal = canDelete && !loading"
      ></span>
    </div>
    <Modal
      v-model="confirmDeleteModal"
      title="Delete transcription"
    >
      <div>Are you sure you want to delete this transcription?</div>
      <template v-slot:footer="{ close }">
        <button
          class="button"
          v-on:click="close"
        >
          Cancel
        </button>
        <button
          class="button is-danger"
          :class="{ 'is-loading': loading }"
          :disabled="loading || undefined"
          v-on:click.prevent="deleteTranscription"
        >
          Delete
        </button>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import ConfidenceTag from '@/components/ConfidenceTag.vue'
import Modal from '@/components/Modal.vue'
import { errorParser } from '@/helpers'
import { corporaMixin } from '@/mixins'
import { useEntityStore, useNotificationStore, useTranscriptionStore } from '@/stores'
import type { Element, ElementBase, UUID } from '@/types'
import type { Transcription } from '@/types/transcription'

export default defineComponent({
  emits: ['edit'],
  mixins: [corporaMixin],
  components: {
    ConfidenceTag,
    Modal,
  },
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
    transcription: {
      type: Object as PropType<Transcription>,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    confirmDeleteModal: false,
  }),
  computed: {
    ...mapState(useEntityStore, ['inTranscription']),
    corpusId(): UUID | null {
      return this.element.corpus?.id ?? null
    },
    /**
     * Only allow copying transcriptions on a writable corpus
     */
    canCopy(): boolean {
      return this.canWrite(this.corpus)
    },
    canEdit(): boolean {
      // Only allow editing a transcription without a worker run on a writable corpus
      if (!this.corpus || !this.canWrite(this.corpus) || this.transcription.worker_run) return false
      /*
       * Only allow transcriptions without entities.
       * We do not call the entity list actions here as this is already done in DetailsPanel and doing so here
       * could result in list calls being made twice, which would cause even more issues due to the pagination.
       * When a transcription entity list call is made, inTranscription[transcription.id] is available and has a
       * count of null; we can assume in this case that we can just wait for it to be loaded, then we can properly
       * check. But when nothing is started, we do not know if the call has been made or if it will be made by the
       * transcription components in the DetailsPanel, so we allow by default.
       */
      if (!this.inTranscription[this.transcription.id]) return true
      return this.inTranscription[this.transcription.id]?.count === 0
    },
    /**
     * Allow deleting manual transcriptions on corpora with write access
     * and worker transcriptions on corpora with admin access
     */
    canDelete(): boolean {
      return (
        this.corpus !== null &&
        this.canWrite(this.corpus) &&
        (!this.transcription.worker_run || this.canAdmin(this.corpus))
      )
    },
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useTranscriptionStore, ['create', 'delete']),
    editTranscription() {
      // Emit an event to let the parent component know it should trigger its edition mode
      if (this.canEdit) this.$emit('edit')
    },

    async deleteTranscription() {
      if (this.loading) return
      if (!this.canWrite(this.corpus)) {
        this.notify({
          type: 'error',
          text: 'A write right on the project is required to delete a transcription',
        })
        return
      }
      if (this.transcription.worker_run && !this.canAdmin(this.corpus)) {
        this.notify({
          type: 'error',
          text: 'An admin right on the project is required to delete a transcription with a worker run',
        })
        return
      }
      try {
        this.loading = true
        await this.delete(this.element.id, this.transcription.id)
        this.notify({ type: 'success', text: 'Transcription deleted.' })
      } catch (e) {
        this.notify({
          type: 'error',
          text: `An error occurred during transcription deletion: ${errorParser(e)}`,
        })
      } finally {
        this.loading = false
      }
    },

    async copyTranscription() {
      if (!this.canCopy) {
        this.notify({
          type: 'error',
          text: 'A write right on the project is required to copy a transcription',
        })
        return
      }
      try {
        this.loading = true
        await this.create(this.element.id, {
          text: this.transcription.text,
        })
        this.notify({ type: 'success', text: 'Transcription copied to a manual transcription' })
      } catch (err) {
        this.notify({ type: 'error', text: `Failed to copy transcription text: ${err}` })
      } finally {
        this.loading = false
      }
    },
  },
})
</script>

<style scoped>
.tags {
  flex-wrap: nowrap;
}
.tag {
  margin-bottom: 0;
}
</style>
