<template>
  <polygon
    v-bind="svgProps"
    v-on:mouseover="hover"
    v-on:mouseleave="unhover"
    v-on:mouseup.left="click"
    :class="{ 'is-hovered': active && isHovered }"
  >
    <title>{{ element.name }}</title>
  </polygon>
</template>

<script lang="ts">
import { mapState, mapWritableState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { DEFAULT_POLYGON_COLOR } from '@/config'
import { svgPolygon } from '@/helpers'
import { useAnnotationStore } from '@/stores'
import type { Element, ElementBase } from '@/types'

export default defineComponent({
  emits: ['select'],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
    /**
     * Color to display the polygon with.
     * By default, this component will display the polygon using the colors defined in js/config.
     */
    color: {
      type: String,
      default: null,
    },
    /**
     * When disabled, this component does not react to mouse events and cannot be hovered or selected.
     */
    active: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(useAnnotationStore, ['enabled', 'selectedElement', 'batchDeletion']),
    ...mapWritableState(useAnnotationStore, ['hoveredId']),
    isHovered(): boolean {
      return !this.isSelected && this.hoveredId === this.element.id
    },
    isDeleteTarget(): boolean {
      return this.enabled && this.batchDeletion && this.isHovered
    },
    isSelected(): boolean {
      return this.selectedElement?.id === this.element.id
    },
    eltColor(): string {
      // Return the element color
      if (this.color) return this.color
      else return DEFAULT_POLYGON_COLOR
    },
    highlighted(): string | boolean | null {
      // Use CSS highlighting on select and hover
      return this.active && (this.isHovered || this.isSelected)
    },
    svgProps() {
      if (!this.element.zone) return
      return {
        points: this.svgPolygon(this.element.zone.polygon),
        stroke: this.isDeleteTarget ? 'red' : this.eltColor,
        fill: this.isDeleteTarget ? 'red' : this.eltColor,
        cursor: 'pointer',
        'stroke-opacity': 1,
        'fill-opacity': this.highlighted ? 0.5 : 0.2,
        'stroke-width': this.highlighted ? 2 : 1,
        'vector-effect': 'non-scaling-stroke',
      }
    },
  },
  methods: {
    svgPolygon,
    hover() {
      if (this.active && !this.isHovered) this.hoveredId = this.element.id
    },
    unhover() {
      if (this.active) this.hoveredId = null
    },
    click() {
      if (this.active) this.$emit('select', this.element.id)
    },
  },
})
</script>
