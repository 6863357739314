import axios, { type AxiosRequestConfig } from 'axios'

import { unique } from '@/helpers/api'
import type {
  Classification,
  CorpusLight,
  Element,
  ElementBase,
  ElementLight,
  PageNumberPagination,
  Polygon,
  TextOrientation,
  UUID,
} from '@/types'

import type { PageNumberPaginationParameters } from '.'

/**
 * Build string versions of the list filters.
 * We only really end up sending strings in API requests and manipulating strings in queries,
 * so using only strings make the filter bar simpler and more reliable.
 */
type StringifyListParameters<T> = {
  /*
   * For boolean filters, we allow either true or false, and TypeScript can enforce this so it stays as strict as a boolean
   * For everything else, if the filter is already a string, use it directly. This allows string enums like ConfidenceOperator to use their stricter typing.
   */
  [key in keyof T]: T[key] extends boolean | undefined
    ? 'true' | 'false'
    : T[key] extends string | undefined
      ? T[key]
      : string
}

export type ConfidenceOperator = 'eq' | 'lt' | 'lte' | 'gt' | 'gte'

export type ElementOrdering = 'name' | 'created' | 'random' | TextOrientation | 'position'

export type OrderDirection = 'asc' | 'desc'

type BaseElementListParameters = StringifyListParameters<
  PageNumberPaginationParameters & {
    id: UUID
    class_id?: UUID
    classification_confidence?: number
    classification_confidence_operator?: ConfidenceOperator
    classification_high_confidence?: boolean
    confidence?: number
    confidence_operator?: ConfidenceOperator
    creator_email?: string
    folder?: boolean
    metadata_name?: string
    metadata_operator?: ConfidenceOperator | 'contains'
    metadata_value?: string
    mirrored?: boolean
    modifiedSince?: string
    name?: string
    order?: ElementOrdering
    order_direction?: OrderDirection
    rotation_angle?: number
    transcription_confidence?: number
    transcription_confidence_operator?: ConfidenceOperator
    transcription_worker_run?: UUID
    transcription_worker_version?: UUID
    type?: string
    with_classes?: boolean
    with_corpus?: boolean
    with_has_children?: boolean
    with_metadata?: boolean
    with_zone?: boolean
    worker_run?: UUID
    worker_version?: UUID
  }
>

export type ElementListParameters = BaseElementListParameters &
  StringifyListParameters<{
    top_level?: boolean
    order?: Exclude<ElementOrdering, 'position'>
  }>

export type ElementParentListParameters = BaseElementListParameters &
  StringifyListParameters<{
    recursive?: boolean
    order?: Exclude<ElementOrdering, 'position'>
  }>

export type ElementChildrenListParameters = BaseElementListParameters &
  StringifyListParameters<{
    recursive?: boolean
  }>

interface ElementDestroyParameters
  extends Pick<
    ElementListParameters,
    'id' | 'folder' | 'mirrored' | 'name' | 'rotation_angle' | 'top_level' | 'type' | 'worker_run'
  > {
  delete_children?: boolean
}

interface ElementChildrenDestroyParameters
  extends Pick<
    ElementChildrenListParameters,
    'id' | 'folder' | 'mirrored' | 'name' | 'rotation_angle' | 'recursive' | 'type' | 'worker_run'
  > {
  delete_children?: boolean
}

export interface ApiElementList extends ElementBase {
  classifications: Classification[] | null
}

export interface ApiElement extends Element {
  classifications: Classification[]
}

/**
 * Generic method for element list endpoints (ListElements, ListElementParents, ListElementChildren)
 * with support for `If-Modified-Since`. May return null if the API returns HTTP 304 Not Modified.
 */
async function elementList<
  T extends Omit<BaseElementListParameters, 'id'> | { with_corpus?: 'true' },
>(url: string, payload: T): Promise<PageNumberPagination<ApiElementList & { corpus: CorpusLight }>>
async function elementList<
  T extends Omit<BaseElementListParameters, 'id'> | { modifiedSince?: undefined },
>(url: string, payload: T): Promise<PageNumberPagination<ApiElementList>>
async function elementList<T extends Omit<BaseElementListParameters, 'id'>>(
  url: string,
  payload: T,
): Promise<PageNumberPagination<ApiElementList> | null> {
  const { modifiedSince, ...params } = payload
  const config: AxiosRequestConfig = { params }
  if (modifiedSince) {
    config.headers = { 'If-Modified-Since': modifiedSince, 'Cache-Control': 'no-cache' }
    // Axios treats HTTP 3xx as errors by default because the browser is supposed to handle them
    config.validateStatus = (status) => (status >= 200 && status < 300) || status === 304
  }
  const resp = await axios.get(url, config)
  if (resp.status === 304) return null
  return resp.data
}

// List all elements.
export const listElements = unique(({ id, ...payload }: ElementListParameters) =>
  elementList(`/corpus/${id}/elements/`, payload),
)

// List children elements of an element.
export const listElementChildren = unique(({ id, ...payload }: ElementChildrenListParameters) =>
  elementList(`/elements/${id}/children/`, payload),
)

// List parents elements of an element.
export const listElementParents = unique(({ id, ...payload }: ElementParentListParameters) =>
  elementList(`/elements/${id}/parents/`, payload),
)

// Delete elements asynchronously
export const deleteElements = unique(({ id, ...params }: ElementDestroyParameters) =>
  axios.delete(`/corpus/${id}/elements/`, { params }),
)

// Delete children elements asynchronously
export const deleteElementChildren = unique(({ id, ...params }: ElementChildrenDestroyParameters) =>
  axios.delete(`/elements/${id}/children/`, { params }),
)

export interface ElementNeighbor {
  ordering: number
  previous: ElementLight | null
  next: ElementLight | null
  /**
   * List of parent elements, ending with the most direct parent of this element.
   *
   * When the path in the database refers to elements that no longer exist ("ghost elements"), this path might contain `null` values.
   * An error notification should be displayed to the user to help in troubleshooting those paths.
   */
  path: (ElementLight | null)[]
}

// List an element neighbors
export const listElementNeighbors = unique(
  async (id: UUID): Promise<ElementNeighbor[]> =>
    (await axios.get(`/elements/${id}/neighbors/`)).data,
)

// Retrieve an element.
export const retrieveElement = unique(
  async (id: UUID): Promise<ApiElement> => (await axios.get(`/element/${id}/`)).data,
)

export interface ElementCreate {
  type: string
  name: string
  corpus: UUID
  image?: UUID
  parent?: UUID
  polygon?: Polygon
  rotation_angle?: number
  mirrored?: boolean
  confidence?: number
  worker_run_id?: UUID | null
}

// Create an element.
export const createElement = async (data: ElementCreate): Promise<ApiElement> =>
  (await axios.post('/elements/create/', data)).data

export type ElementUpdate = Partial<
  Pick<
    ElementCreate,
    'type' | 'name' | 'image' | 'polygon' | 'confidence' | 'mirrored' | 'rotation_angle'
  >
>

// Update an element.
export const updateElement = async (id: UUID, data: ElementUpdate): Promise<ApiElement> =>
  (await axios.patch(`/element/${id}/`, data)).data

interface ElementDeleteParameters {
  delete_children?: boolean
}

// Delete an element.
export const deleteElement = unique(
  async (id: UUID, params: ElementDeleteParameters) =>
    (await axios.delete(`/element/${id}/`, { params })).data,
)

export interface MoveElementPayload {
  source: UUID
  destination: UUID
}

// Move an element to a destination folder.
export const moveElement = async (data: MoveElementPayload): Promise<MoveElementPayload> =>
  (await axios.post('/element/move/', data)).data

// Link an element to a new folder.
export const createParentElement = async (childId: UUID, parentId: UUID): Promise<unknown> =>
  (await axios.post(`/element/${childId}/parent/${parentId}/`)).data
