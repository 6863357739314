<template>
  <aside class="menu">
    <a
      v-if="interactive"
      class="is-pulled-right is-inline-flex has-text-link"
      :title="`${displayTitle} visible elements from the tree`"
      v-on:click="toggle"
    >
      {{ displayTitle }}
      <span
        class="icon-eye"
        :class="{ strikethrough: imageShow }"
      ></span>
    </a>
    <ul class="tree">
      <li title="Filter children by type">
        <div class="select is-small">
          <select v-model="typeFilter">
            <option :value="null">Filter by type…</option>
            <option
              v-for="t in orderedTypes"
              :key="t.slug"
              :value="t.slug"
              :title="t.display_name"
            >
              {{ truncateShort(t.display_name) }}
              ({{ t.treeCount }}/{{ flatTree.length - 1 }})
            </option>
          </select>
        </div>
      </li>
      <li
        v-if="workerRunFilterValues.length > 1"
        title="Filter children by worker run"
      >
        <div class="select is-small">
          <select v-model="workerFilter">
            <option :value="null">Filter by worker run…</option>
            <option
              v-for="v in workerRunFilterValues"
              :key="v.id"
              :value="v.id"
              :title="v.name"
            >
              {{ truncateShort(`${v.name}`) }}
              ({{ v.treeCount }}/{{ flatTree.length - 1 }})
            </option>
          </select>
        </div>
      </li>
      <TreeItem
        :node="filteredChildrenTree"
        :interactive="interactive"
        :parent-id="element.id"
        v-on:edit="edit"
        v-on:transcribe="transcribe"
      />
    </ul>
    <EditionForm
      v-if="editionModal && selectedElement"
      v-model="editionModal"
      :element="selectedElement"
    />
    <TranscriptionsModal
      v-if="transcriptionModal && selectedElement"
      v-model:modal="transcriptionModal"
      :element="selectedElement"
    />
  </aside>
</template>

<script lang="ts">
import { orderBy } from 'lodash'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import EditionForm from '@/components/Element/EditionForm.vue'
import TranscriptionsModal from '@/components/Element/Transcription/Modal.vue'
import { MANUAL_WORKER_VERSION } from '@/config'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAnnotationStore, useDisplayStore, useTreeStore } from '@/stores'
import type { Element, ElementBase, Tree, UUID } from '@/types'

import TreeItem from './TreeItem.vue'

export default defineComponent({
  components: {
    TreeItem,
    EditionForm,
    TranscriptionsModal,
  },
  mixins: [corporaMixin, truncateMixin],
  props: {
    element: {
      // Base element
      type: Object as PropType<ElementBase | Element>,
      required: true,
    },
    interactive: {
      // Make tree interactive to visualize sub-elements or transcriptions
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedElement: null as Element | ElementBase | null,
    editionModal: false,
    transcriptionModal: false,
  }),
  computed: {
    ...mapState(useDisplayStore, ['imageShow']),
    ...mapWritableState(useTreeStore, ['typeFilter', 'workerFilter']),
    corpusId(): UUID | null {
      return this.element.corpus?.id || null
    },
    childrenTree(): Tree {
      return this.tree(this.element)
    },
    filteredChildrenTree(): Tree {
      if (!this.typeFilter && !this.workerFilter) return this.childrenTree
      // Default to an empty tree when there are no children found with the applied filters
      return (
        this.filterTree(this.childrenTree) ?? {
          element: this.element,
          expanded: true,
          children: [],
        }
      )
    },
    flatTree() {
      // Return a flat list of all elements on the tree
      return this.flatten(this.childrenTree)
    },
    flatFilteredTree() {
      // Return a flat list of all elements on the tree
      return this.flatten(this.filteredChildrenTree)
    },
    flatFilteredTreeIds() {
      return this.flatFilteredTree.map((e) => e.id)
    },
    treeLengths() {
      return { fullTree: this.flatTree.length, filteredTree: this.flatFilteredTreeIds.length }
    },
    displayTitle() {
      return this.imageShow ? 'Hide' : 'Display'
    },
    modal() {
      return this.editionModal || this.transcriptionModal
    },
    orderedTypes() {
      /*
       * Return a list of types ordered by name and with the count of
       * corresponding elements in the tree. The main element is excluded.
       */
      if (!this.corpus?.types) return []
      // Count types in the tree except for the main element
      const types = this.flatTree.reduce(
        (obj, elt) => {
          if (elt.id === this.element.id) return obj
          obj[elt.type] = (obj[elt.type] || 0) + 1
          return obj
        },
        {} as { [type: string]: number },
      )
      // Add the selected type filter to available types even if no element in the tree have this type
      if (this.typeFilter && !Object.keys(types).includes(this.typeFilter)) {
        types[this.typeFilter] = 0
      }
      // Return ordered types with their props (e.g. display name) and elements count in the tree
      return orderBy(
        Object.entries(types).map(([typeSlug, treeCount]) => ({
          ...(this.corpus?.types[typeSlug] || {}),
          treeCount,
        })),
        (t) => t.display_name,
        ['asc'],
      )
    },
    /**
     * Return the worker run UUIDs with the corresponding display names and element counts, ordered by UUID.
     */
    workerRunFilterValues(): { id: UUID; name: string; treeCount: number }[] {
      const namedFilters = this.flatTree.reduce(
        (obj, elt) => {
          if (elt.id === this.element.id) return obj
          if ('worker_run' in elt && elt.worker_run)
            obj[elt.worker_run.id] = {
              name: elt.worker_run.summary,
              treeCount: (obj[elt.worker_run.id]?.treeCount || 0) + 1,
            }
          else
            obj[MANUAL_WORKER_VERSION] = {
              name: 'Manual',
              treeCount: (obj[MANUAL_WORKER_VERSION]?.treeCount || 0) + 1,
            }
          return obj
        },
        {} as { [id: UUID]: { name: string; treeCount: number } },
      )
      return orderBy(
        Object.entries(namedFilters).map(([id, { name, treeCount }]) => {
          return { id, name, treeCount }
        }),
        (item) => item.id,
        ['asc'],
      )
    },
  },
  methods: {
    ...mapActions(useAnnotationStore, ['setVisibleBulk']),
    ...mapActions(useTreeStore, ['tree']),
    ...mapActions(useDisplayStore, ['setImageShow']),
    flatten(node: Tree) {
      return [
        node.element,
        ...node.children.reduce(
          (array, node) => {
            // TODO: Use array.flat?
            array.push(...this.flatten(node))
            return array
          },
          [] as (Element | ElementBase)[],
        ),
      ]
    },
    workerFiltered(node: Tree): boolean {
      return (
        !this.workerFilter ||
        !('worker_run' in node.element) ||
        (this.workerFilter === MANUAL_WORKER_VERSION && !node.element.worker_run) ||
        node.element.worker_run?.id === this.workerFilter
      )
    },
    typeFiltered(node: Tree): boolean {
      return !this.typeFilter || node.element.type === this.typeFilter
    },
    filterTree(node: Tree): Tree | null {
      /*
       * Recursively filter elements of the tree with a corresponding type slug.
       * Parents are preserved if some children match the type filter.
       * Main element is always displayed.
       */
      const filteredChildren = node.children
        .map((node) => this.filterTree(node))
        .filter((node) => node !== null)
      // Return this node if it match filtering itself or via its children
      if (
        (this.workerFiltered(node) && this.typeFiltered(node)) ||
        filteredChildren.length ||
        node.element.id === this.element.id
      ) {
        return {
          ...node,
          children: filteredChildren,
        }
      }
      return null
    },
    edit(element: Element | ElementBase) {
      this.selectedElement = element
      this.editionModal = true
    },
    transcribe(element: Element | ElementBase) {
      this.selectedElement = element
      this.transcriptionModal = true
    },
    toggle() {
      this.setImageShow(!this.imageShow)
    },
  },
  watch: {
    modal(open: boolean) {
      if (!open) this.selectedElement = null
    },
    treeLengths(newValues, oldValues) {
      /*
       * If no elements are shown anymore due to deletion, reset the filters.
       * Only if the total element count in the tree changes, because if a
       * user chooses filters that, combined, don't return anything, they
       * should be able to see it.
       */
      if (
        newValues.filteredTree === 1 &&
        oldValues.filteredTree > 1 &&
        newValues.fullTree < oldValues.fullTree &&
        (this.typeFilter || this.workerFilter)
      ) {
        this.typeFilter = null
        this.workerFilter = null
      }
    },
    flatFilteredTreeIds(newList: UUID[], oldList: UUID[]) {
      // Removed elements should not be visible anymore
      const removedElts = oldList.filter((id) => !newList.includes(id))
      this.setVisibleBulk(this.element.id, removedElts, false)
      // Added elements should be visible if zones have been set to be displayed by default
      if (!this.imageShow) return
      const addedElts = newList.filter((id) => !oldList.includes(id) && id !== this.element.id)
      this.setVisibleBulk(this.element.id, addedElts)
    },
    imageShow: {
      immediate: true,
      handler(value: boolean) {
        // Toggle each element visible except the parent
        const ids = this.flatFilteredTreeIds.filter((id) => id !== this.element.id)
        this.setVisibleBulk(this.element.id, ids, value)
      },
    },
  },
})
</script>
