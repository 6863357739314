<template>
  <section class="mb-4">
    <div
      class="mt-2 pb-5"
      v-if="manualClassifications.length"
    >
      <strong class="mb-4">Manual</strong>
      <ClassificationComponent
        v-for="classification in manualClassifications"
        :key="classification.id"
        :classification="classification"
        :disabled="!canWrite(corpus)"
        :element-id="element.id"
      />
    </div>

    <div
      class="mt-2 pb-5"
      v-for="[workerRunId, classifications] in workerRunClassifications"
      :key="workerRunId"
    >
      <WorkerRunSummaryComponent
        class="mb-4"
        :worker-run-details="workerRunSummaries[workerRunId]"
      />
      <ClassificationComponent
        v-for="classification in classifications"
        :key="classification.id"
        :classification="classification"
        :disabled="!canWrite(corpus)"
        :element-id="element.id"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { groupBy, orderBy } from 'lodash'
import { mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import WorkerRunSummaryComponent from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import { corporaMixin } from '@/mixins'
import { useClassificationStore } from '@/stores'
import type { Classification, Element, ElementBase } from '@/types'
import type { WorkerRunSummary } from '@/types/process'

import ClassificationComponent from './Classification.vue'

export default defineComponent({
  mixins: [corporaMixin],
  components: {
    ClassificationComponent,
    WorkerRunSummaryComponent,
  },
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
      validator: (element): boolean =>
        element !== null &&
        typeof element === 'object' &&
        'id' in element &&
        typeof element.id === 'string',
    },
  },
  computed: {
    ...mapState(useClassificationStore, ['classifications']),
    corpusId() {
      return this.element.corpus?.id
    },
    /**
     * Classifications sorted by descending confidence and ascending class name.
     * This sorting is computed once and then re-used by other computed properties
     * to perform the grouping.
     */
    sortedClassifications(): Classification[] {
      return orderBy(
        this.classifications[this.element.id],
        ['confidence', 'class_name'],
        ['desc', 'asc'],
      )
    },
    manualClassifications() {
      return this.sortedClassifications.filter((classification) => !classification.worker_run)
    },
    /**
     * Classifications with worker runs, grouped by their worker run ID and sorted by their worker run summary.
     */
    workerRunClassifications() {
      const grouped = groupBy(
        this.sortedClassifications.filter((classification) => classification.worker_run),
        'worker_run.id',
      )
      return orderBy(Object.entries(grouped), ([id]) => this.workerRunSummaries[id])
    },
    /**
     * Worker run summary serializers mapped to their IDs.
     */
    workerRunSummaries() {
      return Object.fromEntries(
        this.sortedClassifications
          .filter(
            (classification): classification is Classification & { worker_run: WorkerRunSummary } =>
              classification.worker_run !== null,
          )
          .map((classification) => [classification.worker_run.id, classification.worker_run]),
      )
    },
  },
})
</script>
