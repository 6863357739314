<template>
  <div>
    <div
      v-if="!element"
      class="loading-content loader"
    ></div>
    <template v-else-if="element.corpus && canWrite(corpus)">
      <DropdownContent
        id="annotation-tools"
        title="Tools"
      >
        <Tools :corpus-id="element.corpus.id" />
      </DropdownContent>
      <DropdownContent
        id="batch-annotation"
        title="Batch annotation"
      >
        <fieldset
          :disabled="batchFormDisabled"
          :title="
            batchFormDisabled
              ? 'The batch annotation settings are only available for element creation, type edition or deletion tools.'
              : ''
          "
        >
          <span v-if="tool === 'deletion'">
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="batchDeletion"
              />
              Enable batch deletion (no confirmation)
            </label>
          </span>
          <span v-else-if="tool === 'type-edit'">
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="batchTypeEdition"
              />
              Batch type edition
            </label>
          </span>
          <span v-else>
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="batchCreation"
              />
              Enable batch creation (no prompt)
            </label>
          </span>
        </fieldset>

        <fieldset
          :disabled="elementTypeSelectDisabled"
          :title="
            elementTypeSelectDisabled
              ? 'Element type selection is only available for element creation and type edition tools.'
              : ''
          "
        >
          <div class="field mt-2">
            <label class="label">Element type</label>
            <div class="control">
              <div
                class="select is-fullwidth"
                :class="{ 'is-danger': showDefaultTypeError }"
              >
                <select v-model="defaultType">
                  <option
                    value=""
                    disabled
                    selected
                  >
                    Type…
                  </option>
                  <option
                    v-for="t in sortedTypes"
                    :key="t.slug"
                    :value="t.slug"
                  >
                    {{ truncateSelect(t.display_name) }}
                  </option>
                </select>
              </div>
            </div>
            <p
              class="help is-danger"
              v-if="showDefaultTypeError"
            >
              An element type is required to use batch creation or type edition.
            </p>
          </div>
        </fieldset>
      </DropdownContent>
      <hr />
    </template>
  </div>
</template>

<script lang="ts">
import { orderBy } from 'lodash'
import { mapState, mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import DropdownContent from '@/components/DropdownContent.vue'
import Tools from '@/components/Image/Tools.vue'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAnnotationStore, useElementStore } from '@/stores'
import type { UUID } from '@/types'

export default defineComponent({
  mixins: [truncateMixin, corporaMixin],
  components: {
    DropdownContent,
    Tools,
  },
  props: {
    elementId: {
      type: String as PropType<UUID>,
      required: true,
    },
  },
  computed: {
    ...mapState(useElementStore, ['elements']),
    ...mapState(useAnnotationStore, ['batchCreation', 'batchDeletion', 'batchTypeEdition', 'tool']),
    ...mapStores(useAnnotationStore),
    element() {
      return this.elements[this.elementId]
    },
    corpusId() {
      return this.element?.corpus?.id
    },
    sortedTypes() {
      if (!this.corpus?.types) return []
      return orderBy(this.corpus.types, [(t) => t.display_name.toLowerCase(), (t) => t.slug])
    },
    /**
     * Disable the batch annotation form if the selected tool does not use it.
     * Only the element creation tools (rectangle and polygon) make use of the batch annotation settings.
     */
    batchFormDisabled(): true | undefined {
      return !['rectangle', 'polygon', 'deletion', 'type-edit'].includes(this.tool) || undefined
    },
    /**
     * Disable the element type selection dropdown if not using an element creation tool.
     */
    elementTypeSelectDisabled(): true | undefined {
      return !['rectangle', 'polygon', 'type-edit'].includes(this.tool) || undefined
    },
    batchCreation: {
      get() {
        return this.annotationStore.batchCreation
      },
      set(newValue: boolean) {
        this.annotationStore.setBatchCreation(newValue)
        /*
         * Everything, except this panel, is ready to support the default classification setting.
         * The annotation module is going to expect a default class ID to be defined, so for now,
         * we just set it to never create a classification.
         * TODO: Fix the MLClassSelect to allow setting default classifications in this panel.
         */
        if (this.annotationStore.batchCreation && this.corpusId)
          this.annotationStore.defaultClass[this.corpusId] = null
      },
    },
    batchDeletion: {
      get() {
        return this.annotationStore.batchDeletion
      },
      set(newValue: boolean) {
        this.annotationStore.setBatchDeletion(newValue)
      },
    },
    batchTypeEdition: {
      get() {
        return this.annotationStore.batchTypeEdition
      },
      set(newValue: boolean) {
        this.annotationStore.setBatchTypeEdition(newValue)
      },
    },
    defaultType: {
      get() {
        // Default to the empty string to make the disabled placeholder option selected
        return (this.corpusId && this.annotationStore.defaultType[this.corpusId]) ?? ''
      },
      set(type: string) {
        if (this.corpusId) this.annotationStore.defaultType[this.corpusId] = type
      },
    },
    showDefaultTypeError() {
      return (this.batchCreation || this.batchTypeEdition) && !this.defaultType
    },
  },
  watch: {
    // If we know the user's rights on the corpus, and we find that the user is not a contributor, turn annotation off
    corpus: {
      immediate: true,
      handler(newCorpus) {
        if (Array.isArray(newCorpus?.rights) && !this.canWrite(newCorpus))
          this.annotationStore.toggle(false)
      },
    },
  },
})
</script>

<style scoped>
.loading-content {
  font-size: 2.5rem;
  margin: 2.5rem auto 0 auto;
}
</style>
