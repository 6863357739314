<template>
  <Modal
    title="Background jobs"
    :model-value="modelValue"
    v-on:update:model-value="input"
  >
    <Job
      v-for="(job, id) in jobs"
      :key="id"
      :job="job"
    />
    <div
      class="notification"
      v-if="isEmpty(jobs)"
    >
      There are no background jobs.
    </div>

    <template v-slot:footer>
      <button
        class="button"
        v-on:click="list"
        :class="{ 'is-loading': loading }"
        :disabled="loading || undefined"
      >
        Refresh
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { isEmpty } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import Modal from '@/components/Modal.vue'
import { useJobsStore } from '@/stores'

import Job from './Job.vue'

export default defineComponent({
  components: {
    Modal,
    Job,
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useJobsStore, ['jobs', 'loading']),
  },
  mounted() {
    // Triggers as soon as a user logs in or the frontend's authentication gets loaded
    this.list()
  },
  methods: {
    ...mapActions(useJobsStore, ['list', 'startPolling', 'stopPolling']),
    isEmpty,
    input(value: boolean) {
      // v-model passthrough
      this.$emit('update:modelValue', value)
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      async handler(newValue: boolean) {
        if (newValue) await this.startPolling()
        else this.stopPolling()
      },
    },
  },
})
</script>
