<template>
  <label class="checkbox">
    <input
      type="checkbox"
      class="large-checkbox"
      :checked="modelValue"
      v-on:change="$emit('update:modelValue', ($event.target as HTMLInputElement).checked)"
    />
  </label>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import type { BooleanUserConfigurationField } from '@/types/workerConfiguration'

export default defineComponent({
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    field: {
      type: Object as PropType<BooleanUserConfigurationField>,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
})
</script>

<style scoped>
.large-checkbox {
  width: 2em;
  height: 2em;
}
</style>
