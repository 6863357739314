import { defineComponent } from 'vue'

import { TRUNCATE_LENGTHS } from '@/config'

const truncateFilter = (maxLength: number) => (text: string | null | undefined) => {
  if (text && text?.length > maxLength) return text.slice(0, maxLength) + '…'
  return text ?? ''
}

export default defineComponent({
  methods: {
    truncateSelect: truncateFilter(TRUNCATE_LENGTHS.select),
    truncateLong: truncateFilter(TRUNCATE_LENGTHS.long),
    truncateShort: truncateFilter(TRUNCATE_LENGTHS.short),
    truncateNotification: truncateFilter(TRUNCATE_LENGTHS.notification),
    truncateId: truncateFilter(TRUNCATE_LENGTHS.id),
  },
})
