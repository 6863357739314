<template>
  <span
    v-bind="$attrs"
    class="is-clickable"
    :class="{ 'is-singleline': !multiline }"
    v-on:click="workerRunModal = true"
    :title="`${workerRunDetails.summary}\nClick to view worker run details`"
  >
    <i class="icon-cog-alt"></i>
    <template v-if="!iconOnly"> {{ workerRunDetails.summary }}</template>
  </span>
  <WorkerRunDetails
    v-model="workerRunModal"
    :worker-run-id="workerRunDetails.id"
    :title="workerRunDetails.summary"
  />
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import type { WorkerRunSummary } from '@/types/process'

import WorkerRunDetails from './WorkerRunDetails.vue'

export default defineComponent({
  components: {
    WorkerRunDetails,
  },
  data: () => ({
    workerRunModal: false,
  }),
  props: {
    workerRunDetails: {
      type: Object as PropType<WorkerRunSummary>,
      required: true,
      validator: (value: unknown): boolean =>
        typeof value === 'object' &&
        value !== null &&
        'id' in value &&
        typeof value.id === 'string' &&
        'summary' in value &&
        typeof value.summary === 'string',
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped>
.is-singleline {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
