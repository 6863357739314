<template>
  <tr>
    <th class="is-narrow is-vcentered">{{ index }}</th>
    <td
      colspan="2"
      v-if="editing"
    >
      <EditionForm
        class="mb-2"
        :element="element"
        :transcription="transcription"
        v-on:close="editing = false"
      />
    </td>
    <template v-else>
      <td>
        <div
          class="has-line-breaks"
          :style="orientationStyle(transcription.orientation)"
        >
          <span>{{ transcription.text }}</span>
        </div>
      </td>
      <td class="shrink">
        <Actions
          class="are-medium"
          :element="element"
          :transcription="transcription"
          v-on:edit="editing = true"
        />
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import { TEXT_ORIENTATIONS } from '@/config'
import { orientationStyle } from '@/helpers'
import type { Element, ElementBase } from '@/types'
import type { Transcription } from '@/types/transcription'

import Actions from './Actions.vue'
import EditionForm from './EditionForm.vue'

export default defineComponent({
  components: {
    Actions,
    EditionForm,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
    transcription: {
      type: Object as PropType<Transcription>,
      required: true,
    },
  },
  data: () => ({
    editing: false,
    TEXT_ORIENTATIONS,
  }),
  methods: {
    orientationStyle,
  },
})
</script>

<style scoped>
textarea.input {
  height: auto;
}
</style>
