import axios from 'axios'

import { unique } from '@/helpers/api'
import type { PageNumberPagination, UUID } from '@/types'
import type { AgentDetails, AgentState, Artifact, Farm, Task } from '@/types/ponos'

import type { PageNumberPaginationParameters } from '.'

export const retrieveTask = unique(
  async (id: UUID): Promise<Task> => (await axios.get(`/task/${id}/`)).data,
)

export type TaskUpdatePayload = Partial<Pick<Task, 'state'>>

export const updateTask = async (id: UUID, payload: TaskUpdatePayload): Promise<Task> =>
  (await axios.patch(`/task/${id}/`, payload)).data

export const restartTask = async (id: UUID): Promise<Task> =>
  (await axios.post(`/task/${id}/restart/`)).data

export const listArtifacts = unique(
  async (id: UUID): Promise<Artifact[]> => (await axios.get(`/task/${id}/artifacts/`)).data,
)

// List Ponos agents with their status
export const listAgents = unique(
  async (params: PageNumberPaginationParameters): Promise<PageNumberPagination<AgentState>> =>
    (await axios.get('/agents/', { params })).data,
)

// Retrieve a Ponos agent with its running tasks
export const retrieveAgent = unique(
  async (id: UUID): Promise<AgentDetails> => (await axios.get(`/agent/${id}/`)).data,
)

// List Ponos farms
export const listFarms = unique(
  async (params: PageNumberPaginationParameters): Promise<PageNumberPagination<Farm>> =>
    (await axios.get('/farms/', { params })).data,
)
