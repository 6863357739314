<template>
  <template v-if="!elements.length">
    <slot name="no-results">
      <div class="notification is-warning">
        <span>No elements to display.</span>
      </div>
    </slot>
  </template>
  <div
    v-else-if="elementsTableLayout || asTable"
    class="columns is-multiline"
  >
    <table class="table centered-table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th class="is-two-third-table">Name</th>
          <th>Type</th>
          <th v-if="hasClasses && displayElementClasses">Classes</th>
          <th
            class="is-narrow"
            v-if="!disabled"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <ElementInLine
          v-for="element in elements"
          :key="element.id"
          :element="element"
          :disabled="disabled"
        />
      </tbody>
    </table>
  </div>
  <div
    class="columns is-multiline"
    v-else
  >
    <div
      class="column"
      :class="size"
      v-for="element in elements"
      :key="element.id"
    >
      <ElementThumbnail
        v-if="isElementBase(element)"
        :element="element"
        :disabled="disabled"
        :dataset="dataset"
        :set="set"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { useClassificationStore, useDisplayStore } from '@/stores'
import type { ElementBase } from '@/types'
import type { Dataset, DatasetSet } from '@/types/dataset'
import type { ProcessElementList } from '@/types/process'

import ElementInLine from './ElementInLine.vue'
import ElementThumbnail from './ElementThumbnail.vue'

function isElementBase(element: ElementBase | ProcessElementList): element is ElementBase {
  return 'type' in element
}

export default defineComponent({
  components: {
    ElementThumbnail,
    ElementInLine,
  },
  props: {
    elements: {
      type: Array as PropType<ElementBase[] | ProcessElementList[]>,
      required: true,
      validator: (elements: ElementBase[] | ProcessElementList[]) => {
        return elements.every(
          (element) =>
            element.id &&
            element.name &&
            element.corpus &&
            (isElementBase(element) ? element.type : element.type_id),
        )
      },
    },
    /**
     * Make all actions unavailable on elements, to make the list appear as read-only.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Force elements to be displayed as a table and not as thumbnails.
     */
    asTable: {
      type: Boolean,
      default: false,
    },
    /**
     * Increase the amount of displayed thumbnail columns from 4 to 5.
     */
    maxSize: {
      type: Boolean,
      default: false,
    },
    /**
     * These two props are only used when this component is included in the Dataset/Details vue,
     * to be transmitted down to ElementThumbnail.
     */
    dataset: {
      type: Object as PropType<Dataset | null>,
      default: null,
    },
    set: {
      type: Object as PropType<DatasetSet | null>,
      default: null,
    },
  },
  computed: {
    ...mapState(useDisplayStore, [
      'elementsTableLayout',
      'displayDetails',
      'displayElementClasses',
    ]),
    ...mapState(useClassificationStore, ['classifications']),
    size() {
      return this.maxSize ? 'is-one-fifth-desktop is-one-third' : 'is-one-quarter-desktop is-half'
    },
    /**
     * Whether or not any of the listed elements have loaded classes.
     * This returns True even if every element has no classes at all, just an empty array;
     * the classes column should be displayed anyway, with every row showing no classes.
     */
    hasClasses(): boolean {
      return this.elements.some(
        (element) => isElementBase(element) && Array.isArray(this.classifications[element.id]),
      )
    },
  },
  methods: { isElementBase },
})
</script>

<style scoped>
.is-two-third-table {
  width: 66%;
}
</style>
