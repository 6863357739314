import axios from 'axios'

import { unique } from '@/helpers/api'
import type { Job, UUID } from '@/types'

// List background jobs
export const listJobs = unique(async (): Promise<Job[]> => (await axios.get('/jobs/')).data)

// Cancel a pending job or delete a finished/failed job
export const deleteJob = unique((id: UUID) => axios.delete(`/jobs/${id}/`))
