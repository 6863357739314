<template>
  <section v-if="transcriptions[element.id]">
    <div
      class="pb-4"
      v-if="manualTranscriptions.length"
    >
      <strong class="mb-2">Manual</strong>
      <TranscriptionComponent
        v-for="transcription in manualTranscriptions"
        :key="transcription.id"
        :element="element"
        :transcription="transcription"
      />
    </div>

    <div
      class="pb-4"
      v-for="[workerRunId, transcriptions] in workerRunTranscriptions"
      :key="workerRunId"
    >
      <WorkerRunSummaryComponent
        class="mb-2"
        :worker-run-details="workerRunSummaries[workerRunId]"
      />
      <TranscriptionComponent
        v-for="transcription in transcriptions"
        :key="transcription.id"
        :element="element"
        :transcription="transcription"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { groupBy, orderBy } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import WorkerRunSummaryComponent from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import { useTranscriptionStore } from '@/stores'
import type { Element, ElementBase, UUID } from '@/types'
import type { WorkerRunSummary } from '@/types/process'
import type { Transcription } from '@/types/transcription'

import TranscriptionComponent from './Transcription.vue'

export default defineComponent({
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
      validator: (value: Element) => {
        return Object.keys(value).includes('id')
      },
    },
  },
  components: {
    TranscriptionComponent,
    WorkerRunSummaryComponent,
  },
  computed: {
    ...mapState(useTranscriptionStore, ['transcriptions']),
    /**
     * Transcriptions sorted by descending confidence and ascending text.
     * This sorting is computed once and then re-used by other computed properties
     * to perform the grouping.
     */
    sortedTranscriptions(): Transcription[] {
      return orderBy(this.transcriptions[this.element.id], ['confidence', 'text'], ['desc', 'asc'])
    },
    manualTranscriptions(): Transcription[] {
      return this.sortedTranscriptions.filter((transcription) => !transcription.worker_run)
    },
    /**
     * Transcriptions grouped by worker run ID, and sorted by their worker run summary.
     */
    workerRunTranscriptions(): [UUID, Transcription[]][] {
      const grouped = groupBy(
        this.sortedTranscriptions.filter((transcription) => transcription.worker_run),
        'worker_run.id',
      )
      return orderBy(Object.entries(grouped), ([id]) => this.workerRunSummaries[id])
    },
    /**
     * Worker run summary serializers mapped to their IDs.
     */
    workerRunSummaries(): { [workerRunId: UUID]: WorkerRunSummary } {
      return Object.fromEntries(
        this.sortedTranscriptions
          .filter((transcription) => transcription?.worker_run)
          .map((transcription) => [transcription.worker_run?.id, transcription.worker_run]),
      )
    },
  },
  methods: {
    ...mapActions(useTranscriptionStore, ['list']),
  },
  watch: {
    element: {
      immediate: true,
      async handler(newValue) {
        if (!newValue) return
        if (!this.transcriptions[newValue.id]) await this.list(newValue.id)
      },
    },
  },
})
</script>
