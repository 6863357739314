import { isAxiosError } from 'axios'
import { defineStore } from 'pinia'

import {
  type DeleteWorkerResultsParameters,
  deleteWorkerResults,
  listCorpusWorkerVersions,
} from '@/api'
import { errorParser } from '@/helpers'
import { useJobsStore, useNotificationStore } from '@/stores'
import type { UUID, WorkerVersionCache } from '@/types'
import type { WorkerVersion } from '@/types/worker'

interface State {
  /**
   * Cached ML results referenced by corpus ID
   * Each result contains a worker version, optional model version and worker configuration
   */
  corpusWorkerVersionsCache: { [corpusId: UUID]: { [id: UUID]: WorkerVersionCache } }
}

export const useMLResultsStore = defineStore('mlresults', {
  state: (): State => ({
    corpusWorkerVersionsCache: {},
  }),
  actions: {
    async listWorkerVersionsCache(corpusId: UUID, url: string | null = null) {
      try {
        const data = await listCorpusWorkerVersions(corpusId, url)
        const { results, ...pagination } = data

        this.corpusWorkerVersionsCache[corpusId] = {
          ...this.corpusWorkerVersionsCache[corpusId],
          ...Object.fromEntries(results.map((cache) => [cache.id, cache])),
        }
        // Paginate until we fetched all the worker versions cache for this corpus
        if (pagination.next) this.listWorkerVersionsCache(corpusId, pagination.next)
      } catch (err) {
        const notificationStore = useNotificationStore()
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      }
    },
    async deleteWorkerResults(corpusId: UUID, params: DeleteWorkerResultsParameters) {
      const notificationStore = useNotificationStore()
      try {
        await deleteWorkerResults(corpusId, params)
        notificationStore.notify({
          type: 'success',
          text: 'Worker results deletion has been scheduled.',
        })
      } catch (err) {
        notificationStore.notify({ type: 'error', text: errorParser(err) })
        if (isAxiosError(err) && err.response?.status === 400 && err.response?.data) throw err
      } finally {
        useJobsStore().list()
      }
    },
  },
  getters: {
    /**
     * Returns a list of distinct worker versions that generated
     * ML results on a corpus, referenced by their ID.
     */
    corpusWorkerVersions() {
      return (corpusId: UUID): { [id: UUID]: WorkerVersion } => {
        const workerVersionsCache = this.corpusWorkerVersionsCache[corpusId] ?? []
        return Object.fromEntries(
          Object.values(workerVersionsCache).map((cache) => [
            cache.worker_version.id,
            cache.worker_version,
          ]),
        )
      }
    },
  },
})
