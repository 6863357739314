<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="updateModelValue"
    :is-large="!advancedMode"
  >
    <template v-slot:header>
      <p class="modal-card-title mr-5">
        {{ truncateLong('Delete results ' + originDescription) }}
      </p>
      <span class="ml-auto">
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="advancedMode"
          />
          Advanced mode
        </label>
      </span>
    </template>

    <div
      v-if="advancedMode"
      class="content"
    >
      <div class="field">
        <label class="label">Worker run UUID</label>
        <div class="control">
          <input
            type="text"
            placeholder="00000000-0000-0000-0000-000000000000"
            maxlength="36"
            class="input uuid-input"
            v-model="workerRunId"
            :disabled="loading || undefined"
          />
          <template v-if="errors.worker_run_id">
            <p
              class="help is-danger"
              v-for="err in errors.worker_run_id"
              :key="err"
            >
              {{ err }}
            </p>
          </template>
        </div>
      </div>
      <div class="field">
        <label class="label">Worker version UUID</label>
        <div class="control">
          <input
            type="text"
            placeholder="00000000-0000-0000-0000-000000000000"
            maxlength="36"
            class="input uuid-input"
            v-model="workerVersionId"
            :disabled="inWorkerRunMode || loading || undefined"
          />
          <template v-if="errors.worker_version_id">
            <p
              class="help is-danger"
              v-for="err in errors.worker_version_id"
              :key="err"
            >
              {{ err }}
            </p>
          </template>
        </div>
      </div>
      <div class="field">
        <label class="label">Model version UUID</label>
        <div class="control">
          <input
            type="text"
            placeholder="00000000-0000-0000-0000-000000000000"
            maxlength="36"
            class="input uuid-input"
            v-model="modelVersionId"
            :disabled="inWorkerRunMode || loading || undefined"
          />
          <template v-if="errors.model_version_id">
            <p
              class="help is-danger"
              v-for="err in errors.model_version_id"
              :key="err"
            >
              {{ err }}
            </p>
          </template>
        </div>
      </div>
      <div class="field">
        <label class="label">
          Configuration
          <span class="control">
            <label
              v-for="(value, label) in configurationRadio"
              :key="label"
              class="radio ml-4"
              :class="{ active: configurationField === value }"
              v-on:click.capture="setConfigurationField(value)"
            >
              <input
                class="is-checkradio"
                type="radio"
                :checked="(configurationField === value && !inWorkerRunMode) || undefined"
                :disabled="inWorkerRunMode || loading || undefined"
              />
              {{ label }}
            </label>
          </span>
        </label>

        <div class="control">
          <input
            type="text"
            placeholder="00000000-0000-0000-0000-000000000000"
            maxlength="36"
            class="input uuid-input"
            v-model="configurationId"
            :disabled="inWorkerRunMode || configurationField !== true || loading || undefined"
          />
          <template v-if="errors.configuration_id">
            <p
              class="help is-danger"
              v-for="err in errors.configuration_id"
              :key="err"
            >
              {{ err }}
            </p>
          </template>
        </div>
      </div>
      <button
        class="button is-danger"
        type="button"
        v-on:click="checkManual"
      >
        Delete
      </button>
    </div>

    <div
      v-else
      class="content"
    >
      <table
        class="table is-fullwidth is-hoverable"
        v-if="workerVersionsCache"
      >
        <thead>
          <tr>
            <th>Worker&nbsp;version</th>
            <th>Model&nbsp;version</th>
            <th>Configuration</th>
            <th class="is-narrow">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(
              { worker_version, worker_configuration, model_version }, cacheId
            ) in workerVersionsCache"
            :key="cacheId"
          >
            <td>
              <WorkerVersionSummary :worker-version="worker_version" />
            </td>
            <td>
              <ModelVersionSummary
                v-if="model_version"
                :model-version="model_version"
              />
              <template v-else>—</template>
            </td>
            <td>
              <ConfigurationSummary
                v-if="worker_configuration"
                :configuration="worker_configuration"
                :worker-version="worker_version"
              />
              <template v-else>—</template>
            </td>
            <td>
              <button
                class="button is-danger"
                type="button"
                v-on:click="select(cacheId)"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-slot:footer="{ close }">
      <button
        class="button"
        v-on:click="close"
      >
        Cancel
      </button>
    </template>
  </Modal>

  <Modal
    v-model="confirmationModal"
    title="Confirm deletion"
  >
    <div class="content">
      <template v-if="inWorkerRunMode">
        <p>
          You are about to delete all results from worker run <strong>{{ workerRunId }}</strong>
          {{ originDescription }}.
        </p>
        <div
          v-if="!targetWorkerRun"
          class="notification is-warning"
        >
          The worker run details could not be retrieved.
        </div>
        <template v-else>
          <ul class="mb-0">
            <li>
              Worker version:
              <router-link
                :to="{
                  name: 'worker-version',
                  params: { versionId: targetWorkerRun.worker_version.id },
                }"
                target="_blank"
                title="See worker version details"
              >
                <samp class="tag is-size-6">{{ targetWorkerRun.worker_version.id }}</samp>
              </router-link>
              (worker
              <router-link
                :to="{
                  name: 'worker-manage',
                  params: { workerId: targetWorkerRun.worker_version.worker.id },
                }"
                target="_blank"
                title="See worker details"
              >
                {{ truncateShort(targetWorkerRun.worker_version.worker.name) }} </router-link
              >)
            </li>
            <li v-if="targetWorkerRun.model_version">
              Model version:
              <router-link
                :to="{
                  name: 'model-version',
                  params: { versionId: targetWorkerRun.model_version.id },
                }"
                target="_blank"
                title="See model version details"
              >
                <samp class="tag">{{ targetWorkerRun.model_version.id }}</samp>
              </router-link>
              (model
              <router-link
                :to="{ name: 'model', params: { modelId: targetWorkerRun.model_version.model.id } }"
                target="_blank"
                title="See model details"
              >
                {{ truncateShort(targetWorkerRun.model_version.model.name) }} </router-link
              >)
            </li>
            <li v-else>Model version: —</li>
            <li v-if="targetWorkerRun.configuration">
              Configuration: {{ targetWorkerRun.configuration.name }}
              <details>
                <summary>Expand configuration</summary>
                <pre>{{
                  JSON.stringify(targetWorkerRun.configuration.configuration, null, 2)
                }}</pre>
              </details>
            </li>
            <li v-else>Configuration: —</li>
          </ul>
        </template>
      </template>

      <template v-else-if="deletesAll">
        You are about to delete <strong>all worker results</strong> {{ originDescription }}.
      </template>

      <template v-else>
        <p
          class="mb-0"
          v-for="(item, index) in deleteMessages"
          :key="index"
        >
          {{ item }}
        </p>
      </template>
      <hr class="my-3" />
      <p class="mb-0">Child elements of these results will also be deleted recursively.</p>
      <p>This action is irreversible.</p>
    </div>

    <template v-slot:footer="{ close }">
      <button
        class="button"
        v-on:click="close"
      >
        Cancel
      </button>
      <button
        class="button is-danger"
        v-on:click="performDelete"
        :title="originDescription"
      >
        {{ truncateLong('Delete results ' + originDescription) }}
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import type { DeleteWorkerResultsParameters } from '@/api'
import Modal from '@/components/Modal.vue'
import ModelVersionSummary from '@/components/Model/Versions/Summary.vue'
import ConfigurationSummary from '@/components/Process/Workers/Configurations/Summary.vue'
import WorkerVersionSummary from '@/components/Process/Workers/Versions/Summary.vue'
import { UUID_REGEX } from '@/config'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useElementStore, useMLResultsStore, useWorkerStore } from '@/stores'
import type { Element, ElementBase, UUID, WorkerVersionCache } from '@/types'

export default defineComponent({
  mixins: [corporaMixin, truncateMixin],
  components: {
    Modal,
    WorkerVersionSummary,
    ModelVersionSummary,
    ConfigurationSummary,
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * Id of the corpus to delete results on
     */
    corpusId: {
      type: String as PropType<UUID>,
      validator: (value) => typeof value === 'string' && UUID_REGEX.test(value),
      required: true,
    },
    /**
     * Limit results deletion to an element and its children
     * Cannot be set together with the selection prop
     */
    elementId: {
      type: String as PropType<UUID | null>,
      validator: (value) => value === null || (typeof value === 'string' && UUID_REGEX.test(value)),
      default: null,
    },
    /**
     * Limit results deletion to a selection of elements
     * Cannot be set together with the elementId prop
     */
    selection: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    advancedMode: false,
    /**
     * Optional worker version cache to delete
     */
    cacheUUIDToDelete: null as UUID | null,
    /**
     * Custom fields for manual deletion
     */
    workerRunId: '',
    workerVersionId: '',
    modelVersionId: '',
    configurationId: '',
    configurationField: null as boolean | null,
    configurationRadio: {
      Any: null,
      None: false,
      UUID: true,
    },
    confirmationModal: false,
    // Mappings of [field, error_msg] for the manual deletion form
    errors: {} as { [key: string]: Array<string> },
  }),
  computed: {
    ...mapState(useElementStore, ['elements']),
    ...mapState(useWorkerStore, ['workerRuns']),
    ...mapState(useMLResultsStore, {
      workerVersionsCache(store): Record<UUID, WorkerVersionCache> {
        // @ts-expect-error `this` typed incorrectly https://pinia.vuejs.org/core-concepts/state.html#Usage-with-the-Options-API
        return store.corpusWorkerVersionsCache[this.corpusId] ?? null
      },
    }),
    originDescription(): string {
      if (this.element?.name !== undefined) {
        return ` on "${this.element.name}" and its children`
      } else if (this.selection && this.corpus?.name !== undefined) {
        return ` on selected elements from "${this.corpus.name}"`
      } else {
        return ` on project "${this.corpus?.name}"`
      }
    },
    deletesAll(): boolean {
      return (
        this.advancedMode &&
        this.workerVersionId === '' &&
        this.modelVersionId === '' &&
        this.configurationField === null
      )
    },
    element(): Element | ElementBase | null {
      if (!this.elementId) return null
      return this.elements[this.elementId] || null
    },
    versionCacheToDelete(): WorkerVersionCache | null {
      if (this.cacheUUIDToDelete === null) return null
      return this.workerVersionsCache[this.cacheUUIDToDelete]
    },
    deleteMessages(): Array<string> {
      const messages = []
      // Elements to be deleted
      messages.push(`You are about to delete results ${this.originDescription}`)
      // Worker version
      const versionName = this.truncateLong(
        this.versionCacheToDelete?.worker_version?.worker?.name || this.workerVersionId || 'Any',
      )
      messages.push(`• Worker version: ${versionName}`)
      // Model version
      if (this.advancedMode) {
        messages.push(`• Model version: ${this.modelVersionId || 'Any'}`)
      } else if (this.versionCacheToDelete?.model_version) {
        messages.push(`• Model version: ${this.versionCacheToDelete.model_version.model.name}`)
      }
      // Worker configuration
      const confName = this.advancedMode
        ? this.configurationId || (this.configurationField === null && 'Any') || 'None'
        : this.truncateLong(this.versionCacheToDelete?.worker_configuration?.name || 'None')
      messages.push(`• Configuration: ${confName}`)
      return messages
    },
    payload() {
      const payload: DeleteWorkerResultsParameters = {
        use_selection: this.selection,
      }
      if (this.elementId) payload.element_id = this.elementId
      if (this.advancedMode) {
        if (this.workerRunId) payload.worker_run_id = this.workerRunId
        if (this.workerVersionId) {
          payload.worker_version_id = this.workerVersionId
        }
        if (this.modelVersionId) payload.model_version_id = this.modelVersionId
        if (this.configurationField !== null) {
          payload.configuration_id = this.configurationField ? this.configurationId : false
        }
      } else if (this.versionCacheToDelete !== null) {
        payload.worker_version_id = this.versionCacheToDelete.worker_version.id
        payload.configuration_id = this.versionCacheToDelete?.worker_configuration?.id ?? false
        if (this.versionCacheToDelete?.model_version?.id) {
          payload.model_version_id = this.versionCacheToDelete.model_version.id
        }
      }
      return payload
    },
    inWorkerRunMode(): boolean {
      return this.workerRunId !== ''
    },
    targetWorkerRun() {
      if (!this.workerRunId) return null
      return this.workerRuns[this.workerRunId]
    },
  },
  methods: {
    ...mapActions(useMLResultsStore, ['listWorkerVersionsCache', 'deleteWorkerResults']),
    ...mapActions(useWorkerStore, ['getWorkerRun']),
    updateModelValue(value: boolean) {
      this.$emit('update:modelValue', value)
    },
    select(cacheId: UUID) {
      this.cacheUUIDToDelete = cacheId
      this.confirmationModal = true
    },
    setConfigurationField(value: boolean | null) {
      this.configurationField = value
    },
    async retrieveWorkerRun() {
      if (this.workerRuns[this.workerRunId]) return
      this.loading = true
      try {
        await this.getWorkerRun(this.workerRunId)
      } catch {
        /**
         * We catch the error silently because when the confirmation modal opens,
         * we display a notification there to warn that the worker run could not be
         * retrieved. Also, displaying an error notification but still opening the
         * modal might be confusing for users.
         */
      } finally {
        this.loading = false
      }
    },
    async checkManual() {
      this.errors = {}
      if (this.workerRunId && !UUID_REGEX.test(this.workerRunId)) {
        this.errors.worker_run_id = ['This value must be a valid UUID.']
      }
      if (this.workerVersionId && !UUID_REGEX.test(this.workerVersionId)) {
        this.errors.worker_version_id = ['This value must be a valid UUID.']
      }
      if (this.modelVersionId && !UUID_REGEX.test(this.modelVersionId)) {
        this.errors.model_version_id = ['This value must be a valid UUID.']
      }
      if (this.configurationId && !UUID_REGEX.test(this.configurationId)) {
        this.errors.configuration_id = ['This value must be a valid UUID.']
      }
      if (this.configurationField === true && this.configurationId === '') {
        this.errors.configuration_id = ['This value must be set.']
      }
      if (Object.keys(this.errors).length === 0) {
        if (this.workerRunId) await this.retrieveWorkerRun()
        this.confirmationModal = true
      }
    },
    async performDelete() {
      this.errors = {}
      // No need to check for access rights, as the component can only be accessed by corpus admins
      this.loading = true

      try {
        await this.deleteWorkerResults(this.corpusId, this.payload)
        this.$emit('update:modelValue', false)
      } catch (err) {
        if (isAxiosError(err)) this.errors = err?.response?.data || {}
      } finally {
        this.confirmationModal = false
        this.advancedMode = false
        this.loading = false
      }
    },
  },
  watch: {
    modelValue(newValue: boolean) {
      if (newValue && this.workerVersionsCache === null) {
        this.listWorkerVersionsCache(this.corpusId)
      }
    },
    confirmationModal(newValue: boolean) {
      if (newValue === false) {
        this.cacheUUIDToDelete = null
      }
    },
    configurationField(newValue: boolean | null) {
      if ([false, null].includes(newValue)) this.configurationId = ''
    },
  },
})
</script>

<style scoped>
.uuid-input {
  max-width: 40ch;
}
pre {
  white-space: pre-line;
}
</style>
