import { API_BASE_URL, MIRADOR, UNIVERSAL_VIEWER } from '@/config'
import type { UUID, Zone } from '@/types'

import { boundingBox } from './polygon'

export const manifestUri = (folderId: UUID): string => `${API_BASE_URL}/iiif/${folderId}/manifest/`

export const miradorUri = (folderId: UUID): string | null =>
  MIRADOR ? MIRADOR + manifestUri(folderId) : null

export const uvUri = (folderId: UUID): string | null =>
  UNIVERSAL_VIEWER ? UNIVERSAL_VIEWER + manifestUri(folderId) : null

interface IIIFURIParameters {
  /**
   * Maximum width of the requested image.
   */
  width?: number | null
  /**
   * Maximum height of the requested image.
   */
  height?: number | null
  /**
   * Margin to add around each border of the bounding box of the zone's polygon, in pixels.
   */
  margin?: number
}

/**
 * Generate a IIIF URI for a JPEG image with the default profile and no rotation,
 * optionally cropping to a zone's polygon and optionally limiting to a maximum size.
 *
 * @returns {string} An IIIF URI.
 */
export const iiifUri = (zone: Zone, { width, height, margin }: IIIFURIParameters = {}): string => {
  if (!zone.image) throw new Error('An image is required.')
  if ((zone.image.width ?? 0) <= 0 || (zone.image.height ?? 0) <= 0)
    throw new Error('An image with valid dimensions is required.')
  if (!zone.image.url) throw new Error('An image with a valid URL is required.')

  if (!width) width = null
  if (!height) height = null
  if (!margin) margin = 0

  let url = zone.image.url
  if (!url.endsWith('/')) url += '/'

  // By default, use the entire image as a bounding box
  let box = { x: 0, y: 0, width: zone.image.width, height: zone.image.height }

  // Do not crop if there is no polygon
  if (!zone.polygon?.length) url += 'full'
  else {
    box = boundingBox(zone, { margin })
    /*
     * Do not crop if the box just fits the entire image.
     * Some IIIF servers are not clever enough to guess this on their own and optimize
     */
    if (
      box.x <= 0 &&
      box.y <= 0 &&
      box.width >= zone.image.width &&
      box.height >= zone.image.height
    )
      url += 'full'
    else url += [box.x, box.y, box.width, box.height].join(',')
  }

  // Only one dimension is greater than the image; resize both resize parameters before applying it to avoid going over 100% of the image
  if ((width !== null && width > box.width) !== (height !== null && height > box.height)) {
    const ratio = Math.max((width ?? 0) / box.width, (height ?? 0) / box.height)
    if (width) width = Math.round(width / ratio)
    if (height) height = Math.round(height / ratio)
  }

  // When both the specified maximum width and height are greater than or equal to the image's size, just use full
  if ((width ?? Infinity) >= box.width && (height ?? Infinity) >= box.height)
    return url + '/full/0/default.jpg'

  // Add a ! prefix to require the IIIF server to preserve aspect ratio if we set both dimensions at once in a resize
  return `${url}/${width && height ? '!' : ''}${width ?? ''},${height ?? ''}/0/default.jpg`
}
