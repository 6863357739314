<template>
  <div class="box">
    <div class="columns is-vcentered">
      <div class="column desc-col">
        <div>
          <p class="jobdesc">
            <strong>{{ job.description }}</strong>
          </p>
          <span class="is-capitalized">{{ job.status }}</span>
          <span
            :title="statusDate.toString()"
            v-if="statusDate"
            >&nbsp;{{ ago(statusDate) }}</span
          >
        </div>
        <progress
          class="progress is-primary"
          v-if="job.status === 'started'"
          v-bind="progressAttrs"
        ></progress>
      </div>
      <div
        class="column is-narrow"
        v-if="canDelete"
      >
        <button
          class="button is-danger"
          :class="{ 'is-loading': loading }"
          :disabled="loading || undefined"
          v-on:click="deleteJob"
        >
          <i class="icon-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import { ago } from '@/helpers'
import { useJobsStore } from '@/stores'
import type { Job } from '@/types'

export default defineComponent({
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    statusDate() {
      let stringDate = this.job.enqueued_at
      if (this.job.status === 'started') stringDate = this.job.started_at
      else if (['finished', 'failed'].includes(this.job.status)) stringDate = this.job.ended_at
      return stringDate ? new Date(stringDate) : null
    },
    canDelete() {
      return this.job.status !== 'started'
    },
    progressAttrs() {
      // Leave the `value` attribute unset when progress is not yet set
      if (this.job.progress !== null && Number.isFinite(this.job.progress))
        return { value: this.job.progress }
      return {}
    },
  },
  methods: {
    async deleteJob() {
      if (!this.canDelete || this.loading) return
      this.loading = true
      try {
        const jobsStore = useJobsStore()
        await jobsStore.delete(this.job.id)
      } finally {
        this.loading = false
      }
    },
    ago,
  },
})
</script>

<style scoped>
.columns {
  max-width: 100%;
}
.desc-col {
  max-width: 95%;
  margin: auto;
}
.jobdesc {
  overflow-wrap: break-word;
}
</style>
