<template>
  <span
    class="tag is-light"
    :class="colorClass"
    :title="`${value * 100}%`"
    >{{ (value * 100).toFixed() }}%</span
  >
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
      validator: (value) => typeof value === 'number' && value >= 0.0 && value <= 1.0,
    },
  },
  computed: {
    colorClass() {
      if (this.value > 0.8) return 'is-success'
      if (this.value > 0.6) return 'is-warning'
      return 'is-danger'
    },
  },
})
</script>
