<template>
  <tr>
    <td
      :title="iconTitle"
      class="key"
    >
      <i :class="iconClass"></i>
      {{ metadata.name }}
    </td>
    <td class="value">
      <InterpretedDate
        v-if="containsDates"
        :dates="metadata.dates"
        :raw-date="metadata.value.toString()"
      />
      <router-link
        v-else-if="metadata.entity"
        :to="{ name: 'entity-details', params: { id: metadata.entity.id } }"
      >
        {{ metadata.entity.name }}
      </router-link>
      <a
        v-else-if="metadata.type === 'url'"
        :href="metadata.value.toString()"
        target="_blank"
      >
        {{ metadata.value }}
      </a>
      <template v-else>{{ metadata.value }}</template>
    </td>
    <td class="is-narrow pr-0">
      <!-- Pass v-bind as the last attribute, allowing to override the placeholder or value -->
      <MetadataActions
        :corpus-id="corpusId"
        :element-id="elementId"
        :metadata="metadata"
        v-bind="$attrs"
      />
    </td>
    <td class="is-narrow pl-1">
      <WorkerRunSummary
        v-if="metadata.worker_run"
        :worker-run-details="metadata.worker_run"
        icon-only
      />
    </td>
  </tr>
</template>

<script lang="ts">
import { isEmpty } from 'lodash'
import { type PropType, defineComponent } from 'vue'

import InterpretedDate from '@/components/InterpretedDate.vue'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import { METADATA_TYPES } from '@/config'
import type { UUID } from '@/types'
import type { MetaData } from '@/types/metadata'

import MetadataActions from './Actions.vue'

/**
 * Displays a single non-markdown metadata.
 */
export default defineComponent({
  components: {
    InterpretedDate,
    MetadataActions,
    WorkerRunSummary,
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true,
    },
    metadata: {
      type: Object as PropType<MetaData>,
      required: true,
      validator: (value: unknown): boolean =>
        value !== null && typeof value === 'object' && 'type' in value && value.type !== 'markdown',
    },
  },
  computed: {
    containsDates(): boolean {
      return !this.metadata.entity && !isEmpty(this.metadata.dates)
    },
    iconClass(): string {
      return METADATA_TYPES[this.metadata.type]?.icon ?? 'icon-feather'
    },
    iconTitle(): string {
      const typeDisplay = METADATA_TYPES[this.metadata.type]?.display ?? 'Metadata'
      if (this.metadata.entity) return `${typeDisplay} - ${this.metadata.entity.type.name}`
      return typeDisplay
    },
  },
})
</script>

<style scoped>
table td.key {
  color: #555;
  padding-left: 0.1em;
}

table {
  border-bottom: 1px solid #eee;
}
</style>
