<template>
  <tr :class="{ 'has-background-white-ter': disabled }">
    <td>
      <p>
        <router-link
          v-if="!disabled"
          :to="elementRoute"
          :title="element.name"
        >
          {{ truncateLong(element.name) }}
        </router-link>
        <span
          v-else
          :title="element.name"
        >
          {{ truncateLong(element.name) }}
        </span>
      </p>
    </td>
    <td :title="elementTypeName">
      {{ truncateShort(elementTypeName) }}
    </td>
    <td v-if="classDisplay">
      {{ classDisplay }}
    </td>
    <td v-if="!disabled">
      <span class="field has-addons action-buttons">
        <button
          class="button"
          :class="{ 'is-success': isLoggedOn && selected }"
          v-if="hasFeature('selection')"
          v-on:click="toggleSelection"
          :disabled="!isLoggedOn || undefined"
        >
          <i class="icon-check"></i>
        </button>
        <template v-if="hasPreview">
          <PreviewDropdown :element-id="element.id" />
        </template>
        <DeleteModal :target="element">
          <template v-slot:default="{ open, canDelete }">
            <button
              :class="canDelete ? 'has-text-danger' : 'has-text-grey'"
              :title="
                canDelete
                  ? 'Delete this element'
                  : 'You do not have the required rights to delete this element'
              "
              class="button"
              v-on:click="open"
            >
              <i class="icon-trash"></i>
            </button>
          </template>
        </DeleteModal>
      </span>
    </td>
  </tr>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'
import type { LocationQueryRaw, RouteLocationRaw } from 'vue-router'

import DeleteModal from '@/components/SingleDeletionModal.vue'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAuthStore, useClassificationStore, useDisplayStore, useSelectionStore } from '@/stores'
import type { ElementBase, ElementType, UUID } from '@/types'
import type { ProcessElementList } from '@/types/process'

import PreviewDropdown from './PreviewDropdown.vue'

export default defineComponent({
  mixins: [truncateMixin, corporaMixin],
  components: {
    DeleteModal,
    PreviewDropdown,
  },
  props: {
    element: {
      type: Object as PropType<ElementBase | ProcessElementList>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useSelectionStore, ['selection']),
    ...mapState(useDisplayStore, ['elementsTableLayout', 'displayElementClasses']),
    ...mapState(useClassificationStore, ['classifications']),
    ...mapState(useAuthStore, ['isLoggedOn', 'hasFeature']),
    elementRoute(): RouteLocationRaw {
      const query: LocationQueryRaw = this.elementsTableLayout ? { display: 'table' } : {}
      if (this.$route?.params?.id) query.from = this.$route.params.id
      return { name: 'element-details', params: { id: this.element.id }, query }
    },
    corpusId(): UUID | null {
      if (!this.element.corpus) return null
      return this.element.corpus.id
    },
    elementType(): ElementType | null {
      if ('type' in this.element) return this.getType(this.element.type)
      else if (this.element.type_id && this.corpus?.types)
        return (
          Object.values(this.corpus.types).find(
            ({ id }) => 'type_id' in this.element && id === this.element.type_id,
          ) ?? null
        )
      return null
    },
    elementTypeName(): string {
      return (
        this.elementType?.display_name ??
        this.elementType?.slug ??
        ('type' in this.element ? this.element.type : '')
      )
    },
    selected(): boolean {
      return this.corpusId !== null && this.selection[this.corpusId]?.includes(this.element.id)
    },
    classDisplay(): string | null {
      if (!this.displayElementClasses || !Array.isArray(this.classifications[this.element.id]))
        return null
      if (!this.classifications[this.element.id].length) return '—'
      const names = this.classifications[this.element.id].map(({ ml_class: { name } }) => name)
      names.sort()
      return names.join(', ')
    },
    /**
     * The backend generates thumbnail URLs for all folder elements, or for non-folder elements with a zone.
     * Since most folder elements do not have a zone anyway, we only display the preview modal for non-folder
     * elements with a thumbnail URL.
     */
    hasPreview(): boolean {
      return (
        !this.elementType?.folder &&
        'thumbnail_url' in this.element &&
        this.element.thumbnail_url !== null
      )
    },
  },
  methods: {
    ...mapActions(useSelectionStore, ['select', 'unselect']),
    toggleSelection() {
      if (this.element.corpus === null) return
      // This pleases TypeScript by making it understand that the corpus is not null on this element
      const element = { ...this.element, corpus: { ...this.element.corpus } }
      if (this.selected) this.unselect(element)
      else this.select([element])
    },
  },
})
</script>

<style scoped>
.has-tiny-text {
  font-size: x-small !important;
  line-height: 0.5em;
}
.nowrap {
  white-space: nowrap;
}
td {
  vertical-align: middle;
  padding: 0.25em 0.75em;
}

.action-buttons > :not(:first-child) {
  margin-left: 0.5rem;
}
</style>
