<template>
  <form
    class="form"
    v-on:submit.prevent="updateTranscription"
  >
    <div class="field">
      <div class="control">
        <textarea
          ref="textInput"
          class="input"
          :class="{ 'is-loading': loading }"
          :style="orientationStyle(newOrientation)"
          v-model="newText"
          v-on:keydown.enter.exact.prevent="updateTranscription"
          placeholder="Transcription text"
          :rows="rows"
          :disabled="loading || undefined"
          required
        ></textarea>
        <template v-if="'text' in fieldErrors">
          <p
            class="help is-danger"
            v-for="err in fieldErrors.text"
            :key="err"
          >
            {{ err }}
          </p>
        </template>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <div class="control">
            <div class="select is-small">
              <select
                v-model="newOrientation"
                :disabled="loading || undefined"
                required
              >
                <option
                  v-for="(textOrientation, key) in TEXT_ORIENTATIONS"
                  :key="key"
                  :value="key"
                  :title="textOrientation.display"
                >
                  {{ textOrientation.display }}
                </option>
              </select>
              <template v-if="'orientation' in fieldErrors">
                <p
                  class="help is-danger"
                  v-for="err in fieldErrors.orientation"
                  :key="err"
                >
                  {{ err }}
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="control has-text-right">
            <button
              type="button"
              class="button"
              v-on:click="$emit('close')"
              :disabled="loading || undefined"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="button is-primary"
              :disabled="!canUpdate || loading || undefined"
              :title="canUpdate ? 'Update transcription' : updateDisabledTitle"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { mapActions } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { TEXT_ORIENTATIONS } from '@/config'
import { errorParser, hasWorkerRun, orientationStyle } from '@/helpers'
import { useNotificationStore, useTranscriptionStore } from '@/stores'
import type { Element, ElementBase, TextOrientation } from '@/types'
import type { Transcription } from '@/types/transcription'

/**
 * A transcription edition form.
 * Emits a `close` event to notify the parent component when a transcription was edited successfully
 * or when the user clicked the Cancel button.
 */
export default defineComponent({
  emits: ['close'],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
    transcription: {
      type: Object as PropType<Transcription>,
      required: true,
      // Cannot edit a transcription with a worker run
      validator: (transcription: Transcription): boolean => {
        return (
          transcription?.text.length > 0 &&
          transcription?.orientation &&
          !hasWorkerRun(transcription)
        )
      },
    },
  },
  data: () => ({
    newText: '',
    newOrientation: '' as TextOrientation,
    loading: false,
    fieldErrors: {},
    TEXT_ORIENTATIONS,
  }),
  mounted() {
    this.newText = this.transcription.text
    this.newOrientation = this.transcription.orientation
    ;(this.$refs.textInput as HTMLInputElement | null)?.focus()
  },
  computed: {
    /**
     * How many rows to allow in the textarea.
     */
    rows(): number {
      return Math.max(1, this.newText.split(/\r\n|\r|\n/).length)
    },
    canUpdate(): boolean {
      return (
        this.newText.trim() !== '' &&
        this.newOrientation !== null &&
        this.newOrientation in TEXT_ORIENTATIONS
      )
    },
    updateDisabledTitle(): string {
      if (!this.newOrientation || !(this.newOrientation in TEXT_ORIENTATIONS))
        return 'A valid text orientation value is required'
      else if (!this.newText.trim()) return 'A transcription text is required'
      return ''
    },
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useTranscriptionStore, ['update']),
    orientationStyle,
    setErrors(error: unknown) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!Object.keys(error).includes('response'))
        this.fieldErrors = { error: errorParser(error) }
      if (isAxiosError(error)) {
        if (typeof error.response?.data !== 'object')
          this.fieldErrors = { error: errorParser(error) }
        else this.fieldErrors = error.response.data
      }
    },
    async updateTranscription() {
      if (!this.canUpdate) return
      this.loading = true
      try {
        await this.update(this.element.id, this.transcription.id, {
          text: this.newText,
          orientation: this.newOrientation,
        })
        // Notify the parent component that the edition is complete
        this.$emit('close')
      } catch (e) {
        this.setErrors(e)
        this.notify({
          type: 'error',
          text: `An error occurred when updating the transcription: ${errorParser(e)}`,
        })
      } finally {
        this.loading = false
      }
    },
  },
})
</script>
