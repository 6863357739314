<template>
  <input
    type="text"
    maxlength="10"
    placeholder="YYYY[-MM[-DD]]"
    title="The date must contain at least a full year, optionally a month and a day"
    class="input"
    :class="{ 'is-danger': !dateValidated, 'is-success': currentDate.length && dateValidated }"
    v-model="currentDate"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  emits: {
    valid: (value: boolean) => typeof value === 'boolean',
    'update:modelValue': (value: string) => typeof value === 'string',
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dateValidated: true,
  }),
  mounted() {
    this.currentDate = this.modelValue
  },
  computed: {
    currentDate: {
      get(): string {
        return this.modelValue
      },
      set(value: string) {
        this.dateValidated =
          !value.length || (/^\d{4}(-\d{2})?(-\d{2})?$/.test(value) && !isNaN(Date.parse(value)))
        this.$emit('update:modelValue', value)
        this.$emit('valid', this.dateValidated)
      },
    },
  },
})
</script>
