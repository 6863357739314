import axios from 'axios'

import { unique } from '@/helpers/api'
import type { PageNumberPagination, UUID } from '@/types'
import type { Model, ModelVersion } from '@/types/model'

import type { PageNumberPaginationParameters } from '.'

export type CreateModelPayload = Pick<Model, 'name'> & Partial<Pick<Model, 'description'>>
export type UpdateModelPayload = Partial<Pick<Model, 'name' | 'description' | 'archived'>>

export const createModel = unique(
  async (params: CreateModelPayload): Promise<Model> => (await axios.post('/models/', params)).data,
)

export interface ModelListParameters extends PageNumberPaginationParameters {
  /**
   * Restrict to models declared as compatible with a worker ID
   */
  compatible_worker?: UUID

  /**
   * Filter models by case-insensitive name
   */
  name?: string

  /**
   * Filter models according to whether or not they are archived
   */
  archived?: boolean
}

export type ModelVersionEditParameters = Partial<
  Pick<ModelVersion, 'description' | 'tag' | 'parent' | 'configuration'>
>

export const listModels = unique(
  async (params: ModelListParameters): Promise<PageNumberPagination<Model>> =>
    (await axios.get('/models/', { params })).data,
)

export const retrieveModel = unique(
  async (id: UUID): Promise<Model> => (await axios.get(`/model/${id}/`)).data,
)

export const updateModel = unique(
  async (id: UUID, params: UpdateModelPayload): Promise<Model> =>
    (await axios.patch(`/model/${id}/`, params)).data,
)

export const listModelVersions = unique(
  async (
    modelId: UUID,
    params: PageNumberPaginationParameters,
  ): Promise<PageNumberPagination<ModelVersion>> =>
    (await axios.get(`/model/${modelId}/versions/`, { params })).data,
)

export const deleteModelVersion = unique(
  async (id: UUID) => await axios.delete(`/modelversion/${id}/`),
)

export const retrieveModelVersion = unique(
  async (id: UUID): Promise<ModelVersion> => (await axios.get(`/modelversion/${id}/`)).data,
)

export const updateModelVersion = unique(
  async (id: UUID, params: ModelVersionEditParameters): Promise<ModelVersion> =>
    (await axios.patch(`/modelversion/${id}/`, params)).data,
)

export const createCompatibleWorker = unique(
  async (modelId: UUID, workerId: UUID) =>
    await axios.post(`/model/${modelId}/compatible-worker/${workerId}/`),
)

export const deleteCompatibleWorker = unique(
  async (modelId: UUID, workerId: UUID) =>
    await axios.delete(`/model/${modelId}/compatible-worker/${workerId}/`),
)
