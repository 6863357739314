<template>
  <router-link
    :to="{ name: 'worker-version', params: { versionId: workerVersion.id } }"
    target="_blank"
  >
    <span
      class="tag is-uppercase is-light mr-1"
      :title="workerVersion.worker.type"
      :class="workerClass"
    >
      {{ workerVersion.worker.type }}
    </span>
    {{ truncateLong(workerVersion.worker.name) }}
  </router-link>
  <br />
  <small>
    <template v-if="workerVersion.revision_url">
      <a
        :href="workerVersion.revision_url"
        target="_blank"
      >
        {{ truncateLong(workerVersion.revision_url) }}
      </a>
    </template>
    <template v-else>Version {{ workerVersion.version }}</template>
  </small>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import { WORKER_TYPE_COLORS } from '@/config'
import { truncateMixin } from '@/mixins'
import type { WorkerVersion } from '@/types/worker'

export default defineComponent({
  props: {
    workerVersion: {
      type: Object as PropType<WorkerVersion>,
      required: true,
    },
  },
  mixins: [truncateMixin],
  computed: {
    workerClass(): string {
      return (
        WORKER_TYPE_COLORS[this.workerVersion.worker.type]?.cssClass ??
        WORKER_TYPE_COLORS.default.cssClass
      )
    },
  },
})
</script>
