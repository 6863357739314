<template>
  <Modal v-model="modalState">
    <div v-if="loading">
      <span class="loader is-size-2 mx-auto"></span>
    </div>
    <div
      v-else-if="error"
      class="notification is-danger"
    >
      Failed to load worker run <ItemId :item-id="workerRunId" />: {{ error }}
    </div>
    <div v-else-if="workerRun">
      <div class="mb-5">
        <strong>Worker run ID: </strong>
        <ItemId :item-id="workerRunId" />
      </div>
      <hr />
      <div class="mb-5">
        <h2 class="subtitle has-text-weight-bold">
          Worker
          <span
            class="tag is-uppercase is-light mx-1"
            :class="workerClass(workerRun.worker_version.worker.type)"
            >{{ workerType?.display_name ?? workerRun.worker_version.worker.type }}</span
          >
        </h2>
        <div class="mt-2">
          <p>
            <!-- Spacing the field label and value from inside the <strong> due to vue3 space handling -->
            <strong>Name: </strong>
            {{ workerRun.worker_version.worker.name || '—' }}
          </p>
          <template v-if="workerRun.worker_version.revision_url">
            <p>
              <strong>Revision: </strong>
              <a
                :href="workerRun.worker_version.revision_url"
                target="_blank"
              >
                {{ truncateLong(workerRun.worker_version.revision_url) }}
              </a>
            </p>
          </template>
          <p v-else>
            <strong>Version: </strong>
            {{ workerRun.worker_version.version }}
          </p>
          <p class="mt-2">
            <strong>Worker version ID: </strong>
            <ItemId :item-id="workerRun.worker_version.id" />
          </p>
          <p>
            <strong>Worker version creation date: </strong>
            {{ versionCreatedDate }}
          </p>
          <p>
            <strong>Worker ID: </strong>
            <ItemId :item-id="workerRun.worker_version.worker.id" />
          </p>
        </div>
      </div>
      <hr />
      <div
        class="mb-5"
        v-if="!loading && workerRun?.model_version?.id"
      >
        <h2 class="subtitle has-text-weight-bold">Model</h2>
        <div class="mt-2">
          <p>
            <strong>Name: </strong>
            {{ workerRun.model_version.model.name || '—' }}
          </p>
          <p>
            <strong>State: </strong>
            <span
              class="tag is-light mx-1"
              :class="modelVersionStateClass(workerRun.model_version.state)"
              >{{ workerRun.model_version.state }}</span
            >
          </p>
          <p>
            <strong>Size: </strong>
            {{ workerRun.model_version.size }}
          </p>
          <p class="mt-2">
            <strong>Model version ID: </strong>
            <ItemId :item-id="workerRun.model_version.id" />
          </p>
          <p>
            <strong>Model version tag:</strong>
            <span class="tag mx-1">{{ workerRun.model_version.tag || '—' }}</span>
          </p>
          <p>
            <strong>Model ID: </strong>
            <ItemId :item-id="workerRun.model_version.model.id" />
          </p>
          <details>
            <summary><strong>Configuration</strong></summary>
            <pre>{{ workerRun.model_version.configuration }}</pre>
          </details>
        </div>
      </div>
      <div
        v-else
        class="mb-5"
      >
        <div class="notification is-info is-light">No model.</div>
      </div>
      <hr />
      <div
        class="mb-5"
        v-if="!loading && workerRun?.configuration?.id"
      >
        <h2 class="subtitle has-text-weight-bold">Configuration</h2>
        <div class="mt-2">
          <p>
            <strong>Name: </strong>
            {{ workerRun.configuration.name || '—' }}
          </p>
          <details>
            <summary><strong>Configuration</strong></summary>
            <pre>{{ workerRun.configuration.configuration }}</pre>
          </details>
          <p class="mt-2">
            <strong>Configuration ID: </strong>
            <ItemId :item-id="workerRun.configuration.id" />
          </p>
          <p>
            <strong>Archived: </strong>
            {{ humanBoolean(workerRun.configuration.archived) }}
          </p>
        </div>
      </div>
      <div
        v-else
        class="mb-5"
      >
        <div class="notification is-info is-light">No configuration.</div>
      </div>
      <hr />
      <div v-if="!loading && workerRun?.process?.id">
        <h2 class="subtitle has-text-weight-bold">Process</h2>
        <div class="mt-2">
          <p>
            <strong>Name: </strong>
            {{ workerRun.process.name || '—' }}
          </p>
          <p>
            <strong>State: </strong>
            <ProcessStateTag
              class="mx-1"
              :state="workerRun.process.state"
            />
          </p>
          <p class="mt-2">
            <strong>Process ID: </strong>
            <ItemId :item-id="workerRun.process.id" />
          </p>
        </div>
      </div>
    </div>
    <template v-slot:footer="{ close }">
      <span
        class="button"
        v-on:click="close"
        >Close</span
      >
      <div class="field is-grouped">
        <router-link
          v-if="workerRun?.model_version?.id"
          class="button"
          :to="{ name: 'model-version', params: { versionId: workerRun?.model_version.id } }"
        >
          View model version
        </router-link>
        <router-link
          v-if="workerRun?.worker_version.id"
          class="button"
          :to="{ name: 'worker-version', params: { versionId: workerRun?.worker_version.id } }"
        >
          View worker version
        </router-link>
        <router-link
          v-if="workerRun?.process.id"
          class="button is-success"
          :to="{ name: 'process-details', params: { id: workerRun.process.id } }"
        >
          View process
        </router-link>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { isEmpty } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import ItemId from '@/components/ItemId.vue'
import Modal from '@/components/Modal.vue'
import ProcessStateTag from '@/components/Process/StateTag.vue'
import { MODEL_VERSION_STATE_COLORS, WORKER_TYPE_COLORS } from '@/config'
import { ModelVersionState } from '@/enums'
import { ago, errorParser } from '@/helpers'
import { truncateMixin } from '@/mixins'
import { useNotificationStore, useWorkerStore } from '@/stores'

export default defineComponent({
  emits: {
    'update:modelValue'(value: boolean) {
      return typeof value === 'boolean'
    },
  },
  mixins: [truncateMixin],
  components: {
    ItemId,
    Modal,
    ProcessStateTag,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    workerRunId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    error: null as string | null,
  }),
  mounted() {
    if (isEmpty(this.workerTypes)) this.listWorkerTypes()
  },
  computed: {
    ...mapState(useWorkerStore, ['workerRuns', 'workerTypes']),
    workerRun() {
      if (!this.workerRunId || !this.workerRuns) return
      return this.workerRuns[this.workerRunId]
    },
    workerType() {
      if (this.workerRun === undefined || isEmpty(this.workerTypes)) return
      return Object.values(this.workerTypes).find(
        (t) => t.slug === this.workerRun?.worker_version.worker.type,
      )
    },
    modalState: {
      get() {
        return this.modelValue
      },
      set(value: boolean) {
        this.$emit('update:modelValue', value)
      },
    },
    versionCreatedDate() {
      if (this.workerRun) return ago(new Date(this.workerRun.worker_version.created))
      return ''
    },
  },
  methods: {
    ...mapActions(useWorkerStore, ['getWorkerRun', 'listWorkerTypes']),
    ...mapActions(useNotificationStore, ['notify']),
    humanBoolean(value: boolean): string {
      if (value) return 'Yes'
      return 'No'
    },
    async workerRunDetails() {
      if (!this.workerRunId || this.loading || this.workerRuns?.[this.workerRunId]) return
      this.loading = true
      this.error = null
      try {
        await this.getWorkerRun(this.workerRunId)
      } catch (err) {
        this.error = errorParser(err)
      } finally {
        this.loading = false
      }
    },
    workerClass: (type: string) => {
      return WORKER_TYPE_COLORS[type]?.cssClass ?? WORKER_TYPE_COLORS.default.cssClass
    },
    modelVersionStateClass: (state: ModelVersionState) => {
      return MODEL_VERSION_STATE_COLORS[state]
    },
  },
  watch: {
    workerRunId: 'workerRunDetails',
    modalState: {
      immediate: true,
      handler(newValue) {
        if (newValue) this.workerRunDetails()
      },
    },
  },
})
</script>
