<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="(value) => $emit('update:modelValue', value)"
    title="Delete export"
  >
    <span> Are you sure you want to delete this export? </span>
    <br />
    <span>This action is irreversible.</span>
    <template v-slot:footer="{ close }">
      <button
        class="button"
        v-on:click="close"
      >
        Cancel
      </button>
      <button
        class="button is-danger"
        :class="{ 'is-loading': loading }"
        v-on:click="deleteExport"
      >
        Delete
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { mapActions } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import Modal from '@/components/Modal.vue'
import { useExportStore } from '@/stores'
import type { CorpusExport } from '@/types/export'

export default defineComponent({
  components: {
    Modal,
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    corpusExport: {
      type: Object as PropType<CorpusExport>,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions(useExportStore, ['delete']),
    async deleteExport() {
      if (this.loading) return
      this.loading = true
      try {
        await this.delete(this.corpusExport.id, this.corpusExport.corpus_id)
      } finally {
        this.loading = false
        this.$emit('update:modelValue', false)
      }
    },
  },
})
</script>
