<template>
  <div class="is-pulled-right slider-wrapper">
    <a
      class="icon-add-square has-text-grey-darker"
      title="Zoom in"
      v-on:click="zoom(scale.factor + 1)"
    ></a>
    <input
      type="range"
      :min="0"
      :max="ZOOM_FACTORS.length - 1"
      :title="`${ZOOM_FACTORS[scale.factor]} %`"
      v-model="slideLevel"
      v-on:input="zoom(slideLevel)"
    />
    <a
      class="icon-remove-square has-text-grey-darker"
      title="Zoom out"
      v-on:click="zoom(scale.factor - 1)"
    ></a>
  </div>
</template>

<script>
import { ZOOM_FACTORS } from '@/config'

export default {
  emits: ['update:scale'],
  props: {
    scale: {
      // A { x, y, factor, applied } object representing zoom state
      type: Object,
      required: true,
    },
  },
  data: () => ({
    ZOOM_FACTORS,
    slideLevel: 0,
  }),
  methods: {
    zoom(factor) {
      if (factor >= ZOOM_FACTORS.length || factor < 0) return
      this.$emit('update:scale', {
        x: null,
        y: null,
        factor: parseInt(factor),
        applied: false,
      })
    },
  },
  watch: {
    scale: {
      immediate: true,
      handler() {
        this.slideLevel = this.scale.factor
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.slider-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 3rem;
  height: 5.5rem;
  background: white;
  border: solid 1px #ccc;
  border-radius: 2px;
  & input {
    /*
     * Required for Firefox compatibility
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/range#orient_attribute
     */
    transform: rotate(-90deg);
    height: 3rem;
  }
  /*
   * The ImageLayer's overlay spans the entire InteractiveImage, so the overlay disables all pointer events on itself
   * so that they can go through it and be handled on the image or polygons.
   * We re-enable them on this component so that the user can interact with either the slider or the image/polygons.
   */
  pointer-events: initial;
}
</style>
