<template>
  <tr>
    <td>
      <router-link :to="{ name: 'entity-details', params: { id: entity.id } }">{{
        entity.name
      }}</router-link>
    </td>
    <td>
      <p
        v-for="[key, value] in sortedMetas"
        :key="key"
      >
        <strong>{{ key }}</strong
        >: {{ value }}
      </p>
      <template v-if="!sortedMetas.length">&mdash;</template>
    </td>
    <td>
      <WorkerRunSummary
        v-if="entity.worker_run"
        multiline
        :worker-run-details="entity.worker_run"
      />
      <template v-else>&mdash;</template>
    </td>
  </tr>
</template>

<script lang="ts">
import { orderBy } from 'lodash'
import { type PropType, defineComponent } from 'vue'

import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import type { EntityLight } from '@/types/entity'

export default defineComponent({
  components: {
    WorkerRunSummary,
  },
  props: {
    entity: {
      type: Object as PropType<EntityLight>,
      required: true,
    },
  },
  computed: {
    sortedMetas() {
      if (!this.entity.metas) return []
      return orderBy(Object.entries(this.entity.metas), ([key]) => key)
    },
  },
})
</script>
