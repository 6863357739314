<template>
  <main class="container is-fluid">
    <h1 class="title">Search in project</h1>

    <div class="columns is-multiline">
      <div class="column is-one-quarter-desktop is-full">
        <span class="select is-fullwidth mb-4">
          <select
            :disabled="loading || undefined"
            v-model="selectedCorpusId"
          >
            <option
              value=""
              selected
              disabled
            >
              Choose a project
            </option>
            <option
              v-for="corpus in indexableCorpora"
              :key="corpus.id"
              :value="corpus.id"
            >
              {{ corpus.name }}
            </option>
          </select>
        </span>
        <SearchForm
          v-if="corpusId"
          :corpus-id="corpusId"
          :all-facets="documents?.facets"
          :loading="loading"
          v-model="query"
        />
      </div>
      <div class="column">
        <Paginator
          v-if="corpusId"
          :response="documents ?? undefined"
          bottom-bar
          v-model:page="page"
          v-slot="{ results }"
        >
          <div class="columns is-multiline">
            <div
              class="column is-one-quarter-desktop is-one-third"
              v-for="document in results"
              :key="document.id"
            >
              <SearchResult :document="document" />
            </div>
          </div>
        </Paginator>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { isEqual } from 'lodash'
import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import Paginator from '@/components/Paginator.vue'
import SearchForm from '@/components/Search/Form.vue'
import SearchResult from '@/components/Search/Result.vue'
import { UUID_REGEX } from '@/config'
import { corporaMixin } from '@/mixins'
import { useCorporaStore, useSearchStore } from '@/stores'
import type { Corpus, UUID } from '@/types'
import type { SearchQuery } from '@/types/search'

export default defineComponent({
  components: {
    Paginator,
    SearchForm,
    SearchResult,
  },
  mixins: [corporaMixin],
  props: {
    corpusId: {
      type: String,
      default: '',
      validator: (value) => typeof value === 'string' && (value === '' || UUID_REGEX.test(value)),
    },
  },
  mounted() {
    useSearchStore().$reset()

    // There can be a random race condition causing the corpora to not always be listed
    if (!this.corporaStore.loaded) this.corporaStore.list()

    if (this.selectedCorpusId) this.search(this.selectedCorpusId, this.$route.query)
    // Support the older search URLs that used ?corpus=… instead of a path parameter
    else if (this.$route.query?.corpus) this.selectedCorpusId = this.$route.query.corpus.toString()
  },
  beforeRouteUpdate(to) {
    if (!to.params.corpusId || Array.isArray(to.params.corpusId)) return
    this.search(to.params.corpusId, to.query)
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapStores(useCorporaStore),
    ...mapState(useSearchStore, ['documents']),
    indexableCorpora(): Corpus[] {
      return Object.values(this.corpora).filter((c) => c.indexable)
    },
    selectedCorpusId: {
      get(): UUID | '' {
        return this.corpusId
      },
      set(corpusId: UUID) {
        if (this.corpusId === corpusId) return
        this.$router.push({ name: this.$route.name, params: { corpusId }, query: { query: '*' } })
      },
    },
    query: {
      get() {
        return this.$route.query
      },
      set(query: SearchQuery) {
        const newQuery = {
          ...query,
          only_facets: (query.only_facets || false).toString(),
        }

        if (!isEqual(this.$route.query, newQuery)) {
          // The query has changed, update the route.
          this.$router.push({ ...this.$route, query: newQuery })
        } else if (this.corpusId) {
          // If the search button is hit, the query hasn't changed, and a corpus is selected, re-run the search
          this.search(this.corpusId, query)
        }
      },
    },
    page: {
      get(): number {
        if (!this.query.page) return 1
        return Number.parseInt(this.query.page.toString(), 10) || 1
      },
      set(page: number) {
        this.query = { ...this.query, page: page.toString() }
      },
    },
  },
  methods: {
    async search(corpusId: UUID, query: Partial<SearchQuery> = {}) {
      if (!corpusId) corpusId = this.corpusId
      if (this.loading || !UUID_REGEX.test(corpusId)) return

      this.loading = true
      try {
        await useSearchStore().search(corpusId, {
          // Use `?query=*` by default because a search query is mandatory
          query: '*',
          // Also enable all sources by default. This means searching with zero sources selected will act like all sources are selected.
          sources: ['element', 'transcription', 'metadata', 'entity', 'classification'],
          ...query,
        })
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    corpusId(newValue: UUID | '') {
      // Block using a corpus ID that does not exist or that is not indexable
      if (newValue && !this.corpus?.indexable) this.selectedCorpusId = ''
      // Reset results when clicking 'Search' in the NavBar
      if (!newValue) useSearchStore().$reset()
    },
  },
})
</script>
