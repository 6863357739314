<template>
  <div
    class="card"
    v-if="sortedItems.length"
  >
    <div class="card-header">
      <div class="card-header-title is-capitalized">{{ name.replace('_', ' ') }}</div>
      <div
        v-if="sortedItems.length > 1"
        class="facet-sort has-text-right"
        v-on:click="nameSort = !nameSort"
      >
        <a>{{ sortingOption }}</a>
      </div>
    </div>
    <div class="card-content">
      <div
        v-for="[value, count] in sortedItems"
        :key="value"
      >
        <input
          type="checkbox"
          :disabled="loading || undefined"
          v-on:change="updateChecked(value, ($event.target as HTMLInputElement).checked)"
          :checked="modelValue === value"
        />
        <label
          :for="value"
          :title="value"
          >{{ truncateSelect(value) }} ({{ count }})</label
        >
        <!-- The input and label tags must be on the same line for the space between checkbox and label to show -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { orderBy } from 'lodash'
import { type PropType, defineComponent } from 'vue'

import { truncateMixin } from '@/mixins'

export default defineComponent({
  emits: {
    'update:modelValue': (value: string) => typeof value === 'string',
  },
  mixins: [truncateMixin],
  props: {
    name: {
      type: String,
      required: true,
    },
    values: {
      type: Object as PropType<Record<string, number>>,
      required: true,
    },
    modelValue: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    nameSort: true,
  }),
  computed: {
    sortingOption() {
      if (this.nameSort) return 'Sort by occurrences'
      return 'Sort by name'
    },
    sortedItems(): [string, number][] {
      if (this.modelValue) return [[this.modelValue, this.values[this.modelValue]]]
      else {
        const arrayed = Object.entries(this.values).filter((item) => item[1] > 0)
        if (this.nameSort) return orderBy(arrayed, 0, 'asc')
        else return orderBy(arrayed, [1, 0], ['desc', 'asc'])
      }
    },
  },
  methods: {
    updateChecked(facet: string, value: boolean) {
      if (value) this.$emit('update:modelValue', facet)
      else this.$emit('update:modelValue', '')
    },
  },
})
</script>

<style scoped>
.facet-sort {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-right: 1em;
}
</style>
