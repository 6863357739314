<template>
  <header
    class="header"
    v-if="corpus"
  >
    <div class="columns is-vcentered">
      <div class="column">
        <span class="navbar-item">
          <strong>{{ truncateLong(corpus.name) }}</strong>
          <router-link
            v-if="isVerified"
            :to="{ name: 'corpus-update', params: { corpusId } }"
            title="Project information"
            class="ml-1"
          >
            <i class="icon-edit"></i>
          </router-link>
        </span>
      </div>
      <HeaderActions :corpus-id="corpusId" />
    </div>
  </header>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import HeaderActions from '@/components/HeaderActions.vue'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAuthStore } from '@/stores'
import type { UUID } from '@/types'

export default defineComponent({
  mixins: [corporaMixin, truncateMixin],
  components: {
    HeaderActions,
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
  },
  computed: {
    ...mapState(useAuthStore, ['isVerified']),
  },
})
</script>
