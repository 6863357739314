<template>
  <form
    v-if="editing && enabled"
    v-on:submit.prevent="save"
  >
    <div class="field has-addons">
      <p class="control">
        <input
          class="input"
          type="text"
          placeholder="Name"
          :disabled="loading || undefined"
          v-model="name"
        />
      </p>
      <p class="control">
        <button
          class="button is-primary"
          type="submit"
          :class="{ 'is-loading': loading }"
          :disabled="loading || undefined"
        >
          <i class="icon-edit"></i>
        </button>
      </p>
    </div>
  </form>
  <div
    v-else
    class="is-flex"
  >
    <span
      :title="instance.name"
      class="is-selectable"
    >
      {{ truncateLong(instance.name) }}
    </span>
    <a
      v-if="enabled"
      v-on:click="editing = true"
    >
      <i class="icon-edit has-text-info"></i>
    </a>
  </div>
</template>

<script lang="ts">
import { mapActions } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { errorParser } from '@/helpers'
import { truncateMixin } from '@/mixins'
import { useNotificationStore } from '@/stores'

export default defineComponent({
  mixins: [truncateMixin],
  props: {
    instance: {
      type: Object as PropType<{ name: string }>,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    saveFunction: {
      type: Function as PropType<(name: string) => Promise<unknown>>,
      required: true,
    },
  },
  data() {
    return {
      editing: false,
      loading: false,
      name: this.instance.name,
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    async save() {
      if (!this.editing || this.loading) return
      this.loading = true
      try {
        await this.saveFunction(this.name)
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
      } finally {
        this.loading = false
        this.editing = false
      }
    },
  },
  watch: {
    instance() {
      if (!this.editing || this.loading) this.name = this.instance.name
      // Do not keep the editing form open if the element changes
      this.editing = false
    },
  },
})
</script>

<style>
.is-selectable {
  user-select: text;
}
</style>
