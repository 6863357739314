<template>
  <textarea
    v-if="field.multiline"
    class="textarea"
    :value="modelValue"
    v-on:input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    :placeholder="field.type"
  ></textarea>
  <input
    v-else
    class="input"
    :value="modelValue"
    v-on:input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    :placeholder="field.type"
  />
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import type { StringUserConfigurationField } from '@/types/workerConfiguration'

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    field: {
      type: Object as PropType<StringUserConfigurationField>,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
})
</script>
