<template>
  <div class="field">
    <div
      class="field has-addons"
      v-if="modelValue"
    >
      <p class="control">
        <input
          class="input"
          disabled
          :value="modelDisplayString"
        />
      </p>
      <p class="control">
        <button
          v-on:click="modelsModal = true"
          title="Select another model"
          class="button"
          type="button"
        >
          <i class="icon-edit has-text-primary"></i>
        </button>
      </p>
      <p class="control">
        <button
          class="button"
          type="button"
          v-on:click="$emit('update:modelValue', '')"
          title="Remove model"
        >
          <i class="icon-minus has-text-danger"></i>
        </button>
      </p>
    </div>
    <button
      v-else
      class="button"
      type="button"
      v-on:click="modelsModal = true"
    >
      Select model
    </button>
    <Modal
      v-model="modelsModal"
      title="Select a model"
    >
      <ModelPicker
        :compatible-worker-id="workerId"
        v-model="modelId"
      />
    </Modal>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import Modal from '@/components/Modal.vue'
import ModelPicker from '@/components/Model/ModelPicker.vue'
import { UUID_REGEX } from '@/config'
import { errorParser } from '@/helpers'
import { useModelStore, useNotificationStore } from '@/stores'
import type { UUID } from '@/types'
import type { ModelUserConfigurationField } from '@/types/workerConfiguration'

export default defineComponent({
  emits: {
    'update:modelValue'(value: UUID) {
      return typeof value === 'string' && (value === '' || UUID_REGEX.test(value))
    },
  },
  props: {
    field: {
      type: Object as PropType<ModelUserConfigurationField>,
      required: true,
    },
    modelValue: {
      type: String as PropType<UUID>,
      required: true,
    },
    workerId: {
      type: String as PropType<UUID>,
      required: true,
    },
  },
  components: {
    ModelPicker,
    Modal,
  },
  data: () => ({
    loading: false,
    page: 1,
    modelsModal: false,
  }),
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useModelStore, ['retrieveModel']),
  },
  computed: {
    ...mapState(useModelStore, ['models']),
    modelDisplayString() {
      return this.models[this.modelId]?.name || this.modelId
    },
    modelId: {
      get(): string {
        return this.modelValue
      },
      set(value: string) {
        this.$emit('update:modelValue', value)
        if (value) {
          this.notify({
            type: 'success',
            text: `Model ${this.models[value].name} added to configuration`,
          })
        } else {
          this.notify({ type: 'info', text: 'Model removed from configuration' })
        }
      },
    },
  },
  watch: {
    modelId: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue && !this.models[newValue]) {
          try {
            await this.retrieveModel(newValue)
          } catch (err) {
            this.notify({
              type: 'error',
              text: `An error occurred retrieving model ${newValue}: ${errorParser(err)}`,
            })
          }
        }
      },
    },
  },
})
</script>
