<template>
  <button
    v-if="mode === 'edit'"
    class="button"
    v-on:click="openModal = allowOpen"
    v-bind="$attrs"
    :disabled="!allowOpen || undefined"
    :title="openButtonTitle"
  >
    <i class="icon-edit"></i>
  </button>
  <button
    v-else-if="mode === 'create'"
    class="button is-primary"
    v-on:click="openModal = allowOpen"
    v-bind="$attrs"
    :disabled="!allowOpen || undefined"
    :title="openButtonTitle"
  >
    Create
  </button>

  <Modal
    v-model="openModal"
    :title="modalTitle"
    is-large
  >
    <form
      class="form"
      v-on:submit.prevent="saveAction"
    >
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input
            class="input"
            :class="{ 'is-danger': fieldErrors.name }"
            type="text"
            maxlength="100"
            v-model.trim="payload.name"
            :disabled="loading || undefined"
          />
          <template v-if="fieldErrors.name">
            <p
              class="help is-danger"
              v-for="err in fieldErrors.name"
              :key="err"
            >
              {{ err }}
            </p>
          </template>
        </div>
      </div>

      <div class="field">
        <label class="label">Description</label>
        <div class="control">
          <textarea
            class="textarea"
            :class="{ 'is-danger': fieldErrors.description }"
            type="text"
            v-model.trim="payload.description"
            :disabled="loading || undefined"
          ></textarea>
          <template v-if="fieldErrors.description">
            <p
              class="help is-danger"
              v-for="err in fieldErrors.description"
              :key="err"
            >
              {{ err }}
            </p>
          </template>
        </div>
      </div>
    </form>

    <template v-slot:footer="{ close }">
      <button
        class="button"
        v-on:click="close"
      >
        Cancel
      </button>
      <button
        type="submit"
        v-on:click="saveAction"
        class="button is-primary"
        :class="{ 'is-loading': loading }"
        :disabled="loading || !payload.name.length || undefined"
      >
        {{ saveButtonName }}
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { mapActions } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import type { CreateModelPayload } from '@/api'
import Modal from '@/components/Modal.vue'
import { ensureArray, errorParser } from '@/helpers'
import { useModelStore, useNotificationStore } from '@/stores'
import type { Model } from '@/types/model'

export default defineComponent({
  components: {
    Modal,
  },
  emits: {
    reload: (value: boolean) => typeof value === 'boolean',
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    modelInstance: {
      type: Object as PropType<Model>,
      default: null,
    },
  },
  data: () => ({
    openModal: false,
    payload: {
      name: '',
      description: '',
    } as CreateModelPayload,
    loading: false,
    fieldErrors: {} as Partial<Record<keyof CreateModelPayload, string[]>>,
  }),
  computed: {
    allowOpen() {
      if (this.mode === 'edit' && this.modelInstance)
        return this.modelInstance.rights.includes('write')
      return true
    },
    openButtonTitle() {
      if (this.mode === 'edit') {
        return this.allowOpen
          ? 'Edit model'
          : 'A contributor access level is required to edit a model'
      }
      return 'Create a new model'
    },
    modalTitle() {
      if (this.mode === 'edit' && this.modelInstance)
        return `Edit model "${this.modelInstance.name}"`
      return 'Create a new model'
    },
    saveButtonName() {
      if (this.mode === 'edit') return 'Update'
      return 'Create'
    },
  },
  methods: {
    ...mapActions(useModelStore, ['updateModel', 'createModel', 'listModels']),
    ...mapActions(useNotificationStore, ['notify']),
    async saveAction() {
      if (
        this.loading ||
        (this.mode === 'edit' && !this.modelInstance) ||
        !this.payload.name.length
      )
        return
      this.loading = true
      this.fieldErrors = {}
      try {
        if (this.mode === 'edit' && this.modelInstance) {
          await this.updateModel(this.modelInstance.id, this.payload)
          this.notify({ type: 'info', text: 'Model updated successfully.' })
        } else {
          await this.createModel(this.payload)
          this.$emit('reload', true)
          this.notify({ type: 'success', text: 'Model successfully created.' })
        }
        this.openModal = false
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
        if (isAxiosError(err)) {
          this.fieldErrors = err?.response?.data
            ? Object.fromEntries(
                Object.entries(err?.response?.data).map(([k, v]) => [k, ensureArray(v)]),
              )
            : {}
        }
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    modelInstance: {
      immediate: true,
      handler() {
        if (!this.modelInstance) return
        this.payload.name = this.modelInstance.name
        this.payload.description = this.modelInstance.description
      },
    },
  },
})
</script>
