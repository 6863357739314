<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="updateModal"
    title="Populate dataset"
    is-large
  >
    <div
      v-if="loaded && !corpus?.id"
      class="notification is-warning"
    >
      Project is not found.
    </div>
    <div
      v-else-if="loadedParent && parentId && !parent"
      class="notification is-warning"
    >
      Parent element is not found.
    </div>
    <div
      v-else-if="!hasContribPrivilege"
      class="notification is-warning"
    >
      You must have a contributor access in order to perform this action.
    </div>
    <template v-else>
      <h2>
        Based on
        <template v-if="parent">
          <span
            class="is-capitalized"
            :title="parent.type"
            >{{ truncateLong(typeName(parent.type)) }}</span
          >
          <router-link :to="{ name: 'element-details', params: { id: parent.id } }">
            <span class="ml-1">{{ truncateLong(parent.name) }}</span>
          </router-link>
          of project
        </template>
        <router-link
          v-if="corpus?.id"
          :to="{ name: 'navigation', params: { corpusId } }"
        >
          {{ corpus.name }}
        </router-link>
      </h2>

      <form
        v-if="corpus"
        id="populateFormId"
        v-on:submit.prevent="submit"
      >
        <label class="label">Dataset</label>
        <div class="field has-addons">
          <div class="control is-expanded">
            <div
              class="select is-fullwidth"
              :class="{ 'is-danger': fieldErrors.dataset }"
            >
              <select
                v-if="availableDatasets.length === 0"
                disabled
              >
                <option selected>No available dataset</option>
              </select>
              <select
                v-else
                required
                v-model="selectedDatasetId"
                :class="{ 'is-danger': fieldErrors.top_level_type }"
                :disabled="loading || undefined"
              >
                <option
                  value=""
                  disabled
                  selected
                >
                  Select a dataset
                </option>
                <option
                  v-for="d in availableDatasets"
                  :key="d.id"
                  :value="d.id"
                >
                  {{ d.name }}
                </option>
              </select>
            </div>
            <p
              class="help is-danger"
              v-for="(err, i) in fieldErrors.dataset ?? []"
              :key="i"
            >
              {{ err }}
            </p>
          </div>
          <div class="control">
            <button
              type="button"
              class="button is-success"
              v-on:click="datasetModal = true"
              title="Create a new dataset"
            >
              Create
            </button>
          </div>
        </div>

        <div
          class="notification is-warning content"
          v-if="unsupportedFilterNames.length"
        >
          <p>
            The following navigation filters you have selected cannot be used when populating a
            dataset and will be ignored:
          </p>
          <ul>
            <li
              v-for="filterName in unsupportedFilterNames"
              :key="filterName"
            >
              {{ filterName }}
            </li>
          </ul>
        </div>

        <template v-if="parentId">
          <div class="field">
            <div class="control is-expanded">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="recursive"
                  :disabled="loading || undefined"
                />
                Select child elements recursively
              </label>
            </div>
            <p
              class="help is-danger"
              v-for="(err, i) in fieldErrors.recursive ?? []"
              :key="i"
            >
              {{ err }}
            </p>
          </div>
        </template>
        <!-- Display top-level (which is the inverse of recursive) when not using a parent element -->
        <template v-else>
          <div class="field">
            <div class="control is-expanded">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="topLevel"
                  :disabled="loading || undefined"
                />
                Select top-level elements only
              </label>
            </div>
            <p
              class="help is-danger"
              v-for="(err, i) in fieldErrors.recursive ?? []"
              :key="i"
            >
              {{ err }}
            </p>
          </div>
        </template>

        <label class="label">Filter by element types</label>
        <div class="field">
          <div
            class="select is-multiple"
            :class="{ 'is-danger': fieldErrors.types }"
          >
            <select
              multiple
              v-model="types"
              size="5"
              required
              class="is-fullwidth"
              :class="{ 'is-danger': fieldErrors.types }"
              :disabled="loading || undefined"
            >
              <option
                v-for="t in corpus.types"
                :key="t.slug"
                :value="t.slug"
              >
                {{ t.display_name }}
              </option>
            </select>
            <button
              type="button"
              class="button"
              v-on:click="selectAllTypes"
              :disabled="loading || undefined"
            >
              Select all types
            </button>
          </div>
          <p
            class="help is-danger"
            v-for="(err, i) in fieldErrors.types ?? []"
            :key="i"
          >
            {{ err }}
          </p>
        </div>

        <label class="label">Number of elements</label>
        <div class="field">
          <div class="control">
            <input
              v-model.number="count"
              type="number"
              min="1"
              class="input"
              :class="{ 'is-danger': fieldErrors.count }"
              :disabled="loading || undefined"
            />
          </div>
          <p
            class="help is-danger"
            v-for="(err, i) in fieldErrors.count ?? []"
            :key="i"
          >
            {{ err }}
          </p>
        </div>

        <label class="label">Distribution per set</label>
        <div
          v-if="!dataset"
          class="notification is-warning"
        >
          Please select a dataset first
        </div>
        <template v-else>
          <div class="control mb-4">
            <div class="multi-progress">
              <div
                v-for="({ name }, i) in orderedSets"
                :key="name"
                class="progress-block"
                :class="datasetColors[i % datasetColors.length]"
                :style="{ width: `${setPercentages[name]}%` }"
                :title="`${name}: ${setPercentages[name]}%`"
              ></div>
            </div>
          </div>

          <div
            v-for="(set, i) in orderedSets"
            :key="set.id"
            class="field has-addons"
          >
            <div class="control">
              <div
                class="input is-focused"
                :class="datasetColors[i % datasetColors.length]"
              >
                {{ set.name }}
              </div>
            </div>
            <div class="control">
              <input
                class="input"
                type="number"
                required
                min="0"
                max="100"
                v-model.number="setPercentages[set.name]"
                step="1"
                :class="{ 'is-danger': fieldErrors.sets }"
                :disabled="loading || undefined"
              />
            </div>
            <div class="control">
              <div class="input is-light is-focused">%</div>
            </div>
            <div class="control is-expanded">
              <input
                class="input"
                type="range"
                min="0"
                max="100"
                v-model.number="setPercentages[set.name]"
                step="1"
                tabindex="-1"
                :disabled="loading || undefined"
              />
            </div>
          </div>
          <p
            class="help is-danger"
            v-for="(err, i) in fieldErrors.sets ?? []"
            :key="i"
          >
            {{ err }}
          </p>

          <div
            v-if="!validRatio"
            class="notification is-warning"
          >
            The sum of all ratios must be 100%
          </div>
        </template>
      </form>
    </template>

    <template v-slot:footer="{ close }">
      <button
        class="button"
        v-on:click="close"
      >
        Cancel
      </button>
      <button
        type="submit"
        form="populateFormId"
        class="button is-primary"
        :class="{ 'is-loading': loading }"
      >
        Populate
      </button>
    </template>

    <DatasetCreate
      v-model="datasetModal"
      :corpus-id="corpusId"
      v-on:dataset-action="selectDataset"
    />
  </Modal>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { isEqual } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import type { DatasetPopulate } from '@/api'
import DatasetCreate from '@/components/Corpus/Datasets/EditModal.vue'
import Modal from '@/components/Modal.vue'
import { UUID_REGEX } from '@/config'
import { getNavigationFilters } from '@/filterbar'
import { ensureArray } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import {
  useCorporaStore,
  useDatasetStore,
  useElementStore,
  useNavigationStore,
  useNotificationStore,
} from '@/stores'
import type { Corpus, Element, ElementBase, UUID } from '@/types'
import type { Dataset, DatasetSet } from '@/types/dataset'

export default defineComponent({
  mixins: [truncateMixin, corporaMixin],
  components: {
    DatasetCreate,
    Modal,
  },
  emits: ['update:modelValue'],
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      validator: (value) => typeof value === 'string' && UUID_REGEX.test(value),
      required: true,
    },
    parentId: {
      type: String as PropType<UUID | null>,
      validator: (value) => value === null || (typeof value === 'string' && UUID_REGEX.test(value)),
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedDatasetId: '' as UUID | '',
    recursive: false,
    // Use top level (inverse of recursive) when working without a parent
    topLevel: true,
    types: [] as string[],
    count: 1000,
    setPercentages: {} as Record<string, number>,
    loading: false,
    datasetModal: false,
    loadedParent: false,
    fieldErrors: {} as Record<string, string[] | Record<string, string[]> | null>,
    datasetColors: ['is-link', 'is-danger', 'is-success', 'is-info', 'is-warning', 'is-primary'],
    validRatio: true,
  }),
  computed: {
    ...mapState(useNavigationStore, ['appliedFilters']),
    ...mapState(useElementStore, ['elements']),
    ...mapState(useCorporaStore, ['loaded']),
    ...mapState(useDatasetStore, ['datasets']),
    hasContribPrivilege() {
      return this.corpus?.id && this.canWrite(this.corpus)
    },
    parent(): ElementBase | Element | null {
      // Retrieve optional parent element from query parameter
      return this.parentId ? this.elements[this.parentId] : null
    },
    availableDatasets(): Dataset[] {
      return Object.values(this.datasets).filter((dataset) => dataset.corpus_id === this.corpusId)
    },
    dataset(): Dataset | null {
      return this.selectedDatasetId ? this.datasets[this.selectedDatasetId] : null
    },
    orderedSets(): DatasetSet[] {
      return (this.dataset?.sets || []).sort((a, b) => a.name.localeCompare(b.name))
    },
    watchSets() {
      /**
       * Allow the watcher on sets ratios to be reactive with deep attributes
       * Enables to automatically restore a valid total ratio by updating the last input
       */
      return { ...this.setPercentages }
    },
    /**
     * Display names of the navigation filters that we do not automatically map to filters in this modal,
     * and thus could cause confusion as what is displayed during navigation does not match what will be used to populate
     */
    unsupportedFilterNames(): string[] {
      const availableFilters = getNavigationFilters(this.appliedFilters)

      const supportedFilterKeys = new Set([
        'top_level',
        'recursive',
        'type',
        'folder',
        // appliedFilters includes other query parameters that we can ignore
        'page',
        'page_size',
        'order',
        'order_direction',
      ])
      const filterOperatorKeys = new Set(
        availableFilters.map((filter) => filter.operatorName).filter((key) => key !== undefined),
      )

      const unsupportedFilterKeys = new Set(Object.keys(this.appliedFilters))
        // Remove the filters that we do support
        .difference(supportedFilterKeys)
        // Remove the keys used by filters with operators as those are not explicitly displayed in the filter bar
        .difference(filterOperatorKeys)

      const filterNames: Record<string, string | undefined> = Object.fromEntries(
        availableFilters.map((filter) => [filter.name, filter.displayName]),
      )

      return [...unsupportedFilterKeys].map((key) => filterNames[key] ?? key)
    },
  },
  methods: {
    ...mapActions(useElementStore, { retrieveElement: 'get' }),
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useDatasetStore, ['listCorpusDatasets', 'populateDataset']),
    updateModal(value: boolean) {
      this.$emit('update:modelValue', value)
    },
    selectAllTypes() {
      if (!this.corpus?.id) return
      this.types = Object.values(this.corpus.types).map((t) => t.slug)
    },
    async submit() {
      this.fieldErrors = {}
      if (!this.dataset) {
        this.fieldErrors.dataset = ['This field is required']
        return
      }
      if (Object.values(this.setPercentages).reduce((s, value) => s + value, 0) !== 100) {
        this.fieldErrors.sets = ['The sum of all ratios must be 100%']
        return
      }
      this.loading = true
      const payload: DatasetPopulate = {
        recursive: this.parentId ? this.recursive : !this.topLevel,
        types: this.types,
        count: this.count,
        sets: Object.fromEntries(
          Object.entries(this.setPercentages)
            // Drop any value equal to zero to avoid a backend validation error
            .filter((entry) => entry[1] !== 0)
            .map(([name, value]) => [name, value / 100]),
        ),
      }
      if (this.parentId) payload.parent_id = this.parentId
      try {
        await this.populateDataset(this.dataset.id, payload)
        // On success, redirects to dataset details
        this.notify({
          type: 'success',
          text: `${this.count} elements have been successfully distributed.`,
        })
        this.$emit('update:modelValue', false)
        this.$router.push({ name: 'dataset-details', params: { datasetId: this.dataset.id } })
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 400 && err.response.data) {
          this.fieldErrors = Object.fromEntries(
            new Set(
              Object.entries(err?.response?.data).map(([k, v]) => [k, ensureArray(v) as string[]]),
            ),
          )
        }
      } finally {
        this.loading = false
      }
    },
    selectDataset(datasetId: string) {
      this.selectedDatasetId = datasetId
    },
  },
  watch: {
    parentId: {
      immediate: true,
      async handler(newValue: UUID | null) {
        if (!newValue || !UUID_REGEX.test(newValue) || this.parent) return
        try {
          await this.retrieveElement(newValue)
        } catch {
          // The component displays a warning instead of the form
        } finally {
          this.loadedParent = true
        }
      },
    },
    corpus: {
      immediate: true,
      async handler(newValue: Corpus | null) {
        // List datasets in case none are initially found
        if (!newValue?.id || this.availableDatasets.length > 0 || !this.canWrite(this.corpus))
          return
        try {
          await this.listCorpusDatasets(this.corpusId)
        } catch {
          this.notify({
            type: 'error',
            text: 'An error occurred fetching datasets for this corpus.',
          })
        }
      },
    },
    dataset(newValue: Dataset | null) {
      if (!newValue) return
      const setNames = newValue.sets.map((s: DatasetSet) => s.name)
      if (isEqual(new Set(setNames), new Set(['train', 'dev', 'test']))) {
        // In case sets are train, dev and test, use the usual distribution
        this.setPercentages = { train: 80, dev: 10, test: 10 }
      } else {
        // Otherwise distribute equally
        const ratio = Math.floor(100 / setNames.length)
        // Round the last ratio value so we are exactly at 100% in total
        const lastRatio = 100 - ratio * (setNames.length - 1)
        this.setPercentages = {
          ...Object.fromEntries(setNames.slice(0, -1).map((set: string) => [set, ratio])),
          [setNames.at(-1) as string]: lastRatio,
        }
      }
    },
    // When opening the modal, synchronize the element filters with the currently applied filters in the project/folder navigation
    modelValue: {
      immediate: true,
      async handler(newValue: boolean) {
        if (!newValue || !this.corpus) return

        // If the recursive filter is set when browsing a folder, use it in this modal
        if ('recursive' in this.appliedFilters)
          this.recursive = this.appliedFilters.recursive === 'true'
        // The top-level filter when browsing a project is equivalent, but works in reverse
        else if ('top_level' in this.appliedFilters)
          this.topLevel = this.appliedFilters.top_level === 'true'
        // When no filters are set, the default behavior is to select recursively on a project, and non-recursively on a folder.
        else {
          this.topLevel = true
          this.recursive = false
        }

        // When a type filter is set, select only this type
        if (this.appliedFilters.type) this.types = [this.appliedFilters.type]
        // When a folder type filter is set, select all folder or non-folder types
        else if ('folder' in this.appliedFilters) {
          this.types = Object.values(this.corpus.types)
            .filter((type) => type.folder === (this.appliedFilters.folder === 'true'))
            .map((type) => type.slug)
        } else this.selectAllTypes()
      },
    },
    watchSets: {
      immediate: false,
      deep: true,
      handler(newValue: Record<string, number>, oldValue: Record<string, number>) {
        /**
         * Automatically completes the difference to 100% on the last set
         */
        const lastSet = this.orderedSets.at(-1)?.name
        if (!lastSet) return

        // Avoid negative values
        if (this.setPercentages[lastSet] < 0) {
          this.setPercentages[lastSet] = 0
          return
        }

        const sum = Object.values(newValue).reduce((s, value) => s + value, 0)
        if (sum === 100) {
          this.validRatio = true
          return
        }

        // Allow to move the last slice freely
        if (newValue[lastSet] !== oldValue[lastSet]) {
          this.validRatio = false
          return
        }

        // Finally, automatically adjust the last value
        this.setPercentages[lastSet] = newValue[lastSet] + (100 - sum)
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.progress-block {
  flex: auto;
}
</style>
