<template>
  <form v-on:submit.prevent="createTranscription">
    <div class="field">
      <div class="control">
        <textarea
          v-model="text"
          v-on:keydown.enter.exact.prevent="createTranscription"
          class="textarea"
          :class="{ 'is-loading': loading }"
          :style="orientationStyle(orientation)"
          :disabled="loading || undefined"
          placeholder="Text…"
        ></textarea>
        <template v-if="fieldErrors.text">
          <p
            class="help is-danger"
            v-for="err in fieldErrors.text"
            :key="err"
          >
            {{ err }}
          </p>
        </template>
      </div>
    </div>
    <div class="field is-grouped is-justify-content-space-between">
      <div class="control">
        <div class="select is-small">
          <select
            v-model="orientation"
            :disabled="loading || undefined"
            required
          >
            <option
              v-for="(textOrientation, key) in TEXT_ORIENTATIONS"
              :key="key"
              :value="key"
              :title="textOrientation.display"
            >
              {{ textOrientation.display }}
            </option>
          </select>
          <template v-if="fieldErrors.orientation">
            <p
              class="help is-danger"
              v-for="err in fieldErrors.orientation"
              :key="err"
            >
              {{ err }}
            </p>
          </template>
        </div>
      </div>
      <div class="control">
        <button
          type="submit"
          class="button is-primary"
          :class="{ 'is-loading': loading }"
          :disabled="loading || !isValid || undefined"
          :title="createTitle"
        >
          <span class="icon-plus"></span>
        </button>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { TEXT_ORIENTATIONS } from '@/config'
import { errorParser, orientationStyle } from '@/helpers'
import { useAnnotationStore, useNotificationStore, useTranscriptionStore } from '@/stores'
import type { Element, ElementBase, TextOrientation } from '@/types'

export default defineComponent({
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
  },
  data: () => ({
    text: '',
    fieldErrors: {} as { text?: string[]; orientation?: string[] },
    loading: false,
    TEXT_ORIENTATIONS,
  }),
  computed: {
    ...mapState(useAnnotationStore, ['textOrientation']),
    orientation: {
      get() {
        return this.textOrientation
      },
      set(newValue: TextOrientation) {
        this.setTextOrientation(newValue)
      },
    },
    isValid(): boolean {
      return this.text.trim().length > 0 && this.orientation in TEXT_ORIENTATIONS
    },
    createTitle(): string {
      const text = this.text.trim()
      if (!text && !this.orientation) return 'Please fill out the creation form'
      else if (!text) return 'A valid text is required to create the transcription'
      else if (!this.orientation || !(this.orientation in TEXT_ORIENTATIONS))
        return 'A valid text orientation is required'
      return 'Create a new transcription'
    },
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useAnnotationStore, ['setTextOrientation']),
    ...mapActions(useTranscriptionStore, ['create']),
    orientationStyle,
    setErrors(error: unknown) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (isAxiosError(error) && typeof error.response?.data === 'object')
        this.fieldErrors = error.response.data
    },
    async createTranscription() {
      if (!this.isValid) return
      try {
        this.loading = true
        await this.create(this.element.id, {
          text: this.text,
          orientation: this.orientation,
        })
        this.notify({ type: 'success', text: 'Transcription created.' })
        // Reset form to default to allow typing a new transcription
        this.text = ''
      } catch (e) {
        this.setErrors(e)
        this.notify({
          type: 'error',
          text: `An error occurred during transcription creation: ${errorParser(e)}`,
        })
      } finally {
        this.loading = false
      }
    },
  },
})
</script>
