<template>
  <template v-if="modelVersion">
    <div
      class="tag mr-2"
      v-if="modelVersion.tag"
    >
      {{ modelVersion.tag }}
    </div>
    <router-link
      v-if="modelVersion"
      :to="{ name: 'model-version', params: { versionId: modelVersion.id } }"
      target="_blank"
    >
      {{ truncateShort(modelVersion.model.name) }}
    </router-link>
    <br />
    <small>
      <ItemId :item-id="modelVersion.id" />
    </small>
  </template>
  <template v-else>—</template>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import ItemId from '@/components/ItemId.vue'
import { truncateMixin } from '@/mixins'
import type { ModelVersionLight } from '@/types/model'

export default defineComponent({
  props: {
    modelVersion: {
      type: Object as PropType<ModelVersionLight>,
      required: true,
    },
  },
  components: {
    ItemId,
  },
  mixins: [truncateMixin],
})
</script>
