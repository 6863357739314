<template>
  <span class="select">
    <select v-model="selected">
      <option
        v-for="option in field.choices"
        :value="option"
        :key="option"
      >
        {{ option }}
      </option>
    </select>
  </span>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import type { EnumUserConfigurationField } from '@/types/workerConfiguration'

export default defineComponent({
  emits: {
    'update:modelValue': (value: string | number) => ['string', 'number'].includes(typeof value),
  },
  props: {
    field: {
      type: Object as PropType<EnumUserConfigurationField>,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    selected: {
      get() {
        return this.modelValue
      },
      set(value: string | number) {
        this.$emit('update:modelValue', value)
      },
    },
  },
})
</script>
