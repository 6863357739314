import { cloneDeep, isEqual } from 'lodash'

import type { Point, Polygon, Zone } from '@/types'

import { POLYGON_MAX_POINTS, POLYGON_MIN_SIZE } from '../config'

/**
 * Check for point equality.
 *
 * @param param0 First point to compare.
 * @param param1 Second point to compare.
 * @returns Whether both points are equal.
 */
export const pointsEqual = ([x1, y1]: Point, [x2, y2]: Point): boolean => {
  return x1 === x2 && y1 === y2
}

/**
 * Shifts a polygon depending on a 2-dimensional vector
 *
 * @param polygon Polygon.
 * @param vector Shifting vector.
 * @returns Shifted polygon.
 */
export const shiftPolygon = (polygon: Polygon, [offsetX, offsetY]: [number, number]): Polygon => {
  return polygon.map(([x, y]) => [x + offsetX, y + offsetY])
}

/**
 * Converts a polygon coordinates to SVG syntax
 *
 * @param polygon Polygon
 * @returns SVG Coordinates
 */
export const svgPolygon = (polygon: Polygon): string => {
  return polygon.map((point) => point.join(',')).join(' ')
}

/**
 * Returns width and height of a polygon
 * @param polygon Polygon.
 * @returns Width and height of the polygon.
 */
export const getSize = (polygon: Polygon): [number, number] => {
  const xCoords = polygon.map((p) => p[0])
  const yCoords = polygon.map((p) => p[1])
  const x = Math.min(...xCoords)
  const y = Math.min(...yCoords)
  const width = Math.max(...xCoords) - x
  const height = Math.max(...yCoords) - y
  return [width, height]
}

/**
 * The polygon reordering found the polygon to be invalid.
 */
export class InvalidPolygonError extends Error {
  constructor(message: string | undefined) {
    super(message)
    // Not setting this would make the error look like a normal Error
    this.name = this.constructor.name
  }
}

/**
 * Quick sanity checks for some constraints on polygons:
 *
 * - Remove duplicate points (ABBC → ABC)
 * - Check the polygon has at least 3 distinct points and at most 165 distinct points
 * - Check that the polygon is not too small
 *
 * Some backend constraints are not fully tested:
 *
 * - It is assumed that the backend will automatically close open polygons
 * - Self-intersection is not tested (e.g. bowtie polygons)
 *
 * @param polygon Polygon to reorder.
 * @param minSize Minimum allowed width/height.
 * @returns A sanitized polygon.
 * @throws {InvalidPolygonError} When the polygon will be considered invalid by the backend.
 */
export const checkPolygon = (polygon: Polygon, minSize: number = POLYGON_MIN_SIZE): Polygon => {
  if (!Array.isArray(polygon)) throw new TypeError(`Expected Array, got ${polygon}`)
  for (const index in polygon) {
    const point = polygon[index]
    if (
      !Array.isArray(point) ||
      point.length !== 2 ||
      !Number.isFinite(point[0]) ||
      !Number.isFinite(point[1])
    ) {
      throw new TypeError(`Point ${index}: expected Array of two finite numbers, got ${point}`)
    }
  }

  const newPolygon = cloneDeep(polygon)

  // Round a polygon's points coords to integers
  newPolygon.forEach((point, index) => {
    newPolygon[index] = point.map(Math.round) as Point
  })

  // Ensure all coordinates are positive
  if (newPolygon.some((point) => point.find((coord) => coord < 0))) {
    throw new InvalidPolygonError('A polygon cannot have negative coordinates.')
  }

  // Deduplicate polygon: ABBCCBCA → ABCBCA
  let j = 1
  while (j < newPolygon.length) {
    if (pointsEqual(newPolygon[j], newPolygon[j - 1])) {
      newPolygon.splice(j, 1)
    } else {
      j++
    }
  }

  /*
   * Require three *unique* points: we already made a deduplication, so we could only check the length,
   * but if the first and last points are equal, we will need a length of 4.
   */
  if (
    newPolygon.length <
    3 + (pointsEqual(newPolygon[0], newPolygon[newPolygon.length - 1]) ? 1 : 0)
  ) {
    throw new InvalidPolygonError('This polygon does not have at least three unique points.')
  }

  /*
   * Polygons should have at most 163 distinct points.
   * 164 points are allowed only if the first and last point are equal
   */
  if (
    newPolygon.length > POLYGON_MAX_POINTS + 1 ||
    (newPolygon.length === POLYGON_MAX_POINTS + 1 &&
      !pointsEqual(newPolygon[0], newPolygon[POLYGON_MAX_POINTS]))
  ) {
    throw new InvalidPolygonError(
      `This polygon has more than ${POLYGON_MAX_POINTS} distinct points.`,
    )
  }

  /*
   * Assert polygon width and height are sufficient
   * The correct way to handle this would be to calculate the polygon area
   */
  const [width, height] = getSize(newPolygon)
  if (height < minSize || width < minSize)
    throw new InvalidPolygonError('This polygon is too small.')

  return newPolygon
}

/**
 * Determine if two polygon geometrically represent the same area.
 * @param polygon1 First polygon to compare.
 * @param polygon2 Second polygon to compare.
 * @returns Whether or not the two polygons are geometrically equal.
 */
export const polygonsEqual = (polygon1: Polygon, polygon2: Polygon): boolean =>
  isEqual(checkPolygon(polygon1), checkPolygon(polygon2))

export interface BoundingBoxOptions {
  /**
   * Margin, in pixels, to apply around the bounding box.
   */
  margin?: number
  /**
   * Restrict the bounding box to the image's dimensions.
   */
  imageBounds?: boolean
}

export interface BoundingBox {
  x: number
  y: number
  width: number
  height: number
}

/**
 * Generate a rectangle bounding box for a zone, optionally with margins or without exceeding the image's dimensions.
 * @param zone Zone as returned by a ZoneSerializer. The `image` is optional only if `imageBounds` is false.
 * @param options Add some margins to the resulting bounding box, or allow the bounding box to exceed the image's dimensions.
 * @returns A rectangular bounding box.
 */
export const boundingBox = (
  zone: Zone,
  { margin = 0, imageBounds = true }: BoundingBoxOptions = {},
): BoundingBox => {
  // Create externally-squared iiif coords from zone polygon
  const xCoords = zone.polygon.map((e) => e[0])
  const yCoords = zone.polygon.map((e) => e[1])
  let minX = Math.min(...xCoords) - margin
  let minY = Math.min(...yCoords) - margin
  let maxX = Math.max(...xCoords) + margin
  let maxY = Math.max(...yCoords) + margin

  if (imageBounds) {
    // Require an image to ensure we can properly restrict the bounding box to the image
    if (!zone.image) throw new Error('An image is required.')
    if ((zone.image.width ?? 0) <= 0 || (zone.image.height ?? 0) <= 0)
      throw new Error('An image with valid dimensions is required.')

    // Restrict to the image's dimensions
    minX = Math.min(zone.image.width, Math.max(minX, 0))
    minY = Math.min(zone.image.height, Math.max(minY, 0))
    maxX = Math.min(zone.image.width, Math.max(maxX, 0))
    maxY = Math.min(zone.image.height, Math.max(maxY, 0))
  }

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  }
}

/**
 * Convert an angle from degrees to radians.
 * @param degrees Angle in degrees.
 * @returns Angle in radians.
 */
export const toRadians = (degrees: number): number => (degrees * Math.PI) / 180

/**
 * Rotate a point clockwise around an origin point by an angle.
 * @param point Point to rotate.
 * @param origin Origin point to rotate around.
 * @param angle Angle in degrees.
 * @returns Coordinates of the rotated point.
 */
export const rotateAround = (point: Point, origin: Point = [0, 0], angle = 0): Point => {
  if (!angle) return point
  const radians = toRadians(angle)
  const [originX, originY] = origin
  const [x, y] = point
  const [relativeX, relativeY] = [x - originX, y - originY]
  return [
    relativeX * Math.cos(radians) - relativeY * Math.sin(radians) + originX,
    relativeY * Math.cos(radians) + relativeX * Math.sin(radians) + originY,
  ]
}

/**
 * Mirror a point on the X axis.
 * @param param0 Point to mirror.
 * @param param1 Origin point to mirror on.
 */
export const mirrorX = ([x, y]: Point, [originX]: Point = [0, 0]): Point => [2 * originX - x, y]

/**
 * Calculate the median point on a polygon line.
 * @param index Index of that first point in the polygon.
 * @param point First point of the line.
 * @param polygon Element polygon.
 */
export const medianPoint = (index: number, [x1, y1]: Point, polygon: Polygon): Point => {
  const [x2, y2] = polygon[index + 1]

  const minX = Math.min(x1, x2)
  const minY = Math.min(y1, y2)
  const maxX = Math.max(x1, x2)
  const maxY = Math.max(y1, y2)

  const medianX = Math.round(minX + (maxX - minX) / 2)
  const medianY = Math.round(minY + (maxY - minY) / 2)

  return [medianX, medianY]
}
