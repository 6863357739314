<template>
  <div class="field is-grouped mt-1 mb-0">
    <div class="control mr-1">
      <input
        class="input"
        type="text"
        v-model="setName"
        :class="{ 'is-danger': createError.name }"
        :disabled="!hasContribPrivilege || dataset.state !== 'open'"
      />
    </div>
    <div class="control">
      <button
        class="button is-primary"
        :class="{ 'is-loading': loading }"
        :disabled="loading || !canCreate || undefined"
        v-on:click="setCreate"
        :title="createTitle"
      >
        <i class="icon-plus"></i>
      </button>
    </div>
  </div>
  <template v-if="createError.name">
    <p
      v-for="(err, i) in createError.name"
      :key="i"
      class="help has-text-danger"
    >
      {{ err }}
    </p>
  </template>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { corporaMixin, truncateMixin } from '@/mixins'
import { useAuthStore, useDatasetStore, useNotificationStore } from '@/stores'
import type { UUID } from '@/types'
import type { Dataset } from '@/types/dataset'

export default defineComponent({
  mixins: [corporaMixin, truncateMixin],
  props: {
    dataset: {
      type: Object as PropType<Dataset>,
      required: true,
    },
    // Used to reset the set name field when the modal is closed
    modalOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    setName: '',
    createError: { name: '' },
  }),
  computed: {
    ...mapState(useAuthStore, ['isVerified']),
    hasContribPrivilege(): boolean {
      return this.isVerified && this.canWrite(this.corpus)
    },
    canCreate(): boolean {
      return this.setName.trim() !== '' && this.hasContribPrivilege && this.dataset.state === 'open'
    },
    corpusId(): UUID {
      return this.dataset.corpus_id
    },
    createTitle(): string {
      if (this.dataset.state !== 'open') return 'Sets can only be created in open datasets'
      else if (!this.hasContribPrivilege)
        return 'You must be a contributor on the project to create a set'
      else if (this.canCreate) return 'Create new set'
      return ''
    },
  },
  methods: {
    ...mapActions(useDatasetStore, ['createDatasetSet']),
    ...mapActions(useNotificationStore, ['notify']),
    async setCreate() {
      this.loading = true
      if (!this.canCreate) return
      try {
        await this.createDatasetSet(this.dataset.id, this.setName)
        this.notify({
          type: 'success',
          text: `Dataset set ${this.truncateShort(this.setName)} created`,
        })
        this.createError.name = ''
        this.setName = ''
      } catch (err) {
        if (
          isAxiosError(err) &&
          err.response?.status === 400 &&
          err.response.data &&
          'name' in err.response.data
        ) {
          this.createError.name = err.response.data.name
        }
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    modalOpen(newValue: boolean) {
      // Reset set name field and errors when the modal is closed
      if (newValue === false) {
        this.setName = ''
        this.createError.name = ''
      }
    },
  },
})
</script>
