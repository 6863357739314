<template>
  <span class="select is-truncated">
    <select
      v-model.number="selectedIndex"
      :disabled="!corpusAllowedMetadata.length"
    >
      <option
        :value="null"
        selected
        disabled
      >
        Select a metadata…
      </option>
      <option
        v-for="(meta, index) in corpusAllowedMetadata"
        :key="index"
        :value="index"
      >
        {{ meta.name }} ({{ meta.type }})
      </option>
    </select>
  </span>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { useAllowedMetaDataStore } from '@/stores'
import type { UUID } from '@/types'
import type { AllowedMetaData } from '@/types/metadata'

type NameTypeCombo = Pick<AllowedMetaData, 'type' | 'name'>

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
    /**
     * Currently selected name/type combo. May be `null` when no valid combo is selected.
     */
    modelValue: {
      type: Object as PropType<NameTypeCombo | null>,
      // This is actually required, but a `null` default is the only way to tell Vue that this is a Object | null, since it won't see TypeScript types at runtime.
      default: null,
    },
  },
  computed: {
    ...mapState(useAllowedMetaDataStore, ['allowedMetadata']),
    corpusAllowedMetadata(): AllowedMetaData[] {
      return this.allowedMetadata[this.corpusId] ?? []
    },
    selectedIndex: {
      get(): number | null {
        return this.findIndex(this.modelValue)
      },
      set(newValue: number | null) {
        if (newValue === null || !this.corpusAllowedMetadata[newValue])
          this.$emit('update:modelValue', null)
        else this.$emit('update:modelValue', this.corpusAllowedMetadata[newValue])
      },
    },
  },
  methods: {
    /**
     * Find the index of an AllowedMetadata within the allowed metadata store from its name and type.
     */
    findIndex(selectedValue: NameTypeCombo | null): number | null {
      if (selectedValue === null) return null
      const index = this.corpusAllowedMetadata.findIndex(
        (allowedMetadata) =>
          allowedMetadata.type === selectedValue.type &&
          allowedMetadata.name === selectedValue.name,
      )
      return index >= 0 ? index : null
    },
    detectUnknownValues(selectedValue: NameTypeCombo | null) {
      // Do nothing when allowed metadata are not available
      if (selectedValue === null || !(this.corpusId in this.allowedMetadata)) return
      // When the selected combo does not exist, obliterate it
      if (this.findIndex(selectedValue) === null) this.$emit('update:modelValue', null)
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler: 'detectUnknownValues',
    },
    corpusAllowedMetadata() {
      this.detectUnknownValues(this.modelValue)
    },
  },
})
</script>
