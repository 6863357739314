/**
 * This module provides functions to retrieve navigation filters for the FilterBar component.
 *
 * This is not defined as store getters because some functions within the filters themselves require calling some async
 * store actions; while no actions would be called within the getter itself, this is not allowed by Pinia as getters must be synchronous.
 * This is not defined as Pinia actions either because this would make its integration more complex in components,
 * as it would turn the filter returning functions into a Promise.
 */
import * as api from '@/api'
import { UUID_REGEX } from '@/config'
import {
  useAllowedMetaDataStore,
  useClassificationStore,
  useCorporaStore,
  useMLResultsStore,
  useNavigationStore,
} from '@/stores'

import type { ElementType, MLClass, UUID } from './types'
import type { WorkerVersion } from './types/worker'

interface FilterOptions {
  displayName?: string
  placeholder?: string
  operatorName?: string
  operatorPlaceholder?: string
  operators?: { [key: string]: string }
}

/**
 * A filter bar filter.
 */
export abstract class Filter<T> {
  /**
   * Name of the filter. Used as a key in the component's v-model.
   */
  name: string

  /**
   * Displayed filter name. Defaults to the name, capitalized.
   */
  displayName: string

  /**
   * Text shown as a placeholder when the user is typing a value for the filter.
   */
  placeholder: string

  /**
   * Optional key to include the filter's configured operator in the component's v-model.
   */
  operatorName: string | undefined

  /**
   * Text shown as a placeholder when the user is selecting an operator for the filter.
   */
  operatorPlaceholder: string

  /**
   * Available filter operators, as a mapping of operator keys and displayed names.
   */
  operators: { [key: string]: string }

  /**
   * Create a new filter.
   *
   * @param name Name of the filter.
   * @param options Optional properties of the filter.
   */
  constructor(name: string, options: FilterOptions = {}) {
    this.name = name
    this.displayName =
      options.displayName ??
      name.charAt(0).toUpperCase() + name.slice(1).toLowerCase().replace(/_/g, ' ')
    this.placeholder = options.placeholder ?? 'Filter value'
    this.operatorName = options.operatorName
    this.operatorPlaceholder = options.operatorPlaceholder ?? 'Select an operator'
    this.operators = options.operators ?? { eq: '=' }
  }

  /**
   * Suggest possible filter values.
   *
   * @param input A text input the user has typed. Can be empty.
   * The FilterInput component will further filter down the suggestions to ensure they match the input string;
   * this methods does not have to suggest only the values matching this input.
   * @param operator The selected filter operator's key.
   * @returns A list of possible filter values. Can be a Promise,
   * in which case the component will show a loading state before displaying the results.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  autocomplete(input: string, operator: string): T[] | Promise<T[]> {
    return []
  }

  /**
   * Sort an array of suggested filter values for display.
   * Sorts alphabetically in ascending order by default.
   * This should not mutate the provided array.
   *
   * @param suggestions Array of `[value, display name]` to sort.
   */
  sort(values: [T, string][]): [T, string][] {
    return [...values].sort(([, a], [, b]) => a.localeCompare(b))
  }

  /**
   * Get a human-readable string for a filter value.
   *
   * @param value A filter value to display.
   * @returns A human-readable string for the filter value.
   */
  display(value: T): string {
    return this.serialize(value)
  }

  /**
   * Get a filter value from an arbitrary user input.
   * Is only called when the user types a string input and is not selecting a suggested value.
   * If an error is thrown, the input is rejected and the error message is displayed.
   * @param input User input to validate.
   * @param operator Key of the operator used with this input.
   * @returns Filter value.
   * Can be a Promise, in which case the input shows a loading state while the validation runs.
   */
  abstract validate(input: string, operator: string): T | Promise<T>

  /**
   * Serialize an internal filter value into a string that can be used in the component's v-model.
   * @param filterValue Filter value.
   * @return Exposed v-model value.
   */
  serialize(filterValue: T): string {
    if (filterValue === undefined) return 'undefined'
    if (filterValue === null) return 'null'
    return filterValue.toString()
  }

  /**
   * Deserialize a string from the component's v-model into an internal filter value.
   * @param value Value from the component's v-model.
   * @param operator The selected filter operator's key.
   * @returns Deserialized filter value, or `undefined` if the value is invalid.
   */
  abstract deserialize(value: string, operator: string): T | undefined | Promise<T | undefined>
}

/**
 * String filter. No auto-completion.
 */
export class StringFilter extends Filter<string> {
  validate(input: string): string {
    return input
  }

  deserialize(value: string): string {
    return value
  }
}

/**
 * A simple name filter, allowing any value.
 */
export class NameFilter extends StringFilter {
  constructor() {
    super('name', { operators: { eq: 'contains' } })
  }
}

/**
 * A rotation angle filter, allowing integers between 0 and 359
 */
export class RotationAngleFilter extends Filter<number> {
  constructor() {
    super('rotation_angle', { placeholder: 'Angle between 0 and 359 degrees' })
  }

  validate(input: string): number {
    /*
     * We could use parseInt, but parseInt('1.2') would return 1, a valid value.
     * No error message here would be confusing for the user.
     */
    const num = typeof input === 'number' ? input : Number.parseFloat(input)
    if (Number.isInteger(num) && num >= 0 && num <= 359) return num
    throw new Error('A rotation angle must be a number between 0 and 359 degrees')
  }

  deserialize(value: string): number | undefined {
    try {
      return this.validate(value)
    } catch {
      return undefined
    }
  }
}

/**
 * An element type filter. It expects the corpus to already be loaded in the corpora module.
 */
export class TypeFilter extends Filter<ElementType> {
  protected corporaStore
  protected navigationStore

  constructor() {
    super('type')
    this.corporaStore = useCorporaStore()
    this.navigationStore = useNavigationStore()
  }

  /**
   * Retrieve the current corpus' types. A method is necessary because the types might change
   * during the filter's lifetime (e.g. when a type gets created or updated, or when the corpus gets loaded).
   * @returns A list of types.
   */
  autocomplete(): ElementType[] {
    if (!this.navigationStore.corpusId) return []
    return Object.values(this.corporaStore.corpora[this.navigationStore.corpusId].types)
  }

  display(type: ElementType): string {
    return type.display_name
  }

  validate(input: string): ElementType {
    const found = this.autocomplete().find(
      (type) => type.display_name.toLowerCase() === input.toLowerCase(),
    )
    if (!found) throw new Error(`Type '${input}' not found.`)
    return found
  }

  serialize(type: ElementType): string {
    return type.slug
  }

  deserialize(slug: string): ElementType | undefined {
    return this.autocomplete().find((type) => type.slug === slug)
  }
}

/**
 * An ML class filter, allowing the user to pick an ML class name and using IDs in the URL.
 * Uses the navigation's corpusId to provide autocompletion.
 */
export class MLClassFilter extends Filter<MLClass> {
  protected navigationStore

  constructor() {
    super('class_id', { displayName: 'Class' })
    this.navigationStore = useNavigationStore()
  }

  async autocomplete(input: string = ''): Promise<MLClass[]> {
    if (!this.navigationStore.corpusId) return []
    const { results } = await useClassificationStore().listCorpusMLClasses(
      this.navigationStore.corpusId,
      { search: input },
    )
    return results
  }

  display({ name }: MLClass): string {
    return name
  }

  async validate(input: string): Promise<MLClass> {
    const validInputs = Object.fromEntries(
      (await this.autocomplete(input)).map((mlclass) => [mlclass.name.toLowerCase(), mlclass]),
    )
    const found = validInputs[input.toLowerCase()]
    if (found) return found
    throw new Error(`Unknown class '${input}'`)
  }

  async deserialize(input: string): Promise<MLClass | undefined> {
    if (!this.navigationStore.corpusId) return undefined
    input = input.toString().toLowerCase()
    try {
      return await api.retrieveMLClass(this.navigationStore.corpusId, input)
    } catch {
      return undefined
    }
  }

  serialize({ id }: MLClass): string {
    return id
  }
}

/**
 * A generic confidence filter, allowing floats between 0 and 1 with operators.
 */
export class ConfidenceFilter extends Filter<number> {
  constructor(name: string, options: FilterOptions = {}) {
    super(name, {
      placeholder: 'Confidence between 0.0 and 1.0',
      operators: {
        eq: '=',
        gt: '>',
        lt: '<',
        gte: '>=',
        lte: '<=',
      },
      operatorName: `${name}_operator`,
      ...options,
    })
  }

  validate(input: string): number {
    const num = typeof input === 'number' ? input : Number.parseFloat(input)
    if (num >= 0 && num <= 1) return num
    throw new Error('A confidence must be a number between 0.0 and 1.0')
  }

  deserialize(value: string): number | undefined {
    try {
      return this.validate(value)
    } catch {
      return undefined
    }
  }
}

/**
 * Create a worker version filter, allowing a worker version or 'No worker version' (false).
 * Uses the navigation's corpusId to provide autocompletion.
 */
export class WorkerVersionFilter extends Filter<WorkerVersion | false> {
  protected corporaStore
  currentCorpusId: UUID

  constructor(currentCorpusId: UUID, name = 'worker_version', options: FilterOptions = {}) {
    super(name, options)
    this.corporaStore = useCorporaStore()
    this.currentCorpusId = currentCorpusId
  }

  /**
   * Retrieve the corpus' worker versions, performing API requests as needed.
   * @returns The corpus' worker versions.
   */
  async fetch(): Promise<{ [id: string]: WorkerVersion }> {
    const corpus = this.corporaStore.corpora[this.currentCorpusId]

    const mlResultsStore = useMLResultsStore()
    if (mlResultsStore.corpusWorkerVersionsCache[corpus.id] === undefined)
      await mlResultsStore.listWorkerVersionsCache(corpus.id)
    return mlResultsStore.corpusWorkerVersions(corpus.id)
  }

  async autocomplete(): Promise<(WorkerVersion | false)[]> {
    return [...Object.values(await this.fetch()), false]
  }

  display(workerVersion: WorkerVersion | false): string {
    if (workerVersion === false) return 'No worker version'
    // Display truncated version IDs instead of full revision URL
    const suffix = workerVersion.revision_url
      ? workerVersion.id.substring(0, 6)
      : `version ${workerVersion.version}`
    return `${workerVersion.worker.name} (${suffix})`
  }

  async validate(input: string): Promise<WorkerVersion | false> {
    const result = await this.deserialize(input)
    if (result === undefined) throw new Error(`Unknown worker version ID: '${input}'`)
    return result
  }

  async deserialize(
    input: string | WorkerVersion | false,
  ): Promise<WorkerVersion | false | undefined> {
    if (input === false || input === 'false') return false
    if (typeof input === 'object') return input.id ? input : undefined
    return (await this.fetch())[input]
  }

  serialize(version: WorkerVersion | false): string {
    return version ? version.id : 'false'
  }
}

/**
 * Create a metadata name filter. Provides autocompletion via the navigation's corpusId from allowed metadata,
 * but allows any name to be typed, to handle metadatas created by workers or admins.
 */
export class MetadataNameFilter extends StringFilter {
  protected navigationStore
  protected allowedMetadataStore

  constructor() {
    super('metadata_name')
    this.navigationStore = useNavigationStore()
    this.allowedMetadataStore = useAllowedMetaDataStore()
  }

  /**
   * Offer the corpus' allowed metadata names as autocompletion, without requiring an exact name match
   * @returns Suggested metadata names.
   */
  async autocomplete(): Promise<string[]> {
    const corpusId = this.navigationStore.corpusId
    if (!corpusId) return []
    // Load the corpus' allowed metadata if needed
    await this.allowedMetadataStore.listAllowedMetadata(corpusId)
    // Retrieve unique metadata names; there might be multiple AllowedMetadata with the same name and different types
    return [...new Set(this.allowedMetadataStore.allowedMetadata[corpusId].map(({ name }) => name))]
  }
}

/**
 * Create a metadata value filter. Note that the backend requires a metadata name to filter by metadata value.
 * Allows filtering with arithmetic operators, in which case the value must be a number.
 */
export class MetadataValueFilter extends Filter<string | number> {
  constructor() {
    super('metadata_value', {
      operators: {
        eq: '=',
        gt: '>',
        lt: '<',
        gte: '>=',
        lte: '<=',
        contains: 'contains',
      },
      operatorName: 'metadata_operator',
    })
  }

  deserialize(input: string, operator: string): string | number | undefined {
    // Math comparison operators expect floats
    if (['eq', 'contains'].includes(operator)) return input
    const number = parseFloat(input)
    if (Number.isFinite(number)) return number
  }

  validate(input: string, operator: string): string | number {
    const result = this.deserialize(input, operator)
    if (result === undefined)
      throw new Error('Numeric comparison operators on metadata values require finite numbers')
    return result
  }
}

/**
 * Filter methods that are common to every strictly boolean filter
 */
export class BooleanFilter extends Filter<boolean> {
  autocomplete() {
    return [true, false]
  }

  display(value: boolean | string): string {
    if (typeof value === 'string') {
      /**
       * If the page is refreshed, or accessed from a link, then the filters come from the URL
       * and value is a string, not a boolean, so "value ?" doesn't work.
       */
      if (value === 'true') return 'Yes'
      return 'No'
    }
    return value ? 'Yes' : 'No'
  }

  sort(values: [boolean, string][]): [boolean, string][] {
    // Preserve the sorting order returned from `autocomplete`
    return values
  }

  validate(input: string): boolean {
    if (typeof input === 'boolean') return input
    input = input.toLowerCase()
    if (['true', 'false', 'yes', 'no'].includes(input)) return ['true', 'yes'].includes(input)
    throw new Error('Only Yes and No are allowed')
  }

  deserialize(input: string): boolean | undefined {
    try {
      return this.validate(input)
    } catch {
      return undefined
    }
  }
}

export class UUIDOrFalseFilter extends Filter<UUID | false> {
  constructor(name: string, options: FilterOptions = {}) {
    super(name, {
      // Default, overridable placeholder
      placeholder: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx or false',
      ...options,
    })
  }

  display(value: UUID | false): string {
    if (value === 'false') return 'Manual'
    return super.display(value)
  }

  // Only suggest "false" in autocomplete
  autocomplete() {
    return ['false']
  }

  validate(input: string): UUID | false {
    input = input.toLowerCase()
    if (input === 'false') return false
    if (UUID_REGEX.test(input)) return input
    throw new Error('Invalid UUID')
  }

  deserialize(value: string): UUID | false | undefined {
    try {
      return this.validate(value)
    } catch {
      return undefined
    }
  }
}

/**
 * Retrieve the available filter bar filters depending on the navigation store's configuration.
 *
 * @param context Currently set filters; the value returned by the filter bar's v-model.
 * @returns The available filters.
 */
export const getNavigationFilters = (
  context: { [key: string]: string } = {},
): Filter<unknown>[] => {
  const navigationStore = useNavigationStore()
  // A corpus ID is always required
  if (!navigationStore.corpusId) return []

  // Filters available in both corpora and folders
  const filters: Filter<unknown>[] = [
    new NameFilter(),
    new RotationAngleFilter(),
    new BooleanFilter('mirrored'),
    new WorkerVersionFilter(navigationStore.corpusId),
    new MetadataNameFilter(),
    new ConfidenceFilter('confidence'),
    new ConfidenceFilter('transcription_confidence'),
    new WorkerVersionFilter(navigationStore.corpusId, 'transcription_worker_version'),
    new StringFilter('creator_email'),
    new UUIDOrFalseFilter('worker_run', { displayName: 'Worker run ID' }),
    new UUIDOrFalseFilter('transcription_worker_run', {
      displayName: 'Transcription worker run ID',
    }),
  ]

  // Only include the metadata value filter when the metadata name filter is set
  if (context.metadata_name) filters.push(new MetadataValueFilter())

  // The folder and type filters are mutually exclusive
  if (!context.folder) filters.push(new TypeFilter())
  if (!context.type) filters.push(new BooleanFilter('folder'))

  // Do not include the classification filters if we know for sure there are no classes on this corpus
  if (useClassificationStore().hasMLClasses[navigationStore.corpusId] !== false) {
    filters.push(
      new MLClassFilter(),
      new ConfidenceFilter('classification_confidence'),
      new BooleanFilter('classification_high_confidence', {
        displayName: 'High confidence classification',
      }),
    )
  }

  if (navigationStore.elementId) {
    // Filters only available when browsing a folder
    filters.push(new BooleanFilter('recursive'))
  } else {
    // Filters only available when browsing a corpus
    filters.push(new BooleanFilter('top_level'))
  }

  return filters
}
