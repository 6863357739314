import { defineStore } from 'pinia'

import * as api from '@/api'
import { errorParser } from '@/helpers'
import type { UUID } from '@/types'
import type { AllowedMetaData } from '@/types/metadata'

import { useNotificationStore } from '.'

interface State {
  allowedMetadata: {
    [corpusId: UUID]: AllowedMetaData[]
  }
}

export const useAllowedMetaDataStore = defineStore('allowedMetadata', {
  state: (): State => ({
    allowedMetadata: {},
  }),
  actions: {
    setCorpusAllowedMetadata(corpusId: UUID, allowedMetadata: AllowedMetaData[]) {
      const updatedList = this.allowedMetadata[corpusId] || []
      allowedMetadata.forEach((newMeta) => {
        // Prevent duplicating allowed metadata
        if (!updatedList.some((meta) => meta.type === newMeta.type && meta.name === newMeta.name))
          updatedList.push(newMeta)
      })
      // Merge
      this.allowedMetadata[corpusId] = updatedList
    },
    async listAllowedMetadata(corpusId: UUID, page = 1) {
      // Do not start fetching if they have already been retrieved already
      if (page === 1 && this.allowedMetadata[corpusId]) return
      // Automatically list all allowed metadata for a corpus through pagination
      const data = await api.listCorpusAllowedMetadata({ id: corpusId, page })
      this.setCorpusAllowedMetadata(corpusId, data.results)
      if (!data || !data.number || page !== data.number) {
        // Avoid any loop
        throw new Error(`Pagination failed listing available metadata for corpus "${corpusId}"`)
      }
      // Load other page
      if (data.next) await this.listAllowedMetadata(corpusId, page + 1)
    },
    async createAllowedMetadata(corpusId: UUID, metadata: Omit<AllowedMetaData, 'id'>) {
      try {
        const data = await api.createAllowedMetadata({ corpusId, ...metadata })
        this.setCorpusAllowedMetadata(corpusId, [data])
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
    async updateAllowedMetadata(corpusId: UUID, metadata: AllowedMetaData) {
      try {
        const data = await api.updateAllowedMetadata(corpusId, metadata.id, metadata)
        if (!this.allowedMetadata[corpusId])
          throw new Error(`Allowed metadata for corpus ${corpusId} not found.`)
        const mdList = [...this.allowedMetadata[corpusId]]
        const index = mdList.findIndex((item) => item.id === data.id)
        if (index < 0)
          throw new Error(`Allowed metadata ${metadata.id} not found in corpus ${corpusId}`)
        mdList.splice(index, 1, data)
        this.allowedMetadata[corpusId] = mdList
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
    async deleteAllowedMetadata(corpusId: UUID, metaDataId: UUID) {
      try {
        await api.deleteAllowedMetadata(corpusId, metaDataId)
        if (!this.allowedMetadata[corpusId])
          throw new Error(`Allowed metadata for corpus ${corpusId} not found.`)
        const index = this.allowedMetadata[corpusId].findIndex((item) => item.id === metaDataId)
        if (index < 0)
          throw new Error(`Allowed metadata ${metaDataId} not found in corpus ${corpusId}.`)
        this.allowedMetadata[corpusId].splice(index, 1)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
  },
})
