<template>
  <div>
    <div class="tools field is-grouped mb-2">
      <p class="control">
        <a
          class="button"
          :class="{ 'is-primary is-outlined': tool === 'select' }"
          v-on:click="changeTool('select')"
          title="Select and move the selected element"
        >
          <span class="icon">
            <i class="icon-hand-pointer"></i>
          </span>
          <span>move</span>
        </a>
      </p>
      <p class="control">
        <a
          class="button"
          :class="{ 'is-primary is-outlined': tool === 'type-edit' }"
          title="Change element types"
          v-on:click="changeTool('type-edit')"
        >
          <span class="icon">
            <i class="icon-edit"></i>
          </span>
          <span>type edition</span>
        </a>
      </p>
      <p class="control">
        <a
          class="button"
          :class="{ 'is-primary is-danger is-outlined': tool === 'deletion' }"
          title="Delete an element on the image"
          v-on:click="changeTool('deletion')"
        >
          <span class="icon">
            <i class="icon-trash"></i>
          </span>
          <span>delete</span></a
        >
      </p>
    </div>
    <div class="tools field is-grouped">
      <template v-if="enabled">
        <p class="control">
          <a
            class="button"
            :class="{ 'is-primary is-outlined': tool === 'rectangle' }"
            title="Create a child with a rectangle shape"
            v-on:click="changeTool('rectangle')"
          >
            <span class="icon">
              <i class="icon-draw-rect"></i>
            </span>
            <span>rectangle</span>
          </a>
        </p>
        <p class="control">
          <a
            class="button is-warning is-light"
            title="Cancel the current polygon"
            v-on:click="selectedElement = null"
            v-if="tool === 'polygon' && selectedElement !== null"
          >
            <span class="icon">
              <i class="icon-minus"></i>
            </span>
            <span>Cancel</span>
          </a>
          <a
            class="button"
            :class="{ 'is-primary is-outlined': tool === 'polygon' }"
            title="Create a child with a free polygon shape"
            v-on:click="changeTool('polygon')"
            v-else
          >
            <span class="icon">
              <i class="icon-draw-poly"></i>
            </span>
            <span>polygon</span>
          </a>
        </p>
        <p class="control">
          <a
            class="button"
            :class="{ 'is-primary is-outlined': tool === 'median' }"
            title="Add a median point to a line"
            v-on:click="changeTool('median')"
          >
            <span class="icon">
              <i class="icon-dot-circled"></i>
            </span>
            <span>median point</span>
          </a>
        </p>
      </template>
      <a
        v-else
        class="button is-radiusless icon is-large icon-edit-alt"
        :title="
          canWrite(corpus)
            ? 'Edit element children'
            : 'You must have a write access to this project to edit element children.'
        "
        :disabled="!canWrite(corpus) || null"
        v-on:click="edit"
      ></a>
      <slot class="icon is-large"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState, mapWritableState } from 'pinia'
import { defineComponent } from 'vue'

import { corporaMixin } from '@/mixins'
import { useAnnotationStore } from '@/stores'
import type { ToolName } from '@/stores/annotation'

export default defineComponent({
  mixins: [corporaMixin],
  props: {
    corpusId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useAnnotationStore, ['enabled', 'tool']),
    ...mapWritableState(useAnnotationStore, ['selectedElement']),
  },
  methods: {
    ...mapActions(useAnnotationStore, [
      'setTool',
      'setBatchDeletion',
      'setBatchCreation',
      'setBatchTypeEdition',
    ]),
    changeTool(newTool: ToolName) {
      // Unselect element unless switching between median point and select (one less click)
      const keepSelected = ['median', 'select']
      if (!(keepSelected.includes(this.tool) && keepSelected.includes(newTool)))
        this.selectedElement = null
      // Deactivate batch options unless switching between the two element creation tools
      const keepBatch = ['polygon', 'rectangle']
      this.setBatchDeletion(false)
      if (!(keepBatch.includes(this.tool) && keepBatch.includes(newTool)))
        this.setBatchCreation(false)
      this.setBatchTypeEdition(false)
      // Update tool
      this.setTool(newTool)
    },
    edit() {
      if (!this.canWrite(this.corpus)) return
      if (this.selectedElement && this.tool !== 'select') {
        // Automatically set the edition mode if an element is selected
        this.setTool('select')
      }
      this.toggle(true)
    },
  },
})
</script>

<style lang="scss" scoped>
.tools {
  margin-bottom: 20px;
}
</style>
