import { defineStore } from 'pinia'

import {
  type DatasetCreate,
  type DatasetEdit,
  cloneDataset,
  createDataset,
  createDatasetElementsSelection,
  createDatasetSet,
  deleteDataset,
  deleteDatasetElement,
  deleteDatasetSet,
  listCorpusDataset,
  listDatasetElements,
  populateDataset,
  retrieveDataset,
  updateDataset,
  updateDatasetSet,
} from '@/api'
import type { DatasetPopulate } from '@/api/dataset'
import { errorParser } from '@/helpers'
import type { UUID } from '@/types'
import type { Dataset, DatasetElementList, DatasetSet } from '@/types/dataset'

import { useElementStore } from './element'
import { useNotificationStore } from './notification'
import { useSelectionStore } from './selection'

interface State {
  /**
   * Dataset details mapped by ID.
   */
  datasets: { [datasetId: UUID]: Dataset }
  /**
   * Set of dataset IDs per corpora.
   */
  corpusDatasets: { [corpusId: UUID]: Set<UUID> }
  /**
   * List of elements on a dataset and url to fetch the next page.
   */
  datasetElementPagination: {
    [datasetId: UUID]: {
      [setName: string]: { datasetElements: DatasetElementList[]; nextCursor: string | null }
    }
  }
}

export const useDatasetStore = defineStore('dataset', {
  state: (): State => ({
    datasets: {},
    corpusDatasets: {},
    datasetElementPagination: {},
  }),
  actions: {
    async listCorpusDatasets(corpusId: UUID, page = 1) {
      // Do not start fetching corpus datasets if they have been retrieved already
      if (page === 1 && this.corpusDatasets[corpusId]) return
      const data = await listCorpusDataset(corpusId, { page })

      // Fill corpusDatasets[corpusId] with dataset UUIDs
      this.corpusDatasets[corpusId] = new Set([
        ...(this.corpusDatasets[corpusId] ?? []),
        ...data.results.map((dataset) => dataset.id),
      ])

      // Add retrieved datasets to this.datasets
      data.results.forEach((newDataset) => {
        this.datasets[newDataset.id] = { ...(this.datasets[newDataset.id] ?? {}), ...newDataset }
      })

      if (!data || !data.number || page !== data.number) {
        // Avoid any loop
        throw new Error(`Pagination failed listing datasets for corpus "${corpusId}"`)
      }
      // Load other pages
      if (data.next) await this.listCorpusDatasets(corpusId, page + 1)
    },

    async createCorpusDataset(corpusId: UUID, dataset: DatasetCreate) {
      try {
        const data = await createDataset(corpusId, dataset)
        this.corpusDatasets[corpusId].add(data.id)
        this.datasets[data.id] = data
        return data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async updateCorpusDataset({ id, ...dataset }: DatasetEdit) {
      try {
        const data = await updateDataset({ id, ...dataset })
        this.datasets[data.id] = data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async retrieveDataset(datasetId: UUID) {
      try {
        this.datasets[datasetId] = await retrieveDataset(datasetId)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async deleteCorpusDataset(corpusId: UUID, datasetId: UUID) {
      try {
        await deleteDataset(datasetId)
        if (!this.corpusDatasets[corpusId])
          throw new Error(`Datasets for corpus ${corpusId} not found`)
        // Remove datasetId from this.corpusDatasets
        const deleted = this.corpusDatasets[corpusId].delete(datasetId)
        if (!deleted) throw new Error(`Dataset ${datasetId} not found in corpus ${corpusId}`)
        // Remove dataset from this.datasets
        delete this.datasets[datasetId]
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async cloneDataset(datasetId: UUID) {
      try {
        const data = await cloneDataset(datasetId)
        this.datasets[data.id] = data
        if (this.corpusDatasets[data.corpus_id]) this.corpusDatasets[data.corpus_id].add(data.id)
        return data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    // Add selected elements to a dataset of the same corpus
    async addDatasetElementsSelection(corpusId: UUID, datasetId: UUID, datasetSet: DatasetSet) {
      try {
        await createDatasetElementsSelection(corpusId, datasetSet.id)

        // Reset an eventual cursor pagination listing elements of this set
        delete this.datasetElementPagination[datasetId]?.[datasetSet.name]

        useNotificationStore().notify({
          type: 'success',
          text: 'Elements have been added to the dataset set.',
        })

        // Erase the cached datasets for all elements that were in the selection, so that they are reloaded with the new dataset set
        const elementStore = useElementStore()
        for (const elementId of useSelectionStore().selection[corpusId] ?? []) {
          delete elementStore.elementDatasetSets[elementId]
        }
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    /**
     * Start, restart or continue fetching elements within a dataset, one page at a time.
     *
     * If no pagination was stored in the state, starts from the first page.
     * If a pagination existed and a next page is available, resumes paginating.
     */
    async nextDatasetElements(datasetId: UUID, setName: string) {
      if (this.datasetElementPagination[datasetId] === undefined)
        this.datasetElementPagination[datasetId] = {}
      const currentPage = this.datasetElementPagination[datasetId][setName]
      if (currentPage && currentPage.nextCursor === null) {
        useNotificationStore().notify({
          type: 'warning',
          text: 'All elements have been fetched already.',
        })
        return
      }
      // Return early when we know no element is part of this set
      if (this.datasets[datasetId]?.set_elements?.[setName] === 0) {
        this.datasetElementPagination[datasetId][setName] = {
          datasetElements: [],
          nextCursor: null,
        }
        return
      }
      try {
        const resp = await listDatasetElements(datasetId, {
          set: setName,
          cursor: currentPage?.nextCursor ?? null,
        })
        const nextCursor = resp.next && new URL(resp.next).searchParams.get('cursor')
        this.datasetElementPagination[datasetId][setName] = {
          datasetElements: [...(currentPage?.datasetElements ?? []), ...resp.results],
          nextCursor,
        }
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async removeDatasetElement(datasetId: UUID, elementId: UUID, setName: string) {
      try {
        await deleteDatasetElement(datasetId, elementId, setName)
        // When the action is called from the element details panel, this.datasetElementPagination[datasetId] can be undefined
        if (this.datasetElementPagination[datasetId]) {
          const currentPage = this.datasetElementPagination[datasetId][setName]
          if (currentPage) {
            const index = currentPage.datasetElements.findIndex(
              (datasetElement) => datasetElement.element.id === elementId,
            )
            if (index >= 0) currentPage.datasetElements.splice(index, 1)
          }
        }
        // Update dataset sets count
        const setElements = this.datasets[datasetId]?.set_elements
        if (setElements && setElements[setName] !== undefined) setElements[setName] -= 1
        // Remove element link to the dataset
        const elementDatasetSets = useElementStore().elementDatasetSets[elementId]
        if (elementDatasetSets === undefined) return
        const index = elementDatasetSets.findIndex(
          (dss) => dss.dataset.id === datasetId && dss.set === setName,
        )
        if (index < 0)
          throw new Error(
            `Element ${elementId} not found in set ${setName} of dataset ${datasetId}`,
          )
        elementDatasetSets.splice(index, 1)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async createDatasetSet(datasetId: UUID, name: string) {
      try {
        const data = await createDatasetSet(datasetId, name)
        this.datasets[datasetId].sets.push(data)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async updateDatasetSet(datasetId: UUID, setId: UUID, name: string) {
      try {
        const data = await updateDatasetSet(datasetId, setId, name)
        const datasetSets = this.datasets[datasetId].sets
        const index = datasetSets.findIndex((dss) => dss.id === setId)
        if (index < 0) throw new Error(`Set ${setId} not found in dataset ${datasetId}`)
        datasetSets[index] = data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async deleteDatasetSet(datasetId: UUID, setId: UUID) {
      try {
        await deleteDatasetSet(datasetId, setId)
        const datasetSets = this.datasets[datasetId].sets
        const index = datasetSets.findIndex((dss) => dss.id === setId)
        if (index < 0) throw new Error(`Set ${setId} not found in dataset ${datasetId}`)
        datasetSets.splice(index, 1)
        useNotificationStore().notify({ type: 'success', text: 'Dataset set deleted' })
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },
    async populateDataset(datasetId: UUID, payload: DatasetPopulate) {
      try {
        await populateDataset(datasetId, payload)
        delete this.datasetElementPagination[datasetId]
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async reopenDataset(datasetId: UUID) {
      try {
        const data = await updateDataset({ id: datasetId, state: 'open' })
        this.datasets[data.id] = data
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },
  },

  getters: {
    /**
     * Retrieve the Dataset objects belonging to a corpus from the list of UUIDs in corpusDatasets
     */
    singleCorpusDatasets() {
      return (corpusId: UUID) => {
        return [...(this.corpusDatasets[corpusId] ?? [])].map((id) => this.datasets[id])
      }
    },
  },
})
