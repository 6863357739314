<template>
  <transition
    name="notification-fade"
    appear
  >
    <div
      class="notification"
      :class="NOTIFICATION_TYPES[type]"
    >
      <button
        class="delete"
        v-on:click="close"
      ></button>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="markdown"
        v-html="md.render(truncatedText)"
      ></div>
      <!-- eslint-enable vue/no-v-html -->
      <div v-else>{{ truncatedText }}</div>
      <router-link
        v-if="link && link.route"
        :to="link.route"
      >
        {{ truncateLong(link.text || 'link') }}
      </router-link>
    </div>
  </transition>
</template>

<script lang="ts">
import MarkdownIt from 'markdown-it'
import { type PropType, defineComponent } from 'vue'

import { NOTIFICATION_TYPES } from '@/config'
import { truncateMixin } from '@/mixins'
import { useNotificationStore } from '@/stores'
import type { NotificationLink } from '@/stores/notification'

export default defineComponent({
  mixins: [truncateMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String as PropType<keyof typeof NOTIFICATION_TYPES>,
      required: true,
      validator: (value) => typeof value === 'string' && value in NOTIFICATION_TYPES,
    },
    text: {
      type: String,
      required: true,
    },
    timeout: {
      type: Number,
      default: 5000,
      validator: (value) => typeof value === 'number' && value >= 0,
    },
    link: {
      // Valid route to provide a link in the notification
      type: Object as PropType<NotificationLink | null>,
      default: null,
    },
    markdown: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    NOTIFICATION_TYPES,
    timeoutId: null as number | null,
    md: MarkdownIt({ breaks: true }),
  }),
  mounted() {
    if (this.timeout > 0) this.timeoutId = setTimeout(this.close, this.timeout)
  },
  computed: {
    truncatedText() {
      return this.truncateNotification(this.text)
    },
  },
  methods: {
    close() {
      if (this.timeoutId !== null) clearTimeout(this.timeoutId)
      const store = useNotificationStore()
      store.remove(this.id)
    },
  },
})
</script>

<style scoped>
.notification {
  margin: 0;
  padding: 1rem;
  padding-right: 2.5rem;
  word-break: break-all;
}
.notification:not(:last-child) {
  margin-bottom: 0.5rem;
}
</style>
