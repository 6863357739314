<template>
  <div class="transcription">
    <EditionForm
      v-if="editing"
      :element="element"
      :transcription="transcription"
      v-on:close="editing = false"
    />
    <template v-else>
      <Actions
        class="is-pulled-right mb-1"
        :element="element"
        :transcription="transcription"
        v-on:edit="editing = true"
      />
      <div
        class="select is-truncated"
        v-if="entityFilterValues.length"
      >
        <select v-model="workerRunFilter">
          <option value="">No entities</option>
          <option
            v-for="[id, name] in entityFilterValues"
            :key="id"
            :value="id"
          >
            {{ truncateSelect(name) }}
          </option>
        </select>
      </div>
      <span class="is-clearfix"></span>
      <Box
        :transcription="transcription"
        :worker-run-filter="workerRunFilter"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { sortBy } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { hasWorkerRun } from '@/helpers'
import { truncateMixin } from '@/mixins'
import { useEntityStore, useWorkerStore } from '@/stores'
import type { Element, ElementBase } from '@/types'
import type { Transcription } from '@/types/transcription'

import Actions from './Actions.vue'
import Box from './Box.vue'
import EditionForm from './EditionForm.vue'

export default defineComponent({
  mixins: [truncateMixin],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
    transcription: {
      type: Object as PropType<Transcription>,
      required: true,
    },
  },
  components: {
    Actions,
    Box,
    EditionForm,
  },
  data: () => ({
    workerRunFilter: '',
    editing: false,
  }),
  mounted() {
    if (!this.inTranscription[this.transcription.id]?.results)
      this.listInTranscription(this.transcription.id)
  },
  computed: {
    ...mapState(useEntityStore, ['inTranscription']),
    /**
     * Values and display names for the options of the entity worker runs filter.
     * Array of IDs and display names.
     */
    entityFilterValues(): [string, string][] {
      const transcriptionEntities = this.inTranscription[this.transcription.id]?.results
      if (!transcriptionEntities) return []

      const values: [string, string][] = []
      // If there are TranscriptionEntities with no worker run, add the manual option as the very first item
      if (transcriptionEntities.some((transcriptionEntity) => !hasWorkerRun(transcriptionEntity))) {
        values.push(['manual', 'Manual'])
      }

      values.push(
        ...sortBy(
          // Turn into an object to make the array unique by ID, but turn back into an array to allow sorting
          Object.entries(
            Object.fromEntries(
              transcriptionEntities
                .filter(hasWorkerRun)
                .map((transcriptionEntity) => [
                  transcriptionEntity.worker_run.id,
                  transcriptionEntity.worker_run.summary,
                ]),
            ),
          ),
          [1, 0],
        ),
      )

      return values
    },
  },
  methods: {
    ...mapActions(useWorkerStore, ['getWorkerVersion']),
    ...mapActions(useEntityStore, ['listInTranscription']),
  },
  watch: {
    entityFilterValues: {
      handler(newValue) {
        // Automatically select the first available filter option
        if (newValue.length) this.workerRunFilter = newValue[0][0]
      },
      immediate: true,
    },
  },
})
</script>

<style scoped>
/* Add some spacing between two transcriptions */
.transcription:not(:last-child) {
  margin-bottom: 0.5rem;
}
</style>
