import { defineStore } from 'pinia'

import { deleteJob, listJobs } from '@/api'
import { JOBS_POLLING_DELAY } from '@/config'
import { errorParser } from '@/helpers'
import type { Job, UUID } from '@/types'

import { useNotificationStore } from '.'

interface State {
  jobs: {
    [jobId: UUID]: Job
  }
  /**
   * ID of the timeout returned by `setTimeout` used to perform the polling.
   *
   * This has a different return type in Node.js and in a web browser,
   * so this is declared as `ReturnType<typeof setTimeout>`,
   * but in practice it can be assumed to be an integer or `null`.
   */
  timeoutId: ReturnType<typeof setTimeout> | null
  /**
   * Whether job polling is currently enabled.
   */
  polling: boolean
  /**
   * Whether the jobs list is being refreshed.
   */
  loading: boolean
}

export const useJobsStore = defineStore('jobs', {
  state: (): State => ({
    jobs: {},
    timeoutId: null,
    polling: false,
    loading: false,
  }),
  actions: {
    async list() {
      if (this.loading) return
      this.loading = true
      try {
        const jobs = await listJobs()
        this.jobs = Object.fromEntries(jobs.map((job) => [job.id, job]))
      } catch (err) {
        const notificationStore = useNotificationStore()
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      } finally {
        this.loading = false
      }
    },

    async delete(id: UUID) {
      try {
        await deleteJob(id)
        delete this.jobs[id]
      } catch (err) {
        const notificationStore = useNotificationStore()
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      }
    },

    async startPolling() {
      this.stopPolling()
      this.polling = true
      const update = async () => {
        if (this.polling) {
          await this.list()
          this.timeoutId = setTimeout(update, JOBS_POLLING_DELAY)
        }
      }
      await update()
    },

    stopPolling() {
      this.polling = false
      if (this.timeoutId) clearTimeout(this.timeoutId)
      this.timeoutId = null
    },
  },
})
