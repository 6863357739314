import { defineStore } from 'pinia'

import {
  type CreateModelPayload,
  type ModelListParameters,
  type ModelVersionEditParameters,
  type UpdateModelPayload,
  createCompatibleWorker,
  createModel,
  deleteCompatibleWorker,
  deleteModelVersion,
  listModelVersions,
  listModels,
  retrieveModel,
  retrieveModelVersion,
  updateModel,
  updateModelVersion,
} from '@/api'
import { errorParser } from '@/helpers'
import type { UUID } from '@/types'
import type { Model, ModelVersion } from '@/types/model'
import type { Worker } from '@/types/worker'

import { useNotificationStore, useWorkerStore } from '.'

interface State {
  models: { [id: UUID]: Model }
  modelVersions: { [id: UUID]: ModelVersion }
  // Lists of compatible workers for a specific model
  compatibleWorkers: { [modelId: UUID]: Worker[] }
}

export const useModelStore = defineStore('model', {
  state: (): State => ({
    models: {},
    modelVersions: {},
    compatibleWorkers: {},
  }),
  actions: {
    async createModel(params: CreateModelPayload) {
      const model = await createModel(params)
      this.models[model.id] = model
      return model
    },

    async updateModel(id: UUID, params: UpdateModelPayload) {
      const model = await updateModel(id, params)
      this.models[model.id] = model
      return model
    },

    async listModels(params: ModelListParameters) {
      const resp = await listModels(params)
      this.models = {
        ...this.models,
        ...Object.fromEntries(resp.results.map((model) => [model.id, model])),
      }
      return resp
    },

    async listModelVersions(modelId: UUID, page = 1) {
      const resp = await listModelVersions(modelId, { page })
      this.modelVersions = {
        ...this.modelVersions,
        ...Object.fromEntries(resp.results.map((modelVersion) => [modelVersion.id, modelVersion])),
      }
      return resp
    },

    /**
     * Automatically lists workers compatible with a specific model through all pages
     */
    async listCompatibleWorkers(modelId: UUID, page = 1) {
      if (page === 1) this.compatibleWorkers[modelId] = []
      try {
        const workerStore = useWorkerStore()
        const resp = await workerStore.listWorkers({ compatible_model: modelId, page })
        if (!resp || !resp.number || page !== resp.number) {
          throw new Error("Pagination failed while listing models's compatible workers")
        } else if (resp.next) {
          // Fetch next pages asynchronously
          this.listCompatibleWorkers(modelId, page + 1)
        }
        this.compatibleWorkers[modelId].push(...resp.results)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async addCompatibleWorker(modelId: UUID, worker: Worker) {
      await createCompatibleWorker(modelId, worker.id)
      if (this.compatibleWorkers[modelId] === undefined) return
      this.compatibleWorkers[modelId].push(worker)
    },

    async removeCompatibleWorker(modelId: UUID, worker: Worker) {
      await deleteCompatibleWorker(modelId, worker.id)
      const workersList = this.compatibleWorkers[modelId]
      if (workersList === undefined) return
      const index = workersList.findIndex((w) => w.id === worker.id)
      if (index >= 0) workersList.splice(index, 1)
    },

    async retrieveModel(modelId: UUID) {
      const model = await retrieveModel(modelId)
      this.models[model.id] = model
    },

    async getModelVersion(modelVersionId: UUID) {
      const modelVersion = await retrieveModelVersion(modelVersionId)
      this.modelVersions[modelVersion.id] = modelVersion
    },

    async deleteModelVersion(versionId: UUID) {
      try {
        await deleteModelVersion(versionId)
        delete this.modelVersions[versionId]
      } catch (err) {
        const notificationStore = useNotificationStore()
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      }
    },

    async updateModelVersion(id: UUID, params: ModelVersionEditParameters) {
      const resp = await updateModelVersion(id, params)
      this.modelVersions[id] = resp
    },
  },
})
