<template>
  <div
    class="dropdown is-hoverable"
    :class="{ 'is-up': up }"
    v-on:mouseenter="mouseEnter"
    v-on:mouseleave="mouseLeave"
  >
    <div class="dropdown-trigger">
      <slot>
        <i class="icon icon-help has-text-info"></i>
      </slot>
    </div>
    <div
      class="dropdown-menu"
      :class="{ visible }"
    >
      <div class="dropdown-content">
        <div class="dropdown-item">
          <slot name="popup"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    up: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    visible: false,
  }),
  mounted() {
    /*
     * Hide the tooltip when user clicks elsewhere in the document
     */
    document.addEventListener('mouseup', this.mouseLeave)
  },
  methods: {
    mouseEnter() {
      this.visible = true
    },
    mouseLeave() {
      /*
       * Hide the tooltip unless its contents are clicked or selected
       */
      const selection = window.getSelection()
      if (selection && this.$el.contains(selection.focusNode)) return
      this.visible = false
    },
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  & .dropdown-trigger {
    cursor: pointer;
  }

  & .dropdown-menu {
    display: block;
    top: 50%;
    left: 50%;
    visibility: hidden;
    transition: visibility 0.1s 0s;

    &.visible {
      visibility: visible;
    }

    & .dropdown-content {
      border-radius: 0 0.5rem 0.5rem 0.5rem;
      max-width: 20rem;
      word-break: normal;
    }
  }
  &.is-up .dropdown-menu {
    top: auto;
    bottom: 50%;

    & .dropdown-content {
      border-radius: 0.5rem 0.5rem 0.5rem 0;
    }
  }
}
</style>
