<template>
  <input
    class="input"
    :value="modelValue"
    v-on:input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    :placeholder="field.type"
  />
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import type { IntegerUserConfigurationField } from '@/types/workerConfiguration'

export default defineComponent({
  emits: {
    'update:modelValue': (value: number | string) => ['string', 'number'].includes(typeof value),
  },
  props: {
    field: {
      type: Object as PropType<IntegerUserConfigurationField>,
      required: true,
    },
    modelValue: {
      type: [Number, String],
      required: true,
    },
  },
})
</script>
