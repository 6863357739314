<template>
  <div class="paginator">
    <slot
      name="loading"
      v-if="loading"
    >
      <div class="loading-content loader"></div>
    </slot>
    <template v-else>
      <template v-if="response && (allowEmpty || (count ?? 0) > 0)">
        <PaginationBar
          :response="response"
          :singular="singular"
          :plural="plural"
          :page-size="pageSize"
          v-on:navigate="load"
        />
        <hr class="is-hr-top" />

        <slot :results="response.results"></slot>

        <template v-if="bottomBar">
          <hr class="is-hr-bottom" />
          <PaginationBar
            :response="response"
            :singular="singular"
            :plural="plural"
            :page-size="pageSize"
            v-on:navigate="load"
          />
        </template>
      </template>
      <slot
        name="no-results"
        v-else-if="response && count === 0"
      >
        <div class="notification is-warning">No results.</div>
      </slot>
    </template>
  </div>
</template>

<script setup lang="ts" generic="T">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { DEFAULT_PAGE_SIZE } from '@/config'
import type { PaginationParams } from '@/helpers'

import PaginationBar, { type Props as PaginationBarProps } from './PaginationBar.vue'

interface Props extends PaginationBarProps<T> {
  /**
   * Avoid updating URL query when updating the page. An `update:page` event will be emitted instead.
   *
   * This property requires v-model:page in order to be updated.
   */
  page?: number | null
  bottomBar?: boolean
  loading?: boolean
  allowEmpty?: boolean
}

const {
  response,
  singular = 'result',
  plural = 'results',
  page = null,
  pageSize = DEFAULT_PAGE_SIZE,
  bottomBar = false,
  loading = false,
  allowEmpty = false,
} = defineProps<Props>()

const emit = defineEmits<{ 'update:page': [page: number] }>()

const router = useRouter()
const route = useRoute()

const count = computed((): number | null => {
  return response?.results?.length ?? null
})

function load(params: PaginationParams) {
  if (loading || (!params.page && !params.cursor)) return
  if (page && params.page) {
    // Update the page prop only
    emit('update:page', parseInt(params.page, 10))
    return
  }
  const query = { ...route.query }
  if (params.page) query.page = params.page
  if (params.cursor) query.cursor = params.cursor
  // Update URL page number
  router.push({ name: route.name, query })
}
</script>

<style scoped>
.paginator:not(:first-child) {
  margin-top: 1em;
}
.title + .paginator {
  margin-top: 0;
}
hr {
  margin: 1rem 0;
}
.loading-content {
  font-size: 2.5rem;
  margin: 2.5rem auto 0 auto;
}
</style>
