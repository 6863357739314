<template>
  <div class="m-0">
    <WorkerRunSummary
      class="is-pulled-right"
      v-if="metadata.worker_run"
      :worker-run-details="metadata.worker_run"
      icon-only
    />
    <!-- Pass v-bind as the last attribute, allowing to override the metadata value -->
    <MetadataActions
      class="pb-1 is-actions is-paddingless"
      :corpus-id="corpusId"
      :element-id="elementId"
      :metadata="metadata"
      v-bind="$attrs"
    />
    <div class="content">
      <h4 class="pb-1 m-0">{{ metadata.name }}</h4>
      <div class="pb-1">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <blockquote v-html="html"></blockquote>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MarkdownIt from 'markdown-it'
import { type PropType, defineComponent } from 'vue'

import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import type { UUID } from '@/types'
import type { MetaData } from '@/types/metadata'

import MetadataActions from './Actions.vue'

/**
 * Displays a single metadata of a Markdown type.
 */
export default defineComponent({
  components: {
    MetadataActions,
    WorkerRunSummary,
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true,
    },
    metadata: {
      type: Object as PropType<MetaData>,
      required: true,
      validator: (value: unknown): boolean =>
        value !== null && typeof value === 'object' && 'type' in value && value.type === 'markdown',
    },
  },
  computed: {
    html(): string {
      if (typeof this.metadata.value !== 'string') throw new Error('Unsupported metadata value')
      return MarkdownIt({ html: true }).render(this.metadata.value)
    },
  },
})
</script>

<style scoped>
.content:hover > .is-actions {
  display: block !important;
}
.content:hover > h4 {
  background-color: #fafafa;
}
</style>
