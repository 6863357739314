<template>
  <a
    :title="configuration.name"
    v-on:click="configurationModal = true"
  >
    {{ truncateShort(configuration.name) }}
  </a>
  <br />
  <small>
    <ItemId :item-id="configuration.id" />
  </small>
  <ConfigurationsList
    v-if="configurationModal"
    :configuration-id="configuration?.id"
    v-model="configurationModal"
    :worker-version="workerVersion"
    :read-only="true"
  />
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import ItemId from '@/components/ItemId.vue'
import { truncateMixin } from '@/mixins'
import type { WorkerVersion } from '@/types/worker'
import type { WorkerConfiguration } from '@/types/workerConfiguration'

import ConfigurationsList from './List.vue'

export default defineComponent({
  props: {
    configuration: {
      type: Object as PropType<WorkerConfiguration>,
      required: true,
    },
    workerVersion: {
      type: Object as PropType<WorkerVersion>,
      required: true,
    },
  },
  components: {
    ConfigurationsList,
    ItemId,
  },
  mixins: [truncateMixin],
  data: () => ({
    configurationModal: false,
  }),
})
</script>
