<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="(value: boolean) => $emit('update:modelValue', value)"
    title="New folder"
  >
    <form v-on:submit.prevent="addFolder">
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Name</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                type="text"
                class="input"
                v-model="name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">Type</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <span class="select is-fullwidth">
                <select
                  v-model="typeSlug"
                  class="select"
                  :disabled="availableTypes.length < 1 || undefined"
                >
                  <option
                    value=""
                    disabled
                    selected
                  >
                    Select a type
                  </option>
                  <option
                    v-for="{ slug, display_name } in availableTypes"
                    :key="slug"
                    :value="slug"
                  >
                    {{ truncateSelect(display_name) }}
                  </option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template v-slot:footer>
      <button
        class="button is-primary"
        :class="{ 'is-loading': loading }"
        :disabled="loading || !canWrite(corpus) || !typeSlug || !name || undefined"
        v-on:click="addFolder"
      >
        Add folder
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { mapActions } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import Modal from '@/components/Modal.vue'
import { errorParser } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useElementStore, useNotificationStore } from '@/stores'
import type { ElementType } from '@/types'

export default defineComponent({
  mixins: [truncateMixin, corporaMixin],
  components: {
    Modal,
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    corpusId: {
      type: String,
      required: true,
    },
    parentId: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    typeSlug: '',
    name: '',
  }),
  computed: {
    availableTypes(): ElementType[] {
      return this.corpus ? this.folderTypes(this.corpus.types) : []
    },
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    async addFolder() {
      if (this.loading || !this.canWrite(this.corpus) || !this.typeSlug || !this.name) return
      this.loading = true
      try {
        const folder = await useElementStore().create({
          corpus: this.corpusId,
          parent: this.parentId ?? undefined,
          type: this.typeSlug,
          name: this.name,
        })
        // Display a notification with a link to the folder
        const type = this.availableTypes.find((t) => t.slug === this.typeSlug)
        this.notify({
          type: 'success',
          text: 'Successfully created',
          link: {
            text: `${(type && type.display_name.toLowerCase()) || this.typeSlug} "${this.name}"`,
            route: { name: 'element-details', params: { id: folder.id } },
          },
        })
      } catch (e) {
        this.notify({
          type: 'error',
          text: `An error occurred while creating the folder: ${errorParser(e)}`,
        })
      } finally {
        this.loading = false
        this.$emit('update:modelValue', false)
      }
    },
  },
  watch: {
    availableTypes: {
      handler(newTypes: ElementType[]) {
        // Unselect any non-existent type
        if (!newTypes.some((t) => t.slug === this.typeSlug)) this.typeSlug = ''
        // Autoselect when there is only one folder type
        if (newTypes.length === 1 && this.typeSlug === '') this.typeSlug = newTypes[0].slug
      },
      immediate: true,
    },
  },
})
</script>
