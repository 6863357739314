<template>
  <div class="has-text-right mb-2">
    <CreateForm
      mode="create"
      v-on:reload="updateModelsPage"
    />
  </div>

  <div
    v-if="compatibleWorkerId"
    class="field has-text-right"
  >
    <div class="control">
      <label class="checkbox">
        <input
          type="checkbox"
          v-model="allModels"
        />
        Show all models
      </label>
    </div>
    <p class="help all-models-help is-pulled-right mb-2">
      By default, only models compatible with the selected worker are shown.
    </p>
  </div>

  <div
    v-if="showArchived"
    class="tabs is-centered mb-2"
  >
    <ul>
      <li
        :class="{ 'is-active': !archivedModels }"
        v-on:click="archivedModels = false"
      >
        <a>Available models</a>
      </li>
      <li
        :class="{ 'is-active': archivedModels }"
        v-on:click="archivedModels = true"
      >
        <a>Archived models</a>
      </li>
    </ul>
  </div>

  <form
    v-if="modelsPage"
    class="field"
    v-on:submit.prevent="filter"
  >
    <div class="control">
      <input
        class="input"
        type="text"
        v-model="nameFilter"
        placeholder="Filter by name…"
      />
    </div>
  </form>

  <div class="control">
    <Paginator
      :response="modelsPage"
      v-slot="{ results }"
      :loading="loading"
      v-model:page="page"
      singular="model"
      plural="models"
    >
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="model in results"
            :key="model.id"
            v-on:click="$emit('update:modelValue', model.id)"
            class="is-clickable"
            :class="{ 'is-selected': modelValue === model.id }"
            :title="model.name"
          >
            <td>
              {{ truncateLong(model.name) }}
            </td>
          </tr>
        </tbody>
      </table>
    </Paginator>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import type { ModelListParameters } from '@/api'
import CreateForm from '@/components/Model/EditForm.vue'
import Paginator from '@/components/Paginator.vue'
import { UUID_REGEX } from '@/config'
import { errorParser } from '@/helpers'
import { truncateMixin } from '@/mixins'
import { useModelStore, useNotificationStore } from '@/stores'
import type { PageNumberPagination, UUID } from '@/types'
import type { Model } from '@/types/model'

export default defineComponent({
  mixins: [truncateMixin],
  components: {
    Paginator,
    CreateForm,
  },
  emits: {
    'update:modelValue': (value: UUID) =>
      typeof value === 'string' && (UUID_REGEX.test(value) || value === ''),
  },
  props: {
    /**
     * Show archived models (only when listing models outside of a process)
     */
    showArchived: {
      type: Boolean,
      default: false,
    },
    compatibleWorkerId: {
      type: String as PropType<UUID | null>,
      default: null,
    },
    // A model ID can be passed by a parent to this component
    modelValue: {
      type: String as PropType<UUID | null>,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    modelsPage: undefined as PageNumberPagination<Model> | undefined,
    page: 1,
    nameFilter: '',
    allModels: false,
    archivedModels: false,
  }),
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useModelStore, ['listModels']),
    async updateModelsPage() {
      this.loading = true
      try {
        const payload: ModelListParameters = { page: this.page, archived: this.archivedModels }
        if (this.nameFilter) payload.name = this.nameFilter
        if (this.compatibleWorkerId && !this.allModels)
          payload.compatible_worker = this.compatibleWorkerId
        this.modelsPage = await this.listModels(payload)
      } catch (err) {
        this.notify({
          type: 'error',
          text: `An error occurred listing models: ${errorParser(err)}`,
        })
      } finally {
        this.loading = false
      }
    },
    filter() {
      // Update models list. Reset page if required
      if (this.page === 1) return this.updateModelsPage()
      this.page = 1
    },
  },
  computed: {
    ...mapState(useModelStore, ['models']),
  },
  watch: {
    page: {
      immediate: true,
      handler: 'updateModelsPage',
    },
    /*
     * When toggling the Show all models checkbox or switching between (un)archived models,
     * reset the page number if it is not at page 1, as those actions may change the number of pages.
     * Resetting the page to 1 will trigger updateModelsPage due to the `page` watcher.
     */
    allModels: {
      handler: 'filter',
    },
    archivedModels: {
      immediate: true,
      handler: 'filter',
    },
  },
})
</script>

<style lang="scss" scoped>
.all-models-help {
  margin-top: -0.5em;
}

.tabs {
  width: 100%;
}
.tabs a {
  padding-top: 0;
}
</style>
