<template>
  <span>
    <!--
      You can override the default slot to customize the button that opens this modal:
      <SingleDeletionModal :element="element">
        <button ... />
      </SingleDeletionModal>
      Do not show this slot if the modal is being opened and closed using v-model.
    -->
    <slot
      :open="open"
      :can-delete="canDelete"
    >
      <i
        v-if="modelValue === undefined"
        class="icon-trash"
        :class="canDelete ? 'has-text-danger' : 'has-text-grey'"
        v-on:click.prevent="open"
      ></i>
    </slot>
    <Modal
      v-model="opened"
      :title="modalTitle"
      v-on:update:opened="(value: boolean) => $emit('update:modelValue', value)"
    >
      <p>
        Are you sure you want to delete
        <template v-if="isElement(target)">
          the {{ typeName(target.type) }} <strong>{{ truncateLong(target.name) }}</strong>
        </template>
        <template v-else>
          project <strong>{{ truncateLong(target.name) }}</strong> </template
        >?<br />
        Child elements will also be deleted recursively.<br />
        This action is irreversible.
      </p>
      <template v-slot:footer="{ close }">
        <button
          class="button"
          v-on:click.prevent="close"
        >
          Cancel
        </button>
        <button
          class="button is-danger"
          :class="{ 'is-loading': loading }"
          :disabled="loading || !canDelete || undefined"
          :title="deleteTitle"
          v-on:click.prevent="performDelete"
        >
          Delete
        </button>
      </template>
    </Modal>
  </span>
</template>

<script lang="ts">
import { mapActions, mapState, mapWritableState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import Modal from '@/components/Modal.vue'
import { errorParser } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import {
  useAnnotationStore,
  useAuthStore,
  useCorporaStore,
  useElementStore,
  useNotificationStore,
} from '@/stores'
import type { Corpus, Element, ElementBase, UUID } from '@/types'
import type { ProcessElementList } from '@/types/process'

type DeletionTarget = Corpus | Element | ElementBase | ProcessElementList

function isElement(target: DeletionTarget): target is Element | ElementBase {
  return 'type' in target
}

export default defineComponent({
  components: {
    Modal,
  },
  emits: ['update:modelValue'],
  mixins: [corporaMixin, truncateMixin],
  props: {
    // The object to delete (Element or Corpus).
    target: {
      type: Object as PropType<DeletionTarget>,
      required: true,
    },
    // For redirection if an element is being deleted from its main view.
    redirect: {
      type: Boolean,
      default: false,
    },
    // To open and close the modal without a button
    modelValue: {
      type: Boolean,
      default: undefined,
    },
  },
  data: () => ({
    opened: false,
    loading: false,
  }),
  computed: {
    ...mapState(useElementStore, {
      // canAdmin is already defined in corporaMixin
      canAdminElement: 'canAdmin',
      firstParentId: 'firstParentId',
    }),
    ...mapWritableState(useAnnotationStore, ['selectedElement']),
    ...mapState(useAuthStore, ['isVerified']),
    // Required for the corporaMixin
    corpusId(): UUID | null {
      if (isElement(this.target)) return this.target.corpus?.id || null
      else return this.target.id
    },
    /**
     * Allow deleting elements only if the user is verified and has admin rights on the corpus.
     * Additionally, if the element has a `rights` attribute, look for an admin right.
     * This element rights check cannot be done for every element since the rights attribute is not available in all endpoints.
     */
    canDelete(): boolean {
      if (!this.isVerified) return false
      if (isElement(this.target) && 'rights' in this.target)
        return this.canAdminElement(this.target.id)
      else return this.corpus !== null && this.canAdmin(this.corpus)
    },
    modalTitle() {
      if (isElement(this.target))
        return (
          'Delete ' +
          this.truncateShort(this.typeName(this.target.type)) +
          ' ' +
          this.truncateShort(this.target.name)
        )
      else return 'Delete project ' + this.truncateLong(this.target.name)
    },
    deleteTitle() {
      if (!this.canDelete) {
        if (isElement(this.target))
          return 'You do not have the required rights to delete this element'
        else return 'You do not have the required rights to delete this project'
      }
      return ''
    },
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(useCorporaStore, { deleteCorpus: 'delete' }),
    ...mapActions(useElementStore, { deleteElement: 'delete' }),
    isElement,
    // A simple helper made available in the slot to let you open the modal
    open() {
      this.opened = this.canDelete
    },
    async performDelete() {
      if (!this.canDelete || this.loading) return
      let newRoute = null
      if (this.redirect) {
        if (isElement(this.target)) {
          // When deleting an element that is the main element, redirect to the first parent element or the corpus.
          if (this.firstParentId(this.target.id))
            newRoute = {
              name: 'element-details',
              params: { id: this.firstParentId(this.target.id) },
            }
          else newRoute = { name: 'navigation', params: { corpusId: this.corpusId } }
          // If a project is being deleted, redirect to the project list.
        } else newRoute = { name: 'corpus-list' }
      }
      this.loading = true
      try {
        if (isElement(this.target)) await this.deleteElement(this.target.id)
        else await this.deleteCorpus(this.target.id)
        // Redirect if we defined a route above for a main element or project
        if (newRoute != null) this.$router.push(newRoute)
        // Otherwise unselect an eventual highlighted element
        else this.selectedElement = null
        this.opened = false
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (newValue !== undefined) this.opened = newValue
      },
    },
    opened(newValue, oldValue) {
      if (newValue !== oldValue) this.$emit('update:modelValue', this.opened)
    },
  },
})
</script>
