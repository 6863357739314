<template>
  <div
    class="is-pulled-right"
    v-bind="$attrs"
  >
    <!--
      Only display the edit button for metadata without entities.
      Keep it disabled if the metadata has no entity, but the user does not have access rights to edit it.
    -->
    <a
      class="icon icon-edit has-text-info"
      :class="{ disabled: !editable }"
      v-if="!metadata.entity"
      v-on:click="editModal = editable"
      :title="editable ? 'Edit metadata' : 'Only an administrator can edit this protected metadata'"
    ></a
    ><a
      class="icon icon-trash has-text-danger"
      :class="{ disabled: !editable }"
      v-on:click="deleteModal = editable"
      :title="
        editable ? 'Delete metadata' : 'Only an administrator can delete this protected metadata'
      "
    ></a>
  </div>
  <EditModal
    v-if="editModal"
    v-model="editModal"
    :corpus-id="corpusId"
    :element-id="elementId"
    :metadata="metadata"
  />
  <DeleteModal
    v-if="deleteModal"
    v-model="deleteModal"
    :element-id="elementId"
    :metadata="metadata"
  />
</template>

<script lang="ts">
import { mapState, mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { corporaMixin } from '@/mixins'
import { useAllowedMetaDataStore, useAuthStore, useMetaDataStore } from '@/stores'
import type { UUID } from '@/types'
import type { MetaData } from '@/types/metadata'

import DeleteModal from './DeleteModal.vue'
import EditModal from './EditModal.vue'

export default defineComponent({
  mixins: [corporaMixin],
  components: {
    DeleteModal,
    EditModal,
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true,
    },
    metadata: {
      type: Object as PropType<MetaData>,
      required: true,
    },
  },
  data: () => ({
    editModal: false,
    deleteModal: false,
  }),
  computed: {
    ...mapStores(useMetaDataStore, useAllowedMetaDataStore),
    ...mapState(useAuthStore, ['isAdmin']),
    editable(): boolean {
      // Admins can edit anything
      if (this.isAdmin) return true
      // Non-admins need write access
      if (!this.canWrite(this.corpus)) return false

      // Non-admins need for the metadata to be allowed, but we might not know if it is if that wasn't loaded yet
      return (
        !(this.corpusId in this.allowedMetadataStore.allowedMetadata) ||
        this.allowedMetadataStore.allowedMetadata[this.corpusId].some(
          ({ name, type }) => name === this.metadata.name && type === this.metadata.type,
        )
      )
    },
  },
})
</script>

<style scoped>
.icon.disabled {
  color: lightgray !important;
  cursor: not-allowed;
}
</style>
