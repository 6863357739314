import { defineStore } from 'pinia'

import { useElementStore } from '@/stores'
import type { Element, ElementBase, Tree, UUID } from '@/types'

interface State {
  /*
   * Defines expanded nodes in the tree view
   * elementId[]
   */
  toggledNodes: UUID[]
  /*
   * Stores tree element type filter during neighbors navigation i.e. with a same parent.
   * Filter is cleaned when updating navigation route in the `Element/Main.vue` component.
   */
  typeFilter: string | null
  /**
   * Stores worker run UUID filter value
   */
  workerFilter: UUID | null
}

// Map expanded nodes to elements
const getNodeChildren = (state: State, element: Element | ElementBase) => {
  const elementStore = useElementStore()
  const link = elementStore.links[element.id]
  if (!link) return []
  return link.children.map((id) => elementStore.elements[id]).filter((elt) => elt)
}

// Recursively create the children tree for an element
const createTree = (
  state: State,
  element: Element | ElementBase,
  parent: Element | ElementBase,
): Tree => {
  // Automatically expand first node
  const expanded = element.id === parent.id || state.toggledNodes.includes(element.id)
  // Recursively load expanded nodes
  const children = expanded
    ? getNodeChildren(state, element).map((elt) => createTree(state, elt, parent))
    : []
  return { element, expanded, children }
}

export const useTreeStore = defineStore('tree', {
  state: (): State => {
    return {
      toggledNodes: [],
      typeFilter: null,
      workerFilter: null,
    }
  },
  actions: {
    toggle(elementId: UUID) {
      const index = this.toggledNodes.indexOf(elementId)
      if (index === -1) this.toggledNodes.push(elementId)
      else this.toggledNodes.splice(index, 1)
    },

    // Automatically expand/shrink a node from a children tree
    async toggleNode(elementId: UUID) {
      this.toggle(elementId)
      const elementStore = useElementStore()
      /*
       * Do not fetch automatically if the item gets closed or if the pagination was incomplete
       * Do, however, fetch if the pagination was completed to allow the If-Modified-Since check
       */
      const expanded = this.toggledNodes.includes(elementId)
      const page = elementStore.childrenPagination[elementId]
      if (!expanded || (page && page.next)) return
      await elementStore.nextChildren(elementId, true)
    },

    tree(element: Element | ElementBase) {
      return createTree(this, element, element)
    },
  },
})
