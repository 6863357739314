<template>
  <main class="container is-fluid content">
    <h1 class="title">Page not found</h1>
    <p>
      <router-link :to="{ name: 'corpus-list' }">Homepage</router-link>
      &middot;
      <a v-on:click="goBack">Go back</a>
    </p>
  </main>
</template>

<script setup lang="ts">
function goBack() {
  window.history.back()
}
</script>
