<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="updateModelValue"
    :title="truncateLong(title)"
  >
    <div
      v-if="availableDatasets === null"
      class="loader mx-auto is-size-1"
    ></div>
    <div
      v-else-if="availableDatasets?.length === 0"
      class="notification is-warning"
    >
      <p>No dataset available.</p>
      <p>
        You can create one from the
        <router-link
          :to="{ name: 'corpus-update', params: { corpusId } }"
          class="has-text-weight-semibold"
        >
          corpus details page
        </router-link>
        .
      </p>
    </div>
    <form
      v-else
      v-on:submit.prevent="addToSelection"
    >
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Dataset</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <span class="select is-fullwidth">
                <select
                  v-model="selectedDataset"
                  :disabled="loading ?? null"
                >
                  <option
                    :value="null"
                    selected
                    disabled
                  >
                    —
                  </option>
                  <template v-if="availableDatasets">
                    <option
                      v-for="dataset in availableDatasets"
                      :value="dataset"
                      :key="dataset.id"
                      :title="dataset.description"
                    >
                      {{ truncateLong(dataset.name) }}
                    </option>
                  </template>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Set</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <span class="select is-fullwidth">
                <select
                  v-model="selectedSet"
                  :disabled="(!selectedDataset || loading) ?? null"
                  :title="
                    selectedDataset
                      ? `Set elements will be added to on dataset ${selectedDataset.name}`
                      : 'Please select a dataset first'
                  "
                >
                  <option
                    :value="null"
                    selected
                    disabled
                  >
                    —
                  </option>
                  <template v-if="selectedDataset?.sets">
                    <option
                      v-for="value in selectedDataset.sets"
                      :value="value"
                      :key="value.id"
                    >
                      {{ value.name }}
                    </option>
                  </template>
                </select>
              </span>
            </div>
            <template v-if="fieldErrors.set_id">
              <p
                v-for="err in fieldErrors.set_id"
                :key="err"
                class="help is-danger"
              >
                {{ err }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </form>

    <template v-slot:footer="{ close }">
      <button
        class="button ml-auto"
        v-on:click="close"
      >
        Cancel
      </button>
      <button
        class="button is-info"
        :class="{ 'is-loading': loading }"
        type="submit"
        :disabled="!canAdd"
        :title="
          canAdd
            ? 'Add selected elements to the dataset'
            : 'Please select a dataset and related set'
        "
        v-on:click="addToSelection"
      >
        Add elements
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import Modal from '@/components/Modal.vue'
import { UUID_REGEX } from '@/config'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useDatasetStore } from '@/stores'
import type { UUID } from '@/types'
import type { Dataset, DatasetSet } from '@/types/dataset'

export default defineComponent({
  mixins: [corporaMixin, truncateMixin],
  components: {
    Modal,
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * Id of the corpus the selection belongs to
     */
    corpusId: {
      type: String as PropType<UUID>,
      validator: (value) => typeof value === 'string' && UUID_REGEX.test(value),
      required: true,
    },
  },
  data: () => ({
    loading: false,
    /**
     * ID of the dataset that will receive selected elements
     */
    selectedDataset: null as Dataset | null,
    /**
     * Name of the set elements will be added to
     */
    selectedSet: null as DatasetSet | null,
    fieldErrors: {} as { [key: string]: string[] | undefined },
  }),
  computed: {
    ...mapState(useDatasetStore, ['corpusDatasets', 'singleCorpusDatasets']),
    title(): string {
      return `Add ${this.corpus?.name} selection to a dataset`
    },
    canAdd(): boolean {
      return this.selectedDataset !== null && this.selectedSet !== null
    },
    availableDatasets(): Dataset[] | null {
      if (this.corpusDatasets[this.corpusId] === undefined) return null
      return this.singleCorpusDatasets(this.corpusId)
    },
  },
  methods: {
    ...mapActions(useDatasetStore, ['listCorpusDatasets', 'addDatasetElementsSelection']),
    updateModelValue(value: boolean) {
      this.$emit('update:modelValue', value)
    },
    async addToSelection() {
      if (!this.canAdd) return
      if (!this.selectedDataset || !this.selectedSet) return

      this.loading = true
      this.fieldErrors = {}
      try {
        await this.addDatasetElementsSelection(
          this.corpusId,
          this.selectedDataset.id,
          this.selectedSet,
        )
        this.selectedDataset = null
        this.selectedSet = null
        this.$emit('update:modelValue', false)
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 400 && err.response.data) {
          this.fieldErrors = err.response.data
        }
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    modelValue(newValue: boolean) {
      if (newValue && this.availableDatasets === null) {
        this.listCorpusDatasets(this.corpusId)
      }
    },
  },
})
</script>
