<template>
  <div
    class="columns is-paddingless"
    :class="{ 'is-multiline': !response || !('number' in response) }"
  >
    <div class="column is-flex is-narrow">
      <ul class="pagination-list">
        <li v-if="!response || !response.results || (response.count ?? 0) < 1">No results</li>
        <li v-else>
          <template v-if="pageIndex"
            >Items {{ pageIndex.start }} to {{ pageIndex.end }} out of
          </template>
          <template v-if="response.count && Number.isFinite(response.count)">{{
            pluralize(response.count)
          }}</template>
        </li>
      </ul>
    </div>
    <div class="column">
      <nav
        class="pagination is-pulled-right"
        role="navigation"
        aria-label="pagination"
      >
        <template v-if="response && 'number' in response">
          <ul class="pagination-list is-flex-right">
            <li
              v-if="lastPage && lastPage > 3"
              class="pagination-link page-input"
              :class="{ open: pageNavigation }"
              v-on:click="pageNavigation = !pageNavigation"
            >
              <span title="Go to a specific page"> Go to{{ pageNavigation ? ' page' : '…' }} </span>
              <template v-if="pageNavigation">
                <input
                  type="number"
                  min="1"
                  :max="lastPage"
                  required
                  v-model.number="selectedPage"
                  class="input"
                  :class="checkValue(selectedPage) ? 'is-success' : 'is-danger'"
                  v-on:click="(event) => event.stopPropagation()"
                  v-on:keyup.enter="handleGoto"
                />
                <button
                  class="button is-small"
                  v-on:click="handleGoto"
                >
                  <i class="icon-arrow-right"></i>
                </button>
              </template>
            </li>
            <li v-if="response.number > 1">
              <a
                class="pagination-link"
                :aria-label="label(1)"
                title="Go to first page"
                v-on:click="goto(1)"
              >
                1
              </a>
            </li>
            <li v-if="response.number > 3">
              <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li v-if="response.number > 2">
              <a
                class="pagination-link"
                :aria-label="label(response.number - 1)"
                :title="label(response.number - 1)"
                v-on:click="goto(response.number - 1)"
              >
                {{ response.number - 1 }}
              </a>
            </li>
            <li v-if="lastPage && lastPage > 1">
              <a
                class="pagination-link is-current"
                :aria-label="`Page ${response.number}`"
                aria-current="page"
                :title="label(response.number)"
              >
                {{ response.number }}
              </a>
            </li>
            <li v-if="lastPage && response.number < lastPage - 1">
              <a
                class="pagination-link"
                :aria-label="label(response.number + 1)"
                :title="label(response.number + 1)"
                v-on:click="goto(response.number + 1)"
              >
                {{ response.number + 1 }}
              </a>
            </li>
            <li v-if="lastPage && response.number < lastPage - 2">
              <span class="pagination-ellipsis">&hellip;</span>
            </li>
            <li v-if="lastPage && response.number < lastPage">
              <a
                class="pagination-link"
                :aria-label="label(lastPage)"
                title="Go to last page"
                v-on:click="goto(lastPage)"
              >
                {{ lastPage }}
              </a>
            </li>
          </ul>
        </template>
        <template v-else>
          <a
            class="pagination-previous"
            :disabled="!response?.previous || null"
            :title="simpleNavigationText.previous"
            v-on:click="goBackward"
          >
            Previous
          </a>
          <a
            class="pagination-next"
            :disabled="!response?.next || null"
            :title="simpleNavigationText.next"
            v-on:click="goForward"
          >
            Next
          </a>
        </template>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import { computed, onMounted, ref } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'

import { DEFAULT_PAGE_SIZE } from '@/config'
import { type PaginationParams, getPaginationParams } from '@/helpers'
import type { CursorPagination, PageNumberPagination } from '@/types'

export interface Props<T> {
  /**
   * The paginated API response.
   */
  response?: CursorPagination<T> | PageNumberPagination<T>
  /**
   * Name of a single paginated item. Defaults to "result".
   */
  singular?: string
  /**
   * Plural name for multiple paginated items. Defaults to "results".
   */
  plural?: string
  /**
   * Maximum size of each page of results.
   */
  pageSize?: number
}

const {
  response,
  singular = 'result',
  plural = 'results',
  pageSize = DEFAULT_PAGE_SIZE,
} = defineProps<Props<T>>()

const emit = defineEmits<{ navigate: [params: PaginationParams] }>()

const selectedPage = ref(-1)
const pageNavigation = ref(false)

onMounted(() => {
  if (response && 'number' in response && response.number) selectedPage.value = response.number
})

const previousPage = computed(
  (): PaginationParams => (response?.previous ? getPaginationParams(response.previous) : {}),
)
const nextPage = computed(
  (): PaginationParams => (response?.next ? getPaginationParams(response.next) : {}),
)

const pageIndex = computed(() => {
  // Pagination position when using a page parameter
  if (!response || !('number' in response)) return
  const start = (response.number - 1) * pageSize + 1
  return { start, end: start + response.results.length - 1 }
})

const lastPage = computed(() => {
  // Determine the last page
  if (response && !response.next && 'number' in response) return response.number
  if (!response?.results || !response?.count) return
  const pageSize = response.results.length
  return Math.ceil(response.count / pageSize)
})

function label(pageNumber: number): string {
  return `Go to page ${pageNumber}`
}

/**
 * Return title text for previous and next buttons depending on pages availability and pagination type.
 *
 * If there is a numbered previous page (page X) and no next page the return value will be
 * `{ previous: 'Go to page X', next: 'No next page' }`
 */
const simpleNavigationText = computed((): { previous: string; next: string } => {
  return {
    previous: response?.previous
      ? ('number' in response && label(response.number - 1)) || 'Previous page'
      : 'No previous page',
    next: response?.next
      ? ('number' in response && label(response.number + 1)) || 'Next page'
      : 'No next page',
  }
})

onBeforeRouteUpdate((to) => {
  pageNavigation.value = false
  if (typeof to.query.page === 'string') selectedPage.value = Number.parseInt(to.query.page, 10)
})

function goBackward() {
  if (!response?.previous) return
  // Navigate to previous page
  emit('navigate', previousPage.value)
}
function goForward() {
  if (!response?.next) return
  // Navigate to next page
  emit('navigate', nextPage.value)
}
function handleGoto() {
  if (pageNavigation.value !== true) pageNavigation.value = true
  else if (checkValue(selectedPage.value)) {
    pageNavigation.value = false
    goto(selectedPage.value)
  }
}
function goto(page: number) {
  // Navigate to a specific page
  emit('navigate', { page: page.toString() })
}
function pluralize(count: number) {
  // u00A0 is a non-breaking space
  return count + '\u00A0' + (count === 1 ? singular : plural)
}
function checkValue(page: string | number): boolean {
  const pageNumber = typeof page === 'number' ? page : parseInt(page, 10)
  if (!Number.isInteger(pageNumber)) return false
  return (
    pageNumber > 0 &&
    pageNumber <= (lastPage.value ?? Infinity) &&
    (!response || !('number' in response) || pageNumber !== response.number)
  )
}
</script>

<style lang="scss" scoped>
.is-flex-right {
  justify-content: flex-end;
}
.columns {
  margin-bottom: 0;
  & > .column {
    padding: 0.5rem 0.75rem 0 0.75rem;
  }
}
.page-input {
  cursor: pointer;
  &.open {
    padding-right: 0;
  }
  & input {
    height: 100%;
    background: none;
    margin-left: 1ch;
    font-weight: bold;
    width: 8ch;
    border-radius: 0;
  }
  & button {
    border: none;
    background: none;
  }
}
</style>
