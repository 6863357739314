import { isAxiosError } from 'axios'
import { defineStore } from 'pinia'

import {
  type CreateExportProcessParams,
  type CursorPaginationParameters,
  type FilesProcessPayload,
  type ProcessCreate,
  type ProcessListParameters,
  type ProcessStart,
  type ProcessUpdate,
  type TemplateCreate,
  type WorkerRunCreateParameters,
  type WorkerRunUpdateParameters,
  applyProcessTemplate,
  clearProcess,
  createExportProcess,
  createProcess,
  createProcessFailures,
  createProcessSet,
  createProcessTemplate,
  createWorkerRun,
  deleteProcess,
  deleteProcessSet,
  deleteWorkerRun,
  importFromFiles,
  listProcessElements,
  listProcessSets,
  listProcesses,
  listWorkerRuns,
  retrieveProcess,
  retryProcess,
  selectProcessFailures,
  startProcess,
  updateProcess,
  updateWorkerRun,
} from '@/api'
import { PROCESS_POLLING_DELAY } from '@/config'
import { errorParser } from '@/helpers'
import {
  useJobsStore,
  useNotificationStore,
  usePonosStore,
  useSelectionStore,
  useWorkerStore,
} from '@/stores'
import type { CursorPagination, NullableProperties, PageNumberPagination, UUID } from '@/types'
import type { ProcessSet } from '@/types/dataset'
import type { Process, ProcessElement, ProcessList, WorkerRun } from '@/types/process'

type State = {
  processWorkerRuns: {
    [processId: UUID]: {
      [workerRunId: UUID]: WorkerRun
    }
  }
  processes: {
    [processId: UUID]: ProcessList | Omit<Process, 'tasks'>
  }
  processPage: PageNumberPagination<UUID> | null
  processElementsPage: {
    [processId: UUID]: CursorPagination<ProcessElement>
  }
  processSets: { [processId: UUID]: ProcessSet[] }
  /**
   * List of processes from which user already triggered the creation of a process
   * from elements with activities in an error state. Allows components to provide
   * a feedback once the async task started, to avoid creating duplicate processes.
   */
  processesFromFailures: Set<UUID>
} & NullableProperties<{
  pollingProcessId: UUID
  processTimeoutId: number
}>

export const useProcessStore = defineStore('process', {
  state: (): State => ({
    processWorkerRuns: {},
    processes: {},
    processPage: null,
    // { [processId]: ElementsPaginatedResponse }
    processElementsPage: {},
    processSets: {},
    processesFromFailures: new Set(),
    // ID of the process getting polled, null if it is turned off
    pollingProcessId: null,
    // Timeout ID for the process polling, null if it is turned off
    processTimeoutId: null,
  }),
  actions: {
    set(process: ProcessList | Process) {
      if ('tasks' in process) {
        const { tasks, ...cleanProcess } = process

        usePonosStore().setProcessTasks(process.id, tasks)

        this.processes[process.id] = {
          ...(this.processes[process.id] ?? {}),
          ...cleanProcess,
        }
      } else {
        this.processes[process.id] = {
          ...(this.processes[process.id] ?? {}),
          ...process,
        }
      }
    },

    async listWorkerRuns(processId: UUID, page = 1) {
      // Automatically list all worker runs for a process through infinite pagination
      const data = await listWorkerRuns(processId, { page })

      this.processWorkerRuns[processId] = {
        ...(this.processWorkerRuns[processId] || {}),
        ...Object.fromEntries(data.results.map((workerRun) => [workerRun.id, workerRun])),
      }

      // Add configurations to workerConfigurations store
      const configurationsStore = useWorkerStore().workerConfigurations
      data.results.forEach((workerRun) => {
        if (!workerRun.configuration) return
        if (!(workerRun.worker_version.worker.id in configurationsStore))
          configurationsStore[workerRun.worker_version.worker.id] = {}
        configurationsStore[workerRun.worker_version.worker.id][workerRun.configuration.id] =
          workerRun.configuration
      })

      if (!data || !data.number || page !== data.number) {
        // Avoid any loop
        throw new Error(`Pagination failed listing worker runs for process "${processId}"`)
      }

      // Load other pages
      if (data.next) this.listWorkerRuns(processId, page + 1)
    },

    async createWorkerRun(
      processId: UUID,
      workerRun: WorkerRunCreateParameters,
    ): Promise<WorkerRun> {
      const data = await createWorkerRun(processId, workerRun)
      if (processId in this.processWorkerRuns) this.processWorkerRuns[processId][data.id] = data
      else this.processWorkerRuns[processId] = { [data.id]: data }
      return data
    },

    async updateWorkerRun(
      processId: UUID,
      workerRunId: UUID,
      payload: WorkerRunUpdateParameters,
    ): Promise<WorkerRun> {
      const data = await updateWorkerRun(workerRunId, payload)
      if (processId in this.processWorkerRuns) this.processWorkerRuns[processId][data.id] = data
      else this.processWorkerRuns[processId] = { [data.id]: data }
      return data
    },

    async deleteWorkerRun(processId: UUID, workerRunId: UUID) {
      await deleteWorkerRun(workerRunId)

      if (processId in this.processWorkerRuns) {
        delete this.processWorkerRuns[processId][workerRunId]
        // Also remove the worker run's ID from the parent IDs of other worker runs
        Object.values(this.processWorkerRuns[processId]).forEach((workerRun) => {
          workerRun.parents = workerRun.parents.filter((parent) => parent !== workerRunId)
        })
      }
    },

    async clear(processId: UUID) {
      await clearProcess(processId)
      this.processWorkerRuns[processId] = {}
    },

    async list(params: ProcessListParameters) {
      try {
        const data = await listProcesses(params)

        // Update existing processes in the state instead of overwriting
        this.processes = {
          ...this.processes,
          ...data.results.reduce(
            (obj, process) => {
              obj[process.id] = {
                ...(this.processes[process.id] || {}),
                ...process,
              }
              return obj
            },
            {} as typeof this.processes,
          ),
        }

        this.processPage = {
          ...data,
          results: data.results.map(({ id }) => id),
        }

        return data
      } catch (err) {
        this.processPage = {
          number: 1,
          count: 0,
          previous: null,
          next: null,
          results: [],
        }
        throw err
      }
    },

    listTemplates({ page = 1, name = '' } = {}) {
      return this.list({ mode: 'template', page, name })
    },

    async create(payload: ProcessCreate): Promise<Process> {
      const data = await createProcess(payload)
      this.set(data)
      return data
    },

    async createExportProcess(corpusId: UUID, params: CreateExportProcessParams): Promise<Process> {
      const data = await createExportProcess(corpusId, params)
      this.set(data)
      return data
    },

    async createProcessTemplate(processId: UUID, payload: TemplateCreate): Promise<Process> {
      const data = await createProcessTemplate(processId, payload)
      this.set(data)
      return data
    },

    async applyProcessTemplate(templateId: UUID, processId: UUID): Promise<Process> {
      const data = await applyProcessTemplate(templateId, processId)
      delete this.processWorkerRuns[processId]
      this.set(data)
      return data
    },

    async retrieve(processId: UUID): Promise<Process> {
      const data = await retrieveProcess(processId)
      this.set(data)
      return data
    },

    async update(processId: UUID, payload: ProcessUpdate): Promise<Process> {
      const data = await updateProcess(processId, payload)
      this.set(data)
      return data
    },

    async start(processId: UUID, payload: ProcessStart): Promise<Process> {
      const data = await startProcess(processId, payload)
      this.set(data)
      // The WorkerActivity initialization RQ task is now visible to the user
      useJobsStore().list()
      return data
    },

    stop(id: UUID): Promise<Process> {
      return this.update(id, { state: 'stopping' })
    },

    async delete(processId: UUID) {
      const response = await deleteProcess(processId)
      if (response.status === 202) {
        useNotificationStore().notify({
          type: 'success',
          text: `Deletion of process ${processId} has been recorded and will be performed soon.`,
          timeout: 10000,
        })
      } else {
        delete this.processes[processId]
      }
      return response
    },

    async retry(processId: UUID) {
      const data = await retryProcess(processId)
      this.set(data)
      // The WorkerActivity initialization RQ task is now visible to the user
      useJobsStore().list()
    },

    async listProcessElements(processId: UUID, cursor = '') {
      // Handle url requests for cursor pagination
      const payload: CursorPaginationParameters = { cursor }
      // Automatically fetch elements count if needed
      const processEltsPage = this.processElementsPage[processId]
      if (!processEltsPage || processEltsPage.count == null || !cursor) payload.with_count = true

      const { count, ...response } = await listProcessElements(processId, payload)

      this.processElementsPage[processId] = {
        // Reuse the previously stored count when the response does not include one and we had one in store
        count: count ?? processEltsPage?.count,
        ...response,
      }
    },

    async listProcessSets(processId: UUID, page = 1) {
      // Do not start fetching process datasets if they have been retrieved already
      if (page === 1 && this.processSets[processId]) return

      let data = null
      try {
        data = await listProcessSets(processId, { page })
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }

      if (!data || !data.number || page !== data.number) {
        // Avoid any loop
        throw new Error(`Pagination failed while listing sets for process "${processId}"`)
      }

      if (this.processSets[processId]?.length) {
        this.processSets[processId].push(
          ...data.results.filter(
            // Skip duplicate process sets
            ({ id }) => !this.processSets[processId].some((existingSet) => existingSet.id === id),
          ),
        )
      } else this.processSets[processId] = data.results

      // Load other pages
      if (data.next) await this.listProcessSets(processId, page + 1)
    },

    async createProcessSet(processId: UUID, setId: UUID): Promise<ProcessSet> {
      // Errors are handled in components/Process/Datasets/AddForm.vue
      const processSet = await createProcessSet(processId, setId)
      if (processId in this.processSets) this.processSets[processId].push(processSet)
      else this.processSets[processId] = [processSet]
      return processSet
    },

    async deleteProcessSet(processId: UUID, processSetId: UUID, setId: UUID) {
      try {
        await deleteProcessSet(processId, setId)
        if (!this.processSets[processId]) return
        const index = this.processSets[processId].findIndex(({ id }) => id === processSetId)
        if (index >= 0) this.processSets[processId].splice(index, 1)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async fromFiles(payload: FilesProcessPayload): Promise<Process> {
      try {
        return await importFromFiles(payload)
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    async selectFailures(processId: UUID) {
      const notificationStore = useNotificationStore()
      try {
        await selectProcessFailures(processId)
        useSelectionStore().get()
        notificationStore.notify({
          type: 'success',
          text: 'Elements with failures have been added to your selection',
        })
      } catch (err) {
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      }
    },

    async createProcessFailures(processId: UUID) {
      try {
        if (this.processesFromFailures.has(processId)) return
        await createProcessFailures(processId)
        this.processesFromFailures.add(processId)
        useNotificationStore().notify({
          type: 'success',
          text: 'A new process is being created from elements in error state, you will be notified by email once it is accessible.',
        })
        useJobsStore().list()
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        throw err
      }
    },

    startPolling(id: UUID) {
      this.stopPolling()
      this.pollingProcessId = id
      const poll = async () => {
        // Polling has been stopped or is running on another process
        if (!this.processTimeoutId || this.pollingProcessId !== id) return

        try {
          await this.retrieve(id)
        } catch (err) {
          useNotificationStore().notify({
            type: 'error',
            text: `Error while fetching process: ${errorParser(err)}`,
          })

          // Abort polling on HTTP 4xx
          if (
            isAxiosError(err) &&
            err.response &&
            err.response.status >= 400 &&
            err.response?.status < 500
          ) {
            this.stopPolling()
            return
          }
        }

        // Check again, because the polling might have been stopped while we were awaiting the HTTP request.
        if (!this.processTimeoutId || this.pollingProcessId !== id) return

        this.processTimeoutId = window.setTimeout(poll, PROCESS_POLLING_DELAY)
      }

      // Make the first call; poll cannot be called directly due to the initial timeout ID check
      this.processTimeoutId = window.setTimeout(poll, 0)
    },

    stopPolling() {
      // Stop the process polling
      this.pollingProcessId = null
      if (this.processTimeoutId !== null) window.clearTimeout(this.processTimeoutId)
      this.processTimeoutId = null

      // Stop all task pollings
      usePonosStore().stopAllPolling()
    },
  },
})
