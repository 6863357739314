<template>
  <div>
    <div
      class="field"
      v-for="(item, i) in newList"
      :key="i"
    >
      <div class="field has-addons">
        <div v-if="field.subtype === 'bool'">
          <label class="checkbox">
            <input
              type="checkbox"
              :checked="item === true"
              v-on:change="updateItem(i, ($event.target as HTMLInputElement).checked)"
            />
          </label>
        </div>
        <div
          v-else
          class="control"
        >
          <input
            class="input"
            :value="item"
            :class="{ 'is-danger': itemError[i] }"
            v-on:change="updateItem(i, ($event.target as HTMLInputElement).value)"
          />
        </div>
        <div class="control">
          <a
            class="button is-danger is-outlined"
            title="Remove item from list"
            v-on:click="removeItem(i)"
          >
            <i class="icon-minus"></i>
          </a>
        </div>
      </div>
      <p
        class="help is-danger mt-0"
        v-if="itemError[i]"
      >
        {{ itemError[i] }}
      </p>
    </div>
    <p>
      <a
        class="button is-primary mt-1"
        title="Add new row"
        v-on:click="addRow"
      >
        <i class="icon-plus"></i>
      </a>
    </p>
  </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

import type {
  ListUserConfigurationField,
  ListUserConfigurationFieldValue,
} from '@/types/workerConfiguration'

import FIELDS from '.'

export default defineComponent({
  emits: {
    'update:modelValue': (value: ListUserConfigurationFieldValue | string) =>
      typeof value === 'string' || Array.isArray(value),
  },
  props: {
    field: {
      type: Object as PropType<ListUserConfigurationField>,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    // Array or String because if there is no default value set, value is an empty string
    modelValue: {
      type: [Array, String] as PropType<ListUserConfigurationFieldValue | string>,
      required: true,
      validator(value) {
        return Array.isArray(value) || value === ''
      },
    },
  },
  data: () => ({
    FIELDS,
    newList: [] as ListUserConfigurationFieldValue,
    newItem: null,
    itemError: [] as (string | null)[],
    validatedList: [] as ListUserConfigurationFieldValue,
  }),
  mounted() {
    if (!this.modelValue || !this.modelValue.length || !Array.isArray(this.modelValue)) return
    this.newList = this.modelValue
    this.validatedList = this.modelValue
    this.itemError = new Array(this.modelValue.length).fill(null)
    this.validateFields()
  },
  computed: {
    // Remove blank items from input list
    cleanList() {
      if (Array.isArray(this.validatedList)) {
        return this.validatedList.filter((item) => {
          return String(item).length > 0
        })
      } else return []
    },
    hasErrors() {
      return !Object.values(this.itemError).every((value) => value === null)
    },
  },
  methods: {
    addRow() {
      this.itemError.push(null)
      if (this.field.subtype === 'bool') {
        /**
         * In the case of a list of booleans, adding a new item should update the validated list
         * right away, because there can't be an 'empty' boolean value. If a new item is added to
         * the list, a new 'true' value is added to the list.
         */
        ;(this.newList as boolean[]).push(true)
        ;(this.validatedList as boolean[]).push(true)
      } else (this.newList as (string | number)[]).push('')
    },
    removeItem(i: number) {
      this.newList.splice(i, 1)
      this.validateFields()
      this.itemError.splice(i, 1)
    },
    updateItem(i: number, newValue: string | number | boolean) {
      if (this.newList[i] === newValue) return
      // Do not keep the last valid value in the list if the field is emptied
      if (!String(newValue).length) {
        ;(this.newList as (string | number | boolean)[]).splice(i, 1, newValue)
        this.itemError[i] = null
      }
      ;(this.newList as (string | number | boolean)[]).splice(i, 1, newValue)
      this.validateFields()
    },
    validateFields() {
      for (const [i, item] of this.newList.entries()) {
        if (String(item).length && FIELDS[this.field.subtype].validate !== undefined) {
          try {
            const validated = FIELDS[this.field.subtype].validate(item)
            this.itemError[i] = null
            // @ts-expect-error TS does not have the context to tell that the validation function will return the subtype of this particular list field
            this.validatedList.splice(i, 1, validated)
          } catch (e) {
            if (e instanceof Error) this.itemError[i] = e.message
          }
        } else this.validatedList[i] = item
      }
    },
  },
  watch: {
    cleanList: {
      handler(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
})
</script>

<style lang="scss" scoped>
.control {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  vertical-align: middle;
}
.button.is-primary {
  margin-left: 2px;
}
</style>
