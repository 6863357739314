import { orderBy } from 'lodash'

import type { EntityLight, TranscriptionEntity } from '@/types/entity'

export type TranscriptionEntityLight = Omit<TranscriptionEntity, 'worker_run' | 'confidence'>

export interface Token {
  offset: number
  text: string
  entity?: EntityLight
  children: TranscriptionEntityLight[]
  overflow?: boolean
}

/**
 * Parse TranscriptionEntitys to place them on a transcription text using a series of Token components.
 * @param text Transcription text to place the entities on.
 * @param entities TranscriptionEntitys from the API to place onto the text.
 * @param textOffset Global offset to apply to the text when parsing only a portion of the text.
 * @returns Sets of properties to be used on the Token component for display.
 */
export const parseEntities = (
  text: string,
  entities: TranscriptionEntityLight[],
  textOffset = 0,
) => {
  /**
   * The current position in the text.
   * If we go through an entity, this position is set to the end of that entity;
   * if we find another entity that is before this one, we'll add it to the children
   * of the last built token.
   * The Vue component will take care of calling this function again to parse recursively.
   */
  let cursor = 0
  const tokens: Token[] = []

  for (const { offset, length, entity } of orderBy(
    entities,
    ['offset', 'length', 'id'],
    ['asc', 'desc', 'asc'],
  )) {
    if (cursor > offset) {
      if (!tokens.length) {
        // eslint-disable-next-line no-console
        console.error(`Entity ${entity.id} has a negative offset.`)
      } else {
        tokens[tokens.length - 1].children.push({ offset, length, entity })
      }
      continue
    }

    // There might be some text without an entity before this entity
    const prefix = text.substring(cursor - textOffset, offset - textOffset)
    if (prefix) {
      tokens.push({
        offset: offset - prefix.length,
        text: prefix,
        children: [],
      })
    }

    cursor = offset + length
    const entityText = text.substring(offset - textOffset, cursor - textOffset)
    tokens.push({
      offset,
      entity,
      text: entityText,
      children: [],
      // Detects if this entity exceeds the transcription's or the parent entity's text, an unsupported edge case
      overflow: offset + length - textOffset > text.length,
    })
  }

  // There might be some text without entities after all the tokens
  const suffix = text.substring(cursor - textOffset)
  if (suffix) tokens.push({ offset: cursor, text: suffix, children: [] })

  return tokens
}
