<template>
  <main class="container is-fluid">
    <template v-if="selectedCorpora.length">
      <button
        class="button has-text-danger is-pulled-right mb-3"
        v-on:click="clear"
      >
        Unselect all
      </button>
      <div class="is-clearfix"></div>
      <div
        v-for="[corpusId, elements] in selectedCorpora"
        :key="corpusId"
      >
        <CorpusSelection
          :corpus-id="corpusId"
          v-if="elements.length"
        />
        <hr />
      </div>
    </template>
    <div
      v-else
      class="notification is-info"
    >
      Empty selection
    </div>
  </main>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import CorpusSelection from '@/components/Navigation/CorpusSelection.vue'
import { useSelectionStore } from '@/stores'

export default defineComponent({
  components: {
    CorpusSelection,
  },
  computed: {
    ...mapState(useSelectionStore, ['count', 'selection']),
    /**
     * Filter corpora to ensure we only display corpora with some selected elements;
     * empty arrays should not matter
     */
    selectedCorpora() {
      return Object.entries(this.selection).filter(([, elements]) => elements?.length)
    },
  },
  methods: {
    ...mapActions(useSelectionStore, ['clear']),
  },
})
</script>
