import { defineStore } from 'pinia'

import type { MetaDataType } from '@/types/metadata'

interface ScreenSize {
  width: number | null
  height: number | null
}

interface State {
  /**
   * Screen resolution.
   */
  screen: ScreenSize

  /**
   * Whether to display the element details panel on the element view.
   */
  displayDetails: boolean

  /**
   * Whether to display the children tree on the element view for folder elements.
   */
  displayFolderTree: boolean

  /**
   * Whether to display the children tree on the element view for non-folder elements.
   */
  displayAnnotationsTree: boolean

  /**
   * Whether to display entity types next to TranscriptionEntities inside transcriptions
   * on the details panel.
   */
  displayEntityTypes: boolean

  /**
   * Whether to add `with_classes` to element navigation API requests and
   * display element classes in navigation views
   */
  displayElementClasses: boolean

  /**
   * Whether to expand the element header to show all of the element's paths.
   */
  displayAllPaths: boolean

  /**
   * Whether to display elements as a table in navigation views,
   * instead of thumbnails
   */
  elementsTableLayout: boolean

  /**
   * Whether to hide element names and types below thumbnails in navigation views.
   */
  compactDisplay: boolean

  /**
   * Whether to make elements visible by default on the InteractiveImage
   * when they are loaded by the ChildrenTree
   */
  imageShow: boolean

  /**
   * Pagination size for exploring elements.
   *
   * This is set to `null` by default when the page size has not yet been fetched from the URL;
   * if no page size is found in the URL, the navigation components are responsible
   * for setting it to `DEFAULT_PAGE_SIZE`.
   */
  navigationPageSize: number | null

  /**
   * Last created metadata name, to be reused when the metadata creation modal is re-opened.
   */
  lastMetadataName: string | null

  /**
   * Last created metadata type, to be reused when the metadata creation modal is re-opened.
   */
  lastMetadataType: MetaDataType | null

  /**
   * Opened or closed state of all DropdownContents by ID.
   */
  dropdowns: { [key: string]: boolean }
}

const getScreenSize = (): ScreenSize => {
  if (typeof window === 'undefined' || !window) {
    return { width: null, height: null }
  }
  return { width: window.innerWidth, height: window.innerHeight }
}

const getShowPaths = (): boolean => {
  if (typeof window === 'undefined' || !window.sessionStorage) return false
  return window.sessionStorage.getItem('showPaths') === 'true'
}

const getDropdownsState = (): { [key: string]: boolean } => {
  const dropdownsState = window.localStorage.getItem('ArkindexDropdownState')
  if (dropdownsState !== null) {
    try {
      return JSON.parse(dropdownsState)
    } catch {
      window.localStorage.removeItem('ArkindexDropdownState')
    }
  }
  return {
    configurationRequired: true,
    configurationOptional: false,
  }
}

export const useDisplayStore = defineStore('display', {
  state: (): State => ({
    screen: getScreenSize(),
    displayDetails: true,
    displayFolderTree: false,
    displayAnnotationsTree: true,
    displayEntityTypes: true,
    displayElementClasses: false,
    displayAllPaths: getShowPaths(),
    elementsTableLayout: false,
    compactDisplay: false,
    imageShow: true,
    navigationPageSize: null,
    lastMetadataName: null,
    lastMetadataType: null,
    dropdowns: getDropdownsState(),
  }),
  actions: {
    toggleAllPaths(value: boolean | null = null) {
      if (typeof value === 'boolean') this.displayAllPaths = value
      else this.displayAllPaths = !this.displayAllPaths

      if (typeof window !== 'undefined' && window.sessionStorage) {
        window.sessionStorage.setItem('showPaths', this.displayAllPaths.toString())
      }
    },
    toggleDropdown(id: string, value: boolean | null = null) {
      if (typeof value === 'boolean') {
        this.dropdowns = {
          ...this.dropdowns,
          [id]: value,
        }
      } else {
        this.dropdowns = {
          ...this.dropdowns,
          [id]: !this.dropdowns[id],
        }
      }
      // Save dropdown state to local storage so it is retained when you open a new tab
      window.localStorage.setItem('ArkindexDropdownState', JSON.stringify(this.dropdowns))
    },
    setPageSize(size: number) {
      if (!Number.isInteger(size) || size <= 0)
        throw new TypeError('Page size must be a positive integer')
      this.navigationPageSize = size
    },
    setImageShow(value: boolean) {
      this.imageShow = Boolean(value)
    },
    setLastMetadata(name: string, type: MetaDataType) {
      this.lastMetadataName = name
      this.lastMetadataType = type
    },
    setScreenSize() {
      this.screen = getScreenSize()
    },
  },
  getters: {
    iiifWidth() {
      return (ratio: number): number =>
        this.screen.width === null ? 0 : Math.ceil((this.screen.width * ratio) / 100) * 100
    },
    iiifHeight() {
      return (ratio: number): number =>
        this.screen.height === null ? 0 : Math.ceil((this.screen.height * ratio) / 100) * 100
    },
  },
})
