import { defineStore } from 'pinia'
import { type RouteLocationRaw } from 'vue-router'

import { NOTIFICATION_TYPES } from '@/config'

export interface NotificationLink {
  route: RouteLocationRaw
  text?: string
}

interface Notification {
  id: number
  type: keyof typeof NOTIFICATION_TYPES
  text: string
  timeout?: number
  link?: NotificationLink
  markdown?: boolean
}

interface NotificationRequest extends Omit<Notification, 'id'> {
  id?: number
}

interface State {
  notifications: Notification[]
}

export const useNotificationStore = defineStore('notification', {
  state: (): State => ({
    notifications: [],
  }),
  actions: {
    notify({ ...notification }: NotificationRequest) {
      if (!NOTIFICATION_TYPES[notification.type]) {
        throw new TypeError(`Unsupported message type ${notification.type}`)
      }
      if (!notification.id) {
        /*
         * Assign a unique ID to this notification to help with :key on v-for
         * and removing notifications later on
         */
        notification.id = Math.max(-1, ...this.notifications.map((n) => n.id)) + 1
      }
      this.notifications.push(notification as Notification)
    },
    remove(id: number) {
      const i = this.notifications.findIndex((n) => n.id === id)
      // Assume a non-existent notification is already deleted: ignore it
      if (i < 0) return
      this.notifications.splice(i, 1)
    },
  },
})
