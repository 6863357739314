<template>
  <Modal
    v-if="metadata"
    :model-value="modelValue"
    v-on:update:model-value="(value: boolean) => $emit('update:modelValue', value)"
    title="Delete metadata"
  >
    <span>
      Are you sure you want to delete this
      <strong>{{ metadata.type }}</strong>
      metadata ?
      <div class="card is-flex">
        <span class="card-content p-2">
          {{ metadata.name }} : <strong>{{ metadata.value }}</strong>
        </span>
      </div>
      This action is irreversible.
    </span>
    <template v-slot:footer="{ close }">
      <button
        class="button"
        :class="{ 'is-loading': loading }"
        v-on:click="close"
      >
        Cancel
      </button>
      <button
        class="button is-danger"
        :class="{ 'is-loading': loading }"
        v-on:click="performDelete"
      >
        Delete
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import Modal from '@/components/Modal.vue'
import { useMetaDataStore } from '@/stores'
import type { UUID } from '@/types'
import type { MetaData } from '@/types/metadata'

export default defineComponent({
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    elementId: {
      type: String as PropType<UUID>,
      required: true,
    },
    metadata: {
      type: Object as PropType<MetaData>,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data: () => ({
    opened: true,
    loading: false,
  }),
  computed: {
    ...mapStores(useMetaDataStore),
  },
  methods: {
    async performDelete() {
      if (this.loading) return
      this.loading = true
      try {
        await this.metadataStore.delete(this.elementId, this.metadata.id)
      } finally {
        this.loading = false
      }
    },
  },
})
</script>
