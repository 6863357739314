<template>
  <table class="is-fullwidth">
    <tbody>
      <tr
        v-for="(item, i) in KVPairs"
        :key="i"
      >
        <td>
          <input
            class="input"
            v-model="item.key"
            placeholder="key"
            v-on:input="$emit('update:modelValue', updatedDict)"
          />
        </td>
        <td>
          <input
            class="input"
            v-model="item.value"
            placeholder="value"
            v-on:input="$emit('update:modelValue', updatedDict)"
          />
        </td>
        <td class="is-narrow">
          <a
            v-if="i === KVPairs.length - 1"
            class="button is-primary"
            title="Add another row"
            v-on:click="addRow"
          >
            <i class="icon-plus"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash'
import { type PropType, defineComponent } from 'vue'

import type { DictUserConfigurationField } from '@/types/workerConfiguration'

type KVPair = {
  key: string | null
  value: string | null
}

export default defineComponent({
  emits: {
    'update:modelValue': (value: Record<string, string> | string) =>
      ['string', 'object'].includes(typeof value),
  },
  props: {
    field: {
      type: Object as PropType<DictUserConfigurationField>,
      required: true,
    },
    modelValue: {
      type: [Object, String],
      required: true,
    },
  },
  data: () => ({
    /*
     * KVPairs makes a copy of valuesDict which can then be updated
     * with new empty rows or new [key, value] pairs by the user (as
     * it is not a computed property but a copy); updatedDict recreates
     * a {k: v...} object from the [{key:k, value:v}...] items of KVPairs,
     * ignoring empty or incomplete rows, to update the configuration
     * in the parent component.
     */
    KVPairs: [] as KVPair[],
  }),
  mounted() {
    if (!this.valuesDict) return
    this.KVPairs = cloneDeep(this.valuesDict)
  },
  computed: {
    valuesDict() {
      const aList = []
      if (typeof this.modelValue === 'object') {
        for (const [key, value] of Object.entries(this.modelValue)) {
          aList.push({ key, value })
        }
      }
      aList.push({
        key: null,
        value: null,
      })
      return aList
    },
    updatedDict() {
      const obj: Record<string, string> = {}
      for (const item of this.KVPairs) {
        if (item.key && item.value) {
          obj[item.key] = item.value
        }
      }
      return obj
    },
  },
  methods: {
    addRow() {
      this.KVPairs.push({
        key: null,
        value: null,
      })
    },
  },
})
</script>

<style lang="scss" scoped>
td {
  padding: 2px;
  vertical-align: middle;
}
</style>
