<template>
  <div class="field">
    <label class="label">Name</label>
    <input
      class="input"
      type="text"
      v-model="newConfiguration.name"
      v-on:update="$emit('update:modelValue', newConfiguration)"
      :disabled="loading || undefined"
    />
  </div>
  <span v-if="schema">
    <label class="checkbox">
      <input
        type="checkbox"
        :checked="JSONStringToggled"
        v-on:change="toggleJSONStringMode"
      />
      JSON
    </label>
  </span>
  <div
    class="field"
    v-if="JSONStringMode"
  >
    <label class="label">JSON payload</label>
    <div class="control">
      <textarea
        class="textarea is-family-monospace"
        :class="{ 'is-danger': Boolean(JSONConfigError) }"
        v-model="stringConfiguration"
        :disabled="loading || undefined"
        placeholder="{}"
      ></textarea>
      <p
        class="help is-danger"
        v-if="JSONConfigError"
      >
        {{ JSONConfigError }}
      </p>
      <p
        class="help"
        v-else
      >
        You can define here any JSON object that will be made available to the worker when it runs.
      </p>
    </div>
  </div>
  <div
    class="mb-3"
    v-else-if="Object.keys(newConfiguration.configuration).length"
  >
    <label class="label">Configuration</label>
    <FormFields
      v-model="newConfiguration.configuration"
      :worker-id="workerId"
      :schema="schema"
      v-on:configuration-error="checkConfigurationErrors"
    />
  </div>
</template>

<script lang="ts">
import { cloneDeep, isEmpty, isEqual, isPlainObject, uniqueId } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import type { WorkerConfigurationCreatePayload } from '@/api'
import FIELDS from '@/components/Process/Workers/Configurations/ConfigurationForm/Fields'
import FormFields from '@/components/Process/Workers/Configurations/ConfigurationForm/FormFields.vue'
import { ConfigurationValidationError } from '@/helpers'
import { useNotificationStore, useWorkerStore } from '@/stores'
import type { UUID } from '@/types'

export default defineComponent({
  emits: ['form-errors', 'update:modelValue'],
  components: {
    FormFields,
  },
  props: {
    workerVersionId: {
      type: String as PropType<UUID>,
      required: true,
    },
    workerId: {
      type: String as PropType<UUID>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<WorkerConfigurationCreatePayload>,
      required: true,
    },
  },
  data: () => ({
    FIELDS,
    loading: false,
    // Do not set this property using `this.newConfiguration =`, as this will erase the watcher that sends updates to `modelValue`.
    newConfiguration: {
      name: '',
      configuration: {},
    } as WorkerConfigurationCreatePayload,
    // a configuration entered in free JSON string mode (unvalidated)
    stringConfiguration: '',
    JSONStringToggled: false,
    uid: uniqueId(),
    hasConfigurationError: false,
  }),
  mounted() {
    this.newConfiguration.name = this.modelValue.name
    // handle the case where there is no schema (which also means no form display)
    if (!this.schema) {
      this.stringConfiguration = JSON.stringify(this.modelValue.configuration, null, 2)
      return
    }
    // check that the input configuration matches an existing schema
    try {
      this.validateFilling(this.modelValue.configuration)
      this.newConfiguration.configuration = cloneDeep(this.modelValue.configuration)
    } catch {
      this.notify({
        type: 'warning',
        text: 'Cloned configuration does not match schema. Switching to default configuration values.',
      })
      this.newConfiguration.name = ''
      this.newConfiguration.configuration = cloneDeep(this.defaultConfiguration)
    }
    // fill configuration form with default configuration if none was passed from the parent component
    if (isEmpty(this.newConfiguration.configuration))
      this.newConfiguration.configuration = cloneDeep(this.defaultConfiguration)
  },
  computed: {
    ...mapState(useWorkerStore, ['workerConfigurations', 'workerVersions']),
    schema() {
      if (!this.workerVersions[this.workerVersionId]) return null
      const userconfig = this.workerVersions[this.workerVersionId].configuration?.user_configuration
      if (isEmpty(userconfig)) return null
      // Sort configuration parameters alphabetically by title
      const fields = Object.entries(userconfig)
      fields.sort(function (a, b) {
        const titleA = a[1].title.toLowerCase()
        const titleB = b[1].title.toLowerCase()
        if (titleA < titleB) return -1
        if (titleA > titleB) return 1
        return 0
      })
      return Object.fromEntries(fields)
    },
    defaultConfiguration() {
      if (!this.schema) return {}
      const filledForm: Record<string, unknown> = {}
      for (const property in this.schema) {
        filledForm[property] = this.schema[property].default ?? ''
      }
      return filledForm
    },
    JSONStringMode() {
      return this.JSONStringToggled || !this.schema
    },
    JSONConfigError() {
      if (!this.stringConfiguration.trim()) return null
      try {
        const config = JSON.parse(this.stringConfiguration)
        if (!isPlainObject(config) || !config || isEmpty(config))
          return 'Please enter a valid JSON configuration'
        if (this.schema) {
          if (
            Object.keys(config).some(
              (item) => this.schema && !Object.keys(this.schema).includes(item),
            )
          ) {
            return `Only the following properties may be set in this configuration: ${Object.keys(this.schema)}`
          }
          this.validateFields(config)
        }
      } catch (e) {
        if (e instanceof SyntaxError) return e.message
        if (e instanceof ConfigurationValidationError) return e.errors
        throw e
      }
      return null
    },
    validStringConfig() {
      /*
       * JSON parsing errors are caught and ignored here as the purpose of validStringConfig
       * is that isDefault only checks valid JSON configurations against the default configuration;
       * JSON errors are handled by JSONConfigError.
       */
      try {
        const config = JSON.parse(this.stringConfiguration)
        return config
      } catch {
        return null
      }
    },
    isDefault() {
      let currentConfiguration = {}
      if (!this.defaultConfiguration) return false
      if (!this.JSONStringMode) currentConfiguration = this.newConfiguration.configuration
      else currentConfiguration = this.validStringConfig
      return isEqual(currentConfiguration, this.defaultConfiguration)
    },
    disabledTitle() {
      const name = this.newConfiguration.name.trim()
      if (!name) {
        if (!this.stringConfiguration && !this.newConfiguration.configuration)
          return 'Please fill out the creation form'
        else return 'Please name your configuration'
      }
      if (this.hasConfigurationError) return 'Please enter a valid configuration.'
      if (this.JSONStringMode && !this.stringConfiguration.trim())
        return 'Please enter a valid JSON configuration'
      if (this.JSONStringMode && this.JSONConfigError) return this.JSONConfigError
      if (this.isDefault) return 'This already is the default configuration'
      else return ''
    },
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    toggleJSONStringMode() {
      if (!this.JSONStringToggled) {
        this.stringConfiguration = JSON.stringify(this.newConfiguration.configuration, null, 2)
      } else if (!this.JSONConfigError) {
        if (!this.stringConfiguration.trim())
          this.newConfiguration.configuration = this.defaultConfiguration
        else this.newConfiguration.configuration = JSON.parse(this.stringConfiguration)
      }
      this.JSONStringToggled = !this.JSONStringToggled
    },
    validateFields(config: Record<string, unknown>) {
      /*
       * Validates the configuration fields values against the schema; unexpected fields
       * (absent from the schema) are handled in JSONConfigError.
       */
      if (!this.schema) {
        this.$emit('update:modelValue', {
          name: this.newConfiguration.name,
          configuration: config,
        })
        return
      }
      const errors = {} as Record<string, string>
      for (const [k, v] of Object.entries(config)) {
        const field = this.schema[k]
        if (!field) errors[k] = `Unrecognised field: ${k}`
        else if (!FIELDS[field.type]) {
          errors[k] = `Unknown field type: ${k}`
        } else {
          if (field.required === true) {
            /*
             * !v alone does not work to check that the value of a required field is not empty,
             * because !0 is true and 0 can be a valid value; !String(v).length returns false if
             * 0 (or 00000 etc) is entered, however it does not work to check against null,
             * which is why there is a second (v !== 0 && !v) check.
             */
            if (!String(v).length) errors[k] = 'This field is required.'
            else if (v !== 0 && !v) errors[k] = 'This field is required.'
          }
          if (v && FIELDS[field.type].validate !== undefined) {
            try {
              // @ts-expect-error Each validation function only accepts the UserConfigurationField of their own type, but TS does not understand the intersection of all fields
              config[k] = FIELDS[field.type].validate(v, field)
              delete errors[k]
            } catch (e) {
              if (e instanceof Error) errors[k] = e.message
            }
          }
        }
      }
      if (Object.keys(errors).length > 0) throw new ConfigurationValidationError(errors)
      else {
        this.$emit('update:modelValue', {
          name: this.newConfiguration.name,
          configuration: config,
        })
      }
    },
    validateFilling(config: Record<string, unknown>) {
      /*
       * This method ensures that an existing configuration which is passed to the form (through cloning)
       * is consistent with the worker version's configuration schema. This is because different worker
       * versions can have different user configuration schemas, and the user configuration definition
       * can also have changed since a configuration was created.
       */
      if (
        Object.keys(config).some(
          (item) => this.schema && Object.keys(this.schema).indexOf(item) === -1,
        )
      )
        throw new Error('Undefined field found in configuration')
      this.validateFields(config)
    },
    checkConfigurationErrors(value: boolean) {
      this.hasConfigurationError = value
    },
  },
  watch: {
    disabledTitle: {
      immediate: true,
      handler() {
        this.$emit('form-errors', this.disabledTitle)
      },
    },
    stringConfiguration: {
      immediate: true,
      handler() {
        if (this.stringConfiguration.trim() && !this.JSONConfigError) {
          this.newConfiguration.configuration = JSON.parse(this.stringConfiguration)
          this.$emit('update:modelValue', this.newConfiguration)
        }
      },
    },
    // This is the only way to ensure that the configuration is properly sent to the parent `Create` component in all cases.
    newConfiguration: {
      handler(newValue) {
        if (!isEqual(this.modelValue, newValue)) this.$emit('update:modelValue', newValue)
      },
      deep: true,
    },
  },
})
</script>
