<template>
  <nav
    class="navbar main-nav"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link
        :to="{ name: 'home' }"
        class="navbar-item is-size-4"
      >
        <span class="icon mr-1"><i class="icon-teklia-e"></i></span>
        Arkindex
      </router-link>
      <a
        class="navbar-burger"
        v-on:click="toggleBurger"
      >
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <div
      class="navbar-menu"
      :class="{ 'is-active': openedBurger }"
    >
      <div class="navbar-start">
        <router-link
          :to="{ name: 'corpus-list' }"
          class="navbar-item"
          exact-active-class="is-active"
        >
          Projects
        </router-link>
        <router-link
          v-if="isLoggedOn"
          :to="{ name: 'processes-list', query: { created: 'true', started: 'true' } }"
          class="navbar-item"
          exact-active-class="is-active"
        >
          Process
        </router-link>
        <router-link
          v-if="hasFeature('search')"
          :to="{ name: 'search' }"
          class="navbar-item"
          exact-active-class="is-active"
        >
          Search
        </router-link>
      </div>

      <div class="navbar-end">
        <a
          class="navbar-item"
          v-on:click="jobsModal = true"
          v-if="isVerified && !isEmpty(jobs)"
        >
          {{ Object.keys(jobs).length }}
          <i class="icon-cog-alt"></i>
        </a>
        <router-link
          v-if="isLoggedOn && hasFeature('selection')"
          :to="{ name: 'elements-selected' }"
          class="navbar-item"
          active-class="is-active"
        >
          <template v-if="selectedTotal !== null && selectedCount < selectedTotal">
            <span class="loader"></span>
            &nbsp;
          </template>
          <span>{{ selectedCount }}</span>
          <i class="icon-doc"></i>
        </router-link>
        <div
          class="navbar-item has-dropdown"
          v-if="isLoggedOn && user"
          :class="{ 'is-active': userDropdown }"
        >
          <a
            class="navbar-link"
            v-on:click.stop="toggleUserDropdown"
          >
            <i class="icon-user"></i> {{ user.email }}
          </a>
          <div class="navbar-dropdown">
            <router-link
              :to="{ name: 'user-profile' }"
              class="navbar-item"
              active-class="is-active"
            >
              Profile
            </router-link>
            <router-link
              :to="{ name: 'workers-list' }"
              class="navbar-item"
              active-class="is-active"
            >
              Workers
            </router-link>
            <router-link
              :to="{ name: 'worker-runs' }"
              class="navbar-item"
              active-class="is-active"
            >
              Worker runs
            </router-link>
            <router-link
              :to="{ name: 'models-list' }"
              class="navbar-item"
              active-class="is-active"
            >
              <span>Models</span>
            </router-link>
            <router-link
              v-if="hasFeature('enterprise')"
              :to="{ name: 'budget-list' }"
              class="navbar-item"
              active-class="is-active"
            >
              <span>Budgets</span>
            </router-link>
            <a
              href="/admin/"
              class="navbar-item"
              v-if="isAdmin"
            >
              Admin
            </a>
            <a
              class="navbar-item"
              v-on:click="keyboardModal = true"
            >
              Keyboard shortcuts
            </a>
            <router-link
              :to="{ name: 'logout' }"
              class="navbar-item"
              exact-active-class="is-active"
            >
              Log out
            </router-link>
          </div>
        </div>
        <template v-else>
          <p class="control navbar-item is-inline-block px-1">
            <router-link
              :to="loginRoute"
              class="button is-info"
            >
              Log in
            </router-link>
          </p>
          <p
            class="control navbar-item is-inline-block px-1"
            v-if="hasFeature('signup')"
          >
            <router-link
              :to="registerRoute"
              class="button is-secondary"
            >
              Register
            </router-link>
          </p>
        </template>
      </div>
    </div>
    <HelpModal
      title="Keyboard shortcuts"
      :data="KEYBOARD_HELP"
      no-icon
      v-model="keyboardModal"
    />
    <JobsModal
      v-if="isVerified"
      v-model="jobsModal"
    />
  </nav>
</template>

<script lang="ts">
import { isEmpty } from 'lodash'
import Mousetrap from 'mousetrap'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import { KEYBOARD_HELP } from '@/help'
import { useAuthStore, useJobsStore, useSelectionStore } from '@/stores'

import HelpModal from './HelpModal.vue'
import JobsModal from './Jobs/Modal.vue'

export default defineComponent({
  components: {
    HelpModal,
    JobsModal,
  },
  data: () => ({
    openedBurger: false,
    jobsModal: false,
    keyboardModal: false,
    userDropdown: false,
    KEYBOARD_HELP,
  }),
  mounted() {
    Mousetrap.bind('?', () => {
      this.keyboardModal = true
    })
  },
  computed: {
    ...mapState(useAuthStore, ['user', 'isLoggedOn', 'isVerified', 'isAdmin', 'hasFeature']),
    ...mapState(useSelectionStore, {
      selectedCount: 'loaded',
      selectedTotal: 'count',
    }),
    ...mapState(useJobsStore, ['jobs']),
    registerRoute(): RouteLocationRaw {
      if (this.$route.name === 'register') return this.$route
      return { name: 'register', query: { next: this.$route.fullPath } }
    },
    loginRoute(): RouteLocationRaw {
      if (this.$route.name === 'login') return this.$route
      return { name: 'login', query: { next: this.$route.fullPath } }
    },
  },
  methods: {
    isEmpty,
    toggleBurger() {
      this.openedBurger = !this.openedBurger
    },
    toggleUserDropdown() {
      this.userDropdown = !this.userDropdown
      /*
       * Cause the dropdown to close when clicking anywhere
       * Note that this method must be linked to the element using v-on:click.stop,
       * otherwise the event's propagation will cause the dropdown to be immediately closed.
       */
      if (this.userDropdown) document.addEventListener('click', this.toggleUserDropdown)
      else document.removeEventListener('click', this.toggleUserDropdown)
    },
  },
})
</script>
