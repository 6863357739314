<template>
  <svg
    :viewBox="svgBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      :style="innerStyle"
      v-if="hasValidDimensions"
    >
      <!--
        We bind the zone's unrotated bounding box coordinates to the image
        since the group will take care of the rotating
      -->
      <image
        v-bind="boxCoords"
        :href="source"
      />
      <ElementZone
        :element="element"
        :color="elementColor"
        :active="false"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { type CSSProperties, type PropType, defineComponent } from 'vue'

import ElementZone from '@/components/Image/ElementZone.vue'
import { DEFAULT_POLYGON_COLOR, DRAWN_POLYGON_COLOR } from '@/config'
import { boundingBox, iiifUri, mirrorX, rotateAround } from '@/helpers'
import { useDisplayStore } from '@/stores'
import type { Element, ElementBase, Point, Polygon } from '@/types'

const IMAGE_MARGIN = 25

export default defineComponent({
  components: {
    ElementZone,
  },
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
      validator: (element: unknown): boolean =>
        typeof element === 'object' &&
        element !== null &&
        'zone' in element &&
        typeof element.zone === 'object' &&
        element.zone !== null &&
        'image' in element.zone &&
        element.zone.image !== null &&
        'polygon' in element.zone &&
        Array.isArray(element.zone.polygon),
    },
    fullImage: {
      type: Boolean,
      default: false,
    },
    /*
     * Ratios of the screen's width and height to use as the maximum width and height
     * of the image returned by the IIIF server.
     * The resulting sizes will be rounded up to the nearest multiple of 100 pixels,
     * and /full/ is used if the image is smaller than the maximum sizes.
     */
    widthRatio: {
      type: Number,
      default: 1,
      validator: (ratio: unknown): boolean => typeof ratio === 'number' && ratio > 0 && ratio <= 1,
    },
    heightRatio: {
      type: Number,
      default: 1,
      validator: (ratio: unknown): boolean => typeof ratio === 'number' && ratio > 0 && ratio <= 1,
    },
  },
  computed: {
    ...mapState(useDisplayStore, ['iiifWidth', 'iiifHeight']),
    elementColor() {
      return this.fullImage ? DEFAULT_POLYGON_COLOR : DRAWN_POLYGON_COLOR
    },
    margin() {
      return this.fullImage ? Infinity : IMAGE_MARGIN
    },
    boxCoords() {
      if (!this.element.zone) throw new Error('Element has no zone')
      return boundingBox(this.element.zone, { margin: this.margin })
    },
    /**
     * Central point of the original bounding box to allow for rotation/mirroring
     */
    center(): Point {
      const { x, y, width, height } = this.boxCoords
      return [Math.floor(x + width / 2), Math.floor(y + height / 2)]
    },
    rotatedBBox() {
      if (!this.element.zone) throw new Error('Element has no zone')
      const { x, y, width, height } = this.boxCoords
      /*
       * Build a fake polygon so that all the helpers get compatible arguments and
       * we can let `boundingBox` recompute a proper bounding box, since rotating can
       * cause the x/y/width/height to be inverted
       */
      let polygon: Polygon = [
        [x, y],
        [x, y + height],
        [x + width, y + height],
        [x + width, y],
      ]
      if (this.element.mirrored) polygon = polygon.map((point) => mirrorX(point, this.center))
      if (this.element.rotation_angle)
        polygon = polygon.map((point) =>
          rotateAround(point, this.center, this.element.rotation_angle),
        )
      return boundingBox(
        {
          ...this.element.zone,
          polygon,
        },
        {
          margin: this.margin,
          /*
           * We should never restrict this polygon to the image's bounds, because the bounding box
           * could have coordinates outside of the image after applying the element orientation to
           * the polygon.  We want those coordinates as they will allow setting the SVG viewBox to
           * a value that shows the entire rotated polygon, instead of cropping it.
           *
           * However, this.fullImage, used by vue/Navigation/PreviewDropdown.vue, causes the image
           * margin to be set to Infinity, which causes the entire bounding box to be Infinity and
           * nothing else.  Fixing this requires passing the entire element to boundingBox instead
           * of just the zone and will require a larger refactoring.
           *
           * Enabling imageBounds just for the case of fullImage means that polygons on rotated or
           * mirrored elements will still be displayed properly in element or transcription modals
           * but not in the preview dropdown.
           */
          imageBounds: this.fullImage,
        },
      )
    },
    svgBox(): string | undefined {
      if (!this.hasValidDimensions) return undefined
      const { x, y, width, height } = this.rotatedBBox
      return [x, y, width, height].join(' ')
    },
    /*
     * Style applied to a group inside the SVG.
     * The coordinates used will be on the coordinate system defined by the SVG viewBox, and not the one of the browser.
     */
    innerStyle(): CSSProperties {
      const style = {
        'transform-origin': this.center.map((coord) => `${coord}px`).join(' '),
        transform: '',
      }
      if (this.element.rotation_angle)
        style.transform += `rotate(${this.element.rotation_angle}deg)`
      // Adding the mirroring after the rotate results in the mirroring being applied before the rotate, which is what we actually want
      if (this.element.mirrored) style.transform += ' scale(-1, 1)'
      return style
    },
    hasValidDimensions(): boolean {
      return (
        this.element.zone !== null &&
        this.element.zone.image.width > 0 &&
        this.element.zone.image.height > 0
      )
    },
    source(): string {
      if (!this.element.zone) throw new Error('Element has no zone')
      if (!this.hasValidDimensions) throw new Error('Image has invalid dimensions')
      return iiifUri(this.element.zone, {
        width: this.iiifWidth(this.widthRatio),
        height: this.iiifHeight(this.heightRatio),
        margin: this.margin,
      })
    },
  },
})
</script>
