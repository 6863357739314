<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="(value) => $emit('update:modelValue', value)"
    :title="title"
  >
    <Paginator
      :response="corpusExports[corpusId]"
      :loading="loading"
      singular="export"
      plural="exports"
      v-model:page="page"
    >
      <template v-slot:no-results>
        <div class="notification is-warning">No exports to display.</div>
      </template>
      <template v-slot:default="{ results }">
        <table class="table is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>Creator</th>
              <th v-if="hasFeature('enterprise')">Source</th>
              <th>State</th>
              <th>Updated</th>
              <th class="is-narrow">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="corpusExport in results as CorpusExport[]"
              :key="corpusExport.id"
            >
              <td>{{ corpusExport.user.display_name }}</td>
              <td v-if="hasFeature('enterprise')">{{ corpusExport.source }}</td>
              <td>{{ EXPORT_STATES[corpusExport.state] }}</td>
              <td :title="corpusExport.updated">{{ dateAgo(corpusExport.updated) }}</td>
              <td class="is-narrow">
                <div class="field is-grouped">
                  <!--
                    Since the 'is-disabled' attribute hasn't been available on links since 2017
                    (https://github.com/jgthms/bulma/issues/3582) in order to be able to display the
                    button as disabled this has to be a button enclosed in a link
                  -->
                  <a
                    :href="downloadLink(corpusExport)"
                    target="_blank"
                  >
                    <button
                      class="button is-small is-primary"
                      :disabled="!downloadLink(corpusExport)"
                    >
                      <i class="icon-download"></i>
                    </button>
                  </a>
                  <button
                    class="button is-small is-danger"
                    :disabled="!canDelete(corpusExport)"
                    v-on:click="openDeleteModal(corpusExport)"
                  >
                    <i class="icon-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Paginator>
    <DeleteModal
      v-if="toDelete && canDelete(toDelete)"
      v-model="deleteModal"
      :corpus-export="toDelete"
    />
    <template v-slot:footer>
      <div class="columns m-0">
        <div class="column is-paddingless">
          <button
            type="button"
            class="button mr-2"
            :class="{ 'is-loading': loading }"
            :disabled="loading || undefined"
            v-on:click="load(page)"
          >
            Refresh
          </button>
        </div>
        <div class="column is-paddingless is-narrow">
          <div class="field has-addons">
            <div
              class="control"
              v-if="hasFeature('enterprise') && isVerified && sources?.length"
            >
              <div class="select is-truncated">
                <select
                  :disabled="loading || sources === null || undefined"
                  v-model="source"
                >
                  <option
                    v-for="{ name, last_update } in sources"
                    :key="name"
                    :value="name"
                  >
                    {{ name }}
                    <template v-if="last_update !== null">
                      (last updated {{ dateAgo(last_update) }})
                    </template>
                  </option>
                </select>
              </div>
            </div>
            <div class="control">
              <button
                type="button"
                class="button is-primary"
                :class="{ 'is-loading': loading }"
                :title="buttonTitle"
                :disabled="!canStart || loading || undefined"
                v-on:click="startExport"
              >
                Start export
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import DeleteModal from '@/components/Corpus/Exports/DeleteModal.vue'
import Modal from '@/components/Modal.vue'
import Paginator from '@/components/Paginator.vue'
import { API_BASE_URL, EXPORT_STATES } from '@/config'
import { ago } from '@/helpers'
import { corporaMixin } from '@/mixins'
import { useAuthStore, useExportStore } from '@/stores'
import type { UUID } from '@/types'
import type { CorpusExport } from '@/types/export'

export default defineComponent({
  mixins: [corporaMixin],
  components: {
    DeleteModal,
    Modal,
    Paginator,
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    page: 1,
    loading: false,
    EXPORT_STATES,
    deleteModal: false,
    toDelete: null as CorpusExport | null,
    source: 'default',
  }),
  computed: {
    ...mapState(useAuthStore, ['isVerified', 'hasFeature']),
    ...mapState(useExportStore, ['corpusExports', 'sources']),
    title() {
      if (this.corpus && this.corpus.name) return `Exports of project ${this.corpus.name}`
      return 'Project exports'
    },
    canStart(): boolean {
      return this.canWrite(this.corpus)
    },
    buttonTitle() {
      if (this.canStart) return
      return 'Contributor access is required to start a project export.'
    },
    hasContributorAccess(): boolean {
      return this.isVerified && ((this.corpus && this.canWrite(this.corpus)) ?? false)
    },
  },
  methods: {
    ...mapActions(useExportStore, ['list', 'listSources', 'start']),
    async load(page = 1) {
      this.loading = true
      try {
        await this.list(this.corpusId, page)
      } finally {
        this.loading = false
      }
    },
    async startExport() {
      if (!this.canStart || this.loading) return
      this.loading = true
      try {
        await this.start(this.corpusId, this.source)
        await this.load()
      } finally {
        this.loading = false
      }
    },
    canDelete(corpusExport: CorpusExport): boolean {
      return ['done', 'running'].includes(corpusExport.state) && this.hasContributorAccess
    },
    openDeleteModal(corpusExport: CorpusExport) {
      this.toDelete = corpusExport
      this.deleteModal = true
    },
    downloadLink(corpusExport: CorpusExport): string | undefined {
      if (corpusExport.state !== 'done') return
      return `${API_BASE_URL}/export/${corpusExport.id}/`
    },
    dateAgo(date: string): string {
      return ago(new Date(date))
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue) {
          if (this.hasFeature('enterprise') && this.isVerified) this.listSources()
          this.load(1)
        }
      },
      immediate: true,
    },
    page: 'load',
  },
})
</script>
