<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="(value) => $emit('update:modelValue', value)"
    :title="modalTitle"
    is-large
  >
    <form v-on:submit.prevent="save">
      <div class="field">
        <label class="label">Name</label>
        <div class="control is-expanded">
          <input
            class="input"
            :class="{ 'is-danger': fieldErrors.name }"
            v-model="newDataset.name"
            type="text"
            placeholder="Dataset name"
          />
        </div>
        <template v-if="fieldErrors.name">
          <p class="help is-danger">{{ fieldErrors.name }}</p>
        </template>
      </div>
      <div class="field">
        <label class="label">Description</label>
        <div class="control is-expanded">
          <textarea
            class="textarea"
            :class="{ 'is-danger': fieldErrors.description }"
            v-model="newDataset.description"
            placeholder="Dataset description"
          ></textarea>
        </div>
        <template v-if="fieldErrors.description">
          <p class="help is-danger">{{ fieldErrors.description }}</p>
        </template>
      </div>
      <div class="field">
        <label class="label">Sets</label>
        <div class="field-body">
          <div>
            <!-- Sets field for dataset edition -->
            <template v-if="datasetInstance">
              <div
                v-for="dss in datasetInstance.sets"
                :key="dss.id"
              >
                <DatasetSet
                  :dataset-set="dss"
                  :dataset="datasetInstance"
                  :modal-open="modelValue"
                />
              </div>
              <AddSetForm
                :dataset="datasetInstance"
                :modal-open="modelValue"
              />
            </template>
            <!-- Sets field for dataset creation -->
            <template v-else>
              <div
                class="control"
                v-for="(item, i) in newDataset.set_names"
                :key="i"
              >
                <div class="field mt-1 mb-0">
                  <input
                    class="input"
                    :class="{ 'is-danger': fieldErrors.set_names }"
                    type="text"
                    :value="item"
                    v-on:change="
                      (event) => updateSetNames(i, (event.target as HTMLInputElement).value)
                    "
                  />
                </div>
              </div>
              <div>
                <button
                  class="button is-primary mt-1"
                  :class="{ 'is-loading': loading }"
                  :disabled="loading || !hasContribPrivilege || undefined"
                  v-on:click="addSetField"
                >
                  <i class="icon-plus"></i>
                </button>
              </div>
              <template v-if="fieldErrors.set_names">
                <p class="help is-danger">{{ fieldErrors.set_names }}</p>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="newDataset.unique_elements"
            />
            Require unique elements among sets
          </label>
        </div>
        <template v-if="fieldErrors.unique_elements">
          <p class="help is-danger">{{ fieldErrors.unique_elements }}</p>
        </template>
      </div>
    </form>
    <template v-slot:footer="{ close }">
      <button
        class="button"
        v-on:click="close"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="button is-primary"
        :class="{ 'is-loading': loading }"
        v-on:click="save"
        :disabled="!canSave"
        :title="saveButtonTitle"
      >
        <span v-if="datasetInstance">Save</span>
        <span v-else>Create</span>
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { isEmpty } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import type { DatasetCreate } from '@/api'
import AddSetForm from '@/components/Corpus/Datasets/DatasetSets/CreateForm.vue'
import DatasetSet from '@/components/Corpus/Datasets/DatasetSets/Row.vue'
import Modal from '@/components/Modal.vue'
import { errorParser } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAuthStore, useDatasetStore, useNotificationStore } from '@/stores'
import type { UUID } from '@/types'
import type { Dataset } from '@/types/dataset'

export default defineComponent({
  mixins: [corporaMixin, truncateMixin],
  components: {
    Modal,
    DatasetSet,
    AddSetForm,
  },
  emits: ['dataset-action', 'update:modelValue'],
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    datasetInstance: {
      type: Object as PropType<Dataset | null>,
      default: null,
    },
  },
  data: () => ({
    newDataset: {
      name: '',
      description: '',
      set_names: [],
      unique_elements: true,
    } as DatasetCreate,
    loading: false,
    fieldErrors: {} as Partial<Record<keyof DatasetCreate, string[]>>,
  }),
  mounted() {
    if (this.datasetInstance) {
      this.newDataset = {
        name: this.datasetInstance.name,
        description: this.datasetInstance.description,
        set_names: this.datasetInstance.sets.map((set) => set.name),
        unique_elements: this.datasetInstance.unique_elements,
      }
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isVerified']),
    hasContribPrivilege() {
      return this.isVerified && this.corpus && this.canWrite(this.corpus)
    },
    modalTitle() {
      if (!this.datasetInstance) return 'Create a new dataset'
      else return `Edit dataset ${this.truncateLong(this.datasetInstance.name)}`
    },
    canSave() {
      return this.newDataset.name.trim() && this.newDataset.description.trim()
    },
    saveButtonTitle() {
      if (!this.canSave) return 'Name and description fields cannot be empty'
      else if (this.datasetInstance) return 'Save'
      else return 'Create'
    },
  },
  methods: {
    ...mapActions(useDatasetStore, ['createCorpusDataset', 'updateCorpusDataset']),
    ...mapActions(useNotificationStore, ['notify']),
    async save() {
      if (!this.hasContribPrivilege || this.loading || !this.canSave) return
      this.loading = true
      const data: DatasetCreate = {
        name: this.newDataset.name.trim(),
        description: this.newDataset.description.trim(),
        unique_elements: this.newDataset.unique_elements,
      }
      if (!this.datasetInstance) {
        // Add list of set names to the payload when creating a new dataset
        const setList = this.newDataset.set_names
          ?.map((item) => item.trim())
          .filter((item) => item.length > 0)
        if (!isEmpty(setList)) data.set_names = setList
      }

      let instanceId
      try {
        if (this.datasetInstance) {
          instanceId = this.datasetInstance.id
          await this.updateCorpusDataset({
            id: instanceId,
            ...data,
          })
        } else {
          const resp = await this.createCorpusDataset(this.corpusId, data)
          instanceId = resp.id
        }
        this.loading = false
        // Sending a custom event to let the parent know that it must reload the list of datasets
        this.$emit('dataset-action', instanceId)
        // Close the modal
        this.$emit('update:modelValue', false)
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 400 && e.response.data) {
          this.fieldErrors = this.parseFieldErrors(e.response.data)
        } else this.notify({ type: 'error', text: errorParser(e) })
      } finally {
        this.loading = false
      }
    },
    parseFieldErrors(errors: unknown): Record<string, string> {
      if (!errors || typeof errors !== 'object' || Array.isArray(errors))
        return { detail: errorParser(errors) }
      return Object.fromEntries(
        Object.entries(errors).map(([key, value]) => [key, errorParser(value)]),
      )
    },
    addSetField() {
      if (this.newDataset.set_names) this.newDataset.set_names.push('')
      else this.newDataset.set_names = ['']
    },
    updateSetNames(i: number, newValue: string) {
      if (!this.newDataset.set_names || this.newDataset.set_names[i] === newValue) return
      // Do not keep the last valid value in the list if the field is emptied
      if (!String(newValue).length) {
        this.newDataset.set_names.splice(i, 1, newValue)
      }
      this.newDataset.set_names.splice(i, 1, newValue)
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        if (this.datasetInstance) {
          this.newDataset = {
            name: this.datasetInstance.name,
            description: this.datasetInstance.description,
            set_names: this.datasetInstance.sets.map((set) => set.name),
            unique_elements: this.datasetInstance.unique_elements,
          }
        } else {
          this.newDataset = {
            name: '',
            description: '',
            set_names: ['train', 'dev', 'test'],
            unique_elements: true,
          }
        }
        this.fieldErrors = {}
      },
    },
  },
})
</script>
