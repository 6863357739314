<template>
  <SearchableSelect
    v-bind="$attrs"
    ref="select"
    results-name="classes"
  />
</template>

<script lang="ts">
import { mapActions } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { SEARCHABLE_SELECT_MAX_MATCHES } from '@/config'
import { useClassificationStore } from '@/stores'
import type { Classification, UUID } from '@/types'

import SearchableSelect, { type GetSuggestionsFunc } from './SearchableSelect.vue'

export default defineComponent({
  components: {
    SearchableSelect,
  },
  props: {
    corpusId: {
      type: String,
      required: true,
    },
    classifications: {
      type: Array as PropType<Classification[]>,
      default: () => [],
    },
    /*
     * Exclude from ML classes those already used in manual classifications
     * Use in conjunction with the classifications parameter to specify the current classifications.
     * This is useful for classification creation (see Element/Main and Element/CorpusSelection)
     */
    excludeManual: {
      type: Boolean,
      default: false,
    },
  },
  inheritAttrs: false,
  provide() {
    const getSuggestions: GetSuggestionsFunc = async (search: string) => {
      if (!this.corpusId) throw new Error('No corpus')
      const { count, ...response } = await this.listCorpusMLClasses(this.corpusId, {
        page_size: SEARCHABLE_SELECT_MAX_MATCHES,
        search,
      })
      let { results } = response
      if (this.classifications.length && this.excludeManual) {
        /*
         * Remove from the suggested ML classes any class already used in a manual classification
         * to prevent duplicate classification creation.
         */
        const manualCls = this.classifications
          .filter((cls) => !cls.worker_run)
          .map((cls) => cls.ml_class.id || cls.ml_class)
        if (manualCls) results = results.filter((m) => !manualCls.includes(m.id))
      }
      // Process ML classes to turn them into { id: display_name } suggestions
      return {
        suggestions: results.reduce(
          (obj, { id, name }) => {
            obj[id] = name
            return obj
          },
          {} as Record<UUID, string>,
        ),
        count,
      }
    }
    return { getSuggestions }
  },
  methods: {
    ...mapActions(useClassificationStore, ['listCorpusMLClasses']),
    focus() {
      ;(this.$refs.select as typeof SearchableSelect).focus()
    },
    clear() {
      ;(this.$refs.select as typeof SearchableSelect).clear()
    },
  },
})
</script>
