<template>
  <main class="container is-fluid">
    <template v-if="entity">
      <div class="card mb-5">
        <header class="card-header">
          <div class="card-header-title">
            <div class="columns is-flex-grow-1">
              <div class="column">
                <span
                  class="tag mr-2"
                  :style="entityStyle(entity.type)"
                  >{{ entity.type.name }}</span
                >
                <span class="title">{{ entity.name }}</span>
              </div>
              <div class="column is-narrow has-text-right">
                in project
                <router-link :to="{ name: 'navigation', params: { corpusId: entity.corpus.id } }">
                  {{ entity.corpus.name }}
                </router-link>
                <br />
                <router-link
                  :to="{ name: 'corpus-entities', params: { corpusId: entity.corpus.id } }"
                >
                  View entities in this project
                </router-link>
              </div>
            </div>
          </div>
        </header>
        <div class="card-content">
          <p
            class="mb-3"
            v-if="entity.worker_run"
          >
            Created by
            <WorkerRunSummary
              v-if="entity.worker_run"
              :worker-run-details="entity.worker_run"
              multiline
            />
          </p>
          <h2 class="title is-4">Details</h2>
          <ul v-if="hasMetas">
            <li
              v-for="(value, key) in entity.metas"
              :key="key"
            >
              {{ key }} : {{ value }}
            </li>
          </ul>
          <div
            class="notification is-warning"
            v-else
          >
            No details available.
          </div>
        </div>
      </div>

      <h2 class="title is-4">Related elements</h2>
      <Paginator
        :response="elements ?? undefined"
        singular="element"
        plural="elements"
      >
        <template v-slot:default="{ results }">
          <ElementList :elements="results" />
        </template>
        <template v-slot:no-results>
          <div class="notification is-warning">No elements linked to {{ entity.name }}</div>
        </template>
      </Paginator>
    </template>
    <div
      class="notification is-danger"
      v-else-if="error"
    >
      {{ error }}
    </div>
    <div
      class="notification is-info"
      v-else
    >
      Loading...
    </div>
  </main>
</template>

<script lang="ts">
import { toNumber } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import ElementList from '@/components/Navigation/ElementList.vue'
import Paginator from '@/components/Paginator.vue'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import { errorParser } from '@/helpers'
import { useEntityStore } from '@/stores'
import type { UUID } from '@/types'
import type { EntityType } from '@/types/entity'

export default defineComponent({
  components: {
    Paginator,
    ElementList,
    WorkerRunSummary,
  },
  props: {
    id: {
      type: String as PropType<UUID>,
      required: true,
    },
  },
  mounted() {
    this.load(this.id, toNumber(this.$route.query.page?.toString() ?? 1))
  },
  beforeRouteUpdate(to) {
    this.load(to.params.id.toString(), toNumber(this.$route.query.page?.toString() ?? 1))
  },
  data: () => ({
    error: null as string | null,
  }),
  computed: {
    ...mapState(useEntityStore, ['entity', 'elements']),
    hasMetas(): boolean {
      if (!this.entity) return false
      if (this.entity.metas === null) {
        return false
      } else {
        return Object.keys(this.entity.metas).length !== 0
      }
    },
  },
  methods: {
    ...mapActions(useEntityStore, ['get', 'listElements']),
    async load(id: UUID, page: number) {
      if (this.entity?.id === id && this.elements?.number === page) return
      this.error = null
      try {
        await this.get(id)
        await this.listElements(id, { page })
      } catch (err) {
        this.error = errorParser(err)
      }
    },
    entityStyle(type: EntityType) {
      return { 'background-color': `#${type.color}`, color: '#ffffff' }
    },
  },
})
</script>

<style scoped>
li {
  list-style: none;
}
a[disabled] {
  pointer-events: none;
}
</style>
