import axios from 'axios'

import { unique } from '@/helpers/api'
import type { ElementType, UUID } from '@/types'

interface ElementTypeCreateResponse extends ElementType {
  corpus: UUID
}

// The slug, display_name, and corpus are required, other attributes are optional, and the ID is omitted
type ElementTypeCreatePayload = Pick<
  ElementTypeCreateResponse,
  'slug' | 'display_name' | 'corpus'
> &
  Partial<Pick<ElementTypeCreateResponse, 'color' | 'folder'>>

/**
 * Create an element type.
 * @param type Attributes of the element type to create.
 */
export const createType = async (
  type: ElementTypeCreatePayload,
): Promise<ElementTypeCreateResponse> => (await axios.post('/elements/type/', type)).data

// All element type attributes are optional, but the ID is required
type ElementTypeUpdatePayload = Pick<ElementType, 'id'> & Partial<ElementType>

/**
 * Update an existing element type.
 * @param param0 Attributes to update on the element type.
 */
export const updateType = async ({ id, ...data }: ElementTypeUpdatePayload): Promise<ElementType> =>
  (await axios.patch(`/elements/type/${id}/`, data)).data

/**
 * Delete an element type.
 * @param id ID of the element type to delete.
 */
export const deleteType = unique((id: UUID) => axios.delete(`/elements/type/${id}/`))
