import axios from 'axios'

import { unique } from '@/helpers/api'
import type { CorpusLight, ElementTiny, PageNumberPagination, UUID } from '@/types'

import type { ApiElementList, PageNumberPaginationParameters } from '.'

/**
 * List selected elements.
 */
export const listSelection = unique(
  async (
    params: PageNumberPaginationParameters,
  ): Promise<PageNumberPagination<ApiElementList & { corpus: CorpusLight }>> =>
    (await axios.get('/elements/selection/', { params })).data,
)

/**
 * Add a list of element IDs to the selection.
 */
export const addSelection = unique(
  async (ids: UUID[]): Promise<ElementTiny[]> =>
    (await axios.post('/elements/selection/', { ids })).data,
)

/**
 * Remove a single element from the selection.
 */
export const removeSelection = unique((id: UUID) =>
  axios.delete('/elements/selection/', { data: { id } }),
)

/**
 * Clear the whole selection.
 */
export const clearSelection = unique(() => axios.delete('/elements/selection/'))

/**
 * Create classifications for a single ML class on all selected elements by corpus ID.
 * Expects a corpus ID and an ML class ID (from listCorpusMLClasses).
 */
export const createClassificationsSelection = unique(async (corpusId: UUID, mlClassId: UUID) =>
  axios.post('/classifications/selection/', {
    mode: 'create',
    corpus_id: corpusId,
    ml_class: mlClassId,
  }),
)

/**
 * Validate all best classes on all selected elements by corpus ID.
 */
export const validateClassificationsSelection = unique(async (corpusId: UUID) =>
  axios.post('/classifications/selection/', {
    mode: 'validate',
    corpus_id: corpusId,
  }),
)

/**
 * Delete all selected elements by corpus ID.
 */
export const deleteElementsSelection = unique(async (corpusId: UUID) =>
  axios.delete(`/corpus/${corpusId}/selection/`),
)

/**
 * Move selected elements from a corpus to a destination folder.
 */
export const moveSelection = async (corpusId: UUID, destination: UUID) =>
  await axios.post('/elements/selection/move/', { corpus_id: corpusId, destination })

/**
 * Link selected elements in a corpus to a new folder.
 */
export const createParentSelection = async (corpusId: UUID, parentId: UUID) =>
  await axios.post('/elements/selection/parent/', { corpus_id: corpusId, parent_id: parentId })

/**
 * Add selected elements to a corpus' dataset set
 */
export const createDatasetElementsSelection = unique(
  async (corpusId: UUID, setId: UUID) =>
    await axios.post(`/corpus/${corpusId}/datasets/selection/`, { set_id: setId }),
)
