<template>
  <form v-bind="$attrs">
    <div class="field has-addons">
      <template v-if="freeEdit">
        <div class="control">
          <label class="label">
            <abbr title="Type of metadata value"> Type </abbr>
          </label>
          <span class="select">
            <select v-model="metadataType">
              <option
                value=""
                selected
                disabled
              >
                &mdash;
              </option>
              <option
                v-for="type in Object.keys(METADATA_TYPES) as (keyof typeof METADATA_TYPES)[]"
                :value="type"
                :key="type"
              >
                {{ METADATA_TYPES[type].display }}
              </option>
            </select>
          </span>
          <template v-if="formErrors.type">
            <p
              class="help is-danger"
              v-for="(err, i) in formErrors.type"
              :key="i"
            >
              {{ err }}
            </p>
          </template>
        </div>
        <div class="control">
          <label class="label">
            <abbr title="Metadata name (key)"> Name </abbr>
          </label>
          <input
            class="input"
            v-model="metadataName"
            type="text"
            placeholder="Name"
            :disabled="disabled || undefined"
          />
          <template v-if="formErrors.name">
            <p
              class="help is-danger"
              v-for="(err, i) in formErrors.name"
              :key="i"
            >
              {{ err }}
            </p>
          </template>
        </div>
      </template>
      <div
        class="control"
        v-else
      >
        <label class="label">Metadata</label>
        <AllowedMetadataPicker
          :corpus-id="corpusId"
          v-model="selectedAllowedMetadata"
        />
        <template v-if="formErrors.name">
          <p
            class="help is-danger"
            v-for="(err, i) in formErrors.name"
            :key="i"
          >
            {{ err }}
          </p>
        </template>
        <template v-if="formErrors.type">
          <p
            class="help is-danger"
            v-for="(err, i) in formErrors.type"
            :key="i"
          >
            {{ err }}
          </p>
        </template>
      </div>
      <div
        class="control is-expanded"
        v-if="metadataType !== 'markdown'"
      >
        <label class="label"> Value </label>
        <DateInput
          v-if="metadataType === 'date' && typeof metadataValue === 'string'"
          class="input"
          v-model="metadataValue"
          v-on:valid="(value: boolean) => (validDate = value)"
          :disabled="disabled"
        />
        <input
          v-else
          class="input"
          v-model="metadataValue"
          type="text"
          placeholder="Value"
          :disabled="disabled || undefined"
        />
        <template v-if="formErrors.value">
          <p
            class="help is-danger"
            v-for="(err, i) in formErrors.value"
            :key="i"
          >
            {{ err }}
          </p>
        </template>
      </div>
    </div>
    <!-- Show a textarea for markdown metadata below the name/type fields -->
    <div
      class="field"
      v-if="metadataType === 'markdown'"
    >
      <label class="label">
        <abbr title="Metadata value"> Value </abbr>
      </label>
      <textarea
        v-model="metadataValue"
        placeholder="Value"
        :disabled="disabled || undefined"
        class="textarea"
      ></textarea>
    </div>
  </form>
</template>

<script lang="ts">
import { clone, mergeWith } from 'lodash'
import { mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import type { MetaDataCreate } from '@/api'
import DateInput from '@/components/DateInput.vue'
import { METADATA_TYPES } from '@/config'
import { useAllowedMetaDataStore } from '@/stores'
import type { UUID } from '@/types'
import type { AllowedMetaData, MetaDataType } from '@/types/metadata'

import AllowedMetadataPicker from './AllowedMetadataPicker.vue'

export type FormErrors = { name?: string[]; type?: string[]; value?: string[] }

export default defineComponent({
  emits: {
    'update:modelValue': (value: MetaDataCreate): boolean =>
      typeof value === 'object' && typeof value.name === 'string' && value.type in METADATA_TYPES,
    'update:isValid': (value: boolean) => typeof value === 'boolean',
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
    /**
     * The MetaData properties to edit.
     */
    modelValue: {
      type: Object as PropType<MetaDataCreate>,
      required: true,
    },
    /**
     * Enable free edit mode, which does not restrict metadata names and types to the AllowedMetaData.
     */
    freeEdit: {
      type: Boolean,
      default: false,
    },
    /**
     * Errors to shown on the form fields.
     */
    errors: {
      type: Object as PropType<FormErrors>,
      default: () => ({}),
    },
    /**
     * Whether the form appears to be valid. Use with a `v-model` to get the form's own validation state.
     */
    isValid: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable editing all form fields.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AllowedMetadataPicker,
    DateInput,
  },
  data: () => ({
    METADATA_TYPES,
    /**
     * Errors that come from the component's own validation, not from the API. Use `formErrors` to have both this and `this.errors` combined.
     */
    localErrors: {} as FormErrors,
    /**
     * Whether a value for a date metadata has been validated by the DateInput
     */
    validDate: false,
  }),
  computed: {
    ...mapStores(useAllowedMetaDataStore),
    corpusAllowedMetadata(): AllowedMetaData[] {
      return this.allowedMetadataStore.allowedMetadata[this.corpusId] ?? []
    },
    metadataName: {
      get(): string {
        return this.modelValue.name
      },
      set(name: string) {
        this.$emit('update:modelValue', { ...this.modelValue, name })
      },
    },
    metadataType: {
      get(): MetaDataType {
        return this.modelValue.type
      },
      set(type: MetaDataType) {
        this.$emit('update:modelValue', { ...this.modelValue, type })
      },
    },
    metadataValue: {
      get(): string | number {
        return this.modelValue.value
      },
      set(value: string | number) {
        this.$emit('update:modelValue', { ...this.modelValue, value })
      },
    },
    selectedAllowedMetadata: {
      get(): AllowedMetaData | null {
        return (
          this.corpusAllowedMetadata.find(
            ({ name, type }) => name === this.metadataName && type === this.metadataType,
          ) ?? null
        )
      },
      set({ name, type }: AllowedMetaData) {
        this.$emit('update:modelValue', { ...this.modelValue, name, type })
      },
    },
    /**
     * Form errors that can come from both the `errors` prop, for API errors, and the `localErrors` data for client-side form validation errors.
     */
    formErrors(): FormErrors {
      // Merge this.localErrors and this.errors, concatenating their array values
      return mergeWith(clone(this.localErrors), this.errors, (objValue, srcValue) => {
        if (Array.isArray(objValue) && Array.isArray(srcValue)) return objValue.concat(srcValue)
      })
    },
  },
  methods: {
    validate() {
      this.localErrors = {}
      if (!(this.metadataType in METADATA_TYPES)) {
        this.localErrors.type = ['Invalid metadata type']
      }
      if (!this.freeEdit && !this.selectedAllowedMetadata) {
        this.localErrors.name = ['This name/type combination is not allowed']
      } else if (!this.metadataName.trim().length) {
        this.localErrors.name = ['Name may not be empty']
      }
      if (!(this.metadataValue ?? '').toString().trim().length) {
        this.localErrors.value = ['Value may not be empty']
      } else if (
        /*
         * When a metadata is numeric, we might have an `number` value if it was set by the backend,
         * but when it is updated from the v-model, the value will be a string.
         *
         * To validate that the value is a valid number, we have to go through some JavaScript insanity.
         * We need to allow decimal numbers, so parseInt(value, 10) is out of the window.
         * parseFloat() may still return a valid float even when we type something like `1 potato`
         * because it just stops parsing at the first invalid character. It will therefore parse
         * hexadecimal or octal notations as 0, because it will stop at the `x` in `0x4` and just take the 0.
         * The unary + operator, suggested by https://stackoverflow.com/a/175787/, will return NaN for `1 potato`
         * but will parse `0x4` as 4, which is not supported by the backend. Scientific notation is however acceptable.
         * Therefore, to properly validate, we will use the unary + operator and an extra regex to check that it
         * looks like it contains only digits, a dot, an E or +- signs.
         * This allows `-3`, `+4`, `52`, `52.4`, `.4`, `52.`, `-52.E+4`, `1e8`, etc. which are all valid in Python.
         */
        this.metadataType === 'numeric' &&
        typeof this.metadataValue !== 'number' &&
        (!Number.isFinite(+this.metadataValue) || !/^[0-9.E]+$/i.test(this.metadataValue))
      ) {
        this.localErrors.value = ['Value must be a valid number']
      } else if (this.metadataType === 'date' && !this.validDate) {
        this.localErrors.value = ['This date is invalid']
      } else if (this.metadataType === 'url') {
        try {
          if (!['http:', 'https:'].includes(new URL(this.metadataValue.toString()).protocol))
            this.localErrors.value = ['Only HTTP and HTTPS URLs are allowed']
        } catch (err) {
          if (err instanceof Error) this.localErrors.value = [err.message]
        }
      }

      // Report the form as valid if there are no errors
      this.$emit(
        'update:isValid',
        Object.values(this.localErrors).every((value) => !value.length),
      )
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler: 'validate',
    },
  },
})
</script>
