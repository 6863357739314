/**
 * Prevent running more than one promise at once on an asynchronous function.
 * This allows components to flood some endpoints with calls, such as listCorpora,
 * and only make one HTTP request.
 */
export function unique<Args extends unknown[], Return>(
  func: (...args: Args) => Promise<Return>,
): (...args: Args) => Promise<Return> {
  const pending: Record<string, Promise<Return>> = {}
  return async (...args: Args): Promise<Return> => {
    /*
     * Turn arguments into a string so they fit as an object key
     * This will not work for circular references! (args.key = args)
     */
    const stringArgs = JSON.stringify(args)
    if (stringArgs in pending) return await pending[stringArgs]

    /*
     * Using Promise.finally() on the promise returned by func(...args) to erase the
     * pending promise from the `pending` object causes uncaught errors to show up
     * in web browser consoles, but not in unit tests, even when the errors are caught
     * in downstream code, because they actually get thrown twice:
     * once before the .finally() and once after.
     * So instead, we wrap the Promise returned by func(...args) into another async
     * function that contains the finally block, and call it immediately, which
     * returns another Promise.
     */
    const promise = (async () => {
      try {
        return await func(...args)
      } finally {
        delete pending[stringArgs]
      }
    })()

    pending[stringArgs] = promise
    return await promise
  }
}
