<template>
  <div
    class="notification is-warning"
    v-if="!folderId && !loading && !subfolders.length"
  >
    No folders found.
  </div>
  <ul v-else>
    <Folder
      v-for="folder in subfolders"
      :key="folder.id"
      :folder="folder"
      :exclude="exclude"
      :current-folder-id="currentFolderId"
      v-on:selected-folder="(f: Folder) => $emit('selectedFolder', f)"
    />
    <li v-if="hasNext">
      <button
        class="button is-small"
        :class="{ 'is-loading': loading }"
        v-on:click="fetchFolders"
      >
        Load more…
      </button>
    </li>
  </ul>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { type Component, type PropType, defineAsyncComponent, defineComponent } from 'vue'

import { useFolderPickerStore } from '@/stores'
import type { Folder } from '@/stores/folderpicker'
import type { UUID } from '@/types'

export default defineComponent({
  components: {
    // Using a regular import here would cause issues with circular imports, since Folder also imports FolderList
    Folder: defineAsyncComponent<Component>(async () => await import('./Folder.vue')),
  },
  emits: {
    selectedFolder(payload: Folder) {
      return payload.id !== undefined
    },
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
    folderId: {
      type: String as PropType<UUID | null>,
      default: null,
    },
    // Highlight the currently selected folder
    currentFolderId: {
      type: String as PropType<UUID | null>,
      default: null,
    },
    exclude: {
      type: Array as PropType<UUID[] | null>,
      default: null,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions(useFolderPickerStore, ['nextFolders']),
    async fetchFolders() {
      this.loading = true
      try {
        await this.nextFolders(this.corpusId, this.folderId)
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    ...mapState(useFolderPickerStore, {
      folders: 'folders',
      corpusFolders: 'corpus',
      pagination: 'pagination',
    }),
    subfolders() {
      let folderIds
      if (this.folderId) {
        const parent = this.folders[this.folderId]
        if (!parent) throw new Error(`Parent folder ${this.folderId} not found in store`)
        folderIds = parent.subfolders || []
      } else {
        folderIds = this.corpusFolders[this.corpusId] || []
      }

      if (this.exclude?.length) {
        folderIds = folderIds.filter((id) => !this.exclude?.includes(id))
      }

      return folderIds.map((id) => this.folders[id])
    },
    hasNext() {
      const pagination = this.pagination(this.corpusId, this.folderId)
      // Assume there can be more folders to load if there simply never have been any loaded folders
      return !pagination || pagination.next
    },
  },
  watch: {
    corpusId: {
      handler() {
        if ((!this.subfolders || !this.subfolders.length) && this.hasNext) this.fetchFolders()
      },
      immediate: true,
    },
  },
})
</script>
