<template>
  <span>
    <!--
      You can override the open slot to customize the button that opens this modal:
      <ExportProcessModal :corpus-id="corpusId">
        <button ... />
      </ExportProcessModal>
      Do not show this slot if the modal is being opened and closed using v-model.
    -->
    <slot
      name="open"
      :open="open"
    >
      <i
        class="icon-export"
        v-on:click.prevent="open"
      ></i>
    </slot>
  </span>
  <Modal
    v-if="opened"
    v-model="opened"
    :title="`Export ${exportTarget} as ${exportFormatLabel}`"
  >
    <div v-if="loading">
      <span class="loader is-size-2 mx-auto"></span>
    </div>
    <div
      v-else-if="!exportWorkerVersion"
      class="notification is-danger"
    >
      The worker version for {{ exportFormatLabel }} export could not be retrieved.
    </div>
    <template v-else>
      <!-- selection and element id errors -->
      <template v-if="formErrors.element_id">
        <p
          class="notification is-danger"
          v-for="err in formErrors.element_id"
          :key="err"
        >
          {{ err }}
        </p>
      </template>
      <template v-if="formErrors.selection">
        <p
          class="notification is-danger"
          v-for="err in formErrors.selection"
          :key="err"
        >
          {{ err }}
        </p>
      </template>
      <div class="columns m-0">
        <div class="column is-paddingless">
          <label class="label">Use the current state of the project (default)</label>
        </div>
        <div class="column is-paddingless is-narrow">
          <button
            class="button is-small"
            :class="{ 'is-primary': exportId === null }"
            v-on:click="exportId = null"
          >
            Select
          </button>
        </div>
      </div>
      <label class="label">Use an existing database export</label>
      <Paginator
        :response="corpusExports[corpusId]"
        :loading="loading"
        singular="export"
        plural="exports"
        v-model:page="page"
        :page-size="10"
      >
        <template v-slot:no-results>
          <div class="notification is-warning mb-2">No existing database exports to display.</div>
        </template>
        <template v-slot:default="{ results }">
          <table class="table is-hoverable is-fullwidth mb-2">
            <thead>
              <tr>
                <th>Creator</th>
                <th v-if="hasFeature('enterprise')">Source</th>
                <th>State</th>
                <th>Updated</th>
                <th class="is-narrow">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="corpusExport in results as CorpusExport[]"
                :key="corpusExport.id"
              >
                <td>{{ corpusExport.user.display_name }}</td>
                <td v-if="hasFeature('enterprise')">{{ corpusExport.source }}</td>
                <td>{{ EXPORT_STATES[corpusExport.state] }}</td>
                <td :title="corpusExport.updated">{{ dateAgo(corpusExport.updated) }}</td>
                <td class="is-narrow">
                  <div class="field">
                    <button
                      class="button is-small"
                      :class="{ 'is-primary': exportId === corpusExport.id }"
                      v-on:click="exportId = corpusExport.id"
                      :disabled="corpusExport.state === 'failed'"
                      :title="selectTitle(corpusExport)"
                    >
                      Select
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- export errors -->
          <template v-if="formErrors.export_id">
            <p
              class="notification is-danger"
              v-for="err in formErrors.export_id"
              :key="err"
            >
              {{ err }}
            </p>
          </template>
        </template>
      </Paginator>

      <template v-if="schema">
        <label class="label mt-3">Configuration</label>
        <FormFields
          :worker-id="exportWorkerVersion.worker.id"
          :schema="schema"
          v-model="configuration"
          v-on:configuration-error="checkConfiguration"
        />
        <!-- configuration errors -->
        <template v-if="formErrors.configuration">
          <p
            class="notification is-danger"
            v-for="err in formErrors.configuration"
            :key="err"
          >
            {{ err }}
          </p>
        </template>
      </template>

      <div
        class="columns m-0 mt-3"
        v-if="hasFeature('enterprise')"
      >
        <div class="column is-paddingless is-narrow">
          <label class="label mt-2">Farm</label>
        </div>
        <div class="column is-paddingless pl-4">
          <div class="select is-fullwidth">
            <select v-model="farmId">
              <option :value="null">Use default farm</option>
              <template v-if="farms">
                <option
                  v-for="(farm, key) in farms"
                  :key="key"
                  :value="key"
                >
                  {{ farm.name }}
                </option>
              </template>
            </select>
          </div>
        </div>
        <template v-if="formErrors.farm_id">
          <p
            class="notification is-danger"
            v-for="err in formErrors.farm_id"
            :key="err"
          >
            {{ err }}
          </p>
        </template>
      </div>
    </template>
    <template v-slot:footer="{ close }">
      <button
        class="button"
        v-on:click.prevent="close"
      >
        Cancel
      </button>
      <button
        class="button is-primary"
        :class="{ 'is-loading': loading }"
        v-on:click.prevent="startExport"
        :disabled="!canExport"
        :title="exportButtonTitle"
      >
        Export
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { cloneDeep, isEmpty } from 'lodash'
import { mapActions, mapState, mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import type { CreateExportProcessParams } from '@/api/export'
import Paginator from '@/components/Paginator.vue'
import { EXPORT_FORMATS, EXPORT_STATES, UUID_REGEX } from '@/config'
import { ago, errorParser } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import {
  useAuthStore,
  useElementStore,
  useExportStore,
  useNotificationStore,
  usePonosStore,
  useProcessStore,
  useWorkerStore,
} from '@/stores'
import type { UUID } from '@/types'
import type { CorpusExport, ExportFormat } from '@/types/export'
import type { WorkerVersion } from '@/types/worker'

import Modal from './Modal.vue'
import FormFields from './Process/Workers/Configurations/ConfigurationForm/FormFields.vue'

export default defineComponent({
  components: {
    Modal,
    Paginator,
    FormFields,
  },
  mixins: [corporaMixin, truncateMixin],
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      validator: (value) => value === null || (typeof value === 'string' && UUID_REGEX.test(value)),
      required: true,
    },
    format: {
      type: String as PropType<ExportFormat>,
      required: true,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    elementId: {
      type: String as PropType<UUID>,
      validator: (value) => value === null || (typeof value === 'string' && UUID_REGEX.test(value)),
      default: null,
    },
  },
  data: () => ({
    opened: false,
    loading: false,
    page: 1,
    source: 'default',
    exportId: null as UUID | null,
    EXPORT_STATES,
    farmId: null as UUID | null,
    configuration: {} as Record<string, unknown>,
    hasConfigurationErrors: false,
    formErrors: {} as Record<keyof CreateExportProcessParams, string[] | undefined>,
  }),
  computed: {
    ...mapState(useAuthStore, ['isVerified', 'hasFeature']),
    ...mapState(useElementStore, ['elements']),
    ...mapState(useExportStore, ['corpusExports', 'sources']),
    ...mapState(usePonosStore, ['farms']),
    ...mapStores(useWorkerStore),
    hasAdminPrivilege(): boolean {
      return (this.isVerified && this.corpus && this.canAdmin(this.corpus)) ?? false
    },
    canExport(): boolean {
      return (
        !this.loading &&
        !this.hasConfigurationErrors &&
        this.hasAdminPrivilege &&
        this.exportId !== undefined &&
        this.exportWorkerVersion !== null
      )
    },
    element() {
      if (this.elementId) return this.elements[this.elementId] || null
      return null
    },
    exportTarget(): string {
      if (this.selection)
        return `selected elements from project ${this.truncateShort(this.corpus?.name)}`
      if (this.element)
        return `${this.truncateShort(this.element.type)} ${this.truncateShort(this.element.name)}`
      return `project ${this.truncateShort(this.corpus?.name)}`
    },
    exportFormatLabel(): string {
      return EXPORT_FORMATS[this.format].label
    },
    exportWorkerVersion(): WorkerVersion | null {
      if (!this.format || !(this.format in EXPORT_FORMATS)) return null
      const id = this.workerStore.featureWorkerVersionIds[EXPORT_FORMATS[this.format].feature]
      return id ? this.workerStore.workerVersions[id] : null
    },
    schema() {
      if (!this.exportWorkerVersion) return null
      const userconfig = this.exportWorkerVersion.configuration?.user_configuration
      if (isEmpty(userconfig)) return null
      // Sort configuration parameters alphabetically by title
      const fields = Object.entries(userconfig)
      fields.sort(function (a, b) {
        const titleA = a[1].title.toLowerCase()
        const titleB = b[1].title.toLowerCase()
        if (titleA < titleB) return -1
        if (titleA > titleB) return 1
        return 0
      })
      const schema = Object.fromEntries(fields)
      // remove the "export_id" configuration entry
      delete schema.export_id
      return schema
    },
    defaultConfiguration() {
      if (!this.schema) return {}
      const filledForm: Record<string, unknown> = {}
      for (const property in this.schema) {
        filledForm[property] = this.schema[property].default ?? ''
      }
      return filledForm
    },
    exportButtonTitle() {
      if (this.hasConfigurationErrors) return 'Please enter a valid configuration'
      if (!this.exportWorkerVersion) return
      return 'Start export'
    },
  },
  methods: {
    ...mapActions(useProcessStore, ['createExportProcess']),
    ...mapActions(useExportStore, ['list', 'listSources', 'start']),
    ...mapActions(useNotificationStore, ['notify']),
    ...mapActions(usePonosStore, ['listFarms']),
    open() {
      this.opened = true
    },
    dateAgo(date: string): string {
      return ago(new Date(date))
    },
    async load(page = 1) {
      this.loading = true
      try {
        await this.list(this.corpusId, page)
      } finally {
        this.loading = false
      }
    },
    selectTitle(corpusExport: CorpusExport) {
      if (corpusExport.state === 'failed') return 'This export cannot be selected as it has failed'
      return 'Use this export'
    },
    checkConfiguration(value: boolean) {
      this.hasConfigurationErrors = value
    },
    async startExport() {
      const params: CreateExportProcessParams = {
        format: this.format,
        export_id: this.exportId,
      }
      if (!isEmpty(this.configuration)) params.configuration = this.configuration
      if (this.hasFeature('enterprise')) params.farm_id = this.farmId
      if (this.elementId) params.element_id = this.elementId
      else if (this.selection) params.selection = true

      try {
        const newProcess = await this.createExportProcess(this.corpusId, params)
        this.$router.push({ name: 'process-details', params: { id: newProcess.id } })
      } catch (err) {
        if (isAxiosError(err) && err.response) {
          this.formErrors = err.response.data
        }
        this.notify({ type: 'error', text: errorParser(err) })
      }
    },
  },
  watch: {
    opened: {
      async handler(newValue) {
        if (newValue) {
          try {
            this.loading = true
            if (this.hasFeature('enterprise') && this.isVerified) {
              this.listSources()
              this.listFarms()
            }
            await this.list(this.corpusId, 1)

            if (!this.format) return
            const feature = EXPORT_FORMATS[this.format].feature
            if (!(feature in this.workerStore.featureWorkerVersionIds)) {
              await this.workerStore.getFeatureWorkerVersion(feature)
            }

            this.configuration = cloneDeep(this.defaultConfiguration)
          } finally {
            this.loading = false
          }
        }
      },
      immediate: true,
    },
    page: 'load',
  },
})
</script>
