<template>
  <td>
    <router-link
      :to="{ name: 'dataset-details', params: { datasetId: elementSet.dataset.id } }"
      title="Dataset details"
    >
      {{ elementSet.dataset.name }}
    </router-link>
  </td>
  <td>{{ elementSet.set }}</td>
  <td class="shrink has-text-right">
    <component
      :is="elementSet.previous ? 'router-link' : 'span'"
      :class="!elementSet.previous ? 'has-text-grey' : ''"
      :to="
        elementSet.previous ? { name: 'element-details', params: { id: elementSet.previous } } : ''
      "
      title="Go to the previous element in this dataset and set"
    >
      <i class="icon-arrow-left"></i>
    </component>
    <component
      :is="elementSet.next ? 'router-link' : 'span'"
      :class="!elementSet.next ? 'has-text-grey' : ''"
      :to="elementSet.next ? { name: 'element-details', params: { id: elementSet.next } } : ''"
      title="Go to the next element in this dataset and set"
    >
      <i class="icon-arrow-right"></i>
    </component>
  </td>
  <td class="shrink">
    <a
      :title="title"
      class="icon is-pulled-right"
      v-on:click="removeElement"
    >
      <i
        class="icon-remove-square"
        :class="canRemove ? 'has-text-danger' : 'has-text-grey'"
        type="button"
      ></i>
    </a>
  </td>
  <RemoveModal
    v-if="elementSet.dataset && elementSet.set"
    v-model="removeElementModal"
    :dataset="elementSet.dataset"
    :set="elementSet.set"
    :element="element"
  />
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import RemoveModal from '@/components/Dataset/RemoveModal.vue'
import { corporaMixin } from '@/mixins'
import { useElementStore } from '@/stores'
import type { Element, ElementBase } from '@/types'
import type { ElementDatasetSet } from '@/types/dataset'

export default defineComponent({
  mixins: [corporaMixin],
  components: {
    RemoveModal,
  },
  props: {
    corpusId: {
      type: String,
      required: true,
    },
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
    elementSet: {
      type: Object as PropType<ElementDatasetSet>,
      required: true,
    },
  },
  data: () => ({
    removeElementModal: false,
  }),
  computed: {
    ...mapState(useElementStore, ['elementDatasetSets']),
    datasets(): ElementDatasetSet[] | null {
      return this.elementDatasetSets?.[this.element.id] ?? null
    },
    canRemove() {
      return (
        this.elementSet.dataset &&
        this.elementSet.dataset.state === 'open' &&
        this.elementSet.set &&
        this.canWrite(this.corpus)
      )
    },
    title() {
      if (!this.canWrite(this.corpus))
        return 'You do not have the required rights to edit this dataset'
      else if (this.elementSet.dataset.state !== 'open') return 'Only open datasets can be edited'
      else return 'Remove element from this dataset set'
    },
  },
  methods: {
    removeElement() {
      if (!this.canRemove) return
      this.removeElementModal = true
    },
  },
})
</script>
