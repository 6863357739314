import axios from 'axios'

import { unique } from '@/helpers/api'
import type { PageNumberPagination, UUID } from '@/types'
import type { WorkerConfiguration } from '@/types/workerConfiguration'

import type { PageNumberPaginationParameters } from '.'

export interface ListWorkerConfigurationsParams extends PageNumberPaginationParameters {
  archived?: boolean
}

export const listWorkerConfigurations = unique(
  async (
    workerId: UUID,
    params: ListWorkerConfigurationsParams = {},
  ): Promise<PageNumberPagination<WorkerConfiguration>> =>
    (await axios.get(`/workers/${workerId}/configurations/`, { params })).data,
)

export type WorkerConfigurationCreatePayload = Pick<WorkerConfiguration, 'name' | 'configuration'> &
  Partial<Omit<WorkerConfiguration, 'id'>>

export const createWorkerConfiguration = unique(
  async (
    workerId: UUID,
    configuration: WorkerConfigurationCreatePayload,
  ): Promise<WorkerConfiguration> =>
    (await axios.post(`/workers/${workerId}/configurations/`, configuration)).data,
)

export const retrieveWorkerConfiguration = unique(
  async (id: UUID): Promise<WorkerConfiguration> =>
    (await axios.get(`/workers/configurations/${id}/`)).data,
)

export type WorkerConfigurationUpdatePayload = Partial<
  Omit<WorkerConfiguration, 'id' | 'configuration'>
>

export const updateWorkerConfiguration = unique(
  async (id: UUID, data: WorkerConfigurationUpdatePayload): Promise<WorkerConfiguration> =>
    (await axios.patch(`/workers/configurations/${id}/`, data)).data,
)
