import { defineStore } from 'pinia'

import {
  type ElementChildrenListParameters,
  type ElementListParameters,
  deleteElementChildren,
  deleteElements,
  listElementChildren,
  listElements,
} from '@/api'
import { DEFAULT_PAGE_SIZE } from '@/config'
import { errorParser } from '@/helpers'
import {
  useAuthStore,
  useClassificationStore,
  useCorporaStore,
  useDisplayStore,
  useJobsStore,
  useNotificationStore,
} from '@/stores'
import type { CorpusLight, ElementBase, PageNumberPagination, UUID } from '@/types'

/**
 * All combined parameters of both ListElements and ListElementChildren.
 * Not all parameters are compatible with both endpoints.
 */
// Use the `order` from ListElementChildren and not ListElements, because the latter includes `position`
type GenericElementListParameters = Omit<ElementListParameters, 'order'> &
  ElementChildrenListParameters

// Remove the element or corpus ID since it is handled in `corpusId` and `elementId`
export type NavigationFilters = Omit<GenericElementListParameters, 'id'>

interface State {
  corpusId: UUID | null
  elementId: UUID | null
  appliedFilters: NavigationFilters
  elements: PageNumberPagination<ElementBase> | null
  scheduledDeletion: Set<UUID>
}

export const useNavigationStore = defineStore('navigation', {
  state: (): State => ({
    corpusId: null,
    elementId: null,
    appliedFilters: {},
    elements: null,
    scheduledDeletion: new Set(),
  }),
  actions: {
    setElements(
      elements: PageNumberPagination<ElementBase> | null,
      corpus: CorpusLight | null = null,
    ) {
      if (!elements) {
        this.elements = null
        return
      }
      this.elements = {
        ...elements,
        results: elements.results.map((element) => {
          // We don't return corpus attribute in ListElementChildren as we already know that they're in the same corpus as their parent
          if (typeof element.corpus !== 'object') {
            if (!corpus) throw new Error('Cannot set corpus attribute on element')
            return { ...element, corpus }
          } else return element
        }),
      }
    },

    /**
     * New unified list method, which uses the state's corpusId, elementId and appliedFilters to call
     * ListElements or ListElementChildren. Will cause a call to ListCorpus if the corpus ID does not exist yet,
     * by calling useCorporaStore().get().
     * @param filters Optional filters to apply; overrides appliedFilters.
     */
    async list(filters: NavigationFilters | null = null) {
      if (!this.corpusId) throw new Error('Missing corpus ID')
      if (filters !== null) this.appliedFilters = filters

      this.elements = null
      const corpus = await useCorporaStore().get(this.corpusId)

      let elements
      if (this.elementId) {
        elements = await listElementChildren(this.listParams)
      } else {
        // TypeScript complains about the `position` order being incompatible with ListElements, so we manually check for it
        const { order, ...params } = this.listParams
        if (order === 'position') throw new Error('Unsupported ordering')
        elements = await listElements({ order, ...params })
      }

      this.setElements(
        {
          ...elements,
          results: useClassificationStore().cleanElementList(elements.results),
        },
        corpus,
      )
    },

    async delete() {
      if (!this.corpusId) throw new Error('Missing corpus ID')

      const endpoint = this.elementId ? deleteElementChildren : deleteElements
      const notificationStore = useNotificationStore()
      try {
        await endpoint(this.baseParams)
        notificationStore.notify({ type: 'success', text: 'Element deletion has been scheduled.' })
      } catch (err) {
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      } finally {
        useJobsStore().list()
      }
    },
  },
  getters: {
    baseParams(): GenericElementListParameters {
      if (!this.corpusId) throw new Error('Missing corpus ID')
      return {
        ...this.appliedFilters,
        id: this.elementId ?? this.corpusId,
      }
    },

    listParams(): GenericElementListParameters {
      const displayStore = useDisplayStore()
      return {
        ...this.baseParams,
        with_zone: 'false',
        with_corpus: 'false',
        with_classes: displayStore.displayElementClasses ? 'true' : 'false',
        page_size: (displayStore.navigationPageSize || DEFAULT_PAGE_SIZE).toString(),
      }
    },

    /**
     * Check whether or not the user is allowed to delete elements on the currently browsed project
     * and with the selected navigation filters.
     */
    canDelete(): boolean {
      const authStore = useAuthStore()
      return (
        this.corpusId !== null &&
        authStore.isVerified &&
        ((useCorporaStore().corpora[this.corpusId]?.rights ?? []).includes('admin') ||
          authStore.isAdmin) &&
        Object.keys(this.appliedFilters).every((key) =>
          [
            'name',
            'type',
            'folder',
            'worker_version',
            'worker_run',
            'page',
            /*
             * Recursive and top_level are not supported by deletion APIs, but all deletions are always recursive
             * (we never allow setting delete_children to false in the frontend), so deleting with recursive=true
             * in a folder is equivalent to recursive=false, and top_level=true becomes equivalent to no filter at all.
             */
            'recursive',
            'top_level',
            // Those filters are just ignored, deletions are not ordered
            'order',
            'order_direction',
          ].includes(key),
        )
      )
    },

    visibleElements(): PageNumberPagination<ElementBase> | undefined {
      if (!this.elements) return
      return {
        ...this.elements,
        results: this.elements.results.filter((e) => !this.scheduledDeletion.has(e.id)),
      }
    },
  },
})
