import { toNumber } from 'lodash'
import { type Component, markRaw } from 'vue'

import { UUID_REGEX } from '@/config'
import { useModelStore } from '@/stores'
import type { UUID } from '@/types'
import type { Model } from '@/types/model'
import type {
  EnumUserConfigurationField,
  ListUserConfigurationField,
  ListUserConfigurationFieldValue,
  UserConfigurationField,
  UserConfigurationFields,
} from '@/types/workerConfiguration'

import BooleanField from './BooleanField.vue'
import ChoicesField from './ChoicesField.vue'
import DictField from './DictField.vue'
import FloatField from './FloatField.vue'
import IntegerField from './IntegerField.vue'
import ListField from './ListField.vue'
import ModelField from './ModelField.vue'
import StringField from './StringField.vue'

type ConfigurationFields = {
  [TypeName in UserConfigurationField['type']]: {
    component: Component
    validate: (
      value: unknown,
      field?: UserConfigurationFields[TypeName],
      models?: { [id: UUID]: Model },
    ) => Exclude<UserConfigurationFields[TypeName]['default'], undefined>
  }
}

const FIELDS: ConfigurationFields = {
  int: {
    // Mark the component as an object that should not be made reactive by Vue, to remove a warning about possible performance issues
    component: markRaw(IntegerField),
    validate(value: unknown): number {
      const parsed = toNumber(value)
      if (!Number.isInteger(parsed)) throw new Error('Value must be a valid integer.')
      return parsed
    },
  },
  float: {
    component: markRaw(FloatField),
    validate(value: unknown): number {
      const parsed = toNumber(value)
      if (!Number.isFinite(parsed)) throw new Error('Value must be a valid float.')
      return parsed
    },
  },
  string: {
    component: markRaw(StringField),
    validate(value: unknown): string {
      return String(value)
    },
  },
  enum: {
    component: markRaw(ChoicesField),
    validate(value: unknown, field?: EnumUserConfigurationField): string {
      if (!field) throw new Error('Enum validation requires a field to be set')
      if (typeof value !== 'string' || !field.choices.includes(value))
        throw new Error(`${value} is not a valid option.`)
      return value
    },
  },
  bool: {
    component: markRaw(BooleanField),
    validate(value: unknown): boolean {
      if (typeof value !== 'boolean') throw new Error('Value must be a valid boolean.')
      return value
    },
  },
  dict: {
    component: markRaw(DictField),
    validate(value: unknown): Record<string, string> {
      if (
        value === null ||
        typeof value !== 'object' ||
        Object.getPrototypeOf(value) !== Object.prototype
      )
        throw new Error('Value must be a valid dictionary.')
      // Values should be of type String
      return Object.fromEntries(Object.entries(value).map(([k, v]) => [k, String(v).toString()]))
    },
  },
  list: {
    component: markRaw(ListField),
    validate(value: unknown, field?: ListUserConfigurationField): ListUserConfigurationFieldValue {
      if (!field) throw new Error('List validation requires a field to be set')
      if (!Array.isArray(value)) throw new Error(`Value must be a valid list of ${field.subtype}.`)
      try {
        // TypeScript does not understand that the validation function is constant and will only return one value, not an array with any of the subtypes at once
        return value.map((item) =>
          FIELDS[field.subtype].validate(item),
        ) as ListUserConfigurationFieldValue
      } catch {
        throw new Error(`Value must be a valid list of ${field.subtype}.`)
      }
    },
  },
  model: {
    component: markRaw(ModelField),
    validate(value: unknown): UUID {
      const modelStore = useModelStore()
      if (value === '') return value
      if (typeof value !== 'string' || !UUID_REGEX.test(value))
        throw new Error('Value must be a valid UUID')
      if (!(value in modelStore.models)) throw new Error('This model does not exist')
      return value
    },
  },
}

export default FIELDS
