import axios from 'axios'

import { unique } from '@/helpers/api'
import type { PageNumberPagination, UUID } from '@/types'
import type { AllowedMetaData } from '@/types/metadata'

import type { PageNumberPaginationParameters } from '.'

export interface AllowedMetaDataParameters extends PageNumberPaginationParameters {
  /**
   * ID of the corpus to retrieve allowed metadata from.
   */
  id: UUID
}

interface AllowedMetaDataCreate extends Omit<AllowedMetaData, 'id'> {
  corpusId: UUID
}

// List metadata type-name couples allowed for manual annotation
export const listCorpusAllowedMetadata = unique(
  async ({
    id,
    ...params
  }: AllowedMetaDataParameters): Promise<PageNumberPagination<AllowedMetaData>> =>
    (await axios.get(`/corpus/${id}/allowed-metadata/`, { params })).data,
)

// Create a new metadata type-name couple allowed in a corpus.
export const createAllowedMetadata = unique(
  async ({ corpusId, ...data }: AllowedMetaDataCreate): Promise<AllowedMetaData> =>
    (await axios.post(`/corpus/${corpusId}/allowed-metadata/`, data)).data,
)

// Delete a metadata type-name couple from the list of metadata allowed in a corpus.
export const deleteAllowedMetadata = unique(
  async (corpusId: UUID, mdId: UUID) =>
    await axios.delete(`/corpus/${corpusId}/allowed-metadata/${mdId}/`),
)

// Update an existing metadata type-name couple allowed in a corpus.
export const updateAllowedMetadata = unique(
  async (
    corpusId: UUID,
    allowedMetaDataId: UUID,
    data: Omit<AllowedMetaData, 'id'>,
  ): Promise<AllowedMetaData> =>
    (await axios.patch(`/corpus/${corpusId}/allowed-metadata/${allowedMetaDataId}/`, data)).data,
)
