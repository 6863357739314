<template>
  <figure class="is-inline-block">
    <div
      class="image"
      :style="imageStyle"
    >
      <div
        class="placeholdertext notification is-danger"
        v-if="imgError"
      >
        Media loading failed
      </div>
      <div
        class="placeholdertext"
        v-else-if="!loaded"
      >
        Loading...
      </div>
      <img
        :src="source"
        v-on:load="loaded = true"
        v-on:error="imgError = true"
        :style="imgStyle"
      />
    </div>
    <router-link
      :to="{ name: 'element-details', params: { id: element.id } }"
      class="is-pulled-left"
      :title="`${element.name} (${typeName(element.type)})`"
    >
      {{ truncateShort(element.name) }} ({{ truncateShort(typeName(element.type)) }})
    </router-link>
  </figure>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { type CSSProperties, type PropType, defineComponent } from 'vue'

import { boundingBox, iiifUri } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useDisplayStore } from '@/stores'
import type { Element, ElementBase, UUID } from '@/types'

export default defineComponent({
  mixins: [truncateMixin, corporaMixin],
  props: {
    element: {
      type: Object as PropType<Element | ElementBase>,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
    imgError: false,
  }),
  computed: {
    ...mapState(useDisplayStore, ['iiifWidth']),
    corpusId(): UUID | null {
      return this.element.corpus?.id ?? null
    },
    imageStyle(): CSSProperties {
      if (this.loaded || !this.hasValidDimensions) return {}
      return {
        'padding-bottom': (this.zoneSize.height / this.zoneSize.width) * 100 + '%',
      }
    },
    zoneSize() {
      if (!this.element.zone) throw new Error('Element has no zone')
      return boundingBox(this.element.zone)
    },
    source(): string | undefined {
      if (this.element.thumbnail_url) return this.element.thumbnail_url
      if (this.element.zone) return iiifUri(this.element.zone, { width: this.iiifWidth(2 / 3) })
      return undefined
    },
    hasValidDimensions(): boolean {
      return (
        this.element.zone !== null &&
        this.element.zone.image.width > 0 &&
        this.element.zone.image.height > 0
      )
    },
    imgStyle(): CSSProperties {
      return { display: this.loaded ? 'block' : 'none' }
    },
  },
})
</script>

<style lang="scss" scoped>
figure {
  width: 100%;
}
.image {
  background-color: lightgray;
  img {
    width: 100%;
    vertical-align: middle;
  }
  .placeholdertext {
    position: absolute;
    top: 3em;
    left: 0;
    right: 0;
    text-align: center;
  }
}
</style>
