<template>
  <div>
    <div
      class="dropdown-toggle is-flex"
      v-on:click="toggle"
      :class="{ disabled: disabled }"
    >
      <a
        v-if="!disabled"
        class="has-text-grey-light mr-2"
      >
        <slot name="trigger">
          <span>
            <i
              class="icon-down-open trigger-icon"
              :class="{ active: toggled }"
            ></i>
          </span>
        </slot>
      </a>
      <h2 :title="disabled ? title + ' not available' : ''">
        {{ title }}
      </h2>
    </div>
    <div
      class="mt-2"
      :class="{ 'is-hidden': !toggled || disabled }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import { useDisplayStore } from '@/stores'

export default defineComponent({
  props: {
    /**
     * An identifier that must be unique across the whole frontend.
     * Used to store the dropdown's opened/closed state in the store.
     */
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useDisplayStore, ['dropdowns']),
    toggled() {
      return this.dropdowns[this.id] ?? true
    },
  },
  methods: {
    ...mapActions(useDisplayStore, ['toggleDropdown']),
    toggle() {
      this.toggleDropdown(this.id, !this.toggled)
    },
  },
})
</script>

<style scoped>
.dropdown-toggle:not(.disabled) {
  cursor: pointer;
}
h2 {
  font-size: 1rem;
  font-style: oblique;
  text-transform: uppercase;
  font-weight: normal;
}
.dropdown-toggle.disabled > h2 {
  color: #b5b5b5;
}
.trigger-icon::before {
  transition: transform 0.3s;
}
.trigger-icon:not(.active)::before {
  transform: rotate(0.5turn);
}
</style>
