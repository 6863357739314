import axios from 'axios'

import { unique } from '@/helpers/api'
import type { PageNumberPagination, UUID } from '@/types'
import type { WorkerRun } from '@/types/process'

import type { PageNumberPaginationParameters } from '.'

/**
 * Create a worker run from a process and and worker version.
 */
export interface WorkerRunCreateParameters {
  worker_version_id: UUID
  parents?: UUID[]
  configuration_id?: UUID | null
}

/**
 * Patch an existing worker run.
 * Only configuration and model version properties can be updated.
 */
export interface WorkerRunUpdateParameters {
  parents?: UUID[]
  configuration_id?: UUID | null
  model_version_id?: UUID | null
  use_gpu?: boolean | null
}

// List worker runs for a process
export const listWorkerRuns = unique(
  async (
    id: UUID,
    params: PageNumberPaginationParameters,
  ): Promise<PageNumberPagination<WorkerRun>> =>
    (await axios.get(`/process/${id}/workers/`, { params })).data,
)

// Create a worker run on a process
export const createWorkerRun = unique(
  async (processId: UUID, workerRun: WorkerRunCreateParameters): Promise<WorkerRun> =>
    (await axios.post(`/process/${processId}/workers/`, workerRun)).data,
)

// Update a worker run on a process
export const updateWorkerRun = unique(
  async (id: UUID, payload: WorkerRunUpdateParameters): Promise<WorkerRun> =>
    (await axios.patch(`/process/workers/${id}/`, payload)).data,
)

// Delete a worker run on a process
export const deleteWorkerRun = unique(
  async (id: UUID) => await axios.delete(`/process/workers/${id}/`),
)

// Retrieve a worker run's details
export const retrieveWorkerRun = unique(
  async (id: UUID): Promise<WorkerRun> => (await axios.get(`/process/workers/${id}/`)).data,
)

// List a user's local worker runs
export const listUserWorkerRuns = unique(
  async (params: PageNumberPaginationParameters): Promise<PageNumberPagination<WorkerRun>> =>
    (await axios.get('/process/local/workers/', { params })).data,
)

// Create a local worker run
export const createUserWorkerRun = unique(
  async (versionId: UUID): Promise<WorkerRun> =>
    (await axios.post('/process/local/workers/create/', { worker_version_id: versionId })).data,
)
