import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import { ensureArray } from '@/helpers'
import { useCorporaStore } from '@/stores'
import type { Corpus, ElementType, UUID } from '@/types'

const corporaFilters = {
  // From a corpus, returns true if the corpus is readable.
  canRead: (corpus: Corpus | null): boolean =>
    corpus !== null &&
    corpus?.rights &&
    Array.isArray(corpus?.rights) &&
    corpus?.rights.includes('read'),
  // From a corpus, returns true if the corpus is writable.
  canWrite: (corpus: Corpus | null): boolean =>
    corpus !== null && Array.isArray(corpus?.rights) && corpus?.rights.includes('write'),
  // From a corpus, returns true if the user has admin rights on the corpus.
  canAdmin: (corpus: Corpus | null): boolean =>
    corpus !== null && Array.isArray(corpus?.rights) && corpus?.rights.includes('admin'),
  // From a list of types or an object mapping type slugs to types, returns a list of folder or normal types.
  folderTypes: (types: { [typeId: UUID]: ElementType }): ElementType[] =>
    ensureArray(types).filter((type) => type.folder),
  normalTypes: (types: { [typeId: UUID]: ElementType }): ElementType[] =>
    ensureArray(types).filter((type) => !type.folder),
}

export default defineComponent({
  methods: {
    ...corporaFilters,
    // These cannot be used as filters as they require `this`
    getType(slug: string): ElementType | null {
      if (this.corpus === null || !this.corpus || !this.corpus?.types) return null
      return this.corpus.types[slug] || null
    },
    typeName(slug: string) {
      // Get the display name for a type by slug. Returns the slug when the type is not found.
      return this.getType(slug)?.display_name ?? slug
    },
  },
  computed: {
    ...mapState(useCorporaStore, ['corpora']),
    corpus(): Corpus | null {
      // This cursed condition is required for TypeScript to understand that this.corpusId is a string | null
      if (!('corpusId' in this) || (this.corpusId !== null && typeof this.corpusId !== 'string')) {
        // eslint-disable-next-line no-console
        console.warn('The corpus computed from corporaMixin requires this.corpusId to be defined.')
        return null
      }
      /*
       * Get a single corpus.
       * vue-async-computed does not support parameters on an async computed property;
       * you will need to provide a `corpusId` computed property yourself in the component.
       */
      if (!this.corpusId) return null
      return useCorporaStore().corpora[this.corpusId] ?? {}
    },
  },
})
