import type { StyleValue } from 'vue'

import { TEXT_ORIENTATIONS } from '@/config'
import type { TextOrientation } from '@/types'

interface HighlightChunk {
  /**
   * The text to display.
   */
  text: string
  /**
   * Whether or not this text should be highlighted.
   */
  hl: boolean
}

/**
 * Highlight words in a string.
 *
 * @param text String to highlight words on.
 * @param words Words to highlight.
 * @returns Array of chunks, with each chunk being highlighted or not.
 */
export const highlight = (text: string, words: string[]): HighlightChunk[] => {
  const matchPositions = words
    .map((word) => {
      word = word.trim()
      if (!word) return null
      const position = text.toLowerCase().indexOf(word.toLowerCase())
      return position >= 0 ? [position, position + word.length] : null
    })
    .filter((position: number[] | null): position is number[] => position !== null)
    .sort(([startA], [startB]) => startA - startB)

  const chunks = []
  let lastMatchPosition = 0
  if (!matchPositions.length) return [{ text, hl: false }]
  matchPositions.forEach(([startPosition, endPosition]) => {
    // Remove included positions
    if (lastMatchPosition >= endPosition) return
    if (lastMatchPosition < startPosition) {
      // Fill unmatched text
      chunks.push({ text: text.slice(lastMatchPosition, startPosition), hl: false })
    }
    if (lastMatchPosition <= startPosition) {
      // Add matched part
      chunks.push({ text: text.slice(startPosition, endPosition), hl: true })
    } else if (lastMatchPosition < endPosition) {
      // Handle deduplication adding only the remaining characters
      chunks.push({ text: text.slice(lastMatchPosition, endPosition), hl: true })
    }
    lastMatchPosition = endPosition
  })
  // Complete with remaining text
  chunks.push({ text: text.slice(lastMatchPosition), hl: false })
  return chunks
}

export const orientationStyle = (orientation: TextOrientation): StyleValue => {
  // Fall back to default display if text orientation isn't horizontal-lr or horizontal-rl
  const trOrientation = TEXT_ORIENTATIONS[orientation] ?? TEXT_ORIENTATIONS['horizontal-lr']
  return { 'writing-mode': trOrientation.writing, direction: trOrientation?.direction }
}

/**
 * Get the font currently defined in a DOM element, as formatted for the CSS `font` property.
 *
 * @param element A DOM element to get the font for. Defaults to the document's `<body>`.
 * @returns The currently applied font, as formatted for the CSS `font` property.
 */
export const getElementFont = (element: Element = document.body): string => {
  const style = window.getComputedStyle(element)
  const fontWeight = style.getPropertyValue('font-weight') ?? 'normal'
  const fontSize = style.getPropertyValue('font-size') ?? '16px'
  const fontFamily = style.getPropertyValue('font-family') ?? 'serif'
  return `${fontWeight} ${fontSize} ${fontFamily}`
}

type GetTextSizeFunc = {
  canvas?: HTMLCanvasElement
  (text: string, font: string): TextMetrics
}

/**
 * Computes the size of a string if it was displayed in the browser with a specified font.
 *
 * @param text The text to measure the size of.
 * @param font Font with which the text will be displayed with, formatted as for the CSS `font` property.
 * @returns Measurements returned by the `CanvasRenderingContext2D.measureText` method.
 * @see https://stackoverflow.com/a/21015393/5990435
 */
export const getTextSize: GetTextSizeFunc = (
  text: string,
  font: string = getElementFont(),
): TextMetrics => {
  /*
   * Use a canvas element that we do not insert anywhere, so that it never actually is on the page
   * Keep it cached in the function's context for better performance between calls
   */
  if (!getTextSize.canvas) getTextSize.canvas = document.createElement('canvas')
  const context = getTextSize.canvas.getContext('2d')
  if (!context)
    throw new Error(
      '2D context not supported on the getTextSize canvas, or the canvas has been set to a different mode.',
    )
  context.font = font
  context.textBaseline = 'top'
  return context.measureText(text)
}
