import { setUser as SentryUser } from '@sentry/browser'
import { isAxiosError } from 'axios'
import { defineStore } from 'pinia'

import {
  type PasswordResetPayload,
  type UserCreatePayload,
  type UserLoginPayload,
  type UserUpdatePayload,
  type UserVerifyEmailPayload,
  login,
  logout,
  register,
  resetUserPassword,
  retrieveUser,
  sendResetEmail,
  sendVerificationEmail,
  updateUser,
  verifyEmail,
} from '@/api'
import { errorParser } from '@/helpers'
import type { Feature, User } from '@/types/user'

import { resetStores, useCorporaStore, useNotificationStore, useSelectionStore } from '.'

interface State {
  // User is initially set to null, then set to false in case not logged in
  user: User | null | false
  features: Partial<Record<Feature, boolean>>
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: null,
    features: {},
  }),
  actions: {
    setUser(user: User) {
      this.user = user
      this.features = this.user.features
      // Use that user on Sentry to identify issues
      SentryUser({
        id: user.id,
        email: user.email,
      })
    },

    async get() {
      try {
        this.setUser(await retrieveUser())
      } catch (err) {
        this.user = false
        if (!isAxiosError(err)) return
        this.features = err.response?.data?.features || {}
        if (!err.response || err.response.status !== 403) throw new Error(errorParser(err))
      }
      /*
       * Fetch all corpora as soon as the authentication is retrieved.
       * Fetching all corpora should be handled by this module, as the corpora list needs to be reloaded
       * whenever the user's authentication state changes; login, logout, registration, or just at frontend startup.
       *
       * While all components could handle calling useCorporaStore().list() whenever they need a list of corpora, to handle
       * the initial fetching when the frontend loads, this could cause duplicate API requests and is not really
       * necessary considering that *most* components will need this corpora list.
       */
      if (this.user) {
        useCorporaStore().list()
        if (this.hasFeature('selection') && this.isVerified) useSelectionStore().get()
      }
    },

    async register(payload: UserCreatePayload) {
      this.setUser(await register(payload))

      resetStores(['auth'])

      // Fetch the list of corpora again, as it needed by most components and might have changed after registration
      useCorporaStore().list()
      if (this.hasFeature('selection') && this.isVerified) useSelectionStore().get()
    },

    async login(payload: UserLoginPayload) {
      try {
        this.setUser(await login(payload))

        resetStores(['auth'])

        // Fetch the list of corpora again, as it needed by most components and might have changed due to logging in
        useCorporaStore().list()
        if (this.hasFeature('selection') && this.isVerified) useSelectionStore().get()
      } catch (err) {
        throw new Error(errorParser(err))
      }
    },

    async logout() {
      if (!this.isLoggedOn) return
      await logout()
      this.user = false

      resetStores(['auth'])

      // Fetch the list of corpora again, as it needed by most components and might have changed due to logging out
      useCorporaStore().list()
    },

    async sendResetEmail(payload: Pick<User, 'email'>) {
      try {
        return await sendResetEmail(payload)
      } catch (err) {
        throw new Error(errorParser(err))
      }
    },

    async resetPassword(payload: PasswordResetPayload) {
      return resetUserPassword(payload)
    },

    async updateUser(payload: UserUpdatePayload) {
      this.setUser(await updateUser(payload))
    },

    async verifyEmail(payload: UserVerifyEmailPayload) {
      const user = await verifyEmail(payload)
      if (this.isLoggedOn) this.setUser(user)
    },

    async sendVerificationEmail() {
      const notificationStore = useNotificationStore()
      try {
        await sendVerificationEmail()
        notificationStore.notify({
          type: 'success',
          text: 'You will receive a new email with a validation link soon.',
        })
      } catch (err) {
        notificationStore.notify({ type: 'error', text: errorParser(err) })
      }
    },
  },
  getters: {
    hasInfo(): boolean {
      return this.user !== null
    },
    isLoggedOn(): boolean {
      return this.hasInfo && typeof this.user !== 'boolean'
    },
    isAdmin(): boolean {
      return this.isLoggedOn && (this.user as User).is_admin
    },
    isVerified(): boolean {
      return this.isAdmin || (this.isLoggedOn && (this.user as User).verified_email)
    },
    canArchive(): boolean {
      return (
        this.isLoggedOn && ((this.user as User).is_admin || (this.user as User).can_manage_workers)
      )
    },
    hasFeature() {
      return (feature: Feature): boolean => this.features?.[feature] || false
    },
  },
})
