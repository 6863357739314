<template>
  <div id="main">
    <Navbar />
    <div
      v-if="loggingWarning && !noLoginPage"
      class="notification is-info"
    >
      <button
        class="delete"
        v-on:click="loggingWarning = false"
      ></button>
      <p>
        You are currently not logged in to Arkindex. Consider
        <template v-if="hasFeature('signup')">
          <router-link :to="{ name: 'register', query: { next: $route.fullPath } }">
            creating a new account
          </router-link>
          or
        </template>
        <router-link :to="{ name: 'login', query: { next: $route.fullPath } }">
          logging in
        </router-link>
        to access more resources and tools.
      </p>
    </div>
    <div
      v-if="user && emailWarning"
      class="sticky-warning notification is-info"
    >
      <button
        class="delete"
        v-on:click="emailWarning = false"
      ></button>
      <p>
        Welcome to Arkindex! To fully activate your account, a verification email was sent to
        {{ user.email }}.
      </p>
      <p>
        <strong>
          Please check your mailbox, or
          <a v-on:click="sendVerificationEmail">resend the verification email</a> </strong
        >.
      </p>
    </div>
    <div
      v-else-if="passwordWarning"
      class="sticky-warning notification is-warning"
    >
      <button
        class="delete"
        v-on:click="passwordWarning = false"
      ></button>
      You don't have a password set for your account yet.
      <strong>
        Please define a password from
        <router-link :to="{ name: 'user-profile' }">your profile page</router-link>.
      </strong>
    </div>
    <router-view />
    <footer class="footer is-paddingless is-flex is-justify-content-space-between">
      <span class="footer-item">
        Version
        <a
          v-if="VERSION"
          :href="releaseNotes"
          target="_blank"
        >
          {{ VERSION }}
        </a>
        <span
          class="tag ml-1"
          :class="hasFeature('enterprise') ? 'is-success' : 'is-info'"
        >
          {{ hasFeature('enterprise') ? 'Enterprise' : 'Community' }} Edition
        </span>
      </span>
      <span class="footer-item"
        >Built by
        <a
          href="https://teklia.com"
          target="_blank"
          >Teklia</a
        ></span
      >
      <span class="is-inline-flex">
        <span class="footer-item"
          ><a
            href="https://support.teklia.com"
            target="_blank"
            >Support</a
          ></span
        >
        <span class="footer-item"
          >Documentation for
          <a
            href="https://doc.arkindex.org"
            target="_blank"
            >Arkindex</a
          >
          &amp;
          <a
            href="https://cli.arkindex.org/"
            target="_blank"
            >CLI</a
          ></span
        >
      </span>
    </footer>

    <transition-group
      v-if="notifications"
      name="notifications"
      tag="div"
      class="notifications"
    >
      <Notification
        v-for="notification in notifications"
        v-bind="notification"
        :key="notification.id"
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import Navbar from '@/components/Navbar.vue'
import Notification from '@/components/Notification.vue'
import { BANNER_MESSAGE, BANNER_STYLE, VERSION } from '@/config'
import { useAuthStore, useDisplayStore, useNotificationStore } from '@/stores'

export default defineComponent({
  components: {
    Navbar,
    Notification,
  },
  data: () => ({
    VERSION,
    loggingWarning: false,
    passwordWarning: false,
    emailWarning: false,
  }),
  mounted() {
    this.setScreenSize()
    if (BANNER_MESSAGE) {
      this.notify({ type: BANNER_STYLE, text: BANNER_MESSAGE, timeout: 0, markdown: true })
    }
  },
  computed: {
    ...mapState(useAuthStore, ['user', 'isLoggedOn', 'isVerified', 'hasFeature']),
    ...mapState(useNotificationStore, ['notifications']),
    noLoginPage(): boolean {
      if (!this.$route.name) return true
      // Prevent displaying the notification to specific pages
      return ['home', 'login', 'register'].includes(this.$route.name.toString())
    },
    releaseNotes(): string | undefined {
      if (!VERSION) return
      const matches = VERSION.match(/\d+\.\d+\.\d+/g)
      if (!matches) return
      const versionNumber = matches[0].replace(/\./g, '')
      return `https://teklia.com/our-solutions/arkindex/releases/arkindex-release-${versionNumber}/`
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['sendVerificationEmail']),
    ...mapActions(useDisplayStore, ['setScreenSize']),
    ...mapActions(useNotificationStore, ['notify']),
    checkWarnings() {
      const user = this.user
      const routeName = this.$route.name
      if (!user || !routeName) return
      this.emailWarning = !this.isVerified && routeName !== 'user-verify-email'
      // Avoid confusion as profile page is only accessible to verified emails
      this.passwordWarning = !this.emailWarning && !user.has_usable_password
    },
  },
  watch: {
    isLoggedOn: {
      immediate: true,
      handler(loggedOn: boolean) {
        this.loggingWarning = !loggedOn
      },
    },
    user: {
      immediate: true,
      handler: 'checkWarnings',
    },
    $route: {
      immediate: true,
      handler: 'checkWarnings',
    },
  },
})
</script>

<style lang="scss" scoped>
#main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#main > * {
  flex: 1;
}

#main > nav,
#main > footer {
  flex: 0;
}

#main > .notification {
  flex: 0;
  border-radius: 0;
  margin-bottom: 0;
}

main.container,
footer {
  margin-top: 1em;
}

.sticky-warning {
  position: -webkit-sticky;
  position: sticky;
  top: 1rem;
  left: 1rem;
  z-index: 19;
}

.notifications {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 999;
}

.notifications-enter-from,
.notifications-leave-to {
  opacity: 0;
}

.notifications-enter-active,
.notifications-leave-active {
  transition: opacity 0.3s ease;
}

.notifications-move {
  transition: transform 0.3s;
}

.footer {
  & .footer-item {
    margin: 0.5rem 0.9rem 0.5rem 0.9rem;
  }
}
</style>
