import axios from 'axios'

import { unique } from '@/helpers/api'
import type { CursorPagination, PageNumberPagination, UUID } from '@/types'
import type { ProcessSet } from '@/types/dataset'
import type {
  Process,
  ProcessElement,
  ProcessList,
  ProcessMode,
  ProcessState,
} from '@/types/process'

import type { CursorPaginationParameters, PageNumberPaginationParameters } from '.'

export interface ProcessListParameters extends PageNumberPaginationParameters {
  corpus?: UUID
  created?: boolean
  mode?: ProcessMode
  id?: string
  name?: string
  started?: boolean
  state?: ProcessState
}

export const listProcesses = unique(
  async (params: ProcessListParameters): Promise<PageNumberPagination<ProcessList>> =>
    (await axios.get('/process/', { params })).data,
)

export const retrieveProcess = unique(
  async (id: UUID): Promise<Process> => (await axios.get(`/process/${id}/`)).data,
)

export interface ProcessCreate
  extends Partial<
    Pick<
      Process,
      | 'element_type'
      | 'element_name_contains'
      | 'load_children'
      | 'ml_class_id'
      | 'name'
      | 'use_cache'
    >
  > {
  corpus: UUID
  mode?: 'workers' | 'dataset'
  element?: UUID
  selection?: boolean
}

export const createProcess = async (payload: ProcessCreate): Promise<Process> =>
  (await axios.post('/process/corpus/', payload)).data

export interface ProcessUpdate
  extends Partial<
    Pick<
      Process,
      'element_type' | 'element_name_contains' | 'load_children' | 'ml_class_id' | 'name'
    >
  > {
  element_id?: UUID
  state?: 'stopping'
}

export const updateProcess = async (id: UUID, payload: ProcessUpdate): Promise<Process> =>
  (await axios.patch(`/process/${id}/`, payload)).data

export const retryProcess = unique(
  async (id: UUID): Promise<Process> => (await axios.post(`/process/${id}/retry/`)).data,
)

export const deleteProcess = unique(async (id: UUID) => await axios.delete(`/process/${id}/`))

export interface ProcessStart extends Partial<Pick<Process, 'chunks' | 'use_cache'>> {
  farm?: UUID | null
}

export const startProcess = unique(
  async (id: UUID, payload: ProcessStart): Promise<Process> =>
    (await axios.post(`/process/${id}/start/`, payload)).data,
)

export const listProcessElements = unique(
  async (id: UUID, params: CursorPaginationParameters): Promise<CursorPagination<ProcessElement>> =>
    (await axios.get(`/process/${id}/elements/`, { params })).data,
)

export type TemplateCreate = Partial<Pick<Process, 'name'>>

export const createProcessTemplate = unique(
  async (id: UUID, payload: TemplateCreate): Promise<Process> =>
    (await axios.post(`/process/${id}/template/`, payload)).data,
)

export const applyProcessTemplate = unique(
  async (templateId: UUID, processId: UUID): Promise<Process> =>
    (await axios.post(`/process/${templateId}/apply/`, { process_id: processId })).data,
)

/**
 * Clear a process (remove all worker runs and templates)
 */
export const clearProcess = unique(async (id: UUID) => await axios.delete(`/process/${id}/clear/`))

/**
 * Select elements with a worker activity failure on the process
 */
export const selectProcessFailures = unique(
  async (id: UUID) => await axios.post(`/process/${id}/select-failures/`),
)

/**
 * Create a process from the failures on another process
 */
export const createProcessFailures = async (id: UUID): Promise<Process> =>
  (await axios.post(`/process/${id}/process-failures/`)).data

export const listProcessSets = unique(
  async (
    processId: UUID,
    params: PageNumberPaginationParameters,
  ): Promise<PageNumberPagination<ProcessSet>> =>
    (await axios.get(`/process/${processId}/sets/`, { params })).data,
)

export const createProcessSet = unique(
  async (processId: UUID, setId: UUID): Promise<ProcessSet> =>
    (await axios.post(`/process/${processId}/set/${setId}/`)).data,
)

export const deleteProcessSet = unique((processId: UUID, setId: UUID) =>
  axios.delete(`/process/${processId}/set/${setId}/`),
)
