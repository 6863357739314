<template>
  <form v-on:submit.prevent="create">
    <ConfigurationForm
      v-on:form-errors="disabledTitle = $event"
      :worker-version-id="workerVersionId"
      :worker-id="workerId"
      v-model="formConfiguration"
    />
    <div
      class="help is-danger mb-2"
      v-if="parsedConfigurationExistsError"
    >
      <p v-if="parsedConfigurationExistsError.name">{{ parsedConfigurationExistsError.name[0] }}</p>
      <p v-if="parsedConfigurationExistsError.configuration">
        A configuration already exists with this configuration for this worker.
        <a v-on:click="$emit('set-configuration', parsedConfigurationExistsError.id)"
          >Use this configuration</a
        >.
      </p>
    </div>
    <button
      type="button"
      class="button is-success"
      :disabled="disabledTitle.length > 0 || undefined"
      v-on:click="create"
      :title="disabledTitle || 'Create a new configuration'"
    >
      Create
    </button>
  </form>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { cloneDeep, isEmpty } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import ConfigurationForm from '@/components/Process/Workers/Configurations/ConfigurationForm/Form.vue'
import { errorParser } from '@/helpers'
import { useNotificationStore, useWorkerStore } from '@/stores'
import type { UUID } from '@/types'

interface ConfigurationExistsError {
  name?: [string]
  configuration?: [string]
  id?: [UUID]
}

interface ParsedConfigurationExistsError {
  name?: [string]
  configuration?: [string]
  id?: UUID
}

export default defineComponent({
  emits: ['set-configuration', 'config-created'],
  components: {
    ConfigurationForm,
  },
  props: {
    workerVersionId: {
      type: String as PropType<UUID>,
      required: true,
    },
    workerId: {
      type: String as PropType<UUID>,
      required: true,
    },
    newConfiguration: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    configurationExistsError: null as ConfigurationExistsError | null,
    disabledTitle: '',
    formConfiguration: {
      name: '',
      configuration: {},
    },
  }),
  computed: {
    ...mapState(useWorkerStore, ['workerConfigurations', 'workerVersions']),
    parsedConfigurationExistsError(): ParsedConfigurationExistsError | undefined {
      // The error returned by the backend returns the existing configuration's ID like this [ID]
      if (!this.configurationExistsError) return
      if ((this.configurationExistsError.id?.length ?? 0) > 1)
        throw new Error('Multiple existing configuration IDs returned.')
      return {
        ...this.configurationExistsError,
        id: this.configurationExistsError.id?.[0] ?? undefined,
      }
    },
  },
  methods: {
    ...mapActions(useWorkerStore, ['createConfiguration']),
    ...mapActions(useNotificationStore, ['notify']),
    async create() {
      if (this.loading || this.disabledTitle) return
      this.loading = true
      try {
        const configuration = await this.createConfiguration(this.workerId, {
          name: this.formConfiguration.name,
          configuration: this.formConfiguration.configuration,
        })
        // Go back to configurations list only if the creation succeeded
        this.$emit('config-created')
        // Select the newly created configuration
        this.$emit('set-configuration', configuration.id)
        this.notify({
          type: 'info',
          text: 'Configuration created. Save to add it to this worker run.',
        })
      } catch (err) {
        if (isAxiosError(err) && err.response?.data)
          this.configurationExistsError = err.response.data
        this.notify({ type: 'error', text: errorParser(err) })
        this.loading = false
      }
    },
  },
  watch: {
    newConfiguration: {
      immediate: true,
      handler(newValue) {
        if (!isEmpty(newValue.configuration)) this.formConfiguration = cloneDeep(newValue)
      },
    },
  },
})
</script>
