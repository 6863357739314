<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="(value: boolean) => $emit('update:modelValue', value)"
    :title="metadata ? 'Edit metadata' : 'Create metadata'"
  >
    <template v-slot:header>
      <p class="modal-card-title">{{ metadata ? 'Edit' : 'Create' }} metadata</p>
      <a
        class="mr-2 pl-2"
        :class="freeEdit ? 'has-text-white has-background-danger' : 'has-text-danger'"
        v-on:click="freeEdit = isAdmin && !freeEdit"
        v-if="isAdmin"
        title="Free editing mode (admin only)"
      >
        Free edit
        <i :class="freeEdit ? 'icon-unlock' : 'icon-lock'"></i>
      </a>
    </template>
    <div
      class="notification is-danger"
      v-if="freeEdit"
    >
      <strong>Caution! You are in free editing mode</strong><br />
      This mode is restricted to administrators. There is no form validation.<br />
      Please make sure your metadata types and names are correct to preserve data integrity.<br />
      It is highly recommended to define project-wide allowed metadata in the administration panel
      instead of using this mode.
    </div>
    <div
      class="notification is-warning"
      v-else-if="!loading && !corpusAllowedMetadata.length"
    >
      There are no allowed metadata for this project.
      {{
        isAdmin ? 'Use the administration panel to add them.' : 'Please contact your administrator.'
      }}
    </div>
    <MetadataForm
      :id="formId"
      :corpus-id="corpusId"
      :free-edit="freeEdit"
      :errors="formErrors"
      :disabled="loading"
      v-model="formData"
      v-model:is-valid="formValid"
      v-on:submit.prevent="submit"
    />
    <template v-slot:footer="{ close }">
      <button
        class="button"
        type="button"
        v-on:click="close"
      >
        Cancel
      </button>
      <button
        class="button"
        type="submit"
        :form="formId"
        :class="{ 'is-loading': loading, 'is-info': !freeEdit, 'is-danger': freeEdit }"
        :disabled="loading || !formValid || undefined"
      >
        {{ metadata ? 'Save' : 'Create' }}
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { uniqueId } from 'lodash'
import { mapState, mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import type { MetaDataCreate } from '@/api'
import Modal from '@/components/Modal.vue'
import { errorParser } from '@/helpers'
import {
  useAllowedMetaDataStore,
  useAuthStore,
  useDisplayStore,
  useMetaDataStore,
  useNotificationStore,
} from '@/stores'
import type { UUID } from '@/types'
import type { MetaData } from '@/types/metadata'

import MetadataForm, { type FormErrors } from './Form.vue'

/**
 * A modal to create a new metadata or update an existing one
 */
export default defineComponent({
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  components: {
    MetadataForm,
    Modal,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
    elementId: {
      type: String as PropType<UUID>,
      required: true,
    },
    /**
     * An existing MetaData to edit. When not set, this creates a metadata instead.
     */
    metadata: {
      type: Object as PropType<MetaData | null>,
      default: null,
    },
  },
  data: () => ({
    freeEdit: false,
    loading: false,
    formData: {
      name: '',
      type: 'text',
      value: '',
    } as MetaDataCreate,
    formValid: false,
    formErrors: {} as FormErrors,
    /**
     * Value for the ID attribute on the MetadataForm's `<form>` element.
     * This is used to allow buttons in the modal footer, which are outside of the form, to submit the form.
     */
    formId: `metadataform-${uniqueId()}`,
  }),
  computed: {
    ...mapState(useAuthStore, ['isAdmin']),
    ...mapStores(useAllowedMetaDataStore, useDisplayStore, useMetaDataStore, useNotificationStore),
    corpusAllowedMetadata() {
      return this.allowedMetadataStore.allowedMetadata[this.corpusId] ?? []
    },
  },
  methods: {
    async initialize(metadata: MetaData | null) {
      // Ensure allowed metadata are loaded
      if (!(this.corpusId in this.allowedMetadataStore.allowedMetadata)) {
        this.loading = true
        try {
          await this.allowedMetadataStore.listAllowedMetadata(this.corpusId)
        } catch (err) {
          // Notify of the failure, but continue as if we actually had 0 allowed metadata
          this.notificationStore.notify({ type: 'error', text: errorParser(err) })
        } finally {
          this.loading = false
        }
      }

      if (metadata) {
        this.formData = {
          name: metadata.name,
          type: metadata.type,
          value: metadata.value,
        }
        // When editing a metadata, enable free edit when the user is an admin and this metadata is not allowed
        this.freeEdit =
          this.isAdmin &&
          !this.corpusAllowedMetadata.some(
            ({ name, type }) => name === metadata.name && type === metadata.type,
          )
      } else {
        this.formData = {
          name: this.displayStore.lastMetadataName ?? '',
          type: this.displayStore.lastMetadataType ?? 'text',
          value: this.displayStore.lastMetadataType === 'numeric' ? 0 : '',
        }
        // When creating a metadata, only enable free edit when the user is an admin and there are no allowed metadata at all
        this.freeEdit = this.isAdmin && !this.corpusAllowedMetadata.length
      }
    },
    submit() {
      if (this.loading || !this.formValid) return
      this.loading = true
      try {
        if (this.metadata !== null)
          this.metadataStore.update(this.elementId, { ...this.formData, id: this.metadata.id })
        else this.metadataStore.create(this.elementId, this.formData)

        this.displayStore.setLastMetadata(this.formData.name, this.formData.type)
        this.$emit('update:modelValue', false)
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 400) this.formErrors = err.response.data
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue: boolean) {
        if (newValue) this.initialize(this.metadata)
      },
    },
    metadata: {
      handler: 'initialize',
    },
  },
})
</script>
