<template>
  <main class="container is-fluid">
    <h1 class="title">Elements on the same image</h1>

    <div class="columns">
      <ul class="column">
        <li class="field is-horizontal">
          <div class="field-label">
            <label class="label">ID</label>
          </div>
          <div class="field-body">
            <div class="field">
              <ItemId
                class="control"
                :item-id="imageId"
              />
            </div>
          </div>
        </li>
        <template v-if="image">
          <li class="field is-horizontal">
            <div class="field-label">
              <label class="label">Status</label>
            </div>
            <div class="field-body">
              <div class="field is-capitalized">
                {{ image.status }}
              </div>
            </div>
          </li>
          <li class="field is-horizontal">
            <div class="field-label">
              <label class="label">Width</label>
            </div>
            <div class="field-body">
              <div class="field">
                {{ image.width }}
              </div>
            </div>
          </li>
          <li class="field is-horizontal">
            <div class="field-label">
              <label class="label">Height</label>
            </div>
            <div class="field-body">
              <div class="field">
                {{ image.height }}
              </div>
            </div>
          </li>
        </template>
        <li class="field is-horizontal">
          <div class="field-label">
            <label class="label">URL</label>
          </div>
          <div class="field-body">
            <div class="field">
              <a
                v-if="fullImage"
                target="_blank"
                class="control"
                :href="fullImage"
              >
                Full IIIF image
              </a>
              <a
                class="control"
                :href="image.url"
                target="_blank"
                v-else-if="image"
              >
                {{ image.url }}
              </a>
            </div>
          </div>
        </li>
      </ul>
      <div
        class="column is-narrow image-column"
        v-if="imageThumbnail"
      >
        <img :src="imageThumbnail" />
      </div>
    </div>

    <div v-if="loading">Loading…</div>
    <template v-else>
      <Paginator
        :response="imageStore.elements ?? undefined"
        singular="element"
        plural="elements"
      >
        <template v-slot:default="{ results }">
          <ElementList
            :elements="results"
            as-table
          />
        </template>

        <template v-slot:no-results>
          <span class="has-text-danger"> No elements found on this image. </span>
        </template>
      </Paginator>
    </template>
  </main>
</template>

<script lang="ts">
import { mapActions, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import { retrieveImage } from '@/api'
import ItemId from '@/components/ItemId.vue'
import ElementList from '@/components/Navigation/ElementList.vue'
import Paginator from '@/components/Paginator.vue'
import { iiifUri } from '@/helpers'
import { corporaMixin } from '@/mixins'
import { useImageStore, useNotificationStore } from '@/stores'
import type { Image, Zone } from '@/types'

// Displayed image details retrieved from the backend
export default defineComponent({
  mixins: [corporaMixin],
  components: {
    ItemId,
    Paginator,
    ElementList,
  },
  props: {
    imageId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    image: null as Image | null,
    loading: false,
    opened: false,
  }),
  async mounted() {
    try {
      this.image = (await retrieveImage(this.imageId)).data
    } catch {
      this.notify({ type: 'error', text: 'An error occurred retrieving image details.' })
    }
  },
  computed: {
    ...mapStores(useImageStore),
    corpusId() {
      if (!this.imageStore.elements?.results?.length) return null
      return this.imageStore.elements.results[0].corpus.id
    },
    fakeZone(): Zone | null {
      if (!this.image || !this.hasValidDimensions) return null
      return {
        id: '',
        image: this.image,
        url: this.image.url,
        polygon: [],
      }
    },
    hasValidDimensions(): boolean {
      return this.image !== null && this.image.width > 0 && this.image.height > 0
    },
    imageThumbnail(): string | null {
      if (!this.fakeZone) return null
      return iiifUri(this.fakeZone, { height: 200 })
    },
    fullImage(): string | null {
      if (!this.fakeZone) return null
      return iiifUri(this.fakeZone)
    },
  },
  methods: {
    ...mapActions(useNotificationStore, ['notify']),
    async load(page: number | null) {
      this.loading = true
      try {
        await this.imageStore.listElements({
          id: this.imageId,
          page: page ?? 1,
        })
      } finally {
        this.loading = false
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // @ts-expect-error `vm` is not understood by TypeScript
    next((vm) => vm.load(to.query.page))
  },
  beforeRouteUpdate(to) {
    this.load(typeof to.query.page === 'string' ? Number.parseInt(to.query.page) : null)
  },
})
</script>

<style scoped>
.image-column {
  text-align: center;
  min-width: 25%;
  max-width: 50%;
}
</style>
