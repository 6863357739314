<template>
  <DropdownContent
    id="orientation"
    title="Orientation"
  >
    <div class="field is-horizontal">
      <div class="field-label is-normal is-flex-grow-0">
        <div class="label">Rotation</div>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select
                v-model="rotationAngle"
                :disabled="loading || !canWriteElement(element.id)"
                :title="
                  canWriteElement(element.id)
                    ? 'Change the orientation of the image'
                    : 'You don\'t have write access on this element'
                "
              >
                <option
                  v-for="rotation in allowedRotations"
                  :key="rotation"
                  :value="rotation"
                >
                  {{ rotation }}°
                </option>
                <!-- Handle non-allowed values set via the API -->
                <option
                  v-if="!allowedRotations.includes(rotationAngle)"
                  :value="rotationAngle"
                  disabled
                >
                  {{ rotationAngle }}°
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="field-label is-normal has-text-left">
          <div
            class="control"
            :title="
              canWriteElement(element.id)
                ? 'Mirror the image'
                : 'You don\'t have write access on this element'
            "
          >
            <label class="checkbox">
              <input
                id="mirroredSwitch"
                type="checkbox"
                :checked="mirrored"
                :disabled="loading || !canWriteElement(element.id)"
                v-on:click="toggleMirrored"
              />
              Mirror
            </label>
          </div>
        </div>
      </div>
    </div>
  </DropdownContent>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import DropdownContent from '@/components/DropdownContent.vue'
import { useElementStore } from '@/stores'
import type { Element, ElementBase, UUID } from '@/types'

export default defineComponent({
  props: {
    elementId: {
      type: String as PropType<UUID>,
      required: true,
    },
  },
  components: {
    DropdownContent,
  },
  data: () => ({
    allowedRotations: [0, 90, 180, 270],
    rotationAngle: 0,
    mirrored: false,
    loading: false,
  }),
  computed: {
    ...mapState(useElementStore, ['elements']),
    ...mapState(useElementStore, { canWriteElement: 'canWrite' }),
    element(): Element | ElementBase {
      return this.elements[this.elementId]
    },
  },
  methods: {
    ...mapActions(useElementStore, ['patch']),
    toggleMirrored() {
      if (!this.loading) this.mirrored = !this.mirrored
    },
    async updateElement() {
      /*
       * When the component is loaded, when the element changes while browsing between neighbors
       * or when the element is loaded after the component, a watcher updates this component's fields
       * to keep them up-to-date with the element.
       * This update can cause updateElement to trigger, and that could cause unnecessary PATCH requests
       * to update the element's attributes to the ones it already has.
       * If the user does not have write access, this could also cause 403 errors.
       * We prevent this by not allowing any PATCH requests if the element's attributes are the same as our fields.
       */
      if (
        this.loading ||
        (this.rotationAngle === this.element.rotation_angle &&
          this.mirrored === this.element.mirrored)
      )
        return
      this.loading = true
      try {
        await this.patch(this.elementId, {
          rotation_angle: this.rotationAngle,
          mirrored: this.mirrored,
        })
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    rotationAngle: 'updateElement',
    mirrored: 'updateElement',
    element: {
      handler(newValue) {
        if (!newValue) return
        this.rotationAngle = newValue.rotation_angle
        this.mirrored = newValue.mirrored
      },
      immediate: true,
    },
  },
})
</script>
