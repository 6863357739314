import { isAxiosError } from 'axios'
import { defineStore } from 'pinia'

import {
  type CreateWorkerPayload,
  type CreateWorkerVersionPayload,
  type ListWorkerConfigurationsParams,
  type ListWorkersParameters,
  type PageNumberPaginationParameters,
  type UpdateWorkerPayload,
  type WorkerConfigurationCreatePayload,
  type WorkerConfigurationUpdatePayload,
  type WorkerVersionListParameters,
  createUserWorkerRun,
  createWorker,
  createWorkerConfiguration,
  createWorkerVersion,
  listUserWorkerRuns,
  listWorkerConfigurations,
  listWorkerTypes,
  listWorkerVersions,
  listWorkers,
  retrieveFeatureWorkerVersion,
  retrieveRecommendedWorkerVersion,
  retrieveWorker,
  retrieveWorkerConfiguration,
  retrieveWorkerRun,
  retrieveWorkerVersion,
  updateWorker,
  updateWorkerConfiguration,
} from '@/api'
import { errorParser } from '@/helpers'
import type { ArkindexFeature, UUID } from '@/types'
import type { WorkerRun } from '@/types/process'
import type { Worker, WorkerType, WorkerVersion } from '@/types/worker'
import type { WorkerConfiguration } from '@/types/workerConfiguration'

import { useNotificationStore } from './notification'

interface State {
  workers: {
    [workerId: UUID]: Worker
  }
  workerTypes: {
    [typeId: UUID]: WorkerType
  }
  workerConfigurations: {
    [workerId: UUID]: {
      [workerConfigurationId: UUID]: WorkerConfiguration
    }
  }
  workerVersions: {
    [workerVersionId: UUID]: WorkerVersion
  }
  recommendedWorkerVersions: {
    [workerId: UUID]: WorkerVersion | null
  }
  featureWorkerVersionIds: {
    [feature in ArkindexFeature]?: UUID
  }
  workerRuns: {
    [workerRunId: UUID]: WorkerRun
  }
}

export const useWorkerStore = defineStore('worker', {
  state: (): State => ({
    workers: {},
    workerTypes: {},
    workerConfigurations: {},
    workerVersions: {},
    recommendedWorkerVersions: {},
    featureWorkerVersionIds: {},
    workerRuns: {},
  }),
  actions: {
    async listWorkers(params: ListWorkersParameters = {}) {
      const resp = await listWorkers(params)
      this.workers = {
        ...this.workers,
        ...Object.fromEntries(resp.results.map((worker) => [worker.id, worker])),
      }
      return resp
    },

    async createWorker(params: CreateWorkerPayload) {
      const resp = await createWorker(params)
      this.workers[resp.id] = resp
      // No need to fetch the worker type, as local workers cannot be used to build a worker process
      return resp
    },

    async updateWorker(id: UUID, params: UpdateWorkerPayload) {
      const resp = await updateWorker(id, params)
      this.workers[id] = resp
      return resp
    },

    async listWorkerTypes(page = 1) {
      if (page === 1) this.workerTypes = {}
      try {
        const resp = await listWorkerTypes({ page })
        this.workerTypes = {
          ...this.workerTypes,
          ...Object.fromEntries(resp.results.map((type) => [type.id, type])),
        }
        if (!resp || !resp.number || page !== resp.number) {
          // Avoid any loop
          throw new Error('Pagination failed listing worker types')
        }
        // Load other pages
        if (resp.next) this.listWorkerTypes(page + 1)
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 403) {
          throw err
        } else {
          useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        }
      }
    },

    async listConfigurations(
      workerId: UUID,
      { page = 1, ...options }: ListWorkerConfigurationsParams,
    ) {
      if (page === 1) this.workerConfigurations[workerId] = {}
      try {
        const data = await listWorkerConfigurations(workerId, { page, ...options })

        this.workerConfigurations[workerId] = {
          ...this.workerConfigurations[workerId],
          ...Object.fromEntries(
            data.results.map((configuration) => [configuration.id, configuration]),
          ),
        }

        if (!data || !data.number || page !== data.number) {
          // Avoid any loop
          throw new Error(
            `Pagination failed listing worker configurations for worker "${workerId}"`,
          )
        }
        // Load other pages
        if (data.next) this.listConfigurations(workerId, { page: page + 1, ...options })
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 403) {
          throw err
        } else {
          useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        }
      }
    },

    async createConfiguration(workerId: UUID, configuration: WorkerConfigurationCreatePayload) {
      const resp = await createWorkerConfiguration(workerId, configuration)
      if (workerId in this.workerConfigurations) this.workerConfigurations[workerId][resp.id] = resp
      else this.workerConfigurations[workerId] = { [resp.id]: resp }
      return resp
    },

    async getConfiguration(workerId: UUID, configurationId: UUID) {
      const resp = await retrieveWorkerConfiguration(configurationId)
      if (workerId in this.workerConfigurations) this.workerConfigurations[workerId][resp.id] = resp
      else this.workerConfigurations[workerId] = { [resp.id]: resp }
    },

    async updateConfiguration(
      workerId: UUID,
      configurationId: UUID,
      data: WorkerConfigurationUpdatePayload,
    ) {
      /*
       * Keep the current archived attribute of the configuration, to see if the backend will update it.
       * When the backend changes the archived state, we need to remove the configuration from the list
       * in the store: since we only list configurations that are either archived or not, and never
       * both at once, the configuration should disappear from the list when it switches state.
       * Doing so ourselves, without calling listConfigurations again, is cleaner and avoids stale reads.
       */
      const currentArchivedState = this.workerConfigurations[workerId]?.[configurationId]?.archived

      let resp
      try {
        resp = await updateWorkerConfiguration(configurationId, data)
      } catch (err) {
        if (isAxiosError(err) && err.response?.data?.name) {
          // Display specific error message when updating the name of a worker configuration
          useNotificationStore().notify({
            type: 'error',
            text: errorParser(err.response.data.name),
          })
        } else {
          useNotificationStore().notify({ type: 'error', text: errorParser(err) })
        }
        return
      }

      if (currentArchivedState !== undefined && resp.archived !== currentArchivedState) {
        delete this.workerConfigurations[workerId][configurationId]
      } else {
        this.workerConfigurations[workerId][configurationId] = resp
      }
    },

    async listVersions(workerId: UUID, params: WorkerVersionListParameters) {
      const resp = await listWorkerVersions(workerId, params)
      this.workerVersions = {
        ...this.workerVersions,
        ...Object.fromEntries(resp.results.map((v) => [v.id, v])),
      }
      this.workers = {
        ...this.workers,
        ...Object.fromEntries(
          resp.results.map((v) => [
            v.worker.id,
            { ...(this.workers[v.worker.id] ?? {}), ...v.worker },
          ]),
        ),
      }
      return resp
    },

    async createWorkerVersion(workerId: UUID, params: CreateWorkerVersionPayload) {
      const resp = await createWorkerVersion(workerId, params)
      this.workerVersions[resp.id] = resp
      return resp
    },

    async getWorkerVersion(workerVersionId: UUID) {
      this.workerVersions[workerVersionId] = await retrieveWorkerVersion(workerVersionId)
    },

    async getRecommendedWorkerVersion(workerId: UUID) {
      try {
        const resp = await retrieveRecommendedWorkerVersion(workerId)
        this.recommendedWorkerVersions[workerId] = resp
        return resp
      } catch (err) {
        if (isAxiosError(err) && err.response?.status === 404) {
          this.recommendedWorkerVersions[workerId] = null
          return null
        } else throw err
      }
    },

    async getFeatureWorkerVersion(feature: ArkindexFeature) {
      try {
        const workerVersion = await retrieveFeatureWorkerVersion(feature)
        this.workerVersions[workerVersion.id] = workerVersion
        this.featureWorkerVersionIds[feature] = workerVersion.id
      } catch (err) {
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },

    async getWorker(workerId: UUID) {
      this.workers[workerId] = await retrieveWorker(workerId)
    },

    async getWorkerRun(workerRunId: UUID) {
      const data = await retrieveWorkerRun(workerRunId)
      this.workerRuns[workerRunId] = data
      // Store the configuration in the workerConfigurations store if it exists
      if (!data.configuration) return
      if (!(data.worker_version.worker.id in this.workerConfigurations))
        this.workerConfigurations[data.worker_version.worker.id] = {}
      this.workerConfigurations[data.worker_version.worker.id][data.configuration.id] =
        data.configuration
    },

    async listUserWorkerRuns(params: PageNumberPaginationParameters = {}) {
      const resp = await listUserWorkerRuns(params)
      this.workerRuns = {
        ...this.workerRuns,
        ...Object.fromEntries(resp.results.map((run) => [run.id, run])),
      }
      return resp
    },

    async createUserWorkerRun(versionId: UUID) {
      const resp = await createUserWorkerRun(versionId)
      this.workerRuns[resp.id] = resp
      return resp
    },
  },
})
