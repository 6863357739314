<template>
  <div>
    <CorpusHeader :corpus-id="corpusId" />
    <main class="container is-fluid">
      <ElementNavigation
        :corpus-id="corpusId"
        v-model:query="query"
      />
    </main>
  </div>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { isEmpty } from 'lodash'
import { type PropType, defineComponent } from 'vue'
import type { LocationQueryRaw } from 'vue-router'

import CorpusHeader from '@/components/Corpus/Header.vue'
import ElementNavigation from '@/components/Navigation/ElementNavigation.vue'
import { errorParser } from '@/helpers'
import { corporaMixin } from '@/mixins'
import { useCorporaStore, useNotificationStore } from '@/stores'
import type { UUID } from '@/types'

export default defineComponent({
  mixins: [corporaMixin],
  components: {
    ElementNavigation,
    CorpusHeader,
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
  },
  computed: {
    query: {
      get() {
        return this.$route.query
      },
      set(query: LocationQueryRaw) {
        this.$router.push({ ...this.$route, query })
      },
    },
  },
  /**
   * When entering this view's route for the first time, if there are no defined query parameters,
   * we will set the default filters for the corpus (top-level elements, either of a folder type
   * or of a top-level type if one is defined).
   */
  async beforeRouteEnter(to, from, next) {
    // Retrieve the current corpus to check that it exists and find the top-level type.
    let corpus
    try {
      corpus = await useCorporaStore().get(to.params.corpusId.toString())
    } catch (err) {
      if (isAxiosError(err)) throw err
      useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      next({ name: 'not-found' })
      return
    }

    if (!isEmpty(to.query)) {
      next()
      return
    }
    const query: LocationQueryRaw = { top_level: 'true' }

    if (corpus.top_level_type) query.type = corpus.top_level_type
    else query.folder = 'true'

    next({ path: to.path, query, hash: to.hash })
  },
})
</script>
