<template>
  <div
    class="content"
    :style="orientationStyle(transcription.orientation)"
  >
    <blockquote class="transcription-box">
      <template v-if="transcriptionEntities.length">
        <Token
          v-for="(token, index) in tokens"
          v-bind="token"
          :key="index"
        />
      </template>
      <template v-else>{{ transcription.text }}</template>
    </blockquote>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { orientationStyle, parseEntities } from '@/helpers'
import { useEntityStore } from '@/stores'
import type { UUID } from '@/types'
import type { Transcription } from '@/types/transcription'

import Token from './Token.vue'

export default defineComponent({
  props: {
    transcription: {
      type: Object as PropType<Transcription>,
      required: true,
    },
    /**
     * Filter TranscriptionEntities by WorkerRun UUID.
     * If unset, no TranscriptionEntities are displayed.
     */
    workerRunFilter: {
      type: String as PropType<UUID | 'manual' | ''>,
      default: '',
    },
  },
  components: {
    Token,
  },
  computed: {
    ...mapState(useEntityStore, ['inTranscription']),
    loaded() {
      const { results, count, loaded } = this.inTranscription?.[this.transcription.id] ?? {}
      return results && loaded >= count
    },
    transcriptionEntities() {
      const transcriptionEntities = this.inTranscription?.[this.transcription.id]?.results
      if (!this.loaded || !transcriptionEntities?.length || !this.workerRunFilter) return []
      if (this.workerRunFilter === 'manual') {
        return transcriptionEntities.filter(
          (transcriptionEntity) => transcriptionEntity.worker_run === null,
        )
      }
      return transcriptionEntities.filter(
        (transcriptionEntity) => transcriptionEntity.worker_run?.id === this.workerRunFilter,
      )
    },
    tokens() {
      return parseEntities(this.transcription.text, this.transcriptionEntities)
    },
  },
  methods: {
    orientationStyle,
  },
})
</script>

<style scoped>
.transcription-box {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  overflow-x: auto;
  white-space: pre-line;
}
</style>
