import { defineStore } from 'pinia'

import { type SearchIndexParams, searchCorpus, startIndexation } from '@/api'
import { errorParser } from '@/helpers'
import type { UUID } from '@/types'
import type { SearchQuery, SearchResponse } from '@/types/search'

import { useJobsStore, useNotificationStore } from '.'

export const initialState = () => ({
  documents: null,
})

interface State {
  documents: (SearchResponse & { results: NonNullable<SearchResponse['results']> }) | null
}

export const useSearchStore = defineStore('search', {
  state: (): State => ({
    documents: null,
  }),
  actions: {
    async search(corpusId: UUID, query: SearchQuery) {
      // The search API does not like empty fields, so we remove any optional fields set to empty strings
      for (const [key, value] of Object.entries(query)) {
        if (key !== 'query' && value === '') delete query[key as keyof SearchQuery]
      }

      try {
        const { results, ...data } = await searchCorpus(corpusId, query)
        this.documents = { ...data, results: results ?? [] }
      } catch (err) {
        this.documents = null
        useNotificationStore().notify({ type: 'error', text: errorParser(err) })
      }
    },
    async indexCorpus(corpusId: UUID, params: SearchIndexParams = {}) {
      await startIndexation(corpusId, params)
      useJobsStore().list()
    },
  },
})
