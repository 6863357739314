import axios from 'axios'

import { unique } from '@/helpers/api'
import type { OneOf, PageNumberPagination, UUID } from '@/types'
import type { CorpusExport, ExportFormat, ExportSource } from '@/types/export'
import type { Process } from '@/types/process'

import type { PageNumberPaginationParameters } from '.'

// List a corpus' exports
export const listExports = unique(
  async (
    corpusId: UUID,
    params: PageNumberPaginationParameters = {},
  ): Promise<PageNumberPagination<CorpusExport>> =>
    (await axios.get(`/corpus/${corpusId}/export/`, { params })).data,
)

// Start an export on a corpus
export const startExport = unique(
  async (corpusId: UUID, source = 'default'): Promise<CorpusExport> =>
    (await axios.post(`/corpus/${corpusId}/export/`, { source })).data,
)

// Delete a corpus export
export const deleteExport = unique(async (id: UUID) => await axios.delete(`/export/${id}/`))

/**
 * List the available sources to run exports on.
 * Available on Arkindex EE only.
 */
export const listExportSources = unique(
  async (): Promise<ExportSource[]> => (await axios.get('/export-sources/')).data,
)

export type CreateExportProcessParams = {
  format: ExportFormat
  export_id: UUID | null
  configuration?: Record<string, unknown>
  farm_id?: UUID | null
} & OneOf<[{ element_id?: UUID }, { selection?: boolean }]>

// Start an export process
export const createExportProcess = async (
  corpusId: UUID,
  params: CreateExportProcessParams,
): Promise<Process> => (await axios.post(`/process/export/${corpusId}/`, params)).data
