<template>
  <div v-if="corpus">
    <div class="is-inline-block is-size-4 mb-4">
      <router-link :to="{ name: 'navigation', params: { corpusId } }">
        {{ truncateLong(corpus.name) }}
      </router-link>
    </div>
    <div class="dropdown is-right is-pulled-right is-hoverable dropdown-menu-min-width">
      <div class="dropdown-trigger">
        <button class="button is-info">
          <span>Actions</span>
          <i class="icon-down-open"></i>
        </button>
      </div>
      <div class="dropdown-menu">
        <div class="dropdown-content">
          <a
            :disabled="!canExecute || null"
            class="dropdown-item"
            v-on:click="createProcess"
            :title="
              canExecute ? 'Build a new ML process from those elements.' : executeDisabledTitle
            "
          >
            <i class="icon-cog-alt"></i>
            Create process
          </a>
          <!-- Export process modals for each available export mode -->
          <ExportProcessModal
            v-for="(_, mode) in EXPORT_FORMATS"
            :format="mode"
            :corpus-id="corpusId"
            :selection="true"
            :key="mode"
          >
            <template v-slot:open="{ open }">
              <a
                :class="{ 'is-disabled': !canExport }"
                class="dropdown-item"
                v-on:click="open"
                :title="
                  canExport ? `Export as ${EXPORT_FORMATS[mode].label}` : executeDisabledTitle
                "
              >
                <i class="icon-export"></i>
                Export as {{ EXPORT_FORMATS[mode].label }}
              </a>
            </template>
          </ExportProcessModal>
          <a
            :disabled="!canCreate || null"
            class="dropdown-item"
            v-on:click="moveSelectionModal = canCreate"
            :title="canCreate ? 'Move selected elements.' : executeDisabledTitle"
          >
            <i class="icon-direction"></i>
            Move elements
          </a>
          <a
            :disabled="!canCreate || null"
            class="dropdown-item"
            v-on:click="createParentModal = canCreate"
            :title="
              canCreate ? 'Link selected elements to another parent folder.' : createDisabledTitle
            "
          >
            <i class="icon-direction"></i>
            Link to another parent
          </a>
          <a
            :disabled="!canCreate || null"
            class="dropdown-item"
            v-on:click="datasetSelectionModal = canCreate"
            :title="canCreate ? 'Add those elements to a dataset.' : createDisabledTitle"
          >
            <i class="icon-bookmark"></i>
            Add to a dataset
          </a>
          <a
            :disabled="!canCreate || null"
            class="dropdown-item"
            v-on:click="addClassificationModal = canCreate"
            :title="
              canCreate ? 'Add a classification to all selected elements.' : createDisabledTitle
            "
          >
            <i class="icon-plus"></i>
            Add classification
          </a>
          <a
            :disabled="!canCreate || allValidated || null"
            class="dropdown-item"
            v-on:click="validateClassificationModal = canCreate && !allValidated"
            :title="
              canCreate && !allValidated
                ? 'Validate the classification for all selected elements.'
                : createDisabledTitle
            "
          >
            <i class="icon-check"></i>
            Validate classification
          </a>
          <a
            :disabled="!canExecute || null"
            class="dropdown-item has-text-danger"
            v-on:click="deleteResultsModal = canExecute"
            :title="
              canExecute
                ? 'Delete worker results on the selected elements and their children.'
                : executeDisabledTitle
            "
          >
            <i class="icon-trash"></i>
            Delete worker results
          </a>
          <a
            :disabled="!canExecute || null"
            class="dropdown-item has-text-danger"
            v-on:click="deleteModal = canExecute"
            :title="canExecute ? 'Delete this complete selection.' : executeDisabledTitle"
          >
            <i class="icon-trash"></i>
            Delete selected elements
          </a>
        </div>
      </div>
    </div>

    <Modal
      allow-overflow
      v-model="addClassificationModal"
      title="Add classification"
    >
      <div
        class="control"
        v-if="hasMLClasses[corpusId]"
      >
        <MLClassSelect
          :corpus-id="corpusId"
          v-model="selectedNewClassification"
          placeholder="ML class"
          v-on:submit="createClassification"
        />
      </div>
      <div
        v-else
        class="notification is-warning"
      >
        No classification for this project
      </div>
      <template v-slot:footer="{ close }">
        <button
          class="button"
          v-on:click="close"
        >
          Cancel
        </button>
        <button
          class="button is-primary"
          :class="{ 'is-loading': isSavingNewClassification }"
          :disabled="isSavingNewClassification || !selectedNewClassification || undefined"
          v-on:click="createClassification"
        >
          <span v-if="!isSavingNewClassification">Add</span>
        </button>
      </template>
    </Modal>

    <Modal
      v-model="validateClassificationModal"
      title="Validate classification"
    >
      <div>
        Are you sure you want to validate the classifications for the selection of the
        {{ corpus.name }} project ?
      </div>
      <template v-slot:footer="{ close }">
        <button
          class="button"
          v-on:click="close"
        >
          Cancel
        </button>
        <button
          class="button is-success"
          v-on:click.prevent="validateClassification"
        >
          Validate
        </button>
      </template>
    </Modal>

    <Modal
      v-model="deleteModal"
      :title="'Delete selected elements'"
    >
      <p>
        Are you sure you want to delete all selected elements on project
        <strong>{{ corpus.name }}</strong
        >?<br />
        Child elements on those elements will also be deleted recursively.<br />
        This action is irreversible.
      </p>
      <template v-slot:footer="{ close }">
        <button
          class="button"
          v-on:click="close"
        >
          Cancel
        </button>
        <button
          class="button is-danger"
          :class="{ 'is-loading': deleteLoading }"
          v-on:click="performDelete"
        >
          Delete
        </button>
      </template>
    </Modal>

    <Modal
      v-model="createParentModal"
      title="Link selected elements to another parent"
    >
      <FolderPicker
        v-model="pickedFolder"
        :corpus-id="corpusId"
        :exclude="selectedElementIds"
      />
      <template v-slot:footer="{ close }">
        <button
          class="button"
          v-on:click="close"
        >
          Cancel
        </button>
        <button
          class="button is-primary"
          :class="{ 'is-loading': createParentLoading }"
          :disabled="!pickedFolder || undefined"
          v-on:click="performCreateParent"
        >
          Link elements
        </button>
      </template>
    </Modal>

    <Modal
      v-model="moveSelectionModal"
      title="Move selected elements"
    >
      <FolderPicker
        v-model="pickedFolder"
        :corpus-id="corpusId"
        :exclude="selectedElementIds"
      />
      <span>
        Moving elements will remove all links towards pre-existing (direct) parents.
        <br />
        It will also move all their children elements and all attached transcriptions, entities, and
        classifications. All current paths will be replaced by the newly created paths.
        <br />
        This action is irreversible.
      </span>
      <template v-slot:footer="{ close }">
        <button
          class="button"
          v-on:click="close"
        >
          Cancel
        </button>
        <button
          class="button is-primary"
          :class="{ 'is-loading': moveLoading }"
          :disabled="!pickedFolder || undefined"
          v-on:click="performMove"
        >
          Move elements
        </button>
      </template>
    </Modal>

    <DeleteResultsModal
      v-model="deleteResultsModal"
      :corpus-id="corpusId"
      selection
    />

    <DatasetFromSelectionModal
      v-model="datasetSelectionModal"
      :corpus-id="corpusId"
    />

    <ElementList
      :elements="elements"
      max-size
    />
  </div>
</template>

<script lang="ts">
import { mapActions, mapState, mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import ExportProcessModal from '@/components/ExportProcessModal.vue'
import MLClassSelect from '@/components/MLClassSelect.vue'
import Modal from '@/components/Modal.vue'
import DeleteResultsModal from '@/components/Process/Workers/DeleteResultsModal.vue'
import { EXPORT_FORMATS } from '@/config'
import { createProcessRedirect } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAuthStore, useClassificationStore, useElementStore, useSelectionStore } from '@/stores'
import type { ElementLight, UUID } from '@/types'

import DatasetFromSelectionModal from './DatasetFromSelectionModal.vue'
import ElementList from './ElementList.vue'
import FolderPicker from './FolderPicker'

export default defineComponent({
  mixins: [corporaMixin, truncateMixin],
  components: {
    ElementList,
    DeleteResultsModal,
    MLClassSelect,
    Modal,
    FolderPicker,
    DatasetFromSelectionModal,
    ExportProcessModal,
  },
  props: {
    corpusId: {
      type: String as PropType<UUID>,
      required: true,
    },
  },
  data: () => ({
    selectedNewClassification: '',
    isSavingNewClassification: false,
    addClassificationModal: false,
    validateClassificationModal: false,
    allValidated: false,
    deleteModal: false,
    deleteLoading: false,
    deleteResultsModal: false,
    moveSelectionModal: false,
    moveLoading: false,
    createParentModal: false,
    createParentLoading: false,
    pickedFolder: null as ElementLight | null,
    datasetSelectionModal: false,
    EXPORT_FORMATS,
  }),
  mounted() {
    if (this.hasMLClasses[this.corpusId] === undefined) {
      this.listCorpusMLClasses(this.corpusId, { page_size: 1 })
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isVerified']),
    ...mapState(useElementStore, { storeElements: 'elements' }),
    ...mapState(useClassificationStore, ['hasMLClasses']),
    ...mapStores(useSelectionStore),
    canCreate(): boolean {
      return this.corpus !== null && this.isVerified && this.canWrite(this.corpus)
    },
    canExecute(): boolean {
      return this.corpus !== null && this.isVerified && this.canAdmin(this.corpus)
    },
    canExport(): boolean {
      return this.corpus !== null && this.isVerified && this.canAdmin(this.corpus)
    },
    createDisabledTitle(): string {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.canCreate)
        prefix = 'You must have a contributor access to this project in order'
      else if (this.allValidated) return 'All elements have already been validated'
      return `${prefix} to perform this action.`
    },
    executeDisabledTitle(): string {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.canExecute) prefix = 'You must have an admin access to this project in order'
      return `${prefix} to perform this action.`
    },
    elements() {
      return (
        (this.selectionStore.selection[this.corpusId] ?? [])
          .map((id) => this.storeElements[id])
          /*
           * Even if the element ID is in the selection store,
           * it might not be in the elements store, so we would get an `undefined`
           */
          .filter((element) => element)
      )
    },
    selectedElementIds(): UUID[] {
      return this.elements.map((item) => item.id)
    },
    hasContribPrivilege(): boolean {
      return this.isVerified && this.corpus !== null && this.canWrite(this.corpus)
    },
  },
  methods: {
    ...mapActions(useClassificationStore, ['listCorpusMLClasses']),
    createProcess() {
      if (!this.canCreate) return
      const payload = { corpus: this.corpusId, selection: true }
      createProcessRedirect(this.$router, payload)
    },
    async createClassification() {
      this.isSavingNewClassification = true
      try {
        await this.selectionStore.createClassifications(
          this.corpusId,
          this.selectedNewClassification,
        )
      } finally {
        this.selectionStore.get()
        this.selectedNewClassification = ''
        this.isSavingNewClassification = false
        this.addClassificationModal = false
      }
    },
    async validateClassification() {
      try {
        await this.selectionStore.validateClassifications(this.corpusId)
      } finally {
        this.validateClassificationModal = false
        this.allValidated = true
      }
    },
    async performDelete() {
      if (!this.canExecute) return
      this.deleteLoading = true
      try {
        await this.selectionStore.deleteElements(this.corpusId)
        this.deleteModal = false
      } finally {
        this.deleteLoading = false
      }
    },
    async performMove() {
      if (!this.hasContribPrivilege || !this.pickedFolder) return
      this.moveLoading = true
      try {
        await this.selectionStore.move(this.corpusId, this.pickedFolder.id)
        this.moveSelectionModal = false
      } finally {
        this.moveLoading = false
      }
    },
    async performCreateParent() {
      if (!this.hasContribPrivilege || !this.pickedFolder) return
      this.createParentLoading = true
      try {
        await this.selectionStore.createParent(this.corpusId, this.pickedFolder.id)
        this.createParentModal = false
      } finally {
        this.createParentLoading = false
      }
    },
  },
})
</script>

<style scoped>
.dropdown-menu {
  min-width: 0em;
}
a.dropdown-item[disabled] {
  color: lightgray !important;
  cursor: not-allowed;
}
</style>
