<template>
  <Modal
    :model-value="modelValue"
    :title="'Remove ' + truncateShort(typeName(element.type)) + ' ' + truncateShort(element.name)"
    v-on:update:model-value="updateModelValue"
  >
    <p>
      Are you sure you want to remove
      <span :title="typeName(element.type)">
        {{ truncateShort(typeName(element.type)) }}
        <!-- The closing </span> and opening <span> must be on the same line for the space to work -->
      </span>
      <span :title="element.name"
        ><strong> {{ truncateShort(element.name) }}</strong></span
      >
      from set
      <span :title="set"
        ><strong>{{ truncateShort(set) }}</strong></span
      >
      in dataset
      <span :title="dataset.name"
        ><strong>{{ truncateShort(dataset.name) }}</strong></span
      >?
    </p>
    <template v-slot:footer="{ close }">
      <button
        class="button"
        v-on:click.prevent="close"
      >
        Cancel
      </button>
      <button
        class="button is-danger"
        :class="{ 'is-loading': loading }"
        :disabled="!canRemove || undefined"
        v-on:click.prevent="performRemove"
      >
        Remove
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import Modal from '@/components/Modal.vue'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAuthStore, useDatasetStore, useNotificationStore } from '@/stores'
import type { Element, ElementBase } from '@/types'
import type { Dataset } from '@/types/dataset'

export default defineComponent({
  components: {
    Modal,
  },
  emits: {
    'update:modelValue': (value: boolean) => typeof value === 'boolean',
  },
  mixins: [corporaMixin, truncateMixin],
  props: {
    // The element to delete.
    element: {
      type: Object as PropType<ElementBase | Element>,
      required: true,
    },
    dataset: {
      type: Object as PropType<Dataset>,
      required: true,
    },
    set: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState(useAuthStore, ['isVerified']),
    // Required for the corporaMixin
    corpusId() {
      return this.element.corpus?.id
    },
    /**
     * Allow deleting elements only if the user is verified and has contributor rights on the corpus, and
     * the dataset is in the "open" state.
     */
    canRemove() {
      return (
        !this.loading &&
        this.corpus &&
        this.isVerified &&
        this.canWrite(this.corpus) &&
        this.dataset.state === 'open'
      )
    },
  },
  methods: {
    ...mapActions(useDatasetStore, ['removeDatasetElement']),
    ...mapActions(useNotificationStore, ['notify']),
    updateModelValue(value: boolean) {
      this.$emit('update:modelValue', value)
    },
    async performRemove() {
      if (!this.canRemove) return
      this.loading = true
      try {
        await this.removeDatasetElement(this.dataset.id, this.element.id, this.set)
        this.updateModelValue(false)
      } finally {
        this.loading = false
      }
    },
  },
})
</script>
