<template>
  <div>
    <div
      v-if="!element || !corpusId"
      class="loading-content loader"
    ></div>
    <template v-else>
      <template v-if="!elementType?.folder">
        <OrientationPanel :element-id="elementId" />
        <hr />
      </template>

      <template
        v-if="(canWriteElement(elementId) && hasMLClasses[corpusId]) || classifications.length"
      >
        <DropdownContent
          id="classifications"
          title="Classifications"
        >
          <Classifications
            v-if="classifications"
            :element="element"
          />
          <form v-on:submit.prevent="createClassification">
            <div
              v-if="canWriteElement(elementId) && hasMLClasses"
              class="field"
            >
              <p class="control is-expanded">
                <MLClassSelect
                  ref="newClassificationSelect"
                  v-model="selectedNewClassification"
                  v-model:is-valid="validClassification"
                  placeholder="Add a classification"
                  exclude-manual
                  auto-select
                  :classifications="classifications"
                  :corpus-id="corpusId"
                />
              </p>
            </div>
            <p
              class="help is-danger"
              v-if="manualClassificationExists"
            >
              A manual classification for this ML class already exists.
            </p>
          </form>
        </DropdownContent>
        <hr />
      </template>

      <DropdownContent
        id="transcriptions"
        title="Transcriptions"
      >
        <Transcriptions :element="element" />
        <template v-if="canWriteElement(elementId)">
          <div class="has-text-right">
            <a v-on:click="transcriptionModal = true">
              <button class="button has-text">manage</button>
            </a>
          </div>
          <TranscriptionsModal
            v-if="transcriptionModal"
            v-model:modal="transcriptionModal"
            :element="element"
          />
        </template>
      </DropdownContent>
      <hr />
      <template v-if="canWriteElement(elementId)">
        <DropdownContent
          id="new-transcription"
          title="New transcription"
        >
          <TranscriptionCreationForm :element="element" />
        </DropdownContent>
        <hr />
      </template>

      <DropdownContent
        id="metadata"
        title="Metadata"
      >
        <ElementMetadata
          :corpus-id="corpusId"
          :element-id="element.id"
        />
      </DropdownContent>
      <hr />

      <DropdownContent
        id="datasets"
        title="Datasets"
      >
        <ElementDatasets
          :corpus-id="corpusId"
          :element="element"
        />
      </DropdownContent>
    </template>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState, mapStores } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import DropdownContent from '@/components/DropdownContent.vue'
import Classifications from '@/components/Element/Classifications'
import ElementDatasets from '@/components/Element/Datasets/ElementDatasets.vue'
import ElementMetadata from '@/components/Element/Metadata'
import OrientationPanel from '@/components/Element/OrientationPanel.vue'
import Transcriptions from '@/components/Element/Transcription'
import TranscriptionCreationForm from '@/components/Element/Transcription/CreationForm.vue'
import TranscriptionsModal from '@/components/Element/Transcription/Modal.vue'
import MLClassSelect from '@/components/MLClassSelect.vue'
import { UUID_REGEX } from '@/config'
import { errorParser } from '@/helpers'
import { corporaMixin } from '@/mixins'
import { useClassificationStore, useElementStore, useNotificationStore } from '@/stores'
import type { Classification, Element, ElementBase, ElementType, UUID } from '@/types'
import type { ElementDatasetSet } from '@/types/dataset'

export default defineComponent({
  mixins: [corporaMixin],
  components: {
    Classifications,
    DropdownContent,
    ElementMetadata,
    MLClassSelect,
    OrientationPanel,
    TranscriptionCreationForm,
    Transcriptions,
    TranscriptionsModal,
    ElementDatasets,
  },
  props: {
    /**
     * Id of the element
     */
    elementId: {
      type: String as PropType<UUID>,
      validator: (value) => typeof value === 'string' && UUID_REGEX.test(value),
      required: true,
    },
  },
  data: () => ({
    selectedNewClassification: '',
    validClassification: null,
    isSavingNewClassification: false,
    transcriptionModal: false,
  }),
  computed: {
    ...mapStores(useClassificationStore),
    ...mapState(useElementStore, ['elements', 'elementDatasetSets']),
    ...mapState(useClassificationStore, {
      classes: 'classes',
      hasMLClasses: 'hasMLClasses',
      allClassifications: 'classifications',
    }),
    ...mapState(useElementStore, {
      // canWrite and canAdmin are already defined in corporaMixin
      canWriteElement: 'canWrite',
      canAdminElement: 'canAdmin',
    }),
    element(): Element | ElementBase {
      return this.elements[this.elementId]
    },
    corpusId(): UUID | null {
      return this.element?.corpus?.id ?? null
    },
    elementType(): ElementType | null {
      return this.element ? this.getType(this.element.type) : null
    },
    manualClassificationExists() {
      // A manual classification with the selected ML class exists; cannot create
      return (
        this.selectedNewClassification &&
        this.element &&
        this.classifications &&
        this.classifications.find(
          (c) => c.ml_class.id === this.selectedNewClassification && !c.worker_run,
        )
      )
    },
    classifications(): Classification[] {
      return this.allClassifications[this.elementId] ?? []
    },
    canCreateClassification() {
      return (
        this.element &&
        this.selectedNewClassification &&
        !this.manualClassificationExists &&
        !this.isSavingNewClassification
      )
    },
    datasets(): ElementDatasetSet[] | null {
      return this.elementDatasetSets?.[this.elementId] ?? null
    },
  },
  methods: {
    ...mapActions(useElementStore, {
      retrieveElement: 'get',
      listElementDatasetSets: 'listElementDatasetSets',
    }),
    ...mapActions(useNotificationStore, ['notify']),
    async createClassification() {
      if (!this.canCreateClassification) return
      this.isSavingNewClassification = true
      try {
        const newMLClass = this.classes[this.selectedNewClassification]
        await this.classificationStore.create(this.elementId, newMLClass.id)
      } catch (err) {
        this.notify({ type: 'error', text: errorParser(err) })
      } finally {
        this.selectedNewClassification = ''
        this.isSavingNewClassification = false
      }
    },
    async retrieve(elementId: UUID) {
      if (!elementId || elementId === undefined) return
      if (
        !this.element ||
        this.element.id !== elementId ||
        !('rights' in this.element) ||
        !this.allClassifications[elementId]
      )
        await this.retrieveElement(elementId)
    },
    async getDatasetSets(elementId: UUID) {
      if (!elementId || elementId === undefined) return
      if (!Array.isArray(this.elementDatasetSets[elementId]))
        await this.listElementDatasetSets(elementId, { with_neighbors: true })
    },
  },
  watch: {
    elementId: {
      immediate: true,
      handler(value) {
        this.retrieve(value)
        this.getDatasetSets(value)
      },
    },
    classifications() {
      this.retrieve(this.elementId)
    },
    selectedNewClassification() {
      this.createClassification()
    },
  },
})
</script>

<style scoped>
.loading-content {
  font-size: 2.5rem;
  margin: 2.5rem auto 0 auto;
}
</style>
