<template>
  <div class="field is-grouped mt-1 mb-0">
    <div class="control mr-1">
      <input
        class="input"
        type="text"
        v-model="setName"
        :class="{ 'is-danger': updateError.name }"
        :disabled="!hasContribPrivilege || dataset.state !== 'open'"
      />
    </div>
    <div class="control mr-1">
      <button
        class="button has-text-primary"
        :class="{ 'is-loading': loading }"
        :disabled="loading || !canUpdate || undefined"
        v-on:click="setUpdate"
        :title="updateTitle"
      >
        <i class="icon-edit"></i>
      </button>
    </div>
    <div class="control">
      <button
        class="button has-text-danger"
        :class="{ 'is-loading': loading }"
        :disabled="loading || !canDelete || undefined"
        v-on:click="setDelete"
        :title="deleteTitle"
      >
        <i class="icon-trash"></i>
      </button>
    </div>
  </div>
  <template v-if="updateError.name">
    <p
      v-for="(err, i) in updateError.name"
      :key="i"
      class="help has-text-danger"
    >
      {{ err }}
    </p>
  </template>
</template>

<script lang="ts">
import { isAxiosError } from 'axios'
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import { corporaMixin } from '@/mixins'
import { useAuthStore, useDatasetStore, useNotificationStore } from '@/stores'
import type { UUID } from '@/types'
import type { Dataset, DatasetSet } from '@/types/dataset'

export default defineComponent({
  mixins: [corporaMixin],
  props: {
    datasetSet: {
      type: Object as PropType<DatasetSet>,
      required: true,
    },
    dataset: {
      type: Object as PropType<Dataset>,
      required: true,
    },
    // Used to reset the set name field when the modal is closed
    modalOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    setName: '',
    updateError: { name: '' },
  }),
  mounted() {
    this.setName = this.datasetSet.name
  },
  computed: {
    ...mapState(useAuthStore, ['isVerified']),
    hasContribPrivilege(): boolean {
      return this.isVerified && this.canWrite(this.corpus)
    },
    hasAdminPrivilege(): boolean {
      return this.isVerified && this.canAdmin(this.corpus)
    },
    canUpdate(): boolean {
      return (
        this.datasetSet.name !== this.setName &&
        this.hasContribPrivilege &&
        this.dataset.state === 'open'
      )
    },
    canDelete(): boolean {
      return this.hasAdminPrivilege && this.dataset.state === 'open' && this.dataset.sets.length > 1
    },
    corpusId(): UUID {
      return this.dataset.corpus_id
    },
    updateTitle(): string | undefined {
      if (this.dataset.state !== 'open') return 'Sets can only be edited on open datasets'
      else if (!this.hasContribPrivilege)
        return "You must be a contributor on the project to edit a dataset's sets"
      else if (this.canUpdate) return 'Update set name'
      return undefined
    },
    deleteTitle(): string {
      if (this.dataset.state !== 'open') return 'Sets can only be deleted on open datasets'
      else if (!this.hasAdminPrivilege)
        return 'You must be an administrator on the project to delete dataset sets'
      else if (this.dataset.sets.length <= 1)
        return 'This is the only set in the dataset and cannot be deleted'
      return 'Delete set'
    },
  },
  methods: {
    ...mapActions(useDatasetStore, ['updateDatasetSet', 'deleteDatasetSet']),
    ...mapActions(useNotificationStore, ['notify']),
    async setUpdate() {
      if (!this.canUpdate) return
      this.loading = true
      try {
        await this.updateDatasetSet(this.dataset.id, this.datasetSet.id, this.setName)
        this.notify({ type: 'success', text: 'Dataset set updated' })
        this.updateError.name = ''
      } catch (err) {
        if (
          isAxiosError(err) &&
          err.response?.status === 400 &&
          err.response.data &&
          'name' in err.response.data
        ) {
          this.updateError.name = err.response.data.name
        }
      } finally {
        this.loading = false
      }
    },
    async setDelete() {
      this.loading = true
      if (!this.canDelete) return
      // Errors are handled (notification) in the store
      try {
        await this.deleteDatasetSet(this.dataset.id, this.datasetSet.id)
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    modalOpen(newValue: boolean) {
      // Reset set name field and errors when the modal is closed
      if (newValue === false) {
        this.setName = this.datasetSet.name
        this.updateError.name = ''
      }
    },
  },
})
</script>
