import axios from 'axios'

import { unique } from '@/helpers/api'
import type { ElementTiny, Image, PageNumberPagination, UUID } from '@/types'

import type { PageNumberPaginationParameters } from '.'

/**
 * Retrieve an image's details.
 * @param id UUID of the image.
 */
export const retrieveImage = unique(async (id: UUID) => await axios.get<Image>(`/image/${id}/`))

export interface ImageElementsParameters extends PageNumberPaginationParameters {
  /**
   * ID of the image to find elements on.
   */
  id: UUID

  /**
   * Filter by folder or non-folder types.
   */
  folder?: boolean

  /**
   * Filter by element type slug.
   */
  type?: string
}

/**
 * List all elements on an image.
 */
export const listImageElements = unique(
  async ({ id, ...params }: ImageElementsParameters): Promise<PageNumberPagination<ElementTiny>> =>
    (await axios.get(`/image/${id}/elements/`, { params })).data,
)
