<template>
  <div class="card">
    <router-link
      :to="{ name: 'element-details', params: { id: element.id }, query: routeQuery }"
      :title="`Navigate to ${element.name}`"
    >
      <div class="card-image">
        <figure class="image">
          <img
            src="@/assets/missingPreview.jpg"
            v-if="!thumbnailUrl || imgFailed"
          />
          <img
            :src="thumbnailUrl"
            loading="lazy"
            v-on:error="imgFailed = true"
            v-else
          />
        </figure>
      </div>
      <div
        class="card-content"
        v-if="!compactDisplay"
      >
        <span class="name">{{ truncateLong(element.name) }}</span>
        <template v-if="sortedClasses !== null">
          <span class="tags is-pulled-right">
            <span
              class="tag is-warning"
              v-for="classification in sortedClasses"
              :key="classification.id"
              :title="classification.ml_class.name"
            >
              {{ truncateShort(classification.ml_class.name) }}
            </span>
          </span>
          <span class="is-clearfix"></span>
        </template>
      </div>
    </router-link>
    <div class="card-footer">
      <span
        class="type"
        :title="elementTypeName"
        >{{ truncateShort(elementTypeName) }}</span
      >
      <DeleteModal
        v-if="!disabled"
        :target="element"
      >
        <template v-slot:default="{ open, canDelete }">
          <button
            :title="
              canDelete
                ? 'Delete this element'
                : 'You do not have the required rights to delete this element'
            "
            :class="canDelete ? 'has-text-danger' : 'has-text-grey'"
            class="button"
            v-on:click.prevent="open"
          >
            <i class="icon-trash"></i>
          </button>
        </template>
      </DeleteModal>
      <template v-if="hasPreview">
        <PreviewDropdown :element-id="element.id">
          <!-- Redefine the dropdown button here to allow the scoped style to remove the borders -->
          <button
            title="Preview this element on its image"
            class="button"
            type="button"
          >
            <i class="icon-eye"></i>
          </button>
        </PreviewDropdown>
      </template>
      <button
        v-if="dataset"
        :title="
          dataset.state === 'open'
            ? 'Remove this element from dataset'
            : 'Only open datasets can be edited'
        "
        class="button has-text-danger"
        type="button"
        :disabled="dataset.state !== 'open'"
        v-on:click="removeElementModal = true"
      >
        <i class="icon-remove-square"></i>
      </button>
      <RemoveModal
        v-if="dataset && set && dataset.state === 'open'"
        v-model="removeElementModal"
        :dataset="dataset"
        :set="set.name"
        :element="element"
      />
      <button
        v-if="isVerified && hasFeature('selection') && !disabled"
        title="Add this element to the selection"
        class="button"
        :class="{ 'is-success': selected }"
        v-on:click.prevent="toggleSelection"
        :disabled="!isVerified || undefined"
      >
        <i class="icon-check"></i>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { type PropType, defineComponent } from 'vue'

import RemoveModal from '@/components/Dataset/RemoveModal.vue'
import DeleteModal from '@/components/SingleDeletionModal.vue'
import { iiifUri } from '@/helpers'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useAuthStore, useClassificationStore, useDisplayStore, useSelectionStore } from '@/stores'
import type { ElementBase } from '@/types'
import type { Dataset, DatasetSet } from '@/types/dataset'

import PreviewDropdown from './PreviewDropdown.vue'

export default defineComponent({
  mixins: [truncateMixin, corporaMixin],
  components: {
    DeleteModal,
    PreviewDropdown,
    RemoveModal,
  },
  props: {
    element: {
      type: Object as PropType<ElementBase>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataset: {
      type: Object as PropType<Dataset | null>,
      default: null,
    },
    set: {
      type: Object as PropType<DatasetSet | null>,
      default: null,
    },
  },
  data: () => ({
    imgFailed: false,
    removeElementModal: false,
  }),
  computed: {
    ...mapState(useSelectionStore, ['selection']),
    ...mapState(useDisplayStore, ['displayElementClasses', 'compactDisplay', 'iiifHeight']),
    ...mapState(useAuthStore, ['isVerified', 'hasFeature']),
    ...mapState(useClassificationStore, ['classifications']),
    thumbnailUrl() {
      if (this.element.thumbnail_url) return this.element.thumbnail_url
      if (
        this.element.zone &&
        this.element.zone.image.width > 0 &&
        this.element.zone.image.height > 0
      ) {
        return iiifUri(this.element.zone, { height: this.iiifHeight(1 / 4) })
      }
      return null
    },
    corpusId() {
      return this.element.corpus?.id || null
    },
    elementType() {
      if (this.element.type) return this.getType(this.element.type)
      return null
    },
    elementTypeName() {
      return this.elementType?.display_name ?? this.elementType?.slug ?? this.element.type ?? ''
    },
    selected(): boolean {
      return this.corpusId !== null && this.selection[this.corpusId]?.includes(this.element.id)
    },
    sortedClasses() {
      if (!this.displayElementClasses || !this.classifications[this.element.id]) return []
      const classes = [...this.classifications[this.element.id]]
      classes.sort((a, b) => a.ml_class.name.localeCompare(b.ml_class.name))
      return classes
    },
    /**
     * The backend generates thumbnail URLs for all folder elements, or for non-folder elements with a zone.
     * Since most folder elements do not have a zone anyway, we only display the preview modal for non-folder
     * elements with a thumbnail URL.
     */
    hasPreview() {
      return !this.elementType?.folder && this.element.thumbnail_url
    },
    routeQuery() {
      // Add extra information to the route when navigating among elements
      const eltId = this.$route?.params?.id
      if (!eltId) return {}
      return { from: eltId }
    },
  },
  methods: {
    ...mapActions(useClassificationStore, ['retrieveElementClassifications']),
    ...mapActions(useSelectionStore, ['select', 'unselect']),
    toggleSelection() {
      if (this.element.corpus === null) return
      // This pleases TypeScript by making it understand that the corpus is not null on this element
      const element = { ...this.element, corpus: { ...this.element.corpus } }
      if (this.selected) this.unselect(element)
      else this.select([element])
    },
  },
})
</script>

<style scoped>
samp {
  color: lightgray;
  font-size: 50%;
}
.type {
  color: lightgray;
  overflow-wrap: anywhere;
  margin: 0 auto 0 0.5rem;
}
.name,
.corpus-name {
  white-space: nowrap;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.view {
  width: 100%;
}
.card-content {
  color: #4a4a4a;
}
.card-footer {
  align-items: center;
}
.image img {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  max-height: 330px;
}
.button {
  border-width: 0em;
}
.tags {
  margin-top: 0.5rem;
  /*
   * .tag has a margin-bottom of 0.5rem to separate many tags on multiple lines.
   * This cancels the margin on the last line, since we only want a separation
   * between multiple lines of tags, not below the tags.
   */
  margin-bottom: -0.5rem;
}
</style>
